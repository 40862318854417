import React from 'react';
import { PageHeader, Tabs } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import OngoingOrders from './OngoingOrders';
import CompletedOrders from './CompletedOrders';
import DroppedOrders from './DroppedOrders';
import BackIcon from 'components/UtilComponents/BackIcon';

const Orders = () => {
  const { tab } = useParams();
  const navigate = useNavigate();

  const tabItems = [
    // remember to pass the key prop
    {
      label: 'Ongoing',
      key: 'ongoing-orders',
      children: <OngoingOrders tab={tab} />,
    },
    {
      label: 'Completed',
      key: 'completed-orders',
      children: <CompletedOrders tab={tab} />,
    },
    {
      label: 'Dropped',
      key: 'dropped-orders',
      children: <DroppedOrders tab={tab} />,
    },
  ];

  return (
    <div className="app-container">
      <PageHeader onBack={() => navigate('/buy')} backIcon={<BackIcon />} title="Orders" className="px-0" />

      <Tabs type="line" items={tabItems} defaultActiveKey={tab} onChange={(key) => navigate(`/buy/orders/${key}`)} />
    </div>
  );
};

export default Orders;
