import React from 'react';
import { Avatar, Row, Typography } from 'antd';
import VerifiedIcon from 'assets/images/icon-verified.png';
import NotVerifiedIcon from 'assets/images/icon-not-verified.png';
import CompanyLogo from 'assets/images/dummy-company.png';

const CompanyName = ({ showLogo = false, logoSize = 20, name, verified = false }) => {
  return (
    <Row align="middle">
      <Typography.Text strong style={{ whiteSpace: 'nowrap' }}>
        {showLogo && <Avatar src={CompanyLogo /* showLogo */} shape="circle" size={logoSize} className="mr-1" />}
        {name}
        {verified ? <Avatar src={VerifiedIcon} shape="circle" size={16} className="ml-1" /> : <Avatar src={NotVerifiedIcon} shape="circle" size={14} className="ml-1" />}
      </Typography.Text>
    </Row>
  );
};

export default CompanyName;
