import { Avatar, Button, Card, Col, Collapse, Divider, message, Modal, PageHeader, Rate, Row, Steps, Tag, Typography } from 'antd';
import axios from 'axios';
import { DISPATCH_URL, UPLOAD_URL } from 'constants/ApiConstants';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
// import { Colors } from 'constants/ColorConstants';
import DetailsGrid from 'components/UtilComponents/DetailsGrid';
import { format } from 'date-fns';
import BackIcon from 'components/UtilComponents/BackIcon';
import { isEmpty } from 'lodash';
import Loader from 'components/UtilComponents/Loader';
import { MdCircle } from 'react-icons/md';
import { formatRefNo } from 'utils/formatter';
import { PageNotFound } from 'components/Errors';
import ModalCloseIcon from 'components/UtilComponents/ModalCloseIcon';

const { Title, Paragraph } = Typography;

const MySellDispatch = () => {
  const id = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [value, setValue] = useState(null);
  const [ratingValue, setRatingValue] = useState(false);
  const [dispatch, setDispatch] = useState(null);
  const [notFound, setNotFound] = useState(false);
  const [cancelLoading, setCancelLoading] = useState(false);
  // const color = dispatch?.status === 'Accepted' ? '#0A8A85' : ['Cancelled', 'Rejected'].includes(dispatch?.status) ? '#ff4d4f' : Colors.primary;

  useEffect(() => {
    onLoadDispatch();
  }, [id]);

  const onLoadDispatch = async () => {
    setLoading(true);
    try {
      const dispatchRes = await axios.get(`${DISPATCH_URL}/${id.orderId}?populate=true`);
      if (dispatchRes.data.code === 1006 || dispatchRes.data.code === 1007) {
        setNotFound(true);
        return setLoading(false);
      }
      if (dispatchRes.data.status === 'error') {
        message.error(dispatchRes.data.error);
      } else {
        setDispatch(dispatchRes.data.dispatch);
        setValue(dispatchRes.data.dispatch.metadata.dispatchRating);
        if (dispatchRes.data.dispatch.metadata.dispatchRating) setRatingValue(true);
      }
    } catch (error) {
      message.error(error.message);
    }
    setLoading(false);
  };

  const handleRating = async () => {
    const ratingResponse = await axios.put(`${DISPATCH_URL}/${dispatch._id}`, {
      metadata: {
        dispatchRating: value,
      },
    });
    if (ratingResponse.status === 'error') {
      message.error(ratingResponse.data.error);
    } else {
      message.success('Rating updated successfully');
      setRatingValue(true);
    }
  };

  const transitUpdates = dispatch?.transitUpdates?.map((transitUpdate) => ({
    title: transitUpdate?.status,
    description: format(new Date(transitUpdate?.updatedOn), 'dd MMM yyyy hh:mm a'),
    icon: <MdCircle />,
  }));

  const onCancelDispatch = (dispatch) => {
    Modal.confirm({
      title: `Are you sure you want to cancel the dispatch?`,
      content: `E-Way Bill No: ${dispatch?.purchaseBillNo}`,
      bodyStyle: { backgroundColor: '#fff', borderRadius: 10 },
      icon: null,
      closable: true,
      closeIcon: <ModalCloseIcon />,
      cancelText: 'No',
      cancelButtonProps: {
        type: 'primary',
        ghost: true,
      },
      onCancel() {},
      okText: 'Yes',
      okButtonProps: {
        loading: cancelLoading,
      },
      onOk: async () => {
        if (cancelLoading) return;
        setCancelLoading(true);
        try {
          const res = await axios.put(`${DISPATCH_URL}/${dispatch?._id}`, {
            status: 'Cancelled',
            transitStatus: 'Goods Cancelled',
          });

          if (res.data.status === 'error') {
            message.error(res.data.error);
          } else {
            message.success('Dispatch has been Cancelled');
            onLoadDispatch();
          }
        } catch (err) {
          message.error(err.message);
        }
        setCancelLoading(false);
      },
    });
  };

  if (loading) return <Loader />;

  if (notFound) return <PageNotFound />;

  return (
    <>
      <div className="app-container">
        <PageHeader
          onBack={() => navigate(-1)}
          backIcon={<BackIcon />}
          title={`${formatRefNo(dispatch?.dispatchRefNo, 'BFD')} | ${dispatch?.product?.name}`}
          className="px-0"
          extra={
            ['In Preparation', 'Dispatched'].includes(dispatch?.status) &&
            ['Awaiting Loading', 'Loaded', 'In Transit', 'Awaiting Unloading'].includes(dispatch?.transitStatus) && (
              <>
                <Button type="danger" ghost onClick={() => onCancelDispatch(dispatch)} className="mt-2 ml-2">
                  Cancel Dispatch
                </Button>
              </>
            )
          }
        />
      </div>
      <Divider className="my-2" />
      <div className="app-container">
        <Card className="d-flex justify-content-center mx-sm-4 my-4">
          <Row wrap={false} align="middle" justify="center" className="mb-4">
            <Title level={5} className="fw-bold mr-8">
              Tracking Details
            </Title>
            <Tag color="#1a75da" className="ml-3">
              {dispatch?.transitStatus}
            </Tag>
          </Row>
          <div className="d-flex justify-content-center align-items-center">
            <Steps current={transitUpdates?.length} direction="vertical" items={transitUpdates} />
          </div>
        </Card>
        <Card className="mx-sm-4 my-4">
          <Collapse defaultActiveKey={['order-details']} ghost expandIconPosition="end">
            <Collapse.Panel header={<Title level={5}>Dispatch Details</Title>} key="order-details">
              <Row gutter={[24, 24]}>
                <Col xs={24} sm={12} md={8} lg={8}>
                  <DetailsGrid icon={<Avatar src={require('assets/images/icon-quantity.png')} shape="square" size="small" />} title="Purchase Bill No:" description={dispatch?.purchaseBillNo} />
                </Col>
                <Col xs={24} sm={12} md={8} lg={8}>
                  <DetailsGrid
                    icon={<Avatar src={require('assets/images/dispatched-quantity.png')} shape="square" size="small" />}
                    title="Dispatched Quantity"
                    description={`${dispatch?.dispatchedQty} ${dispatch?.subUnit}`}
                  />
                </Col>
                {dispatch?.dispatchedOn && (
                  <Col xs={24} sm={12} md={8} lg={8}>
                    <DetailsGrid
                      icon={<Avatar src={require('assets/images/icon-calendar.png')} shape="square" size="small" />}
                      title="Dispatched Date"
                      description={`${format(new Date(dispatch?.dispatchedOn), 'dd MMM yyyy')}`}
                    />
                  </Col>
                )}
                <Col xs={24} sm={12} md={8} lg={8}>
                  <DetailsGrid
                    icon={<Avatar src={require('assets/images/nav-dispatch.png')} shape="square" size="small" />}
                    title="Vehicle Number"
                    description={dispatch?.vehicleNo || 'Yet to be Allocated'}
                  />
                </Col>
                <Col xs={24} sm={12} md={8} lg={8}>
                  <DetailsGrid
                    icon={<Avatar src={require('assets/images/icon-location-dark.png')} shape="square" size="small" />}
                    title="Dispatch Area Pincode"
                    description={dispatch?.dispatchFrom || '-'}
                  />
                </Col>
                {dispatch?.notes && (
                  <Col xs={24} sm={12} md={8} lg={8}>
                    <DetailsGrid icon={<Avatar src={require('assets/images/po-number.png')} shape="square" size="small" />} title="Notes" description={dispatch?.notes} />
                  </Col>
                )}
                <Col xs={24} sm={12} md={8} lg={8}>
                  <DetailsGrid
                    icon={<Avatar src={require('assets/images/icon-location-dark.png')} shape="square" size="small" />}
                    title="Pickup Address"
                    description={`${dispatch?.pickupAddress?.line1}, ${dispatch?.pickupAddress?.line2 ? dispatch?.pickupAddress?.line2 + ',' : ''} ${dispatch?.pickupAddress?.city}, ${
                      dispatch?.pickupAddress?.state
                    }, ${dispatch?.pickupAddress?.country} - ${dispatch?.pickupAddress?.pincode}`}
                  />
                </Col>
              </Row>
            </Collapse.Panel>
          </Collapse>
        </Card>
        {!isEmpty(dispatch?.purchase) && (
          <Card className="mx-sm-4 my-4">
            <Collapse defaultActiveKey={['attachments']} ghost expandIconPosition="end">
              <Collapse.Panel header={<Title level={5}>Attachment Details</Title>} key="attachments">
                {dispatch?.purchase?.invoice && (
                  <Button type="link" href={`${UPLOAD_URL}/${dispatch?.purchase?.invoice}`} className="pl-0" target="_blank">
                    <Avatar src={require('assets/images/dispatched-quantity.png')} shape="square" size="small" className="mr-2" />
                    Download Invoice
                  </Button>
                )}
                {dispatch?.purchase?.ewayBill && (
                  <Button type="link" href={`${UPLOAD_URL}/${dispatch?.purchase?.ewayBill}`} className="pl-0" target="_blank">
                    <Avatar src={require('assets/images/dispatched-quantity.png')} shape="square" size="small" className="mr-2" />
                    Download E-way Bill
                  </Button>
                )}
                {dispatch?.purchase?.weightSlip && (
                  <Button type="link" href={`${UPLOAD_URL}/${dispatch?.purchase?.weightSlip}`} className="pl-0" target="_blank">
                    <Avatar src={require('assets/images/dispatched-quantity.png')} shape="square" size="small" className="mr-2" />
                    Download Weight Slip
                  </Button>
                )}
                {dispatch?.purchase?.qualityReport && (
                  <Button type="link" href={`${UPLOAD_URL}/${dispatch?.purchase?.qualityReport}`} className="pl-0" target="_blank">
                    <Avatar src={require('assets/images/dispatched-quantity.png')} shape="square" size="small" className="mr-2" />
                    Download Quality Report
                  </Button>
                )}
                {dispatch?.purchase?.attachments?.length > 0 &&
                  dispatch?.purchase?.attachments.map((attachment, index) => {
                    return (
                      <Button type="link" href={`${UPLOAD_URL}/${attachment}`} className="pl-0" target="_blank">
                        <Avatar src={require('assets/images/dispatched-quantity.png')} shape="square" size="small" className="mr-2" />
                        Download Attachment #{index + 1}
                      </Button>
                    );
                  })}
              </Collapse.Panel>
            </Collapse>
          </Card>
        )}

        <Card className="d-flex justify-content-center align-items-center mx-sm-4 my-4">
          <div className="d-flex justify-content-center align-items-center mx-sm-4 my-4 ">
            <Title strong level={5} className="fw-bold mr-8">
              Rate your delivery experience
            </Title>
          </div>
          {!ratingValue ? (
            <>
              <Row justify="center">
                <Rate onChange={setValue} value={value} />
              </Row>
              <div className="mx-sm-4 my-4">
                <Paragraph>Tap the stars to rate us on a scale of 1 to 5. Your feedback is valuable!</Paragraph>
              </div>
              <div className="m-auto w-50">
                <Button type="primary" size="large" className="w-100" onClick={handleRating}>
                  Submit
                </Button>
              </div>
            </>
          ) : (
            <div style={{ textAlign: 'center', color: '#1A75DA' }}>
              <Row justify="center">
                <Rate value={value} />
              </Row>
              <div className="mx-sm-4 my-4">
                <p>Thank you for the {value}/5 rating! </p>
              </div>
              <div className="mx-sm-4 my-4">
                <p>Always improving for you. Your feedback matters.</p>
              </div>
            </div>
          )}
        </Card>
      </div>
    </>
  );
};

export default MySellDispatch;
