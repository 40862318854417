import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { List, Tag, message, Col, Button, Row, Drawer, Card, Radio, Divider, Space, Typography } from 'antd';
import axios from 'axios';
import { BUSINESS_URL, DISPATCH_URL } from 'constants/ApiConstants';
import EmptyContent from 'components/UtilComponents/EmptyContent';
import IconDispatch from 'assets/images/nav-dispatch.png';
import { getBreakPoint } from 'utils/layout';
import { find, isEmpty } from 'lodash';
import DispatchDetails from './DispatchDetails';
import { getAuth } from 'utils/auth';
import UpdateOrderStatus from 'components/Modals/Sell/UpdateOrderStatus';
import ContentLoader from 'react-content-loader';
import ModalCloseIcon from 'components/UtilComponents/ModalCloseIcon';
import { CloseCircleOutlined } from '@ant-design/icons';
import moment from 'moment';

const { Paragraph } = Typography;

const { CheckableTag } = Tag;

const UpcomingDispatch = ({ tab }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const showFilters = searchParams.get('filters') === 'ongoingActive';
  const [dispatchesLoading, setDispatchesLoading] = useState(false);
  const isMobile = !getBreakPoint().includes('md');
  const [type, setType] = useState('received');
  const [business, setBusiness] = useState(null);
  const [businessId, setBusinessId] = useState(null);
  const [dispatches, setDispatches] = useState(null);
  const [dispatchVisible, setDispatchVisible] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 8,
    total: 0,
    size: 'middle',
    showSizeChanger: false,
    className: isMobile && 'custom-pagination',
  });

  const [filters, setFilters] = useState({
    statuses: [],
  });
  const [sort, setSort] = useState({
    createdAt: 'desc',
  });

  useEffect(() => {
    onLoadBusiness();
  }, []);

  useEffect(() => {
    if (!isEmpty(businessId)) {
      if (tab === 'ongoing-dispatches') {
        onLoadDispatches();
      } else {
        setPagination({ ...pagination, current: 1 });
      }
    }
  }, [businessId, filters, sort, type, tab, pagination.current]);

  const onOpenDispatch = (reload) => {
    if (reload) {
      onLoadDispatches();
    }
    setDispatchVisible(!dispatchVisible);
  };

  const onLoadBusiness = async () => {
    setDispatchesLoading(true);
    try {
      const businessRes = await axios.get(`${BUSINESS_URL}/me?populate=true`);
      if (businessRes.data.status === 'error') {
        message.error(businessRes.data.error);
      } else {
        if (businessRes.data.business.businessType === 'seller') {
          setType('sent');
        }
      }
      setBusinessId(businessRes.data.business._id);
      setBusiness(businessRes.data.business);
    } catch (err) {
      message.error(err.message);
    }
  };

  const onLoadDispatches = async () => {
    setDispatchesLoading(true);
    try {
      let currentPage = pagination.current;
      let currentType = type;
      const localData = await JSON.parse(localStorage.getItem('filters'));
      if (!isEmpty(localData)) {
        currentPage = localData.current || 1;
        currentType = localData.type || type;
      }
      const xApiFilters = { transitStatus: ['Awaiting Loading', 'In Transit', 'Awaiting Unloading'] };
      const xApiSort = {
        createdAt: 'desc',
      };

      if (filters.statuses?.length > 0) {
        xApiFilters.transitStatus = filters.statuses;
      }

      if (filters.GRN) {
        if (filters.GRN === 'Added') {
          xApiFilters.salesAttachments = ['grnReceiptAdded'];
        } else if (filters.GRN === 'Not Added') {
          xApiFilters.salesAttachments = ['grnReceiptMissing'];
        }
      }

      if (filters.dispatchedOn) {
        const today = moment(new Date());

        if (filters.dispatchedOn === '7 days') {
          xApiFilters.dispatchedOn = [moment().subtract(7, 'days'), today];
        } else if (filters.dispatchedOn === '3 days') {
          xApiFilters.dispatchedOn = [moment().subtract(3, 'days'), today];
        } else if (filters.dispatchedOn === '15 days') {
          xApiFilters.dispatchedOn = [moment().subtract(15, 'days'), today];
        } else if (filters.dispatchedOn === '1 months') {
          xApiFilters.dispatchedOn = [moment().subtract(1, 'months'), today];
        }
      }

      if (sort.createdAt) xApiSort.createdAt = sort.createdAt;

      if (currentType === 'sent') {
        xApiFilters.seller = [businessId];
      } else {
        xApiFilters.buyer = [businessId];
      }

      const res = await axios.get(`${DISPATCH_URL}?page=${currentPage}&limit=${pagination.pageSize}&populate=true`, {
        headers: {
          'X-API-Filters': JSON.stringify(xApiFilters),
          'X-API-Sort': JSON.stringify(xApiSort),
        },
      });

      if (res.data.status === 'error') {
        message.error(res.data.error);
      } else {
        setDispatches(res.data.dispatches);
        setPagination({
          ...pagination,
          current: currentPage,
          total: res.data.count,
        });
      }
      localStorage.removeItem('filters');
    } catch (err) {
      message.error(err.message);
    }
    setDispatchesLoading(false);
  };

  const menuOptions = [];
  const userId = getAuth()?._id;
  const user = find(business?.users, (u) => String(u.user._id) === String(userId));
  if (business?.businessType !== 'seller' && ['Owner', 'Administrator', 'Purchase Manager'].includes(user?.role)) {
    menuOptions.push({
      key: 'received',
      label: 'To be Received',
    });
  }
  if (business?.businessType !== 'consumer' && ['Owner', 'Administrator', 'Sales Manager'].includes(user?.role)) {
    menuOptions.push({
      key: 'sent',
      label: 'To be Sent',
    });
  }

  const onStatusChange = (status, checked) => {
    const { statuses } = filters;
    const updatedStatuses = checked ? [...statuses, status] : statuses.filter((t) => t !== status);
    setFilters({ ...filters, statuses: updatedStatuses });
  };

  const onStatusesClear = () => setFilters({ ...filters, statuses: [] });

  const onGRNChange = (e) => {
    setFilters({ ...filters, GRN: e.target.value });
  };

  const onGRNClear = () => setFilters({ ...filters, GRN: null });

  const onDateChange = (e) => {
    setFilters({ ...filters, dispatchedOn: e.target.value });
  };

  const onDateClear = () => setFilters({ ...filters, dispatchedOn: null });

  const onSortByDate = (e) => {
    setSort({
      ...sort,
      createdAt: e && e?.target?.value ? e.target.value : null,
    });
  };

  const FilterComponent = () => {
    return (
      <>
        <>
          <Paragraph type="secondary" className="fw-medium">
            FILTERING OPTIONS
          </Paragraph>

          <div className="custom-tag tag-container my-3">
            <Paragraph type="secondary">Delivery Status</Paragraph>
            {['Awaiting Loading', 'In Transit', 'Awaiting Unloading'].map((status) => (
              <CheckableTag className="mt-2" key={status} checked={filters?.statuses?.indexOf(status) > -1} onChange={(checked) => onStatusChange(status, checked)}>
                {status}
              </CheckableTag>
            ))}
            {filters.statuses.length > 0 && (
              <Button type="link" size="small" onClick={onStatusesClear}>
                <CloseCircleOutlined /> Clear Filter
              </Button>
            )}
          </div>

          {type === 'received' ? (
            <div className="my-4">
              <Paragraph type="secondary">GRN</Paragraph>
              <Radio.Group className="mt-2" value={filters.GRN} onChange={onGRNChange}>
                <Space direction="vertical" size="small">
                  <Radio value="Added">Added</Radio>
                  <Radio value="Not Added">Not Added</Radio>
                  {filters.GRN && (
                    <Button type="link" size="small" onClick={onGRNClear}>
                      <CloseCircleOutlined /> Clear Filter
                    </Button>
                  )}
                </Space>
              </Radio.Group>
            </div>
          ) : (
            ''
          )}

          <div className="my-4">
            <Paragraph type="secondary">By Date</Paragraph>
            <Radio.Group className="mt-2" value={filters.dispatchedOn} onChange={onDateChange}>
              <Space direction="vertical" size="small">
                <Radio value="3 days">Last 3 days</Radio>
                <Radio value="7 days">Last 7 days</Radio>
                <Radio value="15 days">Last 15 days</Radio>
                <Radio value="1 months">Last 1 Months</Radio>
                {filters.dispatchedOn && (
                  <Button type="link" size="small" onClick={() => onDateClear(null)}>
                    <CloseCircleOutlined /> Clear Filter
                  </Button>
                )}
              </Space>
            </Radio.Group>
          </div>
        </>

        <Divider />
        <>
          <Paragraph type="secondary" className="fw-medium">
            SORTING OPTIONS
          </Paragraph>

          <div className="my-4">
            <Paragraph type="secondary">Sort by dispatch date</Paragraph>
            <Radio.Group className="mt-2" value={sort.createdAt} onChange={onSortByDate}>
              <Space direction="vertical" size="small">
                <Radio value="desc">Current to Past</Radio>
                <Radio value="asc">Past to Current</Radio>
                {sort.createdAt && (
                  <Button type="link" size="small" onClick={() => onSortByDate(null)}>
                    <CloseCircleOutlined /> Clear Sort
                  </Button>
                )}
              </Space>
            </Radio.Group>
          </div>
        </>
      </>
    );
  };

  return (
    <>
      {!dispatchesLoading ? (
        <div>
          <Row className="tag-container">
            <Col xs={24} sm={24} mg={12} lg={type === 'sent' ? 16 : 20}>
              {menuOptions.length > 1 &&
                menuOptions.map((tag) => (
                  <CheckableTag
                    key={tag.key}
                    checked={type === tag.key}
                    onChange={() => {
                      setType(tag.key);
                      setPagination({ ...pagination, current: 1 });
                    }}>
                    {tag.label}
                  </CheckableTag>
                ))}
            </Col>
            {type === 'sent' && (
              <Col xs={24} sm={24} mg={12} lg={4}>
                <Button type="primary" ghost onClick={() => onOpenDispatch()}>
                  Create Dispatch
                </Button>
              </Col>
            )}
            <Col xs={24} sm={24} mg={12} lg={4}>
              <Button type="primary" ghost onClick={() => setSearchParams({ filters: 'ongoingActive' })}>
                Filters & Sorts
              </Button>
            </Col>
          </Row>

          {dispatches?.length > 0 ? (
            <List
              className="mt-4"
              footer={null}
              grid={{ gutter: [24, 12], column: isMobile ? 1 : 2 }}
              dataSource={dispatches}
              pagination={{ ...pagination, onChange: (page) => setPagination({ ...pagination, current: page }) }}
              renderItem={(dispatch) => (
                <List.Item key={dispatch?._id}>
                  <DispatchDetails type={type} dispatch={dispatch} business={business} save={onLoadDispatches} />
                </List.Item>
              )}
            />
          ) : (
            <EmptyContent source={IconDispatch} title={'No Ongoing Dispatch'} subTitle={'No ongoing dispatch to display.'} />
          )}
        </div>
      ) : (
        <ContentLoader width="100%" height="70vh" backgroundColor="#d9d9d9" foregroundColor="#ededed">
          <rect x="0" y="25" rx="10" ry="10" width="48%" height="211" />
          <rect x="50%" y="25" rx="10" ry="10" width="50%" height="211" />
          <rect x="0" y="250" rx="10" ry="10" width="48%" height="211" />
          <rect x="50%" y="250" rx="10" ry="10" width="50%" height="211" />
        </ContentLoader>
      )}
      <UpdateOrderStatus mode={'create-dispatch'} visible={dispatchVisible} close={onOpenDispatch} />

      <Drawer width={isMobile ? '70%' : '30%'} headerStyle={{ margin: '0px', height: '0px', padding: '0px' }} closeIcon={false} onClose={() => setSearchParams({})} open={showFilters}>
        <Card
          title={
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <h3>
                <b>Filters & Sorts</b>
              </h3>
              <Button type="text" onClick={() => setSearchParams({})}>
                <ModalCloseIcon />
              </Button>
            </div>
          }>
          {FilterComponent()}
        </Card>
      </Drawer>
    </>
  );
};

export default UpcomingDispatch;
