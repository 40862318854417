import React, { useEffect, useState } from 'react';
import { Avatar, Card, Checkbox, Col, Modal, Row, Typography, message } from 'antd';
import ErrorBoundary from 'components/Errors/ErrorBoundary';
import ModalCloseIcon from 'components/UtilComponents/ModalCloseIcon';
import axios from 'axios';
import { BUSINESS_URL, PRODUCT_URL, UPLOAD_URL } from 'constants/ApiConstants';
import { map } from 'lodash';
import { posthog } from 'posthog-js';

const { Text } = Typography;

const ModifyProducts = ({ type, business, visible, close }) => {
  const viewName = 'account';
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [values, setValues] = useState([]);

  useEffect(() => {
    onLoad();
  }, []);

  useEffect(() => {
    if (type === 'buying') {
      setValues(map(business.buying, '_id'));
    } else if (type === 'selling') {
      setValues(map(business.selling, '_id'));
    }
  }, [type, business]);

  const onLoad = async () => {
    if (loading) return;
    setLoading(true);
    try {
      const res = await axios.get(`${PRODUCT_URL}?&populate=true`, {
        headers: {
          'X-API-Sort': JSON.stringify({ name: 'asc' }),
          'X-API-Filters': JSON.stringify({ status: ['Active'] }),
        },
      });
      if (res.data.status === 'error') {
        message.error(res.data.error);
      } else {
        setProducts(res.data.products);
      }
    } catch (err) {
      message.error(err.message);
    }
    setLoading(false);
  };

  const onChange = (checkedValues) => {
    if (checkedValues.length > 10) {
      message.error('You can choose upto 10 products');
      return;
    } else if (checkedValues.length < 1) {
      message.error('Please select at least one product');
      return;
    }
    setValues(checkedValues);
  };

  const onSaveChanges = async () => {
    if (submitLoading) return;
    setSubmitLoading(true);
    try {
      // TODO: Added business url temporarily. Remove once business/me route is implemented for updating business details.
      const businessRes = await axios.get(`${BUSINESS_URL}/me`);
      const res = await axios.put(`${BUSINESS_URL}/${businessRes?.data?.business?._id}`, {
        [type]: values,
      });
      if (res.data.status === 'error') {
        message.error(res.data.error);
      } else {
        message.success('Products updated successfully');
        posthog.capture(`$${viewName} | $products-updated`, {
          data: {
            type,
            products: values,
          },
          $set: {
            lastActivityDate: new Date().toISOString(),
          },
        });
        close(null, true);
      }
    } catch (err) {
      message.error(err.message);
    }
    setSubmitLoading(false);
  };

  return (
    <Modal
      title={`Select Products to ${type === 'buying' ? 'Buy' : 'Sell'}`}
      open={visible}
      onCancel={() => close(null)}
      closeIcon={<ModalCloseIcon />}
      okText="Save Changes"
      onOk={onSaveChanges}
      confirmLoading={submitLoading}
      okButtonProps={{
        size: 'large',
        block: true,
        style: { marginLeft: '0px' },
      }}
      cancelButtonProps={{ style: { display: 'none' } }}
      bodyStyle={{ height: '70vh', overflowY: 'auto' }}
      centered>
      <ErrorBoundary>
        <Checkbox.Group name="products" value={values} className="w-100 mb-2" onChange={onChange}>
          <Row gutter={[16, 8]}>
            {products.map((product) => {
              return (
                <Col span={24} key={product._id}>
                  <Checkbox value={product._id} className="product-checkbox">
                    <Card
                      style={{
                        border: values.includes(product._id) ? '1px solid #1a75da' : 'none',
                        backgroundColor: values.includes(product._id) ? '#E8F1FB' : '#ffffff',
                      }}
                      bodyStyle={{
                        padding: '2px',
                        cursor: 'pointer',
                        borderRadius: 'inherit',
                      }}>
                      <Row align="middle">
                        <Col span={24}>
                          <Row wrap={false} align="middle">
                            <Avatar shape="square" style={{ width: 50, height: 50 }} src={`${UPLOAD_URL}/${product.image}`} />
                            <Text className="ml-2" ellipsis={true}>
                              {product.name}
                            </Text>
                          </Row>
                        </Col>
                      </Row>
                    </Card>
                  </Checkbox>
                </Col>
              );
            })}
          </Row>
        </Checkbox.Group>
      </ErrorBoundary>
    </Modal>
  );
};

export default ModifyProducts;
