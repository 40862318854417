import React, { useState } from 'react';
import { Avatar, Button, Col, Divider, Row, Image, Tag, Space, Typography, Alert } from 'antd';
import { format } from 'date-fns';
import { DownOutlined, FileTextOutlined, InfoCircleOutlined, FileImageOutlined, UpOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import DetailsGrid from 'components/UtilComponents/DetailsGrid';
import NumberText from 'components/UtilComponents/NumberText';
import { UPLOAD_URL } from 'constants/ApiConstants';

const { Text } = Typography;

const QuoteDetails = ({ quote }) => {
  const navigate = useNavigate();
  const [showMore, setShowMore] = useState(false);

  const onShowMore = () => setShowMore(!showMore);
  const onOpenRfq = () => navigate(`/sell/open-rfq/${quote?.enquiry}`);

  return (
    <div className="my-5" style={{ transition: 'visibility 0s linear 0s, opacity 300ms' }}>
      <Row gutter={16}>
        <Col xs={24} sm={24} md={8} lg={8}>
          <Image alt={quote?.product?.name} height={250} src={`${UPLOAD_URL}/${quote?.product?.image}`} />
        </Col>
        <Col xs={24} sm={24} md={16} lg={16}>
          <Row gutter={[24, 24]} align="middle" className="my-4">
            <Col span={24}>
              <Text strong style={{ textAlign: 'center' }}>
                {`${quote?.product?.name} | ${quote?.qty} ${quote?.subUnit} | ${quote?.frequency}`}
              </Text>
            </Col>
            <Col xs={12} sm={12} md={6} lg={12}>
              <DetailsGrid title="Price Quoted" description={<NumberText bold value={quote?.purchasingPrice / 100} prefix={quote?.currency} suffix={`/ ${quote?.subUnit}`} />} />
            </Col>
            <Col xs={12} sm={12} md={6} lg={12}>
              <DetailsGrid
                title="Quantity Available"
                description={
                  <Text strong>
                    {quote?.qty} {quote?.subUnit}
                  </Text>
                }
              />
            </Col>
            <Col xs={12} sm={12} md={6} lg={12}>
              <DetailsGrid title="Delivery by" description={quote?.expectedDeliveryOn ? format(new Date(quote.expectedDeliveryOn), 'dd MMM yyyy') : ''} />
            </Col>
            {!showMore && (
              <Col xs={12} sm={12} md={6} lg={12}>
                <DetailsGrid title="Pickup Location" description={`${quote?.pickupAddress?.city}, ${quote?.pickupAddress?.state}`} />
              </Col>
            )}
          </Row>
          <Row align={'middle'} gutter={[24, 24]}>
            <Col xs={12} sm={12} md={6} lg={12}>
              <Button type="link" className="p-0" onClick={onShowMore}>
                {showMore ? (
                  <>
                    Close Details <UpOutlined style={{ fontSize: 10 }} />
                  </>
                ) : (
                  <>
                    View Details <DownOutlined style={{ fontSize: 10 }} />
                  </>
                )}
              </Button>
            </Col>
            <Col xs={12} sm={12} md={6} lg={12} span={8} flex={1}>
              <Button type="primary" onClick={onOpenRfq}>
                View OpenRFQ
              </Button>
            </Col>
          </Row>
          {showMore && (
            <>
              <div>
                <DetailsGrid icon={<Avatar src={require('assets/images/icon-cash.png')} shape="square" size="small" />} description="Price Basis" />
                <Row justify="space-between" className="mt-3">
                  <Text>Price Per Unit</Text>
                  <NumberText bold prefix={quote?.currency} value={quote?.purchasingPrice / 100} suffix={`/ ${quote?.subUnit}`} />
                </Row>
                <Row justify="space-between" className="my-1">
                  <Text>Quantity</Text>
                  <Text strong>
                    {quote?.qty} {quote?.subUnit}
                  </Text>
                </Row>
                <Divider className="my-0" />
                <Row justify="space-between" className="my-1">
                  <Text>{quote?.purchasingPriceBasis?.price?.label}</Text>
                  <NumberText bold prefix={quote?.currency} value={quote?.purchasingPriceBasis?.price?.value / 100} />
                </Row>
                {quote?.purchasingPriceBasis?.deliveryCharges?.value > 0 ? (
                  <Row justify="space-between" className="my-1">
                    <Text>{quote?.purchasingPriceBasis?.deliveryCharges?.label}</Text>
                    <NumberText bold prefix={quote?.currency} value={quote?.purchasingPriceBasis?.deliveryCharges?.value / 100} />
                  </Row>
                ) : (
                  ''
                )}
                {quote?.purchasingPriceBasis?.gst?.value > 0 ? (
                  <Row justify="space-between" className="my-1">
                    <Text>{quote?.purchasingPriceBasis?.gst?.label}</Text>
                    <NumberText bold prefix={quote?.currency} value={quote?.purchasingPriceBasis?.gst?.value / 100} />
                  </Row>
                ) : (
                  <Row justify="space-between" className="my-1">
                    <Text>{quote?.purchasingPriceBasis?.gst?.label}</Text>
                    <Text strong>Included</Text>
                  </Row>
                )}
                <Divider className="my-0" />
                <Row justify="space-between" className="my-1">
                  <Text>{quote?.purchasingPriceBasis?.total?.label}</Text>
                  <NumberText bold prefix={quote?.currency} value={quote?.purchasingPriceBasis?.total?.value / 100} />
                </Row>
                <Divider className="my-0" />
                <Tag icon={<InfoCircleOutlined />} color="#B4B4B4" className="my-3">
                  Final invoice amount will be calculated after loading
                </Tag>
              </div>
              <Space direction="vertical" size={'large'} className="w-100 my-2">
                <DetailsGrid
                  icon={<Avatar src={require('assets/images/icon-cash.png')} shape="square" size="small" />}
                  title={<Text strong>Terms of Payment</Text>}
                  description={quote?.paymentTerm?.name}
                />
                <DetailsGrid icon={<Avatar src={require('assets/images/icon-delivery.png')} shape="square" size="small" />} description="Delivery" />
                <Row gutter={[16, 16]}>
                  <Col span={12}>
                    <DetailsGrid title="Pickup Location" description={`${quote?.pickupAddress?.city}, ${quote?.pickupAddress?.state}`} />
                  </Col>
                  <Col span={12}>
                    <DetailsGrid title="Delivery Charges" description={quote?.transportType} />
                  </Col>
                </Row>
                <DetailsGrid
                  icon={<Avatar src={require('assets/images/icon-quality.png')} shape="square" size="small" />}
                  title={<Text strong>Quality Remarks</Text>}
                  description={quote?.remark || 'N/A'}
                />
              </Space>
              <Row gutter={[16, 16]} align="middle" className="my-2">
                {quote?.qualityReportFile && (
                  <Col span={12}>
                    <Button type="link" href={`${UPLOAD_URL}/${quote?.qualityReportFile}`} icon={<FileTextOutlined />} target="_blank">
                      View Quality Report
                    </Button>
                  </Col>
                )}
                {quote?.productImageFile && (
                  <Col xs={24} sm={12} md={12} lg={12}>
                    <Button type="link" href={`${UPLOAD_URL}/${quote?.productImageFile}`} icon={<FileImageOutlined />} target="_blank">
                      View Product Image
                    </Button>
                  </Col>
                )}
              </Row>
            </>
          )}
          <Col className="mt-2">
            <Alert
              type={quote?.status === 'Rejected' ? 'error' : 'info'}
              showIcon
              message={quote?.status === 'Accepted' ? 'Quote has been Accepted & Order has been Placed' : quote.status === 'Rejected' ? 'Quote has been Rejected' : 'Quote is Yet to be Accepted'}
            />
          </Col>
        </Col>
      </Row>
      <Divider />
    </div>
  );
};

export default QuoteDetails;
