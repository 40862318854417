import React, { useState } from 'react';
import { Avatar, Button, Col, Divider, Row, Space, Tag, Typography } from 'antd';
import { ClockCircleOutlined, DownOutlined, UpOutlined } from '@ant-design/icons';
import { BsCheck2All } from 'react-icons/bs';
import { format } from 'date-fns';
import ChatView from 'components/UtilComponents/ChatView';
import DetailsGrid from 'components/UtilComponents/DetailsGrid';

const { Title, Text, Paragraph } = Typography;

const RFQCard = ({ chatType }) => {
  const [showMore, setShowMore] = useState(false);

  const onShowMore = () => setShowMore(!showMore);

  return (
    <ChatView chatType={chatType}>
      <Paragraph type="secondary">Requesting Quote for</Paragraph>
      <Title level={5} className="fw-semi-bold">
        Wood Chips
      </Title>
      <Paragraph>50 MT / Month</Paragraph>

      <Space direction="vertical" size={'middle'} className="w-100">
        <Tag icon={<ClockCircleOutlined />}>Expires in 7 days</Tag>

        {!showMore && (
          <>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <DetailsGrid title="Duration" description={'2 Months'} />
              </Col>
              <Col span={12}>
                <DetailsGrid title="Start Deliveries By" description={format(new Date(), 'dd MMM yyyy')} />
              </Col>
              <Col span={24}>
                <DetailsGrid title="Delivery Location" description={'Coimbatore, TamilNadu'} description2={'1/175, Trichy Road, Sulur, Coimbatore'} />
              </Col>
            </Row>
          </>
        )}

        <Button type="link" className="p-0" onClick={onShowMore}>
          {!showMore ? (
            <>
              More Info <DownOutlined style={{ fontSize: 10 }} />
            </>
          ) : (
            <>
              Less Info <UpOutlined style={{ fontSize: 10 }} />
            </>
          )}
        </Button>

        {showMore && (
          <>
            {chatType === 'sent' && (
              <>
                <DetailsGrid icon={<Avatar src={require('assets/images/icon-quantity.png')} shape="square" size="small" />} description="Quantity" />
                <Row gutter={[16, 16]}>
                  <Col span={12}>
                    <DetailsGrid title="Order Quantity" description={'50 MT / Month'} />
                  </Col>
                  <Col span={12}>
                    <DetailsGrid title="Duration" description={'2 Months'} />
                  </Col>
                </Row>
                <DetailsGrid icon={<Avatar src={require('assets/images/icon-delivery.png')} shape="square" size="small" />} description="Delivery" />
                <Row gutter={[16, 16]}>
                  <Col span={12}>
                    <DetailsGrid title="Start Deliveries By" description={format(new Date(), 'dd MMM yyyy')} />
                  </Col>
                  <Col span={12}>
                    <DetailsGrid title="Delivery Location" description={'Coimbatore, TamilNadu'} description2={'1/175, Trichy Road, Sulur, Coimbatore'} />
                  </Col>
                </Row>
                <DetailsGrid
                  icon={<Avatar src={require('assets/images/icon-quality.png')} shape="square" size="small" />}
                  title={<Text strong>Quality Remarks</Text>}
                  description="Lorem ipsum dolor sit amet, consectetur adipiscinglentesque vulputate porttitor orci sed accumsan. In vitae hendrerit dui. "
                />
              </>
            )}
          </>
        )}
      </Space>
      <Divider className="my-3" />
      <Row align="middle" justify={chatType === 'sent' ? 'end' : 'start'}>
        {chatType === 'sent' && <BsCheck2All />}
        &nbsp;
        <Text className="text-small">{format(new Date(), 'hh:mm a, dd MMM yyyy')}</Text>
      </Row>
    </ChatView>
  );
};

export default RFQCard;
