import React, { useState } from 'react';
import { Button, Col, Divider, Form, Input, Modal, Row, message } from 'antd';
import { FaPhoneAlt } from 'react-icons/fa';
import ErrorBoundary from 'components/Errors/ErrorBoundary';
import DetailsGrid from 'components/UtilComponents/DetailsGrid';
import ModalCloseIcon from 'components/UtilComponents/ModalCloseIcon';
import { USER_URL } from 'constants/ApiConstants';
import axios from 'axios';

const ChangePhoneNumber = ({ user, visible, close }) => {
  const [form] = Form.useForm();
  const [step, setStep] = useState('phone');
  const [otp, setOtp] = useState(null);
  const [phone, setPhone] = useState(null);
  const [loading, setLoading] = useState(false);
  const [sendLoading, setSendLoading] = useState(false);
  const [counter, setCounter] = React.useState(0);

  React.useEffect(() => {
    const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  const onSendOTP = async (values) => {
    if (sendLoading) return;
    setSendLoading(true);
    try {
      const otp = String(Math.floor(Math.random() * 899999 + 100000));
      const res = await axios.post(`${USER_URL}/send/otp/verify`, {
        phone: values.phone ? values.phone : phone,
        otp,
      });
      if (res.data.status === 'error') {
        message.error(res.data.error);
      } else {
        setPhone(values.phone);
        setOtp(otp);
        setStep('otp');
        setCounter(59);
        message.success('OTP sent successfully to your phone number');
      }
    } catch (err) {
      message.error(err.message);
    }
    setSendLoading(false);
  };

  const onSubmit = async (values) => {
    if (loading) return;
    setLoading(true);
    try {
      if (values.otp === otp) {
        const res = await axios.put(`${USER_URL}/me`, {
          phone,
        });
        if (res.data.status === 'error') {
          message.error(res.data.error);
        } else {
          message.success('Phone number changed successfully');
          setPhone(null);
          setOtp(null);
          setStep('phone');
          form.resetFields();
          close(true);
        }
      }
    } catch (err) {
      message.error(err.message);
    }
    setLoading(false);
  };

  const onCancel = () => {
    setPhone(null);
    setOtp(null);
    setStep('phone');
    form.resetFields();
    close();
  };

  return (
    <Modal destroyOnClose={true} title={step === 'phone' ? 'Change Phone Number' : 'Verify Phone Number'} open={visible} onCancel={onCancel} footer={null} closeIcon={<ModalCloseIcon />}>
      <ErrorBoundary>
        <Form form={form} layout="vertical" onFinish={step === 'phone' ? onSendOTP : onSubmit}>
          {step === 'phone' && (
            <>
              <DetailsGrid icon={<FaPhoneAlt size={20} />} title="Phone Number" description={`${user?.user?.phone}`} />

              <Divider />

              <Form.Item
                className="bg-transparent"
                name="phone"
                rules={[
                  {
                    required: true,
                    message: 'Please enter a valid phone number',
                    pattern: new RegExp('^[6-9][0-9]{9}$'),
                  },
                ]}
                hasFeedback>
                <Input size="large" addonBefore="+91" placeholder="New Phone Number" allowClear maxLength={10} />
              </Form.Item>
            </>
          )}

          {step === 'otp' && (
            <>
              <Row align="middle" justify="space-between">
                <Col>
                  <DetailsGrid icon={<FaPhoneAlt size={20} />} title="Your New Phone Number" description={form.getFieldValue('phone')} />
                </Col>
                <Col>
                  <Button type="link" onClick={() => setStep('phone')}>
                    Edit
                  </Button>
                </Col>
              </Row>

              <Divider />

              <Form.Item
                className="bg-transparent"
                name="otp"
                rules={[
                  {
                    required: true,
                    message: 'Please enter the OTP received',
                    pattern: new RegExp('^[0-9]{6}$'),
                  },
                ]}
                hasFeedback
                extra={
                  <>
                    <small>Didn't receive OTP? {counter > 0 && `Request again in ${counter}s`}</small>
                    {counter === 0 && (
                      <Button onClick={onSendOTP} type="link" size="small" loading={sendLoading}>
                        Re-send OTP
                      </Button>
                    )}
                  </>
                }>
                <Input size="large" placeholder="Enter 6 Digit OTP" allowClear />
              </Form.Item>
            </>
          )}

          <Form.Item shouldUpdate className="mt-4">
            {() => (
              <Button size="large" type="primary" block htmlType="submit" loading={step === 'otp' ? loading : sendLoading}>
                {step === 'phone' ? 'Next' : 'Verify Phone Number'}
              </Button>
            )}
          </Form.Item>
        </Form>
      </ErrorBoundary>
    </Modal>
  );
};

export default ChangePhoneNumber;
