import React from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const Loader = () => {
  return (
    <div className="vh-50 d-flex flex-column align-items-center justify-content-center">
      <Spin indicator={<LoadingOutlined />} tip="Loading..." />
    </div>
  );
};

export default Loader;
