import React, { useEffect, useState } from 'react';
import { Button, Form, Input, InputNumber, Modal, Space, Typography, message } from 'antd';
import axios from 'axios';
import { ExclamationCircleOutlined, LeftOutlined } from '@ant-design/icons';
import CopyToClipboard from 'components/UtilComponents/CopyToClipboard';
import ModalCloseIcon from 'components/UtilComponents/ModalCloseIcon';
import ErrorBoundary from 'components/Errors/ErrorBoundary';
import { BUSINESS_URL } from 'constants/ApiConstants';

const { Text, Title } = Typography;

const VirtualAccountDetails = ({ business, visible, close }) => {
  const [form] = Form.useForm();
  const [step, setStep] = useState('view');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    form.setFields([
      {
        name: 'accountName',
        value: business?.beneficiaryAccount?.accountName,
      },
      {
        name: 'accountNo',
        value: business?.beneficiaryAccount?.accountNo,
      },
      {
        name: 'ifscCode',
        value: business?.beneficiaryAccount?.ifscCode,
      },
    ]);
  }, [visible, step]);

  const onSubmit = async (values) => {
    if (loading) return;
    setLoading(true);
    try {
      const businessRes = await axios.get(`${BUSINESS_URL}/me`);
      const res = await axios.put(`${BUSINESS_URL}/${businessRes.data.business._id}`, {
        beneficiaryAccount: {
          accountNo: String(values.accountNo),
          accountName: values.accountName,
          ifscCode: values.ifscCode,
        },
      });

      if (res.data.status === 'success') {
        form.resetFields();
        message.success('Withdrawal account details updated successfully');
        setStep('view');
        close(true);
      } else {
        message.error(res.data.error);
      }
    } catch (err) {
      message.error(err.message);
    }
    setLoading(false);
  };

  const confirmOnClose = () => {
    if (!loading && form.isFieldsTouched()) {
      Modal.confirm({
        title: 'Unsaved Data',
        icon: <ExclamationCircleOutlined />,
        content: 'This form has unsaved data. Are you sure you want to close the screen?',
        okText: 'Yes',
        onOk: () => {
          form.resetFields();
          close();
        },
        cancelText: 'No',
      });
    } else {
      close();
    }
  };

  return (
    <Modal
      title={
        <>
          <Title level={5}>Account Details</Title>
          <small className="fw-normal">For sending payments / purchasing.</small>
        </>
      }
      open={visible}
      onCancel={confirmOnClose}
      footer={null}
      closeIcon={<ModalCloseIcon />}>
      {business?.beneficiaryAccount && step === 'view' && (
        <Space direction="vertical" size="large" className="w-100">
          <div>
            <Text type="secondary">Account Holder Name</Text>
            <CopyToClipboard text={business?.beneficiaryAccount?.accountName} />
          </div>
          <div>
            <Text type="secondary">Account Number</Text>
            <CopyToClipboard text={business?.beneficiaryAccount?.accountNo} />
          </div>
          <div>
            <Text type="secondary">IFSC Code</Text>
            <CopyToClipboard text={business?.beneficiaryAccount?.ifscCode} />
          </div>
          <Button type="primary" block size="large" onClick={() => setStep('edit')}>
            Edit Details
          </Button>
        </Space>
      )}
      {(!business?.beneficiaryAccount || step === 'edit') && (
        <ErrorBoundary>
          <Form form={form} layout="vertical" onFinish={onSubmit}>
            <Form.Item
              className="bg-transparent"
              name="accountName"
              rules={[
                {
                  required: true,
                  message: 'Please enter account holder name',
                },
              ]}
              hasFeedback>
              <Input size="large" placeholder="Account Holder Name" />
            </Form.Item>

            <Form.Item
              className="bg-transparent"
              name="accountNo"
              rules={[
                {
                  required: true,
                  message: 'Please enter bank account number',
                },
              ]}
              hasFeedback>
              <InputNumber controls={false} size="large" placeholder="Account Number" className="w-100" />
            </Form.Item>

            <Form.Item
              className="bg-transparent"
              name="ifscCode"
              rules={[
                {
                  required: true,
                  message: 'Please enter ifsc code',
                },
              ]}
              hasFeedback>
              <Input size="large" placeholder="IFSC Code" />
            </Form.Item>

            {business?.beneficiaryAccount && (
              <Form.Item noStyle className="mt-4">
                <Button type="link" icon={<LeftOutlined />} onClick={() => setStep('view')}>
                  Back
                </Button>
              </Form.Item>
            )}

            <Form.Item className="mt-4">
              <Button size="large" type="primary" block htmlType="submit" loading={loading}>
                Update Account Details
              </Button>
            </Form.Item>
          </Form>
        </ErrorBoundary>
      )}
    </Modal>
  );
};

export default VirtualAccountDetails;
