import React, { useEffect, useState } from 'react';
import { message, Typography } from 'antd';
import axios from 'axios';
import ContentLoader from 'react-content-loader';
import { OPEN_RFQ_QUOTE_URL } from 'constants/ApiConstants';
import QuoteDetails from './QuoteDetails';
import IconQuotes from 'assets/images/enquiry.png';
import EmptyContent from 'components/UtilComponents/EmptyContent';

const { Text } = Typography;

const AcceptedQuotes = ({ tab, navigate }) => {
  const [loading, setLoading] = useState(false);
  const [quotes, setQuotes] = useState([]);

  useEffect(() => {
    if (tab === 'accepted-quotes') {
      onLoadQuotes();
    }
  }, [tab]);

  const onLoadQuotes = async () => {
    setLoading(true);
    try {
      const res = await axios.get(`${OPEN_RFQ_QUOTE_URL}?populate=true`, {
        headers: {
          'X-API-Filters': JSON.stringify({ seller: ['me'], status: ['Accepted'] }),
          'X-API-Sort': JSON.stringify({ createdAt: 'desc' }),
        },
      });
      if (res.data.status === 'error') {
        message.error(res.data.error);
      } else {
        setQuotes(res.data.openrequirementquotes);
      }
    } catch (err) {
      message.error(err.message);
    }
    setLoading(false);
  };

  if (loading) {
    return (
      <ContentLoader width="100%" height="70vh" backgroundColor="#d9d9d9" foregroundColor="#ededed">
        <rect x="0" y="25" rx="10" ry="10" width="48%" height="211" />
        <rect x="50%" y="25" rx="10" ry="10" width="50%" height="211" />
        <rect x="0" y="250" rx="10" ry="10" width="48%" height="211" />
        <rect x="50%" y="250" rx="10" ry="10" width="50%" height="211" />
      </ContentLoader>
    );
  }

  return (
    <>
      {quotes?.length > 0 ? (
        <>
          <Text type="secondary">
            {quotes.length} result{quotes.length > 1 && 's'} in total
          </Text>
          {quotes.map((quote) => (
            <QuoteDetails key={quote?._id} quote={quote} onRefresh={onLoadQuotes} />
          ))}
        </>
      ) : (
        <EmptyContent
          source={IconQuotes}
          title={'No Accepted Quotes found'}
          subTitle={'Explore Open RFQs and send quotes to buyers'}
          actionText={'View Open RFQs'}
          onActionPress={() => navigate('/sell/open-rfqs')}
        />
      )}
    </>
  );
};

export default AcceptedQuotes;
