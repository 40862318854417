import React, { useEffect, useState } from 'react';
import { Typography, Row, Col, Form, Input, Button, Divider, message, Carousel } from 'antd';
import { useNavigate } from 'react-router-dom';
import { USER_URL } from 'constants/ApiConstants';
import axios from 'axios';
import { posthog } from 'posthog-js';

const { Title, Text, Paragraph } = Typography;

const ForgotPassword = () => {
  let navigate = useNavigate();
  const [form] = Form.useForm();
  const [counter, setCounter] = useState(0);
  const [step, setStep] = useState('send-otp');
  const [loading, setLoading] = useState(false);
  const [resending, setResending] = useState(false);

  useEffect(() => {
    const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  const onSendOTP = async (type) => {
    setLoading(type === 'send' ? true : false);
    setResending(type === 'resend' ? true : false);
    try {
      const values = form.getFieldsValue(true);
      const res = await axios.put(`${USER_URL}/password/reset/generate`, {
        username: values.username,
      });
      if (res.data.status === 'success') {
        if (type === 'send') message.success('OTP has been sent to your registered Email and Phone Number');
        if (type === 'resend') message.success('New OTP has been re-sent to your registered Email and Phone Number');
        setStep('verify-otp');
        setCounter(59);
      } else {
        message.error(res.data.error);
      }
    } catch (err) {
      message.error(err.message);
    }
    setLoading(false);
    setResending(false);
  };

  const onVerifyOTP = async (values) => {
    setLoading(true);
    try {
      const res = await axios.put(`${USER_URL}/password/reset/change`, {
        username: values.username,
        token: values.otp,
        pass: values.password,
      });
      if (res.data.status === 'success') {
        message.success('Your password has been changed successfully');
        navigate('/login');
      } else {
        message.error(res.data.error);
      }
    } catch (err) {
      message.error(err.message);
    }
    setLoading(false);
  };

  return (
    <Row align="middle" className="vh-100">
      <Col xs={24} sm={24} md={24} lg={12}>
        <Carousel arrows={true} autoplay pauseOnHover={false}>
          <img src={require('assets/images/slides/slide-1.jpg')} alt="Banner 1" />
          <img src={require('assets/images/slides/slide-2.jpg')} alt="Banner 2" />
          <img src={require('assets/images/slides/slide-3.jpg')} alt="Banner 3" />
          <img src={require('assets/images/slides/slide-4.jpg')} alt="Banner 4" />
        </Carousel>
      </Col>
      <Col xs={24} sm={24} md={24} lg={12} className="p-3 p-lg-5">
        <Title level={3} className="m-0 fw-bold">
          Forgot Password
        </Title>

        <Form className="mt-5" form={form} layout="vertical" onFinish={step === 'send-otp' ? () => onSendOTP('send') : onVerifyOTP}>
          <Form.Item
            className="bg-transparent"
            name="username"
            rules={[
              {
                required: true,
                message: 'Please enter your email address',
              },
              {
                type: 'email',
                message: 'Please enter a valid email address!',
              },
            ]}
            hasFeedback>
            <Input size="large" placeholder="Email Address" allowClear disabled={step === 'verify-otp'} />
          </Form.Item>

          {step === 'verify-otp' && (
            <>
              <Form.Item
                className="bg-transparent"
                name="otp"
                rules={[
                  {
                    required: true,
                    message: 'Please enter the OTP received',
                  },
                  {
                    message: 'Please enter a 6 digit valid OTP',
                    pattern: new RegExp('^[0-9]{6}$'),
                  },
                ]}
                extra={
                  <Row className="mt-2" align="middle">
                    <Text>Didn't receive OTP? {counter > 0 && `Request again in ${counter}s`}</Text>
                    {counter === 0 && (
                      <Button type="link" size="middle" className="px-2" htmlType="submit" onClick={() => onSendOTP('resend')} loading={resending}>
                        Re-send OTP
                      </Button>
                    )}
                  </Row>
                }>
                <Input size="large" placeholder="Enter 6 digit OTP" allowClear />
              </Form.Item>
              <Form.Item
                className="bg-transparent"
                name="password"
                rules={[
                  {
                    required: true,
                    message: 'Please enter a new password',
                  },
                ]}>
                <Input.Password size="large" placeholder="Your new password" allowClear />
              </Form.Item>
            </>
          )}

          <Form.Item>
            <Row justify="center" className="my-3">
              <Button type="primary" htmlType="submit" size="large" style={{ width: '250px' }} loading={loading}>
                {step === 'send-otp' && 'Send OTP'}
                {step === 'verify-otp' && 'Verify OTP'}
              </Button>
            </Row>
            <Row justify="center" className="my-2">
              <Button
                onClick={() => {
                  posthog.capture(`$screenview | $login`, { $set: { lastActivityDate: new Date().toISOString() } });
                  form.resetFields();
                  navigate('/login');
                }}
                type="link"
                size="large">
                Remember Password? Login Now
              </Button>
            </Row>
          </Form.Item>
        </Form>

        <Divider />
        <div className="mb-5">
          <Paragraph type="secondary" className="text-center">
            Copyrights &copy; {new Date().getFullYear()} Buyofuel - All rights reserved
          </Paragraph>
        </div>
      </Col>
    </Row>
  );
};

export default ForgotPassword;
