import React from 'react';

const receivedContainerStyle = {
  position: 'relative',
  display: 'flex',
  width: 450,
  backgroundColor: '#EEEEEE',
  borderRadius: 10,
  borderTopLeftRadius: 0,
  margin: '10px 0px',
};

const receivedArrowStyle = {
  position: 'absolute',
  width: 0,
  height: 0,
  top: '0rem',
  left: '-0.75rem',
  borderTop: '15px solid #EEEEEE',
  borderLeft: '15px solid transparent',
};

const sentContainerStyle = {
  position: 'relative',
  display: 'flex',
  alignSelf: 'flex-end',
  width: 450,
  backgroundColor: '#E8F1FB',
  borderRadius: 10,
  borderTopRightRadius: 0,
  margin: '10px 0px',
};

const sentArrowStyle = {
  position: 'absolute',
  width: 0,
  height: 0,
  top: '0rem',
  right: '-0.75rem',
  borderTop: '15px solid #E8F1FB',
  borderRight: '15px solid transparent',
};

function ChatView({ chatType = 'sent', children }) {
  return (
    <div className="p-3" style={chatType === 'sent' ? sentContainerStyle : receivedContainerStyle}>
      <div style={chatType === 'sent' ? sentArrowStyle : receivedArrowStyle}></div>
      <div className="w-100">{children}</div>
    </div>
  );
}

export default ChatView;
