export const IndustryType = [
  'Automobile',
  'Biotechnology',
  'Cement',
  'Chemicals',
  'Construction',
  'Cosmetics',
  'Electricity Generation',
  'Fertiliser',
  'Food',
  'Forestry',
  'Infrastructure',
  'Iron and Steel',
  'Manufacturing',
  'Mining',
  'Oil and Gas',
  'Paper',
  'Pharmaceuticals',
  'Textile',
  'Transportation',
  'Waste Management',
  'Other',
];
