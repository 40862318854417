import React, { useEffect, useState } from 'react';
import { Alert, Avatar, Button, Col, Divider, Dropdown, Image, List, Modal, PageHeader, Row, Space, Tag, Typography, message, Input, Tooltip, Select } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { motion } from 'framer-motion';
import { format, differenceInDays } from 'date-fns';
import { ClockCircleOutlined, FileImageOutlined, FileTextOutlined, InfoCircleOutlined, MoreOutlined } from '@ant-design/icons';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import BackIcon from 'components/UtilComponents/BackIcon';
import CompanyName from 'components/UtilComponents/CompanyName';
import DetailsGrid from 'components/UtilComponents/DetailsGrid';
import NumberText from 'components/UtilComponents/NumberText';
import ModalCloseIcon from 'components/UtilComponents/ModalCloseIcon';
import Loader from 'components/UtilComponents/Loader';
import { BUSINESS_URL, LISTING_OFFER_URL, LISTING_URL, OPEN_MERCHANT_NAME, UPLOAD_URL } from 'constants/ApiConstants';
import TimeAgo from 'timeago-react';
import ErrorBoundary from 'components/Errors/ErrorBoundary';
import { AccessDenied } from 'components/Errors';
import ExtendListingExpiration from 'components/Modals/Sell/ExtendListingExpiration';

const { Title, Text, Paragraph } = Typography;

const ReceivedOrder = ({ listing, listingOffer, onRefresh }) => {
  const { purchasingPriceBasis } = listingOffer;

  const showActions = listing?.status === 'Created' && listingOffer.status === 'Created';
  const price = listingOffer?.purchasingPrice / 100;
  const totalPrice = purchasingPriceBasis.price.value / 100;
  const deliveryCharges = purchasingPriceBasis.deliveryCharges.value / 100;
  const gst = purchasingPriceBasis.gst.value / 100;
  const grandTotal = purchasingPriceBasis.total.value / 100;

  const [showMore, setShowMore] = useState(false);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [closingReason, setClosingReason] = useState(null);
  const [error, setError] = useState(null);
  const [closingLoader, setClosingLoader] = useState(false);

  const onShowMore = () => setShowMore(!showMore);

  const onAcceptOrder = () => {
    Modal.confirm({
      title: `Are you sure you want to accept order?`,
      bodyStyle: { backgroundColor: '#fff', borderRadius: 10 },
      icon: null,
      closable: true,
      closeIcon: <ModalCloseIcon />,
      cancelText: 'Cancel',
      cancelButtonProps: {
        type: 'primary',
        ghost: true,
      },
      onCancel() {},
      okText: 'Accept Order',
      okButtonProps: {
        loading: loading,
      },
      onOk: async () => {
        if (loading) return;
        setLoading(true);
        try {
          const res = await axios.put(`${LISTING_OFFER_URL}/${listingOffer?._id}`, {
            status: 'Accepted',
          });

          if (res.data.status === 'error') {
            message.error(res.data.error);
          } else {
            message.success('Order Accepted Successfully');
            onRefresh();
          }
        } catch (err) {
          message.error(err.message);
        }
        setLoading(false);
      },
    });
  };

  const handleOk = () => {
    if (closingReason === null) {
      return setError('Please provide valid reason for rejection');
    }
    setClosingLoader(true);
    setTimeout(async () => {
      try {
        const res = await axios.put(`${LISTING_OFFER_URL}/${listingOffer?._id}`, {
          status: 'Rejected',
          metadata: {
            closingReason: closingReason,
          },
        });
        if (res.data.status === 'error') {
          setError(res.data.error);
        } else {
          setVisible(false);
          message.success('Order Rejected Successfully');
          onRefresh();
          setClosingReason(null);
          setError(null);
        }
      } catch (err) {
        setError(err.message);
      }
      setClosingLoader(false);
    }, 100);
  };
  const handleCancel = () => {
    setVisible(false);
    setClosingReason(null);
    setError(null);
    setClosingLoader(false);
  };

  return (
    <>
      <div className="w-100">
        <Row align="middle" justify="space-between" className="my-3">
          <CompanyName showLogo={false} name={OPEN_MERCHANT_NAME} verified={true} />
          <Button type="link" onClick={onShowMore}>
            {showMore ? (
              <>
                Close Details <IoIosArrowUp />
              </>
            ) : (
              <>
                View Details <IoIosArrowDown />
              </>
            )}
          </Button>
        </Row>

        <Row gutter={[24, 16]} className="my-4">
          <Col xs={12} sm={12} md={12} lg={6}>
            <DetailsGrid title="Requested Price" description={<NumberText bold value={price} prefix={listingOffer?.currency} suffix={`/ ${listingOffer?.subUnit}`} />} />
          </Col>
          <Col xs={12} sm={12} md={12} lg={6}>
            <DetailsGrid
              title="Quantity Needed"
              description={
                <Text strong>
                  {listingOffer?.qty} {listingOffer?.subUnit}
                </Text>
              }
            />
          </Col>
          <Col xs={12} sm={12} md={12} lg={6}>
            <DetailsGrid title="Delivery by" description={<Text strong>{listingOffer?.expectedDeliveryOn ? format(new Date(listingOffer?.expectedDeliveryOn), 'dd MMM yyyy') : '-'}</Text>} />
          </Col>
          <Col xs={12} sm={12} md={12} lg={6}>
            <DetailsGrid
              title="Delivery To"
              description={
                <Text strong>
                  {listingOffer?.deliveryAddress?.city}, {listingOffer?.deliveryAddress?.state}
                </Text>
              }
            />
          </Col>
        </Row>

        {showMore && (
          <>
            <Row gutter={[24, 16]} justify="start" className="mb-4">
              <Col xs={24} sm={24} md={16} lg={12}>
                <DetailsGrid icon={<Avatar src={require('assets/images/icon-cash.png')} shape="square" size="small" />} description="Price Basis" />
                <Row justify="space-between" className="mt-3">
                  <Text>Price Per Unit</Text>
                  <NumberText bold value={price} prefix={listingOffer.currency} />
                </Row>
                <Row justify="space-between" className="my-1">
                  <Text>Quantity</Text>
                  <Text strong>
                    {listingOffer?.qty} {listingOffer?.subUnit}
                  </Text>
                </Row>
                <Divider className="my-0" />
                <Row justify="space-between" className="my-1">
                  <Text>{purchasingPriceBasis.price.label}</Text>
                  <NumberText bold value={totalPrice} prefix={listingOffer.currency} />
                </Row>

                {listingOffer?.enquiry?.transportCharges > 0 ? (
                  <Row justify="space-between" className="my-1">
                    <Text>{listingOffer?.tradingPriceBasis?.deliveryCharges?.label}</Text>
                    <NumberText bold value={deliveryCharges} prefix={listingOffer.currency} />
                  </Row>
                ) : (
                  ''
                )}

                {listingOffer?.enquiry?.gstPercentage > 0 ? (
                  <Row justify="space-between" className="my-1">
                    <Text>GST ({listingOffer?.enquiry?.gstPercentage}%)</Text>
                    <NumberText bold value={gst} prefix={listingOffer.currency} />
                  </Row>
                ) : (
                  <Row justify="space-between" className="my-1">
                    <Text>GST</Text>
                    <Text strong>Included</Text>
                  </Row>
                )}

                <Divider className="my-0" />
                <Row justify="space-between" className="my-1">
                  <Text>Grand Total (Approx.)</Text>
                  <NumberText bold value={grandTotal} prefix={listingOffer.currency} />
                </Row>
                <Divider className="my-0" />
                <Tag icon={<InfoCircleOutlined />} color="#B4B4B4" className="my-3">
                  Final invoice amount will be calculated after loading
                </Tag>

                <Paragraph strong>Terms of Payment</Paragraph>
                <Text>{listingOffer?.paymentTerm?.name}</Text>
                <div className="mt-2">
                  <Paragraph strong>Additional Information</Paragraph>
                  <Paragraph>&bull; Quality Testing - {listingOffer?.qualityTesting}</Paragraph>
                  <Paragraph>&bull; Quality Remarks - {listingOffer?.remark}</Paragraph>
                </div>
              </Col>
            </Row>
          </>
        )}

        {showActions ? (
          <Row gutter={[24, 16]}>
            <Col xs={24} sm={24} md={16} lg={12}>
              <Button type="primary" block size="large" onClick={onAcceptOrder}>
                Accept Order
              </Button>
            </Col>
            <Col xs={24} sm={24} md={16} lg={12}>
              <Button type="primary" ghost block size="large" onClick={() => setVisible(true)}>
                Reject Order
              </Button>
            </Col>
          </Row>
        ) : (
          <Row gutter={16}>
            <Col xs={24} sm={24} md={16} lg={12}>
              <Alert
                type={listingOffer.status === 'Rejected' ? 'error' : 'info'}
                showIcon
                message={listingOffer.status === 'Accepted' ? 'Order has been Accepted' : listingOffer.status === 'Rejected' ? 'Order has been Rejected' : 'Order is Yet to be Accepted'}
              />
            </Col>
          </Row>
        )}
      </div>
      <Modal
        title="Are you sure you want to reject order?"
        open={visible}
        onOk={handleOk}
        closeIcon={<ModalCloseIcon />}
        okText={closingLoader ? 'Rejecting..' : 'Reject'}
        okType="danger"
        okButtonProps={{ loading: closingLoader }}
        onCancel={handleCancel}>
        <div>
          <div>
            <Input.TextArea
              value={closingReason}
              maxLength={200}
              placeholder="Enter valid closing reason"
              onChange={(e) => {
                setClosingReason(e.target.value);
                setError(null);
              }}
            />
          </div>
          <motion.div
            initial={{ opacity: 0, marginBottom: 0 }}
            animate={{
              opacity: error ? 1 : 0,
              marginTop: error ? 20 : 0,
              marginBottom: error ? 40 : 0,
            }}>
            <Alert type={'error'} showIcon message={error}></Alert>
          </motion.div>
        </div>
      </Modal>
    </>
  );
};

const WholesaleListingDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [accessDenied, setAccessDenied] = useState(false);
  const [listing, setListing] = useState(null);
  const [listingOffers, setListingOffers] = useState([]);
  const [visible, setVisible] = useState(false);
  const [extendVisible, setExtendVisible] = useState(false);
  const [closingReason, setClosingReason] = useState(null);
  const [reason, setReason] = useState(null);
  const [error, setError] = useState(null);
  const [closingLoader, setClosingLoader] = useState(false);

  useEffect(() => {
    onLoad();
  }, []);

  const onLoad = async () => {
    setLoading(true);
    try {
      const businessRes = await axios.get(`${BUSINESS_URL}/me`);
      const res = await axios.get(`${LISTING_URL}/${id}?&populate=true`);
      if (businessRes?.data?.business?._id !== res.data.openlisting?.seller?._id) {
        setAccessDenied(true);
        return setLoading(false);
      }

      const offerRes = await axios.get(`${LISTING_OFFER_URL}?&populate=true`, {
        headers: {
          'X-API-Filters': JSON.stringify({
            enquiry: [id],
          }),
        },
      });
      if (res.data.status === 'error') {
        message.error(res.data.error);
      } else if (offerRes.data.status === 'error') {
        message.error(offerRes.data.error);
      } else {
        setListing(res.data.openlisting);
        setListingOffers(offerRes.data.openlistingoffers);
      }
    } catch (err) {
      message.error(err.message);
    }
    setLoading(false);
  };

  const handleDelete = () => {
    if (!reason) return setError('Please select valid closing reason');
    if (reason === 'Others' && !closingReason) return setError('Please provide valid closing reason');
    setClosingLoader(true);
    setTimeout(async () => {
      try {
        const res = await axios.put(`${LISTING_URL}/${id}`, {
          status: 'Closed',
          metadata: {
            closingReason: reason === 'Others' ? closingReason : reason,
          },
        });
        if (res.data.status === 'error') {
          setError(res.data.error);
        } else {
          setVisible(false);
          message.success('Open Listing has been deleted successfully');
          navigate('/sell/my-listings/wholesale/');
          setClosingReason(null);
          setReason(null);
          setError(null);
        }
      } catch (err) {
        setError(err.message);
      }
      setClosingLoader(false);
    }, 100);
  };

  const onRepost = (reload) => {
    if (reload) {
      onLoad();
    }
    setExtendVisible(!extendVisible);
  };

  const handleCancel = () => {
    setVisible(false);
    setClosingLoader(false);
    setClosingReason(null);
    setReason(null);
    setError(null);
  };

  const items = [
    {
      key: 'remove-open-listing',
      label: (
        <Button type="text" onClick={() => setVisible(true)}>
          Remove Open Listing
        </Button>
      ),
    },
  ];

  if (loading) return <Loader />;

  if (accessDenied) return <AccessDenied />;

  const isExpired = listing?.metadata?.expiresOn ? new Date(listing?.metadata?.expiresOn) < new Date() : true;
  const expiringIn = listing?.metadata?.expiresOn ? differenceInDays(new Date(listing?.metadata?.expiresOn), new Date()) : 0;

  return (
    <>
      <div className="app-container">
        <PageHeader
          onBack={() => navigate(-1)}
          backIcon={<BackIcon />}
          title={`${listing?.product?.name} | ${listing?.qty} ${listing?.subUnit}`}
          extra={
            ['Requested', 'Created'].includes(listing?.status) && (
              <Dropdown menu={{ items }} trigger="click">
                <Button type="text" icon={<MoreOutlined />} size="large" />
              </Dropdown>
            )
          }
          className="px-0"
        />
      </div>

      <Divider className="mt-1 mb-6" />
      <div className="app-container">
        <ErrorBoundary>
          <Row gutter={[24, 16]}>
            <Col xs={24} sm={24} md={10} lg={10}>
              <Image
                alt="Product Image"
                style={{
                  width: '100%',
                  height: '300',
                  objectFit: 'cover',
                  borderRadius: '15px',
                }}
                src={`${UPLOAD_URL}/${listing?.product?.image}`}
              />
              <Tooltip placement="right" title={'Expires on ' + new Date(listing?.metadata?.expiresOn).toDateString()}>
                <Tag color="red" style={{ position: 'absolute', top: 16, right: 16 }}>
                  <ClockCircleOutlined />
                  &nbsp; Expires&nbsp;
                  <TimeAgo datetime={listing.metadata?.expiresOn} locale="en_US" />
                </Tag>
              </Tooltip>
            </Col>

            <Col xs={24} sm={24} md={14} lg={14}>
              <Title level={3} className="fw-bold">
                {listing?.product?.name}
              </Title>
              <Title level={4} className="fw-medium">
                <NumberText prefix={listing?.currency} value={listing?.purchasingPrice / 100} suffix={`/ ${listing?.subUnit}`} />
              </Title>

              <Row gutter={[16, 8]} className="my-3">
                {listing?.metadata?.isNegotiable && (
                  <Tag
                    className="rounded px-2 py-1 border-0"
                    color="cyan"
                    icon={<Avatar src={require('assets/images/icon-price-negotiable-green.png')} shape="square" size={20} className="rounded-0" />}>
                    &nbsp;Price is Negotiable
                  </Tag>
                )}
                <Tag className="rounded px-2 py-1 border-0" color="gold" icon={<Avatar src={require('assets/images/icon-transport-yellow.png')} shape="square" size={16} className="rounded-0" />}>
                  &nbsp;
                  {listing?.transportCharges > 0
                    ? `Delivery Charges Extra (${listing?.currency === 'USD' ? '$' : '₹'}${listing?.transportCharges / 100} / ${listing?.subUnit})`
                    : `Delivery ${listing?.transportType}`}
                </Tag>
                <Tag className="rounded px-2 py-1 border-0" color="gold" icon={<Avatar src={require('assets/images/icon-gst-yellow.png')} shape="square" size={16} className="rounded-0" />}>
                  &nbsp;{listing?.gstPercentage > 0 ? `GST Extra (${listing?.gstPercentage}%)` : 'GST Included'}
                </Tag>
              </Row>

              <Paragraph className="fw-medium" type="secondary">
                DETAILS
              </Paragraph>
              <Space direction="vertical" className="my-3" style={{ width: '100%' }}>
                <Row gutter={[16, 16]} className="mt-2">
                  <Col span={12}>
                    <DetailsGrid
                      icon={<Avatar src={require('assets/images/icon-quantity.png')} shape="square" size="default" />}
                      title="Available Quantity"
                      description={
                        <Text strong>
                          {listing?.qty} {listing?.subUnit} {listing?.frequency === 'Once' && '/ Once'}
                        </Text>
                      }
                    />
                  </Col>
                  {listing?.frequency === 'Monthly' && (
                    <Col span={12}>
                      <DetailsGrid title="Available For" description={`${listing.duration} Months`} />
                    </Col>
                  )}
                </Row>

                <Divider className="my-3" />

                <Row gutter={[16, 16]} align="middle">
                  <Col span={12}>
                    <DetailsGrid
                      icon={<Avatar src={require('assets/images/icon-location-dark.png')} shape="square" size="default" />}
                      title="Pickup Location"
                      description={`${listing?.pickupAddress?.city}, ${listing?.pickupAddress?.state}`}
                    />
                  </Col>

                  {listing?.preferredDeliveryOn && (
                    <Col span={12}>
                      <DetailsGrid title="Preferred Delivery On" description={format(new Date(listing?.preferredDeliveryOn), 'dd MMM yyyy')} />
                    </Col>
                  )}
                </Row>

                <Divider className="my-3" />

                <DetailsGrid icon={<Avatar src={require('assets/images/icon-cash.png')} shape="square" size="default" />} title="Payment Terms" description={listing?.paymentTerm?.name} />

                <Divider className="my-3" />

                <DetailsGrid icon={<Avatar src={require('assets/images/icon-quality.png')} shape="square" size="default" />} title="Quality Remarks" description={listing?.remark || 'N/A'} />
              </Space>

              <Row gutter={[16, 8]} align="middle" className="my-2">
                {listing?.qualityReportFile && (
                  <Col xs={24} sm={12} md={12} lg={12}>
                    <Button type="link" href={`${UPLOAD_URL}/${listing?.qualityReportFile}`} icon={<FileTextOutlined />} target="_blank">
                      View Quality Report
                    </Button>
                  </Col>
                )}
                {listing?.productImageFile && (
                  <Col xs={24} sm={12} md={12} lg={12}>
                    <Button type="link" href={`${UPLOAD_URL}/${listing?.productImageFile}`} icon={<FileImageOutlined />} target="_blank">
                      View Product Image
                    </Button>
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
          {!isExpired && expiringIn <= 3 && (
            <Row gutter={16} className="mt-3" style={{ marginBottom: '50px' }}>
              <Col span={24}>
                <Alert
                  className="py-1 px-2 text-small"
                  message={<Text type="danger">Expiring Soon</Text>}
                  type="error"
                  showIcon={true}
                  icon={<ClockCircleOutlined />}
                  action={
                    <Button type="link" size="small" onClick={() => onRepost()}>
                      Extend Expiration
                    </Button>
                  }
                />
              </Col>
            </Row>
          )}
        </ErrorBoundary>
      </div>
      {listingOffers.length > 0 && (
        <div className="app-container">
          <Divider />
          <Title level={5}>
            Order{listingOffers.length > 1 ? 's' : ''} Received ({listingOffers.length})
          </Title>
          <List
            header={null}
            footer={null}
            grid={{ gutter: 16, column: 1 }}
            dataSource={listingOffers}
            pagination={false}
            split={false}
            renderItem={(listingOffer) => (
              <List.Item key={listingOffer?._id}>
                {/* Please find the component declared at top this file */}
                <ReceivedOrder listing={listing} listingOffer={listingOffer} onRefresh={onLoad} />
                <Divider />
              </List.Item>
            )}
          />
        </div>
      )}

      <Modal
        title="Are you sure you want to delete this Open Listing?"
        open={visible}
        closeIcon={<ModalCloseIcon />}
        onOk={handleDelete}
        okType="danger"
        okText={closingLoader ? 'Deleting..' : 'Delete'}
        okButtonProps={{ loading: closingLoader }}
        onCancel={handleCancel}>
        <div>
          <div>
            <Alert
              className="mb-4"
              type="warning"
              showIcon
              description="Please note that once this Open Listing is deleted, it won't be possible to accept any new orders for it. This action is irreversible, so please proceed with caution."
            />
            <Select
              placeholder="Select Reason"
              size="large"
              style={{ width: '250px', marginBottom: '15px' }}
              value={reason}
              onChange={(reason) => {
                setError(null);
                setReason(reason);
              }}>
              <Select.Option value="Invalid Quantity / Invalid Price">Invalid Quantity / Invalid Price</Select.Option>
              <Select.Option value="Mistakenly Created">Mistakenly Created</Select.Option>
              <Select.Option value="Listing Changes">Listing Changes</Select.Option>
              <Select.Option value="Others">Others</Select.Option>
            </Select>
            {reason === 'Others' && (
              <Input.TextArea
                value={closingReason}
                maxLength={200}
                placeholder="Enter valid closing reason"
                onChange={(e) => {
                  setClosingReason(e.target.value);
                  setError(null);
                }}
              />
            )}
          </div>
          <motion.div
            initial={{ opacity: 0, marginBottom: 0 }}
            animate={{
              opacity: error ? 1 : 0,
              marginTop: error ? 20 : 0,
              marginBottom: error ? 40 : 0,
            }}>
            <Alert type={'error'} showIcon message={error}></Alert>
          </motion.div>
        </div>
      </Modal>
      <ExtendListingExpiration visible={extendVisible} close={onRepost} listing={listing} />
    </>
  );
};

export default WholesaleListingDetails;
