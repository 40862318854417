import React, { useEffect, useState } from 'react';
import { Avatar, Button, Card, Col, Divider, List, Row, Typography, message } from 'antd';
import axios from 'axios';
import { BsStarFill, BsStarHalf } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import ContentLoader from 'react-content-loader';
import DetailsGrid from 'components/UtilComponents/DetailsGrid';
import RateOrder from 'components/Modals/RateOrder';
import { ORDER_URL, UPLOAD_URL } from 'constants/ApiConstants';
import { formatRefNo } from 'utils/formatter';
import CreateOpenListing from 'components/Modals/Sell/CreateOpenListing';
import IconOrders from 'assets/images/orders.png';
import EmptyContent from 'components/UtilComponents/EmptyContent';
import { getBreakPoint } from 'utils/layout';
import { isEmpty } from 'lodash';
import NumberText from 'components/UtilComponents/NumberText';

const { Text, Title } = Typography;

const CompletedOrders = ({ tab }) => {
  const isMobile = !getBreakPoint().includes('sm');
  const navigate = useNavigate();
  const [showOpenListing, setShowOpenListing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState({});
  const [rateOrderVisible, setRateOrderVisible] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 8,
    total: 0,
    size: 'middle',
    showSizeChanger: false,
    className: isMobile && 'custom-pagination',
  });

  useEffect(() => {
    if (tab === 'completed-orders') {
      onLoadOrders();
    } else {
      setPagination({ ...pagination, current: 1 });
    }
  }, [tab, pagination.current]);

  const onLoadOrders = async () => {
    setLoading(true);
    try {
      let currentPage = pagination.current;
      const localData = await JSON.parse(localStorage.getItem('filters'));
      if (!isEmpty(localData)) {
        currentPage = localData.current;
      }

      const res = await axios.get(`${ORDER_URL}?page=${currentPage}&limit=${pagination.pageSize}&populate=true`, {
        headers: {
          'X-API-Filters': JSON.stringify({ seller: ['me'], status: ['Preclosed', 'Completed'] }),
          'X-API-Sort': JSON.stringify({ createdAt: 'desc' }),
        },
      });
      if (res.data.status === 'error') {
        message.error(res.data.error);
      } else {
        setOrders(res.data.orders);
        setPagination({
          ...pagination,
          current: currentPage,
          total: res.data.count,
        });
      }
      localStorage.removeItem('filters');
    } catch (err) {
      message.error(err.message);
    }
    setLoading(false);
  };

  const onViewOrder = (orderId) => {
    localStorage.setItem('filters', JSON.stringify({ current: pagination.current }));
    navigate(`/sell/orders/completed-orders/${orderId}`);
  };

  const showRateOrder = (reload) => {
    if (reload) {
      onLoadOrders();
    }
    setRateOrderVisible(!rateOrderVisible);
  };

  const onCreateWholesaleOpenListing = () => {
    setShowOpenListing(!showOpenListing);
  };

  if (loading) {
    return (
      <ContentLoader width="100%" height="70vh" backgroundColor="#d9d9d9" foregroundColor="#ededed">
        <rect x="0" y="25" rx="10" ry="10" width="48%" height="211" />
        <rect x="50%" y="25" rx="10" ry="10" width="50%" height="211" />
        <rect x="0" y="250" rx="10" ry="10" width="48%" height="211" />
        <rect x="50%" y="250" rx="10" ry="10" width="50%" height="211" />
      </ContentLoader>
    );
  }

  return (
    <>
      {orders?.length > 0 ? (
        <List
          className="my-4"
          header={null}
          footer={null}
          grid={{ gutter: [16, 4], column: isMobile ? 1 : 2 }}
          dataSource={orders}
          pagination={{ ...pagination, onChange: (page) => setPagination({ ...pagination, current: page }) }}
          renderItem={(order) => {
            const { product } = order;
            return (
              <>
                <List.Item>
                  <Card size={isMobile ? 'small' : 'default'}>
                    <Row align="middle" justify="flex-start" className="mb-3">
                      <Col span={18}>
                        <Row gutter={10} justify="flex-start" wrap={false} className="mb-3">
                          <Col>
                            <Avatar src={`${UPLOAD_URL}/${order?.product?.image}`} shape="square" size="large" />
                          </Col>
                          <Col>
                            <Title level={5} className="fw-medium">
                              {product?.name}
                            </Title>
                            <NumberText value={order?.purchasingPrice / 100} prefix={order?.currency} suffix={`/ ${order?.subUnit}`} />
                          </Col>
                        </Row>
                      </Col>
                      <Col flex={1} className="d-flex justify-content-end">
                        <Button type="primary" onClick={() => onViewOrder(order?._id)}>
                          View
                        </Button>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={24} sm={24} md={24} lg={12}>
                        <DetailsGrid
                          icon={<Avatar src={require('assets/images/po-number.png')} shape="square" size="small" />}
                          title="Order Number"
                          description={<Text strong>{formatRefNo(order?.orderRefNo)}</Text>}
                        />
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={12}>
                        <DetailsGrid
                          icon={<Avatar src={require('assets/images/icon-status.png')} shape="square" size="small" />}
                          title="Order Status"
                          description={<Text strong>{order?.status}</Text>}
                        />
                      </Col>
                    </Row>
                    <Divider dashed={true} className="my-3" />
                    {/* //order?.qty || '∞'} {order?.subUnit */}
                    <Row gutter={16} className="mt-3">
                      <Col span={12}>
                        <DetailsGrid
                          icon={<Avatar src={require('assets/images/total-quantity.png')} shape="square" size="small" />}
                          title="Total Quantity"
                          description={
                            <Text strong>
                              {order?.qty || '∞'} {order?.subUnit}
                            </Text>
                          }
                        />
                      </Col>
                      <Col span={12}>
                        <DetailsGrid
                          icon={<Avatar src={require('assets/images/dispatched-quantity.png')} shape="square" size="small" />}
                          title="Dispatch Quantity"
                          description={
                            <Text strong>
                              {order?.metadata?.dispatchedQty}
                              {order?.subUnit}
                            </Text>
                          }
                        />
                      </Col>
                    </Row>

                    <Divider dashed={true} className="my-3" />

                    <Row gutter={16} align="middle">
                      {!order?.metadata?.buyerRating && (
                        <Col span={12}>
                          <Button
                            type="primary"
                            ghost
                            block
                            onClick={() => {
                              showRateOrder();
                              setSelectedOrder(order);
                            }}>
                            Rate Order
                          </Button>
                        </Col>
                      )}
                      {order?.metadata?.buyerRating && (
                        <Col span={12}>
                          <Row align="middle">
                            <Text>Rating: &nbsp;</Text>
                            {order?.metadata?.buyerRating === 5 ? <BsStarFill style={{ color: '#ffc53d', fontSize: '18px' }} /> : <BsStarHalf style={{ color: '#ffc53d', fontSize: '18px' }} />}
                            &nbsp;
                            <Text>{order?.metadata?.buyerRating} / 5</Text>
                          </Row>
                        </Col>
                      )}
                    </Row>
                  </Card>
                </List.Item>
              </>
            );
          }}
        />
      ) : (
        <EmptyContent source={IconOrders} title={'No Completed Orders'} subTitle={'There are no completed orders found right now.'} />
      )}
      <CreateOpenListing visible={showOpenListing} close={onCreateWholesaleOpenListing} />
      <RateOrder type="buyer" order={selectedOrder} visible={rateOrderVisible} close={showRateOrder} />
    </>
  );
};

export default CompletedOrders;
