import React, { useEffect, useState } from 'react';
import { Button, Divider, List, PageHeader, Typography, message } from 'antd';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import ContentLoader from 'react-content-loader';
import { MAXIMUM_ACTIVE_ITEMS, OPEN_RFQ_URL } from 'constants/ApiConstants';
import CreateNewOpenRFQ from 'components/Modals/Buy/CreateNewOpenRFQ';
import BackIcon from 'components/UtilComponents/BackIcon';
import MyOpenRFQCard from 'components/Cards/MyOpenRFQCard';
import ErrorBoundary from 'components/Errors/ErrorBoundary';
import EmptyContent from 'components/UtilComponents/EmptyContent';
import IconMegaphone from 'assets/images/megaphone.png';
import { getBreakPoint } from 'utils/layout';

const { Paragraph } = Typography;

const OpenRFQs = () => {
  const isMobile = !getBreakPoint().includes('sm');
  const navigate = useNavigate();
  const [showOpenRFQ, setShowOpenRFQ] = useState(false);
  const [activeDataSource, setActiveDataSource] = useState([]);
  const [expiredDataSource, setExpiredDataSource] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    onLoad();
  }, []);

  const onLoad = async () => {
    if (loading) return;
    setLoading(true);
    try {
      const activeRes = await axios.get(`${OPEN_RFQ_URL}?&populate=true`, {
        headers: {
          'X-API-Filters': JSON.stringify({
            buyer: ['me'],
            status: ['Created'],
          }),
          'X-API-Sort': JSON.stringify({ createdAt: 'desc' }),
        },
      });
      const expiredRes = await axios.get(`${OPEN_RFQ_URL}?&populate=true`, {
        headers: {
          'X-API-Filters': JSON.stringify({
            buyer: ['me'],
            status: ['Expired'],
          }),
          'X-API-Sort': JSON.stringify({ createdAt: 'desc' }),
        },
      });
      if (activeRes.data.status === 'error') {
        message.error(activeRes.data.error);
      } else if (expiredRes.data.status === 'error') {
        message.error(expiredRes.data.error);
      } else {
        setActiveDataSource(activeRes.data.openrequirements);
        setExpiredDataSource(expiredRes.data.openrequirements);
      }
    } catch (err) {
      message.error(err.message);
    }
    setLoading(false);
  };

  const onCreateNewOpenRFQ = (reload) => {
    if (reload) {
      onLoad();
    }
    setShowOpenRFQ(!showOpenRFQ);
  };

  return (
    <div className="app-container">
      <PageHeader onBack={() => navigate('/buy')} backIcon={<BackIcon />} title="My Requirements" className="px-0" />
      <ErrorBoundary>
        {loading && (
          <ContentLoader width="100%" height="70vh" backgroundColor="#d9d9d9" foregroundColor="#ededed">
            <rect x="0" y="25" rx="10" ry="10" width="48%" height="211" />
            <rect x="50%" y="25" rx="10" ry="10" width="50%" height="211" />
            <rect x="0" y="250" rx="10" ry="10" width="48%" height="211" />
            <rect x="50%" y="250" rx="10" ry="10" width="50%" height="211" />
          </ContentLoader>
        )}

        {!loading && (
          <>
            {activeDataSource.length > 0 && (
              <>
                <List
                  className="my-4"
                  grid={{ gutter: [24, 12], column: isMobile ? 1 : 2 }}
                  dataSource={activeDataSource}
                  pagination={false}
                  renderItem={(rfq) => <MyOpenRFQCard rfq={rfq} onRefresh={onLoad} />}
                />
                <Button type="primary" size="large" className="px-5 mb-2" onClick={() => onCreateNewOpenRFQ()} disabled={activeDataSource.length >= MAXIMUM_ACTIVE_ITEMS}>
                  Create New Open RFQ
                </Button>
                <Paragraph type="secondary" className="text-small">
                  {MAXIMUM_ACTIVE_ITEMS - activeDataSource.length > 0 ? MAXIMUM_ACTIVE_ITEMS - activeDataSource.length : 0} Open RFQ Left.
                </Paragraph>
              </>
            )}

            {activeDataSource.length === 0 && (
              <EmptyContent
                source={IconMegaphone}
                title={'Find what you want, Easily'}
                subTitle={'Create an Open RFQ and attract quotes from sellers.'}
                onActionPress={() => onCreateNewOpenRFQ()}
                actionText={'Create Open RFQ'}
              />
            )}

            {expiredDataSource.length > 0 && (
              <>
                <Divider />
                <Paragraph>Expired Open Requirements</Paragraph>
                <List
                  className="my-4"
                  grid={{ gutter: [24, 8], column: isMobile ? 1 : 2 }}
                  dataSource={expiredDataSource}
                  pagination={true}
                  renderItem={(rfq) => <MyOpenRFQCard rfq={rfq} onRefresh={onLoad} />}
                />
              </>
            )}
          </>
        )}

        <CreateNewOpenRFQ visible={showOpenRFQ} close={onCreateNewOpenRFQ} />
      </ErrorBoundary>
    </div>
  );
};

export default OpenRFQs;
