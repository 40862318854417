import React, { useEffect, useState } from 'react';
import { Button, Calendar, Checkbox, Col, Collapse, Divider, Form, Image, Input, InputNumber, Modal, Radio, Row, Select, Space, Tooltip, Typography, message } from 'antd';
import { ExclamationCircleOutlined, PlusOutlined, CheckOutlined } from '@ant-design/icons';
import ErrorBoundary from 'components/Errors/ErrorBoundary';
import ModalCloseIcon from 'components/UtilComponents/ModalCloseIcon';
import AddNewAddress from 'components/UtilComponents/AddNewAddress';
import { addDays, format, startOfDay } from 'date-fns';
import moment from 'moment';
import axios from 'axios';
import { keyBy } from 'lodash';
import { BUSINESS_URL, COMMON_URL, OPEN_RFQ_URL, PAYMENT_TERMS_URL, PRODUCT_URL, SAVED_ADDRESS_URL, UPLOAD_URL } from 'constants/ApiConstants';
import { posthog } from 'posthog-js';
import Loader from 'components/UtilComponents/Loader';
import enquiryPosted from 'assets/images/enquiry-posted.png';
import GreatIcon from 'assets/images/great.png';
import { checkForAnomaly } from 'utils/anomaly';

const { Paragraph, Title, Text } = Typography;

const CreateNewOpenRFQ = ({ visible, close }) => {
  const viewName = 'buy';
  const [form] = Form.useForm();
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [productsById, setProductsById] = useState([]);
  const [product, setProduct] = useState(null);
  const [unit, setUnit] = useState('');
  const [subUnits, setSubUnits] = useState([]);
  const [savedAddresses, setSavedAddresses] = useState([]);
  const [savedAddressesById, setSavedAddressesById] = useState([]);
  const [paymentTerms, setPaymentTerms] = useState([]);
  const [paymentTermsById, setPaymentTermsById] = useState(null);
  const [deliveryAddress, setDeliveryAddress] = useState(null);
  const [showAddAddress, setShowAddAddress] = useState(false);
  const [qualities, setQualities] = useState([]);
  const [pincode, setPincode] = useState(null);
  const [currentQuantity, setCurrentQuantity] = useState(null);
  const [showMore, setShowMore] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [qualitiesById, setQualitiesById] = useState(true);

  useEffect(() => {
    if (!visible) {
      setProduct(null);
      setSubUnits([]);
      setUnit(null);
      setSubUnits([]);
      setDeliveryAddress(null);
      setQualities([]);
      setShowMore(false);
      setCurrentQuantity(null);
      setPincode(null);
      form.resetFields();
    } else {
      onLoad();
    }
  }, [visible]);

  useEffect(() => {
    if (product) {
      setQualities([]);
      onLoadProduct();
    }
  }, [product]);

  useEffect(() => {
    shouldDisableButton();
  }, [deliveryAddress, qualities]);

  useEffect(() => {
    setDeliveryAddress(null);
  }, [pincode]);

  const onLoad = async () => {
    setLoading(true);
    try {
      const businessRequest = await axios.get(`${BUSINESS_URL}/me?populate=true`);

      const addressRequest = await axios.get(`${SAVED_ADDRESS_URL}`, {
        headers: {
          'X-API-Filters': JSON.stringify({
            business: ['me'],
            status: ['Active'],
          }),
        },
      });

      const termRequest = await axios.get(`${PAYMENT_TERMS_URL}`);
      const [businessRes, addressRes, termRes] = await Promise.all([businessRequest, addressRequest, termRequest]);

      if (businessRes.data.status === 'error') {
        message.error(businessRes.data.error);
      } else if (addressRes.data.status === 'error') {
        message.error(addressRes.data.error);
      } else if (termRes.data.status === 'error') {
        message.error(termRes.data.error);
      } else {
        setProducts(businessRes.data.business.buying);
        setProductsById(keyBy(businessRes.data.business.buying, '_id'));
        const savedAddresses = addressRes.data.savedaddresses.filter((obj) => obj.type === 'Saved');
        setSavedAddresses(savedAddresses);
        setPaymentTerms(termRes.data.paymentterms);
        if (savedAddresses.length > 0) {
          form.setFields([{ name: 'deliveryAddressId', value: savedAddresses[0]?._id }]);
          setDeliveryAddress(savedAddresses[0]?._id);
          setSavedAddressesById(keyBy(savedAddresses, '_id'));
        }
        if (termRes.data.paymentterms.length > 0) {
          form.setFields([{ name: 'paymentTerm', value: termRes?.data?.paymentterms[0]?._id }]);
          setPaymentTermsById(keyBy(termRes.data.paymentterms, '_id'));
        }
        form.setFields([{ name: 'autoPriceUpdate', value: true }]);
      }
    } catch (err) {
      message.error(err.message);
    }
    setLoading(false);
  };

  const onLoadProduct = async () => {
    try {
      const productRes = await axios.get(`${PRODUCT_URL}/${product._id}?populate=true`);
      if (productRes.data.status === 'error') {
        message.error(productRes.data.error);
      } else {
        const subUnit = productRes.data.product.unit.subUnits[0]?._id;
        setSubUnits(productRes.data.product.unit.subUnits);
        setUnit(subUnit);
        setQualities(productRes.data.product?.qualities || []);
        setQualitiesById(keyBy(productRes.data.product?.qualities, '_id'));
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  /* Create New Open RFQ on Submit */
  const onSubmit = async () => {
    if (loading) return;
    setLoading(true);
    try {
      let values = form.getFieldsValue(true);
      let addressRes = null;

      if (pincode) {
        const addressData = {
          name: 'RFQ Delivery',
          line1: values.deliveryLine1,
          line2: values.deliveryLine2 && values.deliveryLine2,
          city: deliveryAddress.city,
          state: deliveryAddress.state,
          country: 'India',
          pincode: String(pincode),
          type: 'Saved',
          business: 'me', // passing 'me' will take the businessId from session
          location: { type: 'Point', coordinates: [deliveryAddress.longitude, deliveryAddress.latitude] },
          status: 'Active',
        };

        addressRes = await axios.post(`${SAVED_ADDRESS_URL}`, addressData);

        if (addressRes.data.status === 'error') {
          message.error(addressRes.data.error);
          if (step === 2) setStep(1);
          else setStep(3);
          return setLoading(false);
        }
      }
      const address = addressRes?.data?.savedaddress;

      const res = await axios.post(`${OPEN_RFQ_URL}`, {
        product: values.product,
        productQuality: values?.quality,
        subUnit: unit,
        currency: 'INR',
        fromQty: values.quantity,
        toQty: 0,
        frequency: values.frequency,
        metadata: { autoPriceUpdate: values.autoPriceUpdate },
        price: values.price * 100,
        deliveryAddressId: pincode ? address._id : values.deliveryAddressId,
        paymentTerm: values.paymentTerm,
        preferredDeliveryOn: values.preferredDeliveryOn?.format('YYYY-MM-DD') ? new Date(values.preferredDeliveryOn?.format('YYYY-MM-DD')) : addDays(new Date(), 30),
        qualityTesting: values.qualityTesting ? 'Requested' : 'Not Requested',
        trialQuantity: values.trialQuantity ? 'Requested' : 'Not Requested',
        remark: values.remark || '',
        duration: 0,
      });

      if (res.data.status === 'success') {
        message.success('Open RFQ created successfully');
        posthog.capture(`$${viewName} | $open-rfq-created`, {
          data: {
            product: product.name,
            fromQty: values.fromQty,
            toQty: values.toQty,
            subUnit: values.subUnit,
            currency: values.currency,
            frequency: values.frequency,
            duration: 0,
            city: savedAddressesById[values.deliveryAddressId]?.city,
            state: savedAddressesById[values.deliveryAddressId]?.state,
          },
          $set: {
            lastActivityDate: new Date().toISOString(),
          },
        });
        setStep(4);
      } else {
        if (step === 2) setStep(1);
        else setStep(3);
        message.error(res.data.error);
      }
    } catch (err) {
      message.error(err.message);
    }
    setLoading(false);
  };

  const onLocateDeliveryAddress = async () => {
    setDeliveryAddress(null);
    if (!pincode) {
      return;
    }
    try {
      const res = await axios.get(`${COMMON_URL}/geocode/pincode/${pincode}`);
      if (res.data.status === 'error') {
        message.error(res.data.error);
      } else {
        const address = res.data.address;
        setDeliveryAddress(address);
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  useEffect(() => {
    const modalBody = document.querySelector(`.ant-modal-body`);
    if (modalBody) {
      modalBody.scrollTop = 0;
    }
  }, [step]);

  const onClickNext = async (addMore) => {
    try {
      if (step === 4) {
        setStep(1);
        form.resetFields();
        close(true);
      } else if (step === 3 || (step === 2 && !addMore)) {
        await onSubmit();
      } else if (step === 2 && addMore) {
        setStep(3);
      } else {
        const values = form.getFieldsValue(true);
        const detectedAnomaly = checkForAnomaly('create-rfq', { ...values, product: productsById[values?.product], subUnits, subUnit: unit });
        if (detectedAnomaly) {
          setLoading(false);
          return message.error(detectedAnomaly);
        }
        await form.validateFields();
        setStep(2);
      }
    } catch (error) {
      console.error('InComplete Form:', error);
    }
  };

  const getTitle = () => {
    return (
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '1rem' }}>
        <div>
          <Title style={{ margin: '0px', fontWeight: 'bold' }} level={4}>
            Post your Requirement
          </Title>
          <Paragraph
            style={{
              fontSize: '10px',
            }}
            type="secondary">
            It will notify potential sellers around you!
          </Paragraph>
        </div>
        <Button shape="circle" className="mb-3" type="text" onClick={confirmOnClose}>
          <ModalCloseIcon />
        </Button>
      </div>
    );
  };

  const onCloseAddAddress = (reload) => {
    if (reload) {
      onLoad();
    }
    setShowAddAddress(!showAddAddress);
  };

  const confirmOnClose = () => {
    if (!loading && form.isFieldsTouched()) {
      const values = form.getFieldsValue();
      Modal.confirm({
        title: 'Unsaved Data',
        icon: <ExclamationCircleOutlined />,
        content: 'This form has unsaved data. Are you sure you want to close the screen?',
        okText: 'Yes',
        onOk: () => {
          setStep(1);
          if (step === 3) {
            form.setFieldsValue({ preferredDeliveryOn: moment(addDays(new Date(), 30)), remark: null, qualityTesting: null, trialQuantity: null });
            return setStep(2);
          }
          form.resetFields();
          close();
          posthog.capture(`$${viewName} | $create-open-rfq-attempted`, {
            data: {
              product: product?.name,
              fromQty: values.fromQty ? values.fromQty : null,
              toQty: values.toQty ? values.toQty : null,
              subUnit: values.subUnit ? values.subUnit : null,
              currency: values.currency ? values.currency : null,
              frequency: values.frequency ? values.frequency : null,
              duration: values.duration ? values.duration : null,
              city: savedAddressesById[values.deliveryAddressId]?.city,
              state: savedAddressesById[values.deliveryAddressId]?.state,
            },
            $set: {
              lastActivityDate: new Date().toISOString(),
            },
          });
        },
        cancelText: 'No',
      });
    } else {
      setStep(1);
      form.resetFields();
      close();
    }
  };

  const units = (
    <Select defaultValue="MT" onChange={(e) => setUnit(e)} value={unit}>
      {subUnits.map((e) => (
        <Option value={e._id}>{e._id}</Option>
      ))}
    </Select>
  );

  const shouldDisableButton = () => {
    let formCompletion =
      form.getFieldsValue(true)?.product &&
      form.getFieldsValue(true)?.quantity !== null &&
      form.getFieldsValue(true)?.quantity !== undefined &&
      form.getFieldsValue(true)?.frequency &&
      form.getFieldsValue(true)?.price !== null &&
      form.getFieldsValue(true)?.price !== undefined;

    if (qualities?.length > 1) formCompletion = formCompletion && form.getFieldsValue(true)?.quality;

    if (savedAddresses.length < 1) formCompletion = formCompletion && deliveryAddress && form.getFieldsValue(true)?.deliveryLine1;
    else formCompletion = formCompletion && deliveryAddress ? true : false;

    if (step === 4) formCompletion = true;
    setButtonDisabled(formCompletion);
  };

  return (
    <Modal
      open={visible}
      onCancel={confirmOnClose}
      closable={!step == 4}
      closeIcon={step == 4 ? null : <ModalCloseIcon />}
      maskClosable={step === 4 ? false : true}
      width={480}
      bodyStyle={{
        height: step === 4 ? '80vh' : '85vh',
        overflow: step === 4 ? (showMore ? 'scroll' : 'hidden') : 'scroll',
      }}
      centered
      footer={
        <Button disabled={!buttonDisabled} onClick={onClickNext} type="primary" size="large" block>
          {step === 1 && 'Continue to Post'}
          {step === 4 && 'Continue Exploring'}
        </Button>
      }>
      <ErrorBoundary>
        {loading && <Loader />}
        {!loading && step !== 4 && getTitle()}
        <Form form={form} layout="vertical" onFinish={step === 1 ? onClickNext : onSubmit} requiredMark={false} scrollToFirstError>
          {!loading && step !== 4 && (
            <>
              <Form.Item
                name="product"
                rules={[
                  {
                    required: true,
                    message: 'Please select the required Product',
                  },
                ]}>
                <Select
                  onChange={(e) => {
                    form.setFieldValue('quality', undefined);
                    shouldDisableButton();
                    setProduct(productsById[e]);
                  }}
                  placeholder="Select required product"
                  size="large">
                  {products.map((productEle) => {
                    return (
                      <Select.Option value={productEle?._id}>
                        {
                          <Row>
                            <Col span={3}>
                              <Image className="w-75 h-75 rounded" preview={false} src={`${UPLOAD_URL}/${productEle?.image}`} />
                            </Col>
                            <Col className="d-flex" span={21} style={{ alignItems: 'center' }}>
                              <Typography.Text>{productEle?.name}</Typography.Text>
                            </Col>
                          </Row>
                        }
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
              {qualities?.length > 0 ? (
                <Form.Item
                  name="quality"
                  label={<Typography.Text type="secondary">Choose quality {product ? ' of ' + product?.name : ''}</Typography.Text>}
                  rules={[
                    {
                      required: true,
                      message: 'Please select a valid Product Quality',
                    },
                  ]}>
                  <Radio.Group onChange={shouldDisableButton}>
                    <Space direction="vertical" size="middle">
                      {qualities?.map((e) => (
                        <Radio
                          className="w-100 p-2"
                          style={{
                            border: '1px solid #ccc',
                            borderRadius: '10px',
                            minWidth: '100%',
                          }}
                          value={e._id}>
                          <div>
                            <Typography.Text strong>{e?.grade}</Typography.Text>
                            <ul className="px-4">
                              {e?.descriptions?.map((e) => (
                                <li>{e}</li>
                              ))}
                            </ul>
                          </div>
                        </Radio>
                      ))}
                    </Space>
                  </Radio.Group>
                </Form.Item>
              ) : (
                ''
              )}
              <Form.Item
                label={<Text type="secondary">Quantity</Text>}
                name="quantity"
                rules={[
                  {
                    required: true,
                    message: `Please enter valid Quantity ${unit ? `in ${unit}` : ''}`,
                  },
                ]}>
                <InputNumber
                  onChange={(e) => {
                    shouldDisableButton();
                    setCurrentQuantity(e);
                  }}
                  addonBefore={unit ? units : 'MT'}
                  precision={2}
                  placeholder="Enter required quantity"
                  controls={false}
                  size="large"
                  min={1}
                  className="w-100"
                />
              </Form.Item>
              <Form.Item
                name="frequency"
                label={`How often you Buy ${currentQuantity ?? ''}${currentQuantity && unit ? unit : ''}?`}
                rules={[
                  {
                    required: true,
                    message: 'Please select a valid Frequency',
                  },
                ]}>
                <Radio.Group
                  onChange={() => {
                    shouldDisableButton();
                  }}>
                  {['Once', 'Daily', 'Weekly', 'Monthly', 'Quarterly', 'Bi-Monthly'].map((option) => (
                    <Radio.Button style={{ padding: '1px 10px' }} key={option} className={`m-sm-1 rounded `} value={option}>
                      {option}
                    </Radio.Button>
                  ))}
                </Radio.Group>
              </Form.Item>
              <Form.Item
                name="price"
                rules={[
                  {
                    required: true,
                    message: 'Please enter valid Purchasing Price',
                  },
                ]}>
                <InputNumber addonBefore="₹" onChange={shouldDisableButton} controls={false} precision={2} size="large" min={1} placeholder="Current Purchasing Price" className="w-100" />
              </Form.Item>
              <Form.Item
                name="autoPriceUpdate"
                label={<Typography.Text type="secondary">Would you like to update price automatically?</Typography.Text>}
                rules={[
                  {
                    required: true,
                    message: 'Please select a valid option',
                  },
                ]}>
                <Radio.Group defaultValue={true} onChange={shouldDisableButton}>
                  <Space direction="vertical">
                    <Radio value={true}>
                      <div>
                        <Text strong>Yes, Update the price automatically.</Text>
                        <Paragraph type="secondary">
                          From out business side, we analysis the market and update the price <b>+(current Purchasing price)</b> as per market trend.
                        </Paragraph>
                      </div>
                    </Radio>
                    <Radio value={false}>
                      <div>
                        <Text strong>No, I will update the price manually every time.</Text>
                        <Paragraph type="secondary">You will update the price manually when the price range of the product changes, According to market trend.</Paragraph>
                      </div>
                    </Radio>
                  </Space>
                </Radio.Group>
              </Form.Item>
              {savedAddresses?.length < 1 ? (
                <>
                  <Form.Item
                    style={{ margin: '5px 0px 5px 0px' }}
                    label={<Typography.Text type="secondary">Provide Delivery Location</Typography.Text>}
                    name="deliveryLocation"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter your Pin Code',
                      },
                      () => ({
                        validator(_, value) {
                          if (value.toString()?.length !== 6) {
                            return Promise.reject('Please enter a valid 6 digit Pin Code');
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}>
                    <Row>
                      <Col span={19}>
                        <InputNumber onChange={(e) => setPincode(e)} className="w-100" controls={false} size="large" placeholder="Enter your Pin Code" />
                      </Col>
                      <Col span={4}>
                        <Tooltip title={pincode?.toString()?.length !== 6 ? 'Enter a valid Pincode' : 'Verify'}>
                          <Button className="mx-2" color="blue" type="secondary" disabled={pincode?.toString()?.length !== 6} onClick={onLocateDeliveryAddress} size="large">
                            Verify
                          </Button>
                        </Tooltip>
                      </Col>
                    </Row>
                  </Form.Item>
                  {deliveryAddress ? (
                    <div className="mx-sm-2">
                      <Paragraph type="secondary">City & State</Paragraph>
                      <Text>{deliveryAddress?.city + ', ' + deliveryAddress?.state + ' - ' + deliveryAddress?.pincode}</Text>
                    </div>
                  ) : (
                    ''
                  )}
                  <Form.Item
                    style={{ margin: '5px 0px 5px 0px' }}
                    name="deliveryLine1"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter your Address Line 1',
                      },
                    ]}>
                    <Input onChange={shouldDisableButton} className="w-100" size="large" placeholder="Address line 1" />
                  </Form.Item>
                  <Form.Item name="deliveryLine2" style={{ margin: '5px 0px 5px 0px' }}>
                    <Input className="w-100" size="large" placeholder="Address line 2" />
                  </Form.Item>
                </>
              ) : (
                <Form.Item
                  name="deliveryAddressId"
                  label={
                    <>
                      <Row align="middle" justify="space-between">
                        <Col xs={12} sm={17}>
                          <Text type="secondary">Select Delivery Location</Text>
                        </Col>
                        <Col xs={12} sm={7}>
                          <Button type="link" icon={<PlusOutlined />} onClick={() => onCloseAddAddress()}>
                            Add New Address
                          </Button>
                        </Col>
                      </Row>
                    </>
                  }
                  rules={[
                    {
                      required: true,
                      message: 'Please select a delivery location',
                    },
                  ]}>
                  <Select
                    size="large"
                    showSearch
                    filterOption={(inputValue, option) => {
                      const { label, line1, city, state, pincode } = option;
                      const searchValue = inputValue?.toLowerCase();
                      return [label, line1, city, state, pincode].some((value) => value?.toLowerCase().includes(searchValue));
                    }}
                    optionLabelProp="label"
                    value={deliveryAddress}
                    onChange={(deliveryAddress) => {
                      shouldDisableButton();
                      setDeliveryAddress(deliveryAddress);
                      form.setFieldsValue({ deliveryAddress });
                    }}
                    menuItemSelectedIcon={<CheckOutlined />}>
                    {savedAddresses.map((savedAddress) => (
                      <Select.Option key={savedAddress._id} value={savedAddress._id} label={savedAddress?.name}>
                        <div>
                          <h5 className="mt-0">{savedAddress?.name}</h5>
                          <p className="mt-0 mb-0 text-wrap">
                            {savedAddress?.line1}, {savedAddress?.line2 ? savedAddress?.line2 + ',' : ''} {savedAddress?.city}, {savedAddress?.state} - {savedAddress?.pincode}
                          </p>
                        </div>
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
            </>
          )}

          <Modal
            open={step === 2}
            onCancel={() => setStep(1)}
            footer={null}
            closable={false}
            width={310}
            bodyStyle={{
              height: '48vh',
              overflow: 'hidden',
            }}
            centered>
            <div className="flex-column">
              <div className="d-flex flex-column" style={{ alignItems: 'center', marginTop: '0.5rem' }}>
                <Image
                  className="rounded"
                  style={{
                    marginLeft: '40%',
                    marginBottom: '10px',
                    width: '30%',
                    height: '90%',
                  }}
                  preview={false}
                  alt={'Completed'}
                  src={GreatIcon}
                />
                <Typography.Text style={{ fontSize: '20px', margin: '5px' }} strong>
                  Great!
                </Typography.Text>
                <Typography.Text strong style={{ fontSize: '16px', marginBottom: '5px' }}>
                  Continue to add more details?
                </Typography.Text>

                <Typography.Text className="text-sm-center">Add details like expected delivery date, Quality remarks, terms of payments.</Typography.Text>
              </div>
              <div style={{ marginTop: '1.5rem' }} className="d-flex justify-content-around">
                <Button onClick={() => onClickNext(true)} htmlType="submit" size="large" type="link">
                  Yes, Add more
                </Button>
                <Button onClick={() => onClickNext(false)} htmlType="submit" size="large" type="primary">
                  Post it
                </Button>
              </div>
            </div>
          </Modal>

          <Modal
            title="Additional Details"
            open={step == 3}
            footer={null}
            maskClosable={false}
            closable={true}
            onCancel={confirmOnClose}
            width={470}
            bodyStyle={{
              height: '70vh',
              overflow: 'scroll',
            }}
            centered>
            <Typography.Text>Expected Delivery Date</Typography.Text>
            <Form.Item className="my-2" name="preferredDeliveryOn" initialValue={moment(startOfDay(new Date()))}>
              <Calendar validRange={[moment(startOfDay(new Date())), moment().add(1, 'years')]} fullscreen={false} />
            </Form.Item>
            <Typography.Text>Quality Remarks</Typography.Text>
            <Form.Item className="my-2 bg-transparent" name="remark">
              <Input.TextArea rows={3} maxLength={200} showCount />
            </Form.Item>
            <Divider />
            <Collapse ghost>
              <Collapse.Panel header={<Typography.Text level={5}>More Settings</Typography.Text>}>
                <Typography.Text>Terms of payment</Typography.Text>
                <Form.Item className="my-2 bg-transparent" name="paymentTerm">
                  <Radio.Group size="large">
                    <Space size="small" direction="vertical">
                      {paymentTerms?.map((paymentterm) => {
                        return (
                          <Radio
                            className="w-100 p-2"
                            style={{
                              border: '1px solid #ccc',
                              borderRadius: '10px',
                            }}
                            key={paymentterm?._id}
                            value={paymentterm?._id}>
                            {paymentterm?.name}
                          </Radio>
                        );
                      })}
                    </Space>
                  </Radio.Group>
                </Form.Item>
                <Typography.Text>Other Options</Typography.Text>
                <Form.Item className="m-sm-1" name="qualityTesting" valuePropName="checked" initialValue={false}>
                  <Checkbox>Request Quality Testing</Checkbox>
                </Form.Item>
                <Form.Item className="m-sm-1" name="trialQuantity" valuePropName="checked" initialValue={false}>
                  <Checkbox>Request Trial Quantity</Checkbox>
                </Form.Item>
              </Collapse.Panel>
            </Collapse>
            <Button className="w-100" onClick={() => onClickNext('Details Submit')} size="large" type="primary">
              Post
            </Button>
          </Modal>

          {step === 4 && (
            <>
              <div
                className="d-flex flex-column align-content-center justify-content-sm-center"
                style={{
                  borderRadius: '15px',
                  alignItems: 'center',
                }}>
                <Image
                  preview={false}
                  alt={'Completed'}
                  style={{
                    width: '15%',
                    height: '15%',
                    marginLeft: '42.5%',
                    marginBottom: '1.5%',
                  }}
                  src={enquiryPosted}
                />
                <Typography.Text level={4} strong>
                  Posted Successfully!
                </Typography.Text>
              </div>
              <div
                className="mt-2"
                style={{
                  borderRadius: '15px',
                  border: '1px solid #ccc',
                  padding: '15px',
                  marginTop: '0.7rem',
                }}>
                <Row>
                  <Col span={12}>
                    <Text type="secondary">Product Required</Text>
                    <Title level={5}>{product?.name}</Title>
                  </Col>
                  <Col span={12}>
                    <Text type="secondary">Current Purchasing Price</Text>
                    <Title level={5}>
                      Rs {form.getFieldsValue(true)?.price}/{unit}
                    </Title>
                  </Col>
                </Row>
                <Divider />
                <Row>
                  <Col span={12}>
                    <Text type="secondary">Order Quantity</Text>
                    <Title level={5}>
                      {form.getFieldsValue(true)?.quantity} {unit}
                    </Title>
                  </Col>
                  <Col span={12}>
                    <Text type="secondary">Product Quality</Text>
                    <Title level={5}>{qualitiesById[form.getFieldsValue(true)?.quality]?.grade}</Title>
                  </Col>
                </Row>
                <Divider />
                <Row>
                  <Col span={12}>
                    <Text type="secondary">How often you buy?</Text>
                    <Title level={5}>{form.getFieldsValue(true)?.frequency}</Title>
                  </Col>
                  <Col span={12}>
                    <Text type="secondary">Delivery Location</Text>
                    <Title level={5}>{savedAddresses.length > 0 ? savedAddressesById[deliveryAddress]?.city + ', ' + savedAddressesById[deliveryAddress]?.state : deliveryAddress?.city}</Title>
                  </Col>
                </Row>
                <Divider />
                <Row>
                  <Col>
                    <Text type="secondary">Would you like to update the price automatically?</Text>
                    <Title level={5}>{form.getFieldsValue(true)?.autoPriceUpdate ? 'Yes, Update the price automatically.' : 'No, I will update the price manually every time.'}</Title>
                  </Col>
                </Row>
                <Divider
                  orientation="right"
                  onClick={() => {
                    setShowMore(!showMore);
                    const modalBody = document.querySelector(`.ant-modal-body`);
                    if (showMore) {
                      modalBody.scrollTop = 0;
                    }
                  }}>
                  <Text style={{ padding: '0px', margin: '0px', fontSize: '10px' }} type="secondary" className="cursor-pointer">
                    {showMore ? 'Hide Additional Info' : 'View Additional Info'}
                  </Text>
                </Divider>
                {showMore && (
                  <>
                    <Row>
                      <Col span={12}>
                        <Text type="secondary">{form.getFieldsValue(true)?.preferredDeliveryOn ? 'Preferred ' : 'Default ' + 'Delivery Date'}</Text>
                        <Title level={5}>
                          {form.getFieldsValue(true)?.preferredDeliveryOn
                            ? format(new Date(form.getFieldsValue(true).preferredDeliveryOn?.format('YYYY-MM-DD')), "d'th' MMMM yyyy")
                            : format(new Date(addDays(new Date(), 30)), "d'th' MMMM yyyy")}
                        </Title>
                      </Col>
                      <Col span={12}>
                        <Paragraph type="secondary">Selected Payment Term</Paragraph>
                        <Text strong>{paymentTermsById[form.getFieldsValue(true)?.paymentTerm]?.name}</Text>
                      </Col>
                    </Row>
                    <Divider />
                    <Row>
                      <Col span={12}>
                        <Paragraph type="secondary">Quality Testing</Paragraph>
                        <Text strong level={5}>
                          {form.getFieldsValue(true)?.qualityTesting ? 'Requested' : 'Not Requested'}
                        </Text>
                      </Col>
                      <Col span={12}>
                        <Paragraph type="secondary">Trial Quantity</Paragraph>
                        <Text strong level={5}>
                          {form.getFieldsValue(true)?.trialQuantity ? 'Requested' : 'Not Requested'}
                        </Text>
                      </Col>
                    </Row>
                    {form.getFieldsValue(true)?.remark ? (
                      <>
                        <Divider />
                        <Row>
                          <Col>
                            <Paragraph type="secondary">Remark</Paragraph>
                            <Text strong level={5}>
                              {form.getFieldsValue(true)?.remark}
                            </Text>
                          </Col>
                        </Row>
                      </>
                    ) : (
                      ''
                    )}
                  </>
                )}
              </div>
            </>
          )}
        </Form>
      </ErrorBoundary>
      <AddNewAddress visible={showAddAddress} close={onCloseAddAddress} />
    </Modal>
  );
};

export default CreateNewOpenRFQ;
