import React from 'react';
import { Button, Card, Col, Divider, Progress, Row, Typography } from 'antd';
import { RightOutlined } from '@ant-design/icons';

const { Paragraph, Title } = Typography;

const DispatchScheduleCard = ({ type = '', data = {} }) => {
  return (
    <>
      <Card bodyStyle={{ padding: 16 }}>
        <Row justify="space-between" className="mb-2">
          <Col span={12}>
            <Paragraph>{(type === 'buy' && 'PO Number') || (type === 'sell' && 'Order Number')}</Paragraph>
            <Title level={5}>{data?._id}</Title>
          </Col>
          <Col span={12} flex={1} className="d-flex justify-content-end">
            <div>
              <Paragraph>Dispatched Quantity</Paragraph>
              <Title level={5}>25/50 MT</Title>
            </div>
          </Col>
        </Row>
        <Row>
          <Progress percent={30} />
        </Row>
        <Divider className="my-3" />
        <Button block type="link" onClick={() => {}}>
          View Schedule <RightOutlined />
        </Button>
      </Card>
    </>
  );
};

export default DispatchScheduleCard;
