import React, { useState } from 'react';
import { Button, Form, Input, message, Modal, Select } from 'antd';
import { BUSINESS_URL } from 'constants/ApiConstants';
import request from 'utils/request';
import ErrorBoundary from 'components/Errors/ErrorBoundary';
import ModalCloseIcon from 'components/UtilComponents/ModalCloseIcon';
import { startCase } from 'lodash';
import { IndustryType } from '../../containers/industryType';

const AddIndustryType = ({ visible, close, business }) => {
  const [form] = Form.useForm();
  const [industryType, setIndustryType] = useState(null);
  const onSubmitForm = async (values) => {
    const specificIndustryType = industryType ? (industryType === 'Other' ? startCase(values.specificIndustryType) : industryType) : '';
    const res = await request(`${BUSINESS_URL}/${business._id}`, {
      method: 'put',
      body: JSON.stringify({
        metadata: {
          industryType: specificIndustryType,
        },
      }),
    });
    if (res.status === 'error') {
      message.error(res.data.error);
    } else {
      message.success('Industry type added successfully');
      close();
    }
  };

  const onCancel = () => {
    form.resetFields();
    close();
  };

  return (
    <Modal destroyOnClose={true} title={'Add Industry Type'} open={visible} onCancel={onCancel} footer={null} closeIcon={<ModalCloseIcon />}>
      <ErrorBoundary>
        <Form form={form} layout="vertical" onFinish={onSubmitForm}>
          <Form.Item name="industryType">
            <Select allowClear size="large" placeholder="Industry Type" showSearch onChange={setIndustryType}>
              {IndustryType.map((type, index) => (
                <Select.Option key={index} value={type}>
                  {type}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          {industryType === 'Other' && (
            <Form.Item
              name="specificIndustryType"
              label="Specific Industry Type"
              rules={[
                {
                  required: true,
                  message: 'Please enter a valid Specific Industry Type',
                },
              ]}>
              <Input size="large" placeholder="Specific Industry Type" />
            </Form.Item>
          )}
          <Form.Item className="mt-4">
            <Button size="large" type="primary" htmlType="submit" block>
              Save Industry Type
            </Button>
          </Form.Item>
        </Form>
      </ErrorBoundary>
    </Modal>
  );
};

export default AddIndustryType;
