import { Avatar, Button, Card, Col, Divider, Row, Typography, Progress } from 'antd';
import { useNavigate } from 'react-router-dom';
import DetailsGrid from 'components/UtilComponents/DetailsGrid';
import { getBreakPoint } from 'utils/layout';
import { UPLOAD_URL } from 'constants/ApiConstants';
import { format } from 'date-fns';

const POScheduleCard = ({ poSchedule, type, buyDashboard = false }, pagination) => {
  const isMobile = !getBreakPoint().includes('sm');
  const navigate = useNavigate();
  const onViewPoSchedule = (poScheduleId) => {
    localStorage.setItem('filters', JSON.stringify({ current: pagination.current }));
    if (type === 'active') navigate(`/buy/po-schedules/active-schedules/${poScheduleId}`);
    else if (type === 'completed') navigate(`/buy/po-schedules/completed-schedules/${poScheduleId}`);
  };

  const startDate = format(new Date(poSchedule?.startDate), 'dd MMM yyyy');
  const endDate = format(new Date(poSchedule?.endDate), 'dd MMM yyyy');
  const totalDeliveredPercent = Math.min(1, poSchedule?.totalDelivered / poSchedule?.totalCommitment) * 100;

  return (
    <Card size={isMobile ? 'small' : 'default'}>
      <Row align="middle" justify="flex-start" className="mb-3">
        <Col span={18}>
          <Row gutter={10} justify="flex-start" wrap={false}>
            <Col>
              <Avatar alt="check" src={`${UPLOAD_URL}/${poSchedule?.purchaseOrder?.product?.image}`} shape="square" size="large" />
            </Col>
            <Col>
              <Typography.Text>PO Number</Typography.Text>
              <Typography.Title level={5} className="fw-medium">
                {poSchedule?.purchaseOrder?.purchaseOrderNo ?? 'N/A'}
              </Typography.Title>
            </Col>
          </Row>
        </Col>
        <Col flex={1} className="d-flex justify-content-end">
          <Button type="primary" onClick={() => onViewPoSchedule(poSchedule?._id)}>
            View
          </Button>
        </Col>
      </Row>

      <Row gutter={[16, 16]} className="mt-2">
        <Col span={buyDashboard ? 24 : 12}>
          <DetailsGrid
            icon={<Avatar src={require('assets/images/total-quantity.png')} shape="square" size="small" />}
            title="Total Quantity"
            description={
              <Typography.Text strong>
                <Typography.Text>{parseFloat((poSchedule?.totalDelivered || 0).toFixed(2))}</Typography.Text> / {parseFloat((poSchedule?.totalCommitment || 0).toFixed(2))} MT
              </Typography.Text>
            }
          />
        </Col>
        <Col span={buyDashboard ? 12 : 6}>
          <DetailsGrid title="Start Date:" description={<Typography.Text>{startDate}</Typography.Text>} />
        </Col>
        <Col span={buyDashboard ? 12 : 6}>
          <DetailsGrid title="End Date:" description={<Typography.Text>{endDate}</Typography.Text>} />
        </Col>
      </Row>
      <Divider dashed={true} className="my-3" />
      <Progress percent={totalDeliveredPercent} strokeColor={totalDeliveredPercent > 90 ? '#389e0d' : '#1A75DA'} trailColor="#d8e9fc" showInfo={false} format={(percent) => `${percent}%`} />
    </Card>
  );
};

export default POScheduleCard;
