import React, { useEffect, useState } from 'react';
import { Avatar, Col, Divider, PageHeader, Row, Typography, Image, Space, Tag, Alert, Table } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { format } from 'date-fns';
import BackIcon from 'components/UtilComponents/BackIcon';
import DetailsGrid from 'components/UtilComponents/DetailsGrid';
import NumberText from 'components/UtilComponents/NumberText';
import Loader from 'components/UtilComponents/Loader';
import { UPLOAD_URL, PURCHASE_ORDER_SCHEDULE_URL, BUSINESS_URL } from 'constants/ApiConstants';
import { keyBy } from 'lodash';
import moment from 'moment';
import { AccessDenied, PageNotFound } from 'components/Errors';
const { Title, Text } = Typography;

const POScheduleDetails = () => {
  const { poScheduleId } = useParams();
  const navigate = useNavigate();
  const [schedule, setSchedule] = useState(null);
  const [refreshing, setRefreshing] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [accessDenied, setAccessDenied] = useState(false);

  useEffect(() => {
    onLoad();
  }, []);

  const onLoad = async () => {
    setRefreshing(true);
    try {
      const businessRes = await axios.get(`${BUSINESS_URL}/me`);
      const res = await axios.get(`${PURCHASE_ORDER_SCHEDULE_URL}/${poScheduleId}?populate=true`);
      if (res.data?.code === 1006 || res.data?.code === 1007) {
        setNotFound(true);
        return setRefreshing(false);
      }
      const buyerId = res.data?.purchaseorderschedule?.purchaseOrder?.buyer?._id || res.data?.purchaseorderschedule?.purchaseOrder?.buyer;
      if (buyerId !== businessRes.data?.business?._id) {
        setAccessDenied(true);
        return setRefreshing(false);
      }

      if (res.status === 'error') {
        Alert.alertWithType('error', 'Failed', res.error);
      } else {
        setSchedule(res?.data?.purchaseorderschedule);
      }
    } catch (err) {
      Alert.alertWithType('error', 'Error', err.message);
    }
    setRefreshing(false);
  };

  const dateWiseCommitment = keyBy(schedule?.dateWiseCommitment, ({ date }) => format(new Date(date), 'yyyy-MM-dd'));
  const dateWiseDelivered = keyBy(schedule?.dateWiseDelivered, ({ date }) => format(new Date(date), 'yyyy-MM-dd'));

  const getData = () => {
    const data = [];
    let day = moment(schedule?.startDate);
    while (day <= moment(schedule?.endDate)) {
      const date = format(new Date(day), 'yyyy-MM-dd');
      data.push({
        date: day.toISOString(),
        committed: dateWiseCommitment[date]?.quantity,
        delivered: dateWiseDelivered[date]?.quantity,
      });
      day = day.clone().add(1, 'd');
    }
    return data;
  };

  const columns = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      render: (date) => {
        const formattedDate = format(new Date(date), 'dd MMM yyyy');
        const formattedDayOfWeek = format(new Date(date), 'EEEE');
        return (
          <div>
            <span>{formattedDate}</span>
            <br />
            <span style={{ fontSize: '13px' }}>{formattedDayOfWeek}</span>
          </div>
        );
      },
    },
    {
      title: 'Delivered',
      dataIndex: 'delivered',
      key: 'delivered',
      render: (delivered) => {
        return <span>{delivered ? `${parseFloat(delivered.toFixed(2))} ${schedule?.purchaseOrder?.subUnit}` : 0}</span>;
      },
    },
  ];

  if (schedule?.scheduleMode !== 'Flexible') {
    columns.splice(1, 0, {
      title: 'Committed',
      dataIndex: 'committed',
      key: 'committed',
      render: (committed) => {
        return <span>{committed ? `${parseFloat(committed.toFixed(2))} ${schedule?.purchaseOrder?.subUnit}` : 0}</span>;
      },
    });
  }

  if (refreshing) return <Loader />;

  if (accessDenied) return <AccessDenied />;

  if (notFound) return <PageNotFound />;

  return (
    <>
      <div className="app-container">
        <PageHeader
          onBack={() => navigate(-1)}
          backIcon={<BackIcon />}
          title={
            <span>
              PO Schedule Detail <Tag> {schedule?.status}</Tag>
            </span>
          }
          className="px-0"
        />
      </div>
      <Divider className="my-2" />
      <div className="app-container">
        <Row gutter={[24, 16]}>
          <Col xs={24} sm={24} md={8} lg={8}>
            <Image
              alt={schedule?.product?.name}
              style={{
                width: '100%',
                height: '300',
                objectFit: 'cover',
                borderRadius: '15px',
              }}
              src={`${UPLOAD_URL}/${schedule?.purchaseOrder?.product?.image}`}
            />
          </Col>
          <Col xs={24} sm={24} md={16} lg={16}>
            <Title level={3} className="fw-bold">
              {schedule?.product?.name}
            </Title>
            <Title level={4} className="fw-medium">
              <NumberText prefix={schedule?.currency} value={schedule?.price / 100} suffix={`/ ${schedule?.subUnit}`} />
            </Title>
            <Space direction="vertical" className="my-3" style={{ width: '100%' }}>
              <Row gutter={[16, 16]} className="mt-2">
                <Col span={24}>
                  <DetailsGrid
                    icon={<Avatar src={require('assets/images/po-number.png')} shape="square" style={{ borderRadius: '0px', height: 25 }} size="small" />}
                    title="PO Number"
                    description={<Text strong>{schedule?.purchaseOrder?.purchaseOrderNo}</Text>}
                  />
                </Col>
                <Col span={12}>
                  <DetailsGrid
                    icon={<Avatar src={require('assets/images/icon-quantity.png')} shape="square" size="small" />}
                    title="Total Commitment"
                    description={
                      <Text strong>
                        {schedule?.totalCommitment} {schedule?.purchaseOrder?.subUnit}
                      </Text>
                    }
                  />
                </Col>
                <Col span={12}>
                  <DetailsGrid
                    icon={<Avatar src={require('assets/images/icon-quantity.png')} shape="square" size="small" />}
                    title="Delivered Quantity"
                    description={
                      <Text strong>
                        {parseFloat((schedule?.totalDelivered || 0).toFixed(2))} {schedule?.purchaseOrder?.subUnit}
                      </Text>
                    }
                  />
                </Col>
                <Col span={12}>
                  <DetailsGrid
                    icon={<Avatar src={require('assets/images/icon-calendar.png')} shape="square" size="small" />}
                    title="Pending Quantity"
                    description={
                      <Text strong>
                        {parseFloat((schedule?.totalCommitment - schedule?.totalDelivered).toFixed(2))}
                        {schedule?.purchaseOrder?.subUnit}
                      </Text>
                    }
                  />
                </Col>
              </Row>
            </Space>
          </Col>
        </Row>
        <Divider />
        <Title level={5} className="fw-bold">
          Commitment ({schedule?.scheduleMode})
        </Title>
        <Table size="small" bordered={false} showHeader={true} dataSource={getData()} columns={columns} />
      </div>
    </>
  );
};

export default POScheduleDetails;
