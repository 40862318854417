import React, { useEffect, useState } from 'react';
import { Avatar, Card, Col, Divider, List, Row, Typography, message } from 'antd';
import axios from 'axios';
import ContentLoader from 'react-content-loader';
import { CloseCircleTwoTone } from '@ant-design/icons';
import DetailsGrid from 'components/UtilComponents/DetailsGrid';
import { BUSINESS_URL, PURCHASE_ORDER_URL, UPLOAD_URL } from 'constants/ApiConstants';
import EmptyContent from 'components/UtilComponents/EmptyContent';
import IconEnquiry from 'assets/images/enquiry.png';
import CreateNewOpenRFQ from 'components/Modals/Buy/CreateNewOpenRFQ';
import { getBreakPoint } from 'utils/layout';
import CreateNewPurchaseOrder from 'components/Modals/Buy/CreateNewPurchaseOrder';

const { Text, Title } = Typography;

const ClosedOrders = ({ tab }) => {
  const isMobile = !getBreakPoint().includes('sm');
  const [showOpenRFQ, setShowOpenRFQ] = useState(false);
  const [showPurchaseOrder, setShowPurchaseOrder] = useState(false);
  const [loading, setLoading] = useState(false);
  const [purchaseorders, setPurchaseOrders] = useState([]);

  useEffect(() => {
    if (tab === 'closed-orders') {
      onLoadOrders();
    }
  }, [tab]);

  const onLoadOrders = async () => {
    setLoading(true);
    try {
      // TODO: Added business url temporarily. Remove once buyer: ['me'] filter is implemented for fetching purchase orders
      const businessRes = await axios.get(`${BUSINESS_URL}/me`);
      const res = await axios.get(`${PURCHASE_ORDER_URL}?populate=true`, {
        headers: {
          'X-API-Filters': JSON.stringify({ buyer: [businessRes?.data?.business?._id], status: ['Closed'] }),
          'X-API-Sort': JSON.stringify({ createdAt: 'desc' }),
        },
      });

      if (res.data.status === 'error') {
        message.error(res.data.error);
      } else {
        setPurchaseOrders(res.data.purchaseorders);
      }
      localStorage.removeItem('filters');
    } catch (err) {
      message.error(err.message);
    }
    setLoading(false);
  };

  const onCreateNewOpenRFQ = () => {
    setShowOpenRFQ(!showOpenRFQ);
  };

  const onCreateNewPurchaseOrder = () => {
    setShowPurchaseOrder(!showPurchaseOrder);
  };

  if (loading) {
    return (
      <ContentLoader width="100%" height="70vh" backgroundColor="#d9d9d9" foregroundColor="#ededed">
        <rect x="0" y="25" rx="10" ry="10" width="48%" height="211" />
        <rect x="50%" y="25" rx="10" ry="10" width="50%" height="211" />
        <rect x="0" y="250" rx="10" ry="10" width="48%" height="211" />
        <rect x="50%" y="250" rx="10" ry="10" width="50%" height="211" />
      </ContentLoader>
    );
  }

  return (
    <>
      {purchaseorders?.length > 0 ? (
        <List
          className="mt-2 mb-5"
          header={null}
          footer={null}
          grid={{ gutter: [16, 4], column: isMobile ? 1 : 2 }}
          dataSource={purchaseorders}
          renderItem={(order) => {
            return (
              <List.Item key={order?._id}>
                <Card size={isMobile ? 'small' : 'default'}>
                  <Row align="middle" justify="flex-start" className="mb-3">
                    <Col span={18}>
                      <Row gutter={10} justify="flex-start" wrap={false}>
                        <Col>
                          <Avatar src={`${UPLOAD_URL}/${order?.product?.image}`} shape="square" size="large" />
                        </Col>
                        <Col>
                          <Title level={5} className="fw-medium">
                            {order?.product?.name}
                          </Title>
                          <Text>
                            {order?.fromQty} {order?.toQty > 0 ? ` - ${order?.toQty}` : ''} {order?.subUnit}
                          </Text>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <DetailsGrid
                    icon={<Avatar src={require('assets/images/icon-quote-received.png')} shape="square" size="small" />}
                    title="PO Number"
                    description={<Text strong>{order?.purchaseOrderNo}</Text>}
                  />

                  <Divider dashed={true} className="my-3" />
                  <Row gutter={16} className="mt-3">
                    <Col span={12}>
                      {order.toQty ? (
                        <DetailsGrid
                          icon={<Avatar src={require('assets/images/total-quantity.png')} shape="square" size="small" />}
                          title="Total Quantity"
                          description={
                            <Text strong>
                              {order?.fromQty} {order?.toQty > 0 ? ` - ${order?.toQty}` : ''} {order?.subUnit}
                            </Text>
                          }
                        />
                      ) : (
                        <DetailsGrid
                          icon={<Avatar src={require('assets/images/total-quantity.png')} shape="square" size="small" />}
                          title="Total Quantity"
                          description={
                            <Text strong>
                              {order?.fromQty}
                              {order?.subUnit}
                            </Text>
                          }
                        />
                      )}
                    </Col>
                  </Row>
                  <Divider dashed={true} className="my-3" />
                  <Row align="middle" className="my-3">
                    <CloseCircleTwoTone twoToneColor="#ff4d4f" style={{ fontSize: '16px' }} />
                    <Text className="ml-2">Purchase Order is closed.</Text>
                  </Row>
                </Card>
              </List.Item>
            );
          }}
        />
      ) : (
        <EmptyContent
          source={IconEnquiry}
          title={'No orders closed yet'}
          subTitle={'Initiate a new order for swift, customized service. Your satisfaction drives us!'}
          onActionPress={() => onCreateNewPurchaseOrder()}
          actionText={'Request Purchase Order'}
        />
      )}
      <CreateNewOpenRFQ visible={showOpenRFQ} close={onCreateNewOpenRFQ} />
      <CreateNewPurchaseOrder visible={showPurchaseOrder} close={onCreateNewPurchaseOrder} />
    </>
  );
};

export default ClosedOrders;
