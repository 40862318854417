import React, { useEffect, useState } from 'react';
import { Avatar, Button, Card, Col, Divider, Image, List, Progress, Row, Tag, Tooltip, Typography, message } from 'antd';
import { useNavigate } from 'react-router-dom';
import { RightOutlined } from '@ant-design/icons';
import IconCreate from 'assets/images/create-listing.png';
import IconRFQs from 'assets/images/my-rfqs.png';
import IconOffers from 'assets/images/my-offers.png';
import IconOrders from 'assets/images/purchase-order.png';
import IconPayments from 'assets/images/ongoing-payment.png';
import IconDispatch from 'assets/images/ongoing-dispatch.png';
import MegaPhone from 'assets/images/megaphone.png';
import ExploreBanner from 'assets/images/explore-banner.png';
import axios from 'axios';
import { DASHBOARD_URL, PURCHASE_ORDER_SCHEDULE_URL, OPEN_RFQ_URL, DISPATCH_URL, PURCHASE_ORDER_URL } from 'constants/ApiConstants';
import ContentLoader from 'react-content-loader';
import ListingCard from 'components/Cards/ListingCard';
import EmptyContent from 'components/UtilComponents/EmptyContent';
import CreateNewOpenRFQ from 'components/Modals/Buy/CreateNewOpenRFQ';
import DashboardPaymentCard from 'components/Cards/DashboardPaymentCard';
import { getBreakPoint } from 'utils/layout';
import { posthog } from 'posthog-js';
import BuyNotificationScreen from './BuyNotificationScreen';
import POScheduleCard from './POSchedules/POScheduleCard';
import AppTour from 'containers/AppTour';
import { formatRefNo } from 'utils/formatter';

const { Text, Title, Paragraph } = Typography;

const badgeStyle = {
  backgroundColor: '#2181DF',
  borderRadius: 30,
  width: 30,
  height: 30,
  color: '#fff',
  paddingTop: 3,
  marginRight: 7,
};

const BuyDashboard = () => {
  const viewName = 'buy';
  const isMobile = !getBreakPoint().includes('sm');
  const navigate = useNavigate();
  const [showOpenRFQ, setShowOpenRFQ] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [poSchedules, setPOSchedules] = useState([]);
  const [dispatches, setDispatches] = useState([]);
  const [purchaseOrders, setPurchaseOrders] = useState([]);
  const [introModal, setIntroModal] = useState(false);
  const [counts, setCounts] = useState({
    notitications: 0,
    purchaseOrders: 1,
    listings: {
      recommended: 0,
      nearby: 0,
    },
  });
  const [result, setResult] = useState({
    notitications: [],
    payment: {
      overdue: 0,
      today: 0,
      next7Days: 0,
      next14Days: 0,
      next28Days: 0,
    },
    listings: {
      recommended: [],
      nearby: [],
    },
  });

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    onLoad();
  }, []);

  const onLoad = async () => {
    if (loading) return;
    setLoading(true);
    try {
      const res = await axios.get(`${DASHBOARD_URL}/buy`);
      const scheduleRes = await axios.get(`${PURCHASE_ORDER_SCHEDULE_URL}/?populate=true`, {
        method: 'get',
        headers: {
          'X-API-Filters': JSON.stringify({ buyer: ['me'], status: ['Committed'] }),
        },
      });
      const dispatchesRes = await axios.get(`${DISPATCH_URL}?page=${1}&limit=${3}&populate=true`, {
        headers: {
          'X-API-Filters': JSON.stringify({ transitStatus: ['Awaiting Loading', 'In Transit', 'Awaiting Unloading'], business: ['me'] }),
          'X-API-Sort': JSON.stringify({ createdAt: 'desc' }),
        },
      });
      const purchaseOrdersRes = await axios.get(`${PURCHASE_ORDER_URL}/?page=${1}&limit=${3}&populate=true`, {
        method: 'get',
        headers: {
          'X-API-Filters': JSON.stringify({ buyer: ['me'], status: ['Created'] }),
        },
      });
      const activeRes = await axios.get(`${OPEN_RFQ_URL}?&populate=true`, {
        headers: {
          'X-API-Filters': JSON.stringify({
            buyer: ['me'],
            status: ['Created'],
          }),
          'X-API-Sort': JSON.stringify({ createdAt: 'desc' }),
        },
      });

      if (res.data.status === 'error') {
        message.error(res.data.error);
      } else if (dispatchesRes.data.status === 'error') {
        message.error(dispatchesRes.data.error);
      } else if (scheduleRes.data.status === 'error') {
        message.error(scheduleRes.data.error);
      } else if (purchaseOrdersRes.data.status === 'error') {
        message.error(purchaseOrdersRes.data.error);
      } else if (activeRes.data.status === 'error') {
        message.error(activeRes.data.error);
      } else {
        setResult(res.data.result);
        setCounts(res.data.counts);
        setPOSchedules(scheduleRes?.data.purchaseorderschedules);
        setDispatches(dispatchesRes?.data?.dispatches);
        setPurchaseOrders(purchaseOrdersRes?.data?.purchaseorders);
      }
    } catch (err) {
      message.error(err.message);
    }
    setLoading(false);
  };

  /*
   * Create RFQ
   * Open My RFQs
   * My Offers
   * Purchase Orders
   * Ongoing Payments
   * Ongoing Dispatches
   * View All Listings
   */
  const onCreateNewOpenRFQ = (reload = false) => {
    if (reload) {
      onLoad();
    }
    setShowOpenRFQ(!showOpenRFQ);
  };

  const hideNotification = () => {
    setShowNotification(!showNotification);
  };

  const onOpenRFQs = () => {
    posthog.capture(`$${viewName} | $my-open-rfqs`, { $set: { lastActivityDate: new Date().toISOString() } });
    navigate('/buy/rfqs/open-rfqs');
  };

  const onViewDispatch = (id) => {
    navigate(`/buy/purchaseorders/dispatch/${id}`);
  };

  const onOpenMyOffers = () => {
    posthog.capture(`$${viewName} | $my-offers`, { $set: { lastActivityDate: new Date().toISOString() } });
    navigate('/buy/offers/pending-offers');
  };

  const onOpenPurchaseOrders = () => {
    posthog.capture(`$${viewName} | $purchase-orders`, { $set: { lastActivityDate: new Date().toISOString() } });
    navigate('/buy/purchaseorders/active-orders');
  };

  const onPOSchedules = () => {
    posthog.capture(`$${viewName} | $purchase-orders`, { $set: { lastActivityDate: new Date().toISOString() } });
    navigate('/buy/po-schedules/active-schedules');
  };

  const onDispatches = () => {
    posthog.capture(`$${viewName} | $ongoing-dispatches`, { $set: { lastActivityDate: new Date().toISOString() } });
    navigate('/dispatches/ongoing-dispatches');
  };

  const onPurchaseOrders = () => {
    posthog.capture(`$${viewName} | $purchase-orders`, { $set: { lastActivityDate: new Date().toISOString() } });
    navigate('/buy/purchaseorders/active-orders');
  };

  const onViewOrders = (id) => {
    navigate(`/buy/purchaseorders/completed-orders/${id}`);
  };

  const onOpenOngoingPayments = (filter) => {
    posthog.capture(`$${viewName} | $upcoming-payments`, { $set: { lastActivityDate: new Date().toISOString(), filter } });
    if (filter) {
      navigate(`/payments/upcoming-payments?filter=${filter}`);
    } else {
      navigate('/payments/upcoming-payments');
    }
  };

  const onOpenOngoingDispatches = () => {
    posthog.capture(`$${viewName} | $ongoing-dispatches`, { $set: { lastActivityDate: new Date().toISOString() } });
    navigate('/dispatches/ongoing-dispatches');
  };

  const onViewAllListings = () => navigate('/buy/listings');

  const getColor = (status) => {
    if (status === 'Awaiting Loading') return 'gold';
    if (status === 'In Transit') return 'yellow';
    if (status === 'Goods Delivered') return 'green';
    if (status === 'Goods Rejected') return 'red';
    return 'gold';
  };

  const progressConfig = (transitStatus) => {
    if (transitStatus === 'Awaiting Loading') return { color: '#1a75da', percent: 25 };
    if (transitStatus === 'In Transit') return { color: '#1a75da', percent: 50 };
    if (transitStatus === 'Awaiting Unloading') return { color: '#1a75da', percent: 75 };
    if (transitStatus === 'Goods Delivered') return { color: 'Green', percent: 100 };
  };

  return (
    <div className="dashboard-container">
      <div className="dashboard-header">
        <Row align="middle" justify="space-between">
          <Col>
            <Title level={2} className="fw-bold dashboard-title">
              Buy
            </Title>
          </Col>
          <Col>
            <Button type="default" shape="round" size="large" style={{ padding: '0 15px', border: 'none', color: 'rgb(33, 129, 223)' }} onClick={onOpenPurchaseOrders}>
              {counts?.purchaseOrders ? <span style={badgeStyle}>{counts?.purchaseOrders}</span> : <span />}
              Purchase Order
            </Button>
          </Col>
          <Col>
            <Tooltip title="App Tour">
              <Avatar src={require('assets/images/app-tour.png')} size="small" className="cursor-pointer mx-2" onClick={() => setIntroModal(true)} />
            </Tooltip>
            <Tooltip title="Notifications">
              <Avatar src={require('assets/images/notification.png')} size="small" className="cursor-pointer mx-2" onClick={() => setShowNotification(!showNotification)} />
            </Tooltip>
          </Col>
        </Row>
      </div>

      {/* Menu */}
      <Row gutter={[isMobile ? 8 : 48, 8]} className="my-4">
        <Col xs={{ span: 24, order: 2 }} sm={{ span: 24, order: 2 }} md={{ span: 24, order: 2 }} lg={{ span: 16, order: 1 }}>
          <Row gutter={[isMobile ? 8 : 16, 16]}>
            <Col span={8} className="tour-create-rfq">
              <Card
                onClick={() => {
                  onCreateNewOpenRFQ();
                }}
                bordered={false}
                hoverable={true}
                bodyStyle={{ padding: '15px 5px', minHeight: isMobile ? 120 : 0 }}
                className="shadow">
                <Row gutter={[16, 8]} align="middle" justify="center">
                  <Col>
                    <Avatar shape="square" size={36} src={IconCreate} />
                  </Col>
                  <Col span={isMobile && 24}>
                    <Paragraph className="text-center fw-medium">Create RFQ</Paragraph>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col span={8} className="tour-my-rfqs">
              <Card onClick={onOpenRFQs} bordered={false} hoverable={true} bodyStyle={{ padding: '15px 5px', minHeight: isMobile ? 120 : 0 }} className="shadow">
                <Row gutter={[16, 8]} align="middle" justify="center">
                  <Col>
                    <Avatar shape="square" size={36} src={IconRFQs} />
                  </Col>
                  <Col span={isMobile && 24}>
                    <Paragraph className="text-center fw-medium">My RFQs</Paragraph>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col span={8} className="tour-my-offers">
              <Card onClick={onOpenMyOffers} bordered={false} hoverable={true} bodyStyle={{ padding: '15px 5px', minHeight: isMobile ? 120 : 0 }} className="shadow">
                <Row gutter={[16, 8]} align="middle" justify="center">
                  <Col>
                    <Avatar shape="square" size={36} src={IconOffers} />
                  </Col>
                  <Col span={isMobile && 24}>
                    <Paragraph className="text-center fw-medium">My Offers</Paragraph>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col className="tour-purchase-orders" span={8}>
              <Card onClick={onOpenPurchaseOrders} bordered={false} hoverable={true} bodyStyle={{ padding: '15px 5px', minHeight: isMobile ? 120 : 0 }} className="shadow">
                <Row gutter={[16, 8]} align="middle" justify="center">
                  <Col>
                    <Avatar shape="square" size={36} src={IconOrders} />
                  </Col>
                  <Col span={isMobile && 24}>
                    <Paragraph className="text-center fw-medium text-wrap">Purchase Orders</Paragraph>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col className="tour-buy-ongoing-payments" span={8}>
              <Card onClick={() => onOpenOngoingPayments()} bordered={false} hoverable={true} bodyStyle={{ padding: '15px 5px', minHeight: isMobile ? 120 : 0 }} className="shadow">
                <Row gutter={[16, 8]} align="middle" justify="center">
                  <Col>
                    <Avatar shape="square" size={36} src={IconPayments} />
                  </Col>
                  <Col span={isMobile && 24}>
                    <Paragraph className="text-center fw-medium">Ongoing Payments</Paragraph>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col className="tour-buy-ongoing-dispatches" span={8}>
              <Card onClick={onOpenOngoingDispatches} bordered={false} hoverable={true} bodyStyle={{ padding: '15px 5px', minHeight: isMobile ? 120 : 0 }} className="shadow">
                <Row gutter={[16, 8]} align="middle" justify="center">
                  <Col>
                    <Avatar shape="square" size={36} src={IconDispatch} />
                  </Col>
                  <Col span={isMobile && 24}>
                    <Paragraph className="text-center fw-medium">Ongoing Dispatches</Paragraph>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>

          <Divider className="my-4" />

          {/* Pending Payments */}
          <>
            <Row align="middle" justify="space-between" className="mb-2">
              <Col>
                <Paragraph type="secondary">Payment Schedule</Paragraph>
              </Col>
              <Col>
                <Button type="link" onClick={() => onOpenOngoingPayments()}>
                  View all
                  <RightOutlined />
                </Button>
              </Col>
            </Row>
            <Row gutter={16} align="middle" justify="start" wrap={false} style={{ overflowX: 'scroll' }}>
              <Col xs={24} sm={12} md={12} lg={12} xl={10} xxl={8}>
                <DashboardPaymentCard title="Overdue Payment" type="overdue" value={result?.payment?.overdue || 0} onClick={() => onOpenOngoingPayments('overdue')} />
              </Col>
              <Col xs={24} sm={12} md={12} lg={12} xl={10} xxl={8}>
                <DashboardPaymentCard title="Due Today" value={result?.payment?.today || 0} onClick={() => onOpenOngoingPayments('today')} />
              </Col>
              <Col xs={24} sm={12} md={12} lg={12} xl={10} xxl={8}>
                <DashboardPaymentCard title="Next 7 Days" value={result?.payment?.next7Days || 0} onClick={() => onOpenOngoingPayments('next7days')} />
              </Col>
              <Col xs={24} sm={12} md={12} lg={12} xl={10} xxl={8}>
                <DashboardPaymentCard title="Next 14 Days" value={result?.payment?.next14Days || 0} onClick={() => onOpenOngoingPayments('next14days')} />
              </Col>
              <Col xs={24} sm={12} md={12} lg={12} xl={10} xxl={8}>
                <DashboardPaymentCard title="Next 28 Days" value={result?.payment?.next28Days || 0} onClick={() => onOpenOngoingPayments('next28days')} />
              </Col>
            </Row>
          </>

          <Divider className="my-4" />

          {/* Recommended Listing Starts */}
          {result?.listings?.recommended?.length > 0 || loading ? (
            <>
              <Row align="middle" justify="space-between" className="my-3">
                <Col>
                  <Row gutter={8} wrap={false} align="middle" justify="center">
                    <Col>
                      <Title level={isMobile ? 5 : 3} className="fw-bold">
                        Recommended Listings
                      </Title>
                      <Paragraph type="secondary">
                        Showing {result?.listings?.recommended?.length} of {counts?.listings?.recommended} Listings
                      </Paragraph>
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <Button type="link" onClick={onViewAllListings}>
                    View all
                    <RightOutlined />
                  </Button>
                </Col>
              </Row>
              {loading && (
                <ContentLoader width="100%" height="80vh" backgroundColor="#d9d9d9" foregroundColor="#ededed">
                  <rect x="0" y="0" rx="10" ry="10" width="32%" height="300" />
                  <rect x="34%" y="0" rx="10" ry="10" width="32%" height="300" />
                  <rect x="68%" y="0" rx="10" ry="10" width="32%" height="300" />
                  <rect x="0" y="320" rx="10" ry="10" width="32%" height="300" />
                  <rect x="34%" y="320" rx="10" ry="10" width="32%" height="300" />
                  <rect x="68%" y="320" rx="10" ry="10" width="32%" height="300" />
                </ContentLoader>
              )}
              {!loading && (
                <List
                  className="my-4"
                  header={null}
                  footer={
                    result?.listings?.recommended?.length > 0 ? (
                      <Row justify="center">
                        <Button className="px-5" type="primary" size="large" onClick={onViewAllListings}>
                          View All Listings
                        </Button>
                      </Row>
                    ) : null
                  }
                  grid={{ gutter: [24, 12], column: isMobile ? 1 : 3 }}
                  dataSource={result?.listings?.recommended ? result.listings.recommended : []}
                  renderItem={(listing) => (
                    <List.Item>
                      <ListingCard listing={listing} />
                    </List.Item>
                  )}
                />
              )}
              <Divider />
            </>
          ) : (
            <EmptyContent
              source={MegaPhone}
              title={'No listings found at the moment'}
              subTitle={'Create an Open RFQ and attract quotes from sellers.'}
              onActionPress={() => onCreateNewOpenRFQ()}
              actionText={'Create Open RFQ'}
            />
          )}

          {/* Nearby Listings Starts */}
          {result?.listings?.nearby?.length > 0 && (
            <>
              <Row align="middle" justify="space-between" className="my-5 buy-step-4">
                <Col>
                  <Title level={isMobile ? 5 : 3} className="fw-bold">
                    Nearby Listings
                  </Title>
                  <Paragraph type="secondary">
                    Showing {result?.listings?.nearby?.length} of {counts?.listings?.nearby} Listings
                  </Paragraph>
                </Col>
                <Col>
                  <Button type="link" onClick={onViewAllListings}>
                    View all
                    <RightOutlined />
                  </Button>
                </Col>
              </Row>
              <List
                className="my-4"
                header={null}
                footer={
                  result?.listings?.nearby?.length > 0 ? (
                    <Row justify="center">
                      <Button className="px-5" type="primary" size="large" onClick={onViewAllListings}>
                        View All Listings
                      </Button>
                    </Row>
                  ) : null
                }
                grid={{ gutter: [24, 12], column: isMobile ? 1 : 3 }}
                dataSource={result?.listings?.nearby ? result.listings.nearby : []}
                pagination={false}
                renderItem={(listing) => (
                  <List.Item>
                    <ListingCard listing={listing} />
                  </List.Item>
                )}
              />
              <Divider />
            </>
          )}
        </Col>

        {/* Right Side Components */}
        {true && (
          <Col xs={{ span: 24, order: 1 }} sm={{ span: 24, order: 1 }} md={{ span: 24, order: 1 }} lg={{ span: 8, order: 2 }}>
            <Card bodyStyle={{ padding: '0.8rem' }}>
              <Image src={ExploreBanner} preview={false} />
              <div className="mb-4">
                {poSchedules?.length > 0 ? (
                  <Row align="middle" justify="space-between" wrap={false} className="mt-4 mb-2">
                    <Col>
                      <Text className="fw-medium" type="secondary">
                        PO Schedules
                      </Text>
                    </Col>
                    <Col>
                      <Button type="link" onClick={onPOSchedules}>
                        View all
                        <RightOutlined />
                      </Button>
                    </Col>
                  </Row>
                ) : (
                  ''
                )}
                <Row gutter={[16, 16]} align="middle" wrap={false} style={{ overflowX: 'scroll' }}>
                  {poSchedules?.map((data) => {
                    return (
                      <Col xs={24} sm={12} md={12} lg={24} key={data?._id}>
                        <POScheduleCard buyDashboard={true} poSchedule={data} type="active" />
                      </Col>
                    );
                  })}
                </Row>

                {dispatches?.length > 0 ? (
                  <Row align="middle" justify="space-between" wrap={false} className="mt-4 mb-2">
                    <Col>
                      <Text className="fw-medium" type="secondary">
                        Recent Delivery Status
                      </Text>
                    </Col>
                    <Col>
                      <Button type="link" onClick={onDispatches}>
                        View all
                        <RightOutlined />
                      </Button>
                    </Col>
                  </Row>
                ) : (
                  ''
                )}
                {dispatches?.length > 0 ? (
                  <Row gutter={[16, 16]} align="middle" wrap={false} style={{ overflowX: 'scroll' }}>
                    {dispatches?.map((data) => {
                      return (
                        <Col xs={24} sm={12} md={12} lg={24} key={data?._id}>
                          <Card className="cursor-pointer" onClick={() => onViewDispatch(data?._id)} bodyStyle={{ padding: '10px' }}>
                            <Row className="m-sm-1">
                              <Col className="p-sm-0" xs={24} sm={12} md={12} lg={10}>
                                <Text strong>{data?.product?.name}</Text>
                              </Col>
                              <Col className="p-sm-0 d-flex justify-content-end" xs={24} sm={12} md={12} lg={14}>
                                Dispatch No: {formatRefNo(data?.dispatchRefNo, 'BFD')}
                              </Col>
                            </Row>
                            <Row className="m-sm-1">
                              <Col className="p-sm-0" xs={24} sm={12} md={12} lg={12}>
                                <Title level={4}>
                                  {data?.dispatchedQty} {data?.subUnit}
                                </Title>
                              </Col>
                              <Col className="p-sm-0 d-flex justify-content-end" xs={24} sm={12} md={12} lg={12}>
                                <Tag className="rounded border-0" color={getColor(data?.transitStatus)}>
                                  {data?.transitStatus}
                                </Tag>
                              </Col>
                            </Row>

                            <Progress size="small" style={{ padding: '0px' }} strokeColor={progressConfig(data?.transitStatus).color} percent={progressConfig(data?.transitStatus).percent} />
                            <Row className="m-sm-1" gutter={[16, 16]}>
                              <Col className="p-sm-0" xs={24} sm={12} md={12} lg={12}>
                                <Text style={{ fontSize: '12px' }} type="secondary">
                                  Awaiting Loading
                                </Text>
                              </Col>
                              <Col className="p-sm-0 d-flex justify-content-end" xs={24} sm={12} md={12} lg={12}>
                                <Text style={{ fontSize: '12px' }} type="secondary">
                                  Delivered
                                </Text>
                              </Col>
                            </Row>
                          </Card>
                        </Col>
                      );
                    })}
                  </Row>
                ) : (
                  ''
                )}

                {purchaseOrders?.length > 0 ? (
                  <Row align="middle" justify="space-between" wrap={false} className="mt-4 mb-2">
                    <Col>
                      <Text className="fw-medium" type="secondary">
                        Active Purchase Orders
                      </Text>
                    </Col>
                    <Col>
                      <Button type="link" onClick={onPurchaseOrders}>
                        View all
                        <RightOutlined />
                      </Button>
                    </Col>
                  </Row>
                ) : (
                  ''
                )}

                {purchaseOrders?.length > 0 ? (
                  <Row gutter={[16, 16]} align="middle" wrap={false} style={{ overflowX: 'scroll' }}>
                    {purchaseOrders?.map((data) => {
                      return (
                        <Col xs={24} sm={12} md={12} lg={24} key={data?._id}>
                          <Card className="cursor-pointer" onClick={() => onViewOrders(data?._id)} bodyStyle={{ padding: '10px' }}>
                            <Row className="m-sm-1">
                              <Col className="p-sm-0" xs={24} sm={12} md={12} lg={12}>
                                <Text strong>{data?.product?.name}</Text>
                              </Col>
                              <Col className="p-sm-0 d-flex justify-content-end" xs={24} sm={12} md={12} lg={12}>
                                <Text Text type="secondary">
                                  Dispatched Qty:
                                </Text>
                              </Col>
                            </Row>
                            <Row className="m-sm-1">
                              <Col className="p-sm-0" xs={24} sm={24} md={24} lg={12}>
                                <Title level={5}>
                                  {data?.fromQty} {data?.subUnit}
                                </Title>
                              </Col>
                              <Col className="p-sm-0 d-flex justify-content-end" xs={24} sm={24} md={24} lg={12}>
                                <Title level={5}>
                                  {data?.metadata?.dispatchedQty}
                                  {data?.subUnit}
                                </Title>
                              </Col>
                            </Row>
                            <Divider className="my-2" />
                            <Row className="m-sm-1">
                              <Col className="p-sm-0" xs={24} sm={24} md={24} lg={24}>
                                <Text type="secondary">PO Number</Text>
                              </Col>
                            </Row>
                            <Row className="m-sm-1">
                              <Col className="p-sm-0" xs={24} sm={24} md={24} lg={24}>
                                <Title level={5}>{data?.purchaseOrderNo}</Title>
                              </Col>
                            </Row>
                            <Divider className="my-2" />
                            <div style={{ display: 'flex', alignItems: 'center', maxWidth: '100%' }}>
                              <Progress percent={data?.metadata?.percentCompleted} />
                              <span style={{ fontSize: '13px', marginLeft: '31px', marginTop: '5px' }}>Completed</span>
                            </div>
                          </Card>
                        </Col>
                      );
                    })}
                  </Row>
                ) : (
                  ''
                )}
              </div>
            </Card>
          </Col>
        )}
      </Row>
      <BuyNotificationScreen visible={showNotification} close={hideNotification} />
      <CreateNewOpenRFQ visible={showOpenRFQ} close={onCreateNewOpenRFQ} />
      <AppTour type={'Buy'} introModal={introModal} setIntroModal={setIntroModal} />
    </div>
  );
};

export default BuyDashboard;
