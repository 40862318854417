import React, { useEffect, useState } from 'react';
import { Avatar, Button, Card, Col, Divider, List, Row, Tag, Typography, message, Modal, PageHeader, Dropdown, Space } from 'antd';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import IconSent from 'assets/images/icon-sent.png';
import IconReceived from 'assets/images/icon-received.png';
import DetailsGrid from 'components/UtilComponents/DetailsGrid';
import NumberText from 'components/UtilComponents/NumberText';
import { BUSINESS_URL, PAYMENT_ENABLED, DISPATCH_URL, UPLOAD_URL, PAYMENT_URL } from 'constants/ApiConstants';
import { formatRefNo } from 'utils/formatter';
import EmptyContent from 'components/UtilComponents/EmptyContent';
import IconWallet from 'assets/images/wallet.png';
import { getBreakPoint } from 'utils/layout';
import { find, isEmpty, map } from 'lodash';
import { getAuth } from 'utils/auth';
import ModalCloseIcon from 'components/UtilComponents/ModalCloseIcon';
import ContentLoader from 'react-content-loader';
import { HiOutlineExternalLink } from 'react-icons/hi';
import { addDays, format } from 'date-fns';
import { DownOutlined } from '@ant-design/icons';
import moment from 'moment';
// import { saveAs } from 'file-saver';
import LedgerView from './LedgerView';

const { Text, Title } = Typography;
const { CheckableTag } = Tag;

const UpcomingPaymentsCard = ({ type, dispatch, currentPage, role }) => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };

  const onViewDispatch = () => {
    localStorage.setItem('filters', JSON.stringify({ current: currentPage, type: type }));
    navigate(`/${type === 'received' ? 'sell/orders' : 'buy/purchaseorders'}/dispatch/${dispatch?._id}`);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <Card>
      <Row justify="flex-start">
        <Col xs={24} sm={24} md={24} lg={24} xl={12}>
          <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={6}>
              <Avatar src={IconReceived} size="large" style={{ backgroundColor: '#E8F1FB', padding: '2px' }} shape="square" />
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={18}>
              <Typography.Text>Dispatch ID</Typography.Text>
              <Row align="middle">
                <Title level={5} className="fw-bold">
                  <Button type="link" className="pl-0" onClick={onViewDispatch}>
                    {formatRefNo(dispatch?.dispatchRefNo, 'BFD')} <HiOutlineExternalLink size={16} />
                  </Button>
                </Title>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={12}>
          <Text type="secondary">Payment to be received</Text>
          <DetailsGrid description={<NumberText prefix={dispatch?.currency} value={dispatch?.paymentPriceBasis?.pendingPayable?.value / 100} />} />
        </Col>
      </Row>
      <Divider className="my-2" />
      <Row gutter={16} className="mt-3">
        <Col xs={24} sm={24} md={24} lg={24} xl={12}>
          <DetailsGrid
            title="Expected Payment Date"
            description={
              <Text>
                {addDays(new Date(dispatch?.dispatchedOn), dispatch?.purchaseCreditTerm || 0) > new Date()
                  ? format(addDays(new Date(dispatch?.dispatchedOn), dispatch?.purchaseCreditTerm || 0), 'dd MMM yyyy')
                  : 'Due soon'}
              </Text>
            }
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={12}>
          <DetailsGrid title="Against Order" description={dispatch?.order?.orderRefNo ? formatRefNo(dispatch?.order?.orderRefNo, 'BF') : '-'} />
        </Col>
      </Row>
      <Divider dashed={true} className="my-2" />
      <Row gutter={16}>
        {PAYMENT_ENABLED && (
          <Col span={12}>
            {/* TODO: Integration Pending */}
            <Button type="primary" onClick={() => {}}>
              Make Payment
            </Button>
          </Col>
        )}
        <Col span={12}>
          {role !== 'Payment Manager' && dispatch?.purchase?.invoice && (
            <Button type="link" className="pl-0" onClick={() => window.open(`${UPLOAD_URL}/${dispatch?.purchase?.invoice}`)}>
              View Invoice <HiOutlineExternalLink size={16} style={{}} />
            </Button>
          )}
        </Col>
        <Col span={12}>
          <Button type="primary" onClick={showModal}>
            More Details
          </Button>
        </Col>
      </Row>
      <Modal open={isModalOpen} footer={null} onOk={handleOk} onCancel={handleCancel} closeIcon={<ModalCloseIcon />} width={550}>
        <PageHeader title={`Payment | Upcoming | To be received`} />
        <Card>
          <Text strong>Payment Details</Text>
          <Row justify="space-between" className="mt-2">
            <Text>Price Per Unit</Text>
            <NumberText bold value={dispatch?.purchasingPrice / 100} prefix={dispatch?.currency} suffix={`/ ${dispatch?.subUnit}`} />
          </Row>
          <Row justify="space-between" className="mt-2">
            <Text>Quantity</Text>
            <Text strong>
              {dispatch?.dispatchedQty} {dispatch?.subUnit}
            </Text>
          </Row>
          <Row justify="space-between" className="mt-2">
            <Text>{dispatch?.purchasingPriceBasis?.price?.label}</Text>
            <NumberText bold value={dispatch?.purchasingPriceBasis?.price?.value / 100} prefix={dispatch?.currency} />
          </Row>
          <Row justify="space-between" className="mt-2">
            <Text>{dispatch?.purchasingPriceBasis?.deliveryCharges?.label}</Text>
            <NumberText bold value={dispatch?.purchasingPriceBasis?.deliveryCharges?.value / 100} prefix={dispatch?.currency} />
          </Row>
          <Row justify="space-between" className="mt-2">
            <Text>{dispatch?.purchasingPriceBasis?.gst?.label}</Text>
            <NumberText bold value={dispatch?.purchasingPriceBasis?.gst?.value / 100} prefix={dispatch?.currency} />
          </Row>
          <Divider className="my-2" />
          <Row justify="space-between" className="mt-2">
            <Text>Total Amount</Text>
            <NumberText bold value={dispatch?.paymentPriceBasis?.payable?.value / 100} prefix={dispatch?.currency} />
          </Row>
          <Row justify="space-between" className="mt-2">
            <Text>Previous Payments</Text>
            <Text>
              - <NumberText bold value={dispatch?.paymentPriceBasis?.paid?.value / 100} prefix={dispatch?.currency} />
            </Text>
          </Row>
          <Divider className="my-2" />
          <Row justify="space-between" className="mt-2">
            <Text>Final pending amount</Text>
            <NumberText bold value={dispatch?.paymentPriceBasis?.pendingPayable?.value / 100} prefix={dispatch?.currency} />
          </Row>
        </Card>

        {dispatch?.purchase?.invoice && (
          <Card className="mt-2">
            <Text strong>Attachment Details</Text>
            <Row justify="space-between" className="mt-2">
              <Button type="link" href={`${UPLOAD_URL}/${dispatch?.purchase?.invoice}`} target="_blank">
                <Avatar src={require('assets/images/dispatched-quantity.png')} shape="square" size="small" className="mr-2" />
                Download Invoice&nbsp;
                <HiOutlineExternalLink size={16} />
              </Button>
            </Row>
          </Card>
        )}
      </Modal>
    </Card>
  );
};

const CollectionPaymentCard = ({ type, collection, role }) => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const total = collection.amount / 100;

  const onViewDispatch = () => {
    localStorage.setItem('filters', JSON.stringify({ type: type }));
    navigate(`/buy/purchaseorders/dispatch/${dispatch?._id}`);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const { dispatch } = collection;
  return (
    <Card>
      <Row justify="flex-start">
        <Col xs={24} sm={24} md={24} lg={24} xl={12}>
          <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={6}>
              <Avatar src={IconSent} size="large" style={{ backgroundColor: '#E8F1FB', padding: '2px' }} shape="square" />
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={18}>
              <Typography.Text>Dispatch ID</Typography.Text>
              <Row align="middle">
                <Title level={5} className="fw-bold">
                  <Button type="link" className="pl-0" onClick={onViewDispatch}>
                    {formatRefNo(collection?.dispatchRefNo, 'BFD')} <HiOutlineExternalLink size={16} />
                  </Button>
                </Title>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={12}>
          <Text type="secondary">Payment to be sent</Text>
          <DetailsGrid description={<NumberText prefix={collection?.currency} value={total} />} />
        </Col>
      </Row>
      <Divider className="my-2" />
      <Row gutter={16} className="mt-3">
        <Col xs={24} sm={24} md={24} lg={24} xl={12}>
          <DetailsGrid title="Due Date" description={<Text>{format(new Date(collection.dueDate), 'dd MMM yyyy')}</Text>} />
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={12}>
          <DetailsGrid title={`Against PO`} description={`${collection?.purchaseOrderNo ? collection?.purchaseOrderNo : '-'}`} />
        </Col>
      </Row>
      <Divider dashed={true} className="my-2" />
      <Row gutter={16}>
        {PAYMENT_ENABLED && (
          <Col span={12}>
            {/* TODO: Integration Pending */}
            <Button type="primary" onClick={() => {}}>
              Make Payment
            </Button>
          </Col>
        )}
        {role !== 'Payment Manager' && collection?.salesAttachments?.invoice && (
          <Col span={12}>
            <Button type="link" className="pl-0" onClick={() => window.open(`${UPLOAD_URL}/${collection?.salesAttachments?.invoice}`)}>
              View Invoice <HiOutlineExternalLink size={16} style={{}} />
            </Button>
          </Col>
        )}
        <Col span={12}>
          <Button type="primary" onClick={showModal}>
            More Details
          </Button>
        </Col>
      </Row>
      <Modal open={isModalOpen} footer={null} onOk={handleOk} onCancel={handleCancel} closeIcon={<ModalCloseIcon />} width={550}>
        <PageHeader title={`Payment | Upcoming | To be sent`} />
        <Card>
          <Text strong>Payment Details</Text>
          <Row justify="space-between" className="mt-2">
            <Text>Price Per Unit</Text>
            <NumberText bold value={dispatch?.tradingPrice / 100} prefix={dispatch?.currency} suffix={`/ ${dispatch?.subUnit}`} />
          </Row>
          <Row justify="space-between" className="mt-2">
            <Text>Quantity</Text>
            <Text strong>
              {dispatch?.receivedQty ? dispatch?.receivedQty : dispatch?.dispatchedQty} {dispatch?.subUnit}
            </Text>
          </Row>
          <Row justify="space-between" className="mt-2">
            <Text>{dispatch?.tradingPriceBasis?.price?.label}</Text>
            <NumberText bold value={dispatch?.tradingPriceBasis?.price?.value / 100} prefix={dispatch?.currency} />
          </Row>
          <Row justify="space-between" className="mt-2">
            <Text>{dispatch?.tradingPriceBasis?.deliveryCharges?.label}</Text>
            <NumberText bold value={dispatch?.tradingPriceBasis?.deliveryCharges?.value / 100} prefix={dispatch?.currency} />
          </Row>
          <Row justify="space-between" className="mt-2">
            <Text>{dispatch?.tradingPriceBasis?.gst?.label}</Text>
            <NumberText bold value={dispatch?.tradingPriceBasis?.gst?.value / 100} prefix={dispatch?.currency} />
          </Row>
          <Divider className="my-2" />
          <Row justify="space-between" className="mt-2">
            <Text>Total Amount</Text>
            <NumberText bold value={dispatch?.paymentPriceBasis?.receivable?.value / 100} prefix={dispatch?.currency} />
          </Row>
          <Row justify="space-between" className="mt-2">
            <Text>Previous Payments</Text>
            <Text>
              - <NumberText bold value={dispatch?.paymentPriceBasis?.received?.value / 100} prefix={dispatch?.currency} />
            </Text>
          </Row>
          <Divider className="my-2" />
          <Row justify="space-between" className="mt-2">
            <Text>Final pending amount</Text>
            <NumberText bold value={dispatch?.paymentPriceBasis?.pendingReceivable?.value / 100} prefix={dispatch?.currency} />
          </Row>
        </Card>

        {dispatch?.sales?.invoice && (
          <Card className="mt-2">
            <Text strong>Attachment Details</Text>
            <Row justify="space-between" className="mt-2">
              <Button type="link" href={`${UPLOAD_URL}/${dispatch?.sales?.invoice}`} target="_blank">
                <Avatar src={require('assets/images/dispatched-quantity.png')} shape="square" size="small" className="mr-2" />
                Download Invoice&nbsp;
                <HiOutlineExternalLink size={16} />
              </Button>
            </Row>
          </Card>
        )}
      </Modal>
    </Card>
  );
};

const UpcomingPayments = ({ tab, showLedger }) => {
  const UrlParams = new URLSearchParams(window.location.search);
  const isMobile = !getBreakPoint().includes('md');
  const [dispatchesLoading, setDispatchesLoading] = useState(false);
  const [type, setType] = useState('sent');
  const [business, setBusiness] = useState(null);
  const [businessId, setBusinessId] = useState(null);
  const [dispatches, setDispatches] = useState(null);
  const [collections, setCollections] = useState([]);
  const [filter, setFilter] = useState(UrlParams.get('filter') || 'all');
  const [insights, setInsights] = useState({
    total: 0,
    overdue: 0,
    due: 0,
  });
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 8,
    total: 0,
    size: 'middle',
    showSizeChanger: false,
    // className: isMobile && 'custom-pagination',
  });

  useEffect(() => {
    if (!isEmpty(businessId)) {
      if (tab === 'upcoming-payments') {
        onLoadDispatches();
      } else {
        setPagination({ ...pagination, current: 1 });
      }
    }
  }, [businessId, type, tab, pagination.current, showLedger]);

  useEffect(() => {
    onLoadBusiness();
  }, []);

  const onLoadBusiness = async () => {
    setDispatchesLoading(true);
    try {
      const businessRes = await axios.get(`${BUSINESS_URL}/me?populate=true`);
      if (businessRes.data.status === 'error') {
        message.error(businessRes.data.error);
      } else {
        if (businessRes.data.business.businessType === 'seller') {
          setType('received');
        }
        setBusinessId(businessRes.data.business._id);
        setBusiness(businessRes.data.business);
      }
    } catch (err) {
      message.error(err.message);
    }
    setDispatchesLoading(false);
  };

  const onLoadDispatches = async () => {
    setDispatchesLoading(true);
    try {
      let currentPage = pagination.current;
      let currentType = type;
      const localData = await JSON.parse(localStorage.getItem('filters'));
      if (!isEmpty(localData)) {
        currentPage = localData.current;
        currentType = localData.type;
      }
      if (currentType === 'sent' && collections.length === 0) {
        const xApiFilters = {
          business: ['me'],
        };
        const res = await axios.get(`${PAYMENT_URL}/schedule/collections?insights=true`, {
          headers: {
            'X-API-Filters': JSON.stringify(xApiFilters),
            'X-API-Sort': JSON.stringify({ createdAt: 'desc' }),
          },
        });

        if (res.data.status === 'error') {
          message.error(res.data.error);
        } else {
          setCollections(res.data.collections);
          setInsights(res.data.insights);
          setPagination({
            ...pagination,
            current: currentPage,
            total: res?.data?.collections?.length,
          });
        }
      } else if (currentType === 'received') {
        const xApiFilters = {
          status: ['In Preparation', 'Dispatched', 'Completed'],
          payableStatus: ['Awaiting Clearance', 'Cleared for Payment'],
          seller: [businessId],
        };

        let url = `${DISPATCH_URL}?populate=true`;
        if (!showLedger) {
          url = `${DISPATCH_URL}?page=${currentPage}&limit=${pagination.pageSize}&populate=true`;
        }
        const res = await axios.get(url, {
          headers: {
            'X-API-Filters': JSON.stringify(xApiFilters),
            'X-API-Sort': JSON.stringify({ createdAt: 'desc' }),
          },
        });

        if (res.data.status === 'error') {
          message.error(res.data.error);
        } else {
          setDispatches(res.data.dispatches);
          setPagination({
            ...pagination,
            current: currentPage,
            total: res.data.count,
          });
        }
      }

      localStorage.removeItem('filters');
    } catch (err) {
      message.error(err.message);
    }
    setDispatchesLoading(false);
  };

  const menuOptions = [];
  const userId = getAuth()?._id;
  const user = find(business?.users, (u) => String(u.user._id) === String(userId));
  if (business?.businessType !== 'seller' && ['Owner', 'Administrator', 'Purchase Manager'].includes(user?.role)) {
    menuOptions.push({
      key: 'sent',
      label: 'To be sent',
    });
  }
  if (business?.businessType !== 'consumer' && ['Owner', 'Administrator', 'Sales Manager'].includes(user?.role)) {
    menuOptions.push({
      key: 'received',
      label: 'To be received',
    });
  }

  if (dispatchesLoading) {
    return (
      <ContentLoader width="100%" height="70vh" backgroundColor="#d9d9d9" foregroundColor="#ededed">
        <rect x="0" y="25" rx="10" ry="10" width="48%" height="211" />
        <rect x="50%" y="25" rx="10" ry="10" width="50%" height="211" />
        <rect x="0" y="250" rx="10" ry="10" width="48%" height="211" />
        <rect x="50%" y="250" rx="10" ry="10" width="50%" height="211" />
      </ContentLoader>
    );
  }

  const filterOptions = {
    all: 'All Payments',
    overdue: 'Overdue',
    today: 'Today',
    next7days: 'Next 7 days',
    next14days: 'Next 14 days',
    next28days: 'Next 28 days',
  };

  const onFilterClick = ({ key }) => {
    setDispatchesLoading(true);
    setFilter(key);
    setTimeout(() => {
      setDispatchesLoading(false);
    }, 200);
  };

  const getCollections = () => {
    let dates;
    if (filter === 'overdue') {
      dates = [moment('2020-01-01').startOf('day'), moment().subtract(1, 'days').endOf('day')];
    }
    if (filter === 'today') {
      dates = [moment().startOf('day'), moment().endOf('day')];
    }
    if (filter === 'next7days') {
      dates = [moment().add(1, 'days').startOf('day'), moment().add(7, 'days').endOf('day')];
    }
    if (filter === 'next14days') {
      dates = [moment().add(8, 'days').startOf('day'), moment().add(14, 'days').endOf('day')];
    }
    if (filter === 'next28days') {
      dates = [moment().add(15, 'days').startOf('day'), moment().add(28, 'days').endOf('day')];
    }

    const filteredCollections = collections.filter((c) => {
      if (filter && filter !== 'all') {
        return new Date(c.dueDate) >= dates[0].toDate() && new Date(c.dueDate) <= dates[1].toDate();
      }
      return true;
    });
    return filteredCollections;
  };

  return (
    <div>
      <Row className="my-3" align="middle" justify="space-between">
        <div className="tag-container">
          {menuOptions.length > 1 &&
            menuOptions.map((tag) => (
              <CheckableTag
                key={tag.key}
                checked={type === tag.key}
                onChange={() => {
                  setType(tag.key);
                  setPagination({ ...pagination, current: 1 });
                }}>
                {tag.label}
              </CheckableTag>
            ))}
        </div>
        <div>
          {type === 'sent' && (
            <Dropdown menu={{ items: map(filterOptions, (label, key) => ({ key, label })), onClick: onFilterClick }} arrow placement="bottomRight">
              <a onClick={(e) => e.preventDefault()}>
                <Space>
                  {filter ? filterOptions[filter] : 'All Payments'}
                  <DownOutlined />
                </Space>
              </a>
            </Dropdown>
          )}
          {/* {showLedger && (
            <Button style={{ color: '#3183de', marginLeft: '10px', border: '1px solid #3183de' }} icon={<CloudDownloadOutlined style={{ color: '#3183de' }} />} size="medium" onClick={() => console.log('Export CSV')}>
              Export to CSV
            </Button>
        )} */}
        </div>
      </Row>
      {type === 'sent' && !showLedger && (
        <Row gutter={[16, 16]} className="my-4">
          {filter === 'all' && (
            <>
              <Col xs={12} sm={12} md={8} lg={8}>
                <DetailsGrid
                  icon={<Avatar src={require('assets/images/overdue-payment.png')} shape="square" size="default" />}
                  title="Overdue Payment"
                  description={<NumberText bold value={insights.overdue / 100} prefix={'INR'} />}
                />
              </Col>
              <Col xs={12} sm={12} md={8} lg={8}>
                <DetailsGrid
                  icon={<Avatar src={require('assets/images/payment-process.png')} shape="square" size="default" />}
                  title="Upcoming Due"
                  description={<NumberText bold value={insights.due / 100} prefix={'INR'} />}
                />
              </Col>
              <Col xs={12} sm={12} md={8} lg={8}>
                <DetailsGrid
                  icon={<Avatar src={require('assets/images/payment-process.png')} shape="square" size="default" />}
                  title="Total Due"
                  description={<NumberText bold value={insights.total / 100} prefix={'INR'} />}
                />
              </Col>
            </>
          )}
          {filter !== 'all' && (
            <>
              <Col xs={12}>
                <DetailsGrid
                  icon={<Avatar src={require('assets/images/overdue-payment.png')} shape="square" size="default" />}
                  title="Overdue"
                  description={<NumberText bold value={insights.overdue / 100} prefix={'INR'} />}
                />
              </Col>
              {filter === 'today' && (
                <Col xs={12}>
                  <DetailsGrid
                    icon={<Avatar src={require('assets/images/payment-process.png')} shape="square" size="default" />}
                    title="Today"
                    description={<NumberText bold value={insights.dueToday / 100} prefix={'INR'} />}
                  />
                </Col>
              )}
              {filter === 'next7days' && (
                <Col xs={12} sm={12} md={8} lg={6}>
                  <DetailsGrid
                    icon={<Avatar src={require('assets/images/payment-process.png')} shape="square" size="default" />}
                    title="Next 7 days"
                    description={<NumberText bold value={insights.due7days / 100} prefix={'INR'} />}
                  />
                </Col>
              )}
              {filter === 'next14days' && (
                <Col xs={12} sm={12} md={8} lg={6}>
                  <DetailsGrid
                    icon={<Avatar src={require('assets/images/payment-process.png')} shape="square" size="default" />}
                    title="Next 14 days"
                    description={<NumberText bold value={insights.due14days / 100} prefix={'INR'} />}
                  />
                </Col>
              )}
              {filter === 'next28days' && (
                <Col xs={12} sm={12} md={8} lg={6}>
                  <DetailsGrid
                    icon={<Avatar src={require('assets/images/payment-process.png')} shape="square" size="default" />}
                    title="Next 28 days"
                    description={<NumberText bold value={insights.due28days / 100} prefix={'INR'} />}
                  />
                </Col>
              )}
            </>
          )}
        </Row>
      )}
      {type === 'sent' ? (
        showLedger && collections?.length > 0 ? (
          <LedgerView collections={getCollections()} />
        ) : (
          <>
            {getCollections()?.length > 0 ? (
              <List
                footer={null}
                grid={{ gutter: [24, 12], column: isMobile ? 1 : 2 }}
                dataSource={getCollections()}
                pagination={{ ...pagination, onChange: (page) => setPagination({ ...pagination, current: page }) }}
                renderItem={(collection) => (
                  <List.Item key={collection?._id}>
                    <CollectionPaymentCard type={type} collection={collection} tab={tab} role={user?.role} />
                  </List.Item>
                )}
              />
            ) : (
              <EmptyContent source={IconWallet} title={'No recent payments found'} subTitle={"You currently don't have any upcoming payments."} />
            )}
          </>
        )
      ) : showLedger && dispatches?.length > 0 ? (
        <LedgerView dispatches={dispatches} />
      ) : (
        <>
          {dispatches?.length > 0 ? (
            <List
              className="mt-4"
              footer={null}
              grid={{ gutter: [24, 12], column: isMobile ? 1 : 2 }}
              dataSource={dispatches}
              pagination={{ ...pagination, onChange: (page) => setPagination({ ...pagination, current: page }) }}
              renderItem={(dispatch) => (
                <List.Item key={dispatch?._id}>
                  <UpcomingPaymentsCard type={type} dispatch={dispatch} tab={tab} currentPage={pagination.current} role={user?.role} />
                </List.Item>
              )}
            />
          ) : (
            <EmptyContent source={IconWallet} title={'No recent payments found'} subTitle={"You currently don't have any upcoming payments."} />
          )}
        </>
      )}
    </div>
  );
};

export default UpcomingPayments;
