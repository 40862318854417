import React from 'react';
import { Card, Checkbox, Image, Typography } from 'antd';
import { UPLOAD_URL } from 'constants/ApiConstants';

const ImageSelect = ({ name, value, image, selectedProduct, onSelect }) => {
  const selected = selectedProduct && (selectedProduct === value || selectedProduct.includes(value));
  const imageSrc = `${UPLOAD_URL}/${image}`;

  return (
    <Card
      onClick={() => onSelect(value)}
      style={{
        width: 120,
        height: 175,
        cursor: 'pointer',
        backgroundColor: selected ? '#ebf8ff' : '#ffffff',
        border: selected ? '1px solid #1a75da' : '1px solid #d9d9d9',
        borderRadius: 10,
      }}
      bodyStyle={{
        padding: 7,
        width: '100%',
        height: 175,
      }}>
      {selected && <Checkbox checked={selected} style={{ position: 'absolute', top: 10, right: 12, zIndex: 10 }} />}
      <Image
        preview={false}
        src={imageSrc}
        style={{
          borderRadius: 8,
          width: '100%',
          height: 100,
          objectFit: 'cover',
        }}
      />
      <Typography.Paragraph className={selected ? 'fw-semi-bold mt-2' : 'mt-2'} ellipsis={{ rows: 3 }}>
        {name}
      </Typography.Paragraph>
    </Card>
  );
};

export default ImageSelect;
