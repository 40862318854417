import React from 'react';
import { Empty, Button, Card, Typography, Row, Col } from 'antd';
import { getBreakPoint } from 'utils/layout';

const { Title, Paragraph } = Typography;

const EmptyContent = ({ source, title, subTitle = '', actionText = '', onActionPress = () => {} }) => {
  const isMobile = !getBreakPoint().includes('sm');

  return (
    <Row align={'middle'} justify={'center'} className="my-5">
      <Col xs={24} sm={24} md={16} lg={12}>
        <Card className="mt-5">
          <Empty
            image={source}
            imageStyle={{ height: 80, marginTop: -60, marginBottom: 20 }}
            description={
              <>
                <Title level={5} className="fw-medium">
                  {title}
                </Title>
                <Paragraph className="text-small">{subTitle}</Paragraph>
              </>
            }>
            {actionText && (
              <Button type="primary" size={isMobile ? 'middle' : 'large'} className="my-2" onClick={onActionPress}>
                {actionText}
              </Button>
            )}
          </Empty>
        </Card>
      </Col>
    </Row>
  );
};

export default EmptyContent;
