import React, { useEffect, useState } from 'react';
import { List, Alert } from 'antd';
import axios from 'axios';
import ContentLoader from 'react-content-loader';
import { PURCHASE_ORDER_SCHEDULE_URL } from 'constants/ApiConstants';
import EmptyContent from 'components/UtilComponents/EmptyContent';
import IconListing from 'assets/images/listings.png';
import { getBreakPoint } from 'utils/layout';
import POScheduleCard from './POScheduleCard';

const CompletedSchedules = ({ tab }) => {
  const isMobile = !getBreakPoint().includes('sm');
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 8,
    total: 0,
    size: 'middle',
    showSizeChanger: false,
    className: isMobile && 'custom-pagination',
  });

  useEffect(() => {
    if (tab === 'completed-schedules') {
      onLoadData();
    } else {
      setPagination({ ...pagination, current: 1 });
    }
  }, [tab, pagination.current]);

  const [poSchedules, setPOSchedules] = useState([]);

  useEffect(() => {
    onLoadData();
  }, []);

  const onLoadData = async () => {
    setLoading(true);
    try {
      const res = await axios.get(`${PURCHASE_ORDER_SCHEDULE_URL}?populate=true`, {
        method: 'get',
        headers: {
          'X-API-Filters': JSON.stringify({ buyer: ['me'], status: ['Delivered'] }),
        },
      });
      if (res.status === 'error') {
        Alert.alertWithType('error', 'Failed', res.error);
      } else {
        setPOSchedules(res?.data?.purchaseorderschedules);
      }
    } catch (err) {
      Alert.alertWithType('error', 'Error', err.message);
    }
    setLoading(false);
  };

  if (loading) {
    return (
      <ContentLoader width="100%" height="70vh" backgroundColor="#d9d9d9" foregroundColor="#ededed">
        <rect x="0" y="25" rx="10" ry="10" width="48%" height="211" />
        <rect x="50%" y="25" rx="10" ry="10" width="50%" height="211" />
        <rect x="0" y="250" rx="10" ry="10" width="48%" height="211" />
        <rect x="50%" y="250" rx="10" ry="10" width="50%" height="211" />
      </ContentLoader>
    );
  }
  return (
    <>
      {poSchedules?.length > 0 ? (
        <List
          className="mt-2 mb-5"
          header={null}
          footer={null}
          grid={{ gutter: [16, 4], column: isMobile ? 1 : 2 }}
          dataSource={poSchedules}
          pagination={{ ...pagination, onChange: (page) => setPagination({ ...pagination, current: page }) }}
          renderItem={(poSchedule) => {
            return (
              <List.Item key={poSchedule?._id}>
                <POScheduleCard poSchedule={poSchedule} type="completed" />
              </List.Item>
            );
          }}
        />
      ) : (
        <EmptyContent source={IconListing} title={'No PO Schedules to show'} />
      )}
    </>
  );
};

export default CompletedSchedules;
