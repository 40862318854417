const validateEmail = (value) => {
  const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  return emailPattern.test(value);
};

const validatePhoneNumber = (value) => {
  const spacePattern = /\s/;
  const specialCharacter = /\D/;
  return !spacePattern.test(value) && !specialCharacter.test(value) && value.length === 10;
};

const validateName = (_, value) => {
  if (value && value.trim() !== value) {
    return Promise.reject('Should not contain space at start or end');
  }
  return Promise.resolve();
};

const validatePassword = (_, value) => {
  const spacePattern = /\s/;
  if (spacePattern.test(value)) {
    return Promise.reject("Shouldn't contain space");
  }
  return Promise.resolve();
};

const validatePrice = (value) => {
  if (value > 100000000) {
    return Promise.reject('Please enter valid value');
  }
  return Promise.resolve();
};

const validateQuantity = (value) => {
  if (value === null || value === undefined) {
    return Promise.reject('Please enter the Quantity');
  }
  return Promise.resolve();
};

export { validateEmail, validatePhoneNumber, validateName, validatePassword, validatePrice, validateQuantity };
