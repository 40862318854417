import React, { Component } from 'react';

export class Icon extends Component {
  render() {
    const { type, className, color } = this.props;
    const style = color ? { color: color } : {};

    return <>{React.createElement(type, { className: className, style: style })}</>;
  }
}

export default Icon;
