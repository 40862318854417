import React from 'react';
import { Button, Col, Divider, Row, Typography } from 'antd';
import { BsCheck2All, BsFillCheckCircleFill, BsFillXCircleFill } from 'react-icons/bs';
import { format } from 'date-fns';
import ChatView from 'components/UtilComponents/ChatView';

const { Title, Text } = Typography;

const StatusCard = ({ chatType }) => {
  const accepted = true;

  const onMakePayment = () => {};

  const onViewOrder = () => {};

  return (
    <ChatView chatType={chatType}>
      <Row gutter={8} align="middle">
        {accepted ? (
          <>
            <Col>
              <BsFillCheckCircleFill style={{ color: '#0A8A85', fontSize: '20px' }} />
            </Col>
            <Col>
              <Title level={5} className="fw-semi-bold">
                Counter Offer Accepted
              </Title>
            </Col>
          </>
        ) : (
          <>
            <Col>
              <BsFillXCircleFill style={{ color: '#F5222D', fontSize: '20px' }} />
            </Col>
            <Col>
              <Title level={5} className="fw-semi-bold">
                Counter Offer Rejected
              </Title>
            </Col>
          </>
        )}
      </Row>
      <Row gutter={16} className="mt-4">
        <Col span={12}>
          <Button type="primary" block size="large" onClick={onMakePayment}>
            Make Payment
          </Button>
        </Col>
        <Col span={12}>
          <Button type="link" block size="large" onClick={onViewOrder}>
            View Order
          </Button>
        </Col>
      </Row>
      <Divider className="my-3" />
      <Row align="middle" justify={chatType === 'sent' ? 'end' : 'start'}>
        {chatType === 'sent' && <BsCheck2All />}
        &nbsp;
        <Text className="text-small">{format(new Date(), 'hh:mm a, dd MMM yyyy')}</Text>
      </Row>
    </ChatView>
  );
};

export default StatusCard;
