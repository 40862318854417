import React from 'react';
import { PageHeader } from 'antd';
import { useNavigate } from 'react-router-dom';
import DirectRFQs from './DirectRFQs';
import BackIcon from 'components/UtilComponents/BackIcon';

const MyRFQs = () => {
  const navigate = useNavigate();

  return (
    <>
      <PageHeader onBack={() => navigate(-1)} backIcon={<BackIcon />} title="Direct RFQs" className="border-bottom" />
      <div className="p-2">
        <DirectRFQs />
      </div>
    </>
  );
};

export default MyRFQs;
