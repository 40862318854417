import React from 'react';
import { Button, Col, Divider, Row, Space, Tag, Typography } from 'antd';
import { ClockCircleOutlined } from '@ant-design/icons';
import { BsCheck2All } from 'react-icons/bs';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import ChatView from 'components/UtilComponents/ChatView';
import DetailsGrid from 'components/UtilComponents/DetailsGrid';
import NumberText from 'components/UtilComponents/NumberText';

const { Title, Text, Paragraph } = Typography;

const OfferCard = ({ chatType }) => {
  const navigate = useNavigate();

  const onViewOrder = () => navigate();

  const onDownload = () => {};

  const onAcceptOrder = () => {};

  const onRejectOrder = () => {};

  return (
    <ChatView chatType={chatType}>
      <Title level={5} className="fw-semi-bold">
        Counter Offer
      </Title>
      <Paragraph>Wood chips</Paragraph>
      <Paragraph>50 MT / Month</Paragraph>
      <Space direction="vertical" size={'middle'} className="w-100">
        <Tag icon={<ClockCircleOutlined />}>Expires in 7 days</Tag>
        <DetailsGrid title="Requested Price" description={<NumberText bold value={6000} suffix={'/ MT'} />} />
        <DetailsGrid title="New Terms of Payment" description={'50% advance & 50% before dispatch'} />
        {chatType === 'sent' && (
          <>
            <Row>
              <Col span={12}>
                <Button type="primary" ghost block size="large" onClick={onViewOrder}>
                  View Order
                </Button>
              </Col>
              <Col span={12}>
                <Button type="link" block size="large" onClick={onDownload}>
                  Download PO
                </Button>
              </Col>
            </Row>
          </>
        )}
        {chatType === 'received' && (
          <div>
            <Button type="link" className="p-0" onClick={onDownload}>
              Download PO
            </Button>
            <Divider className="my-3" />
            <Title level={5} className="text-small">
              Actions
            </Title>
            <Row gutter={16} className="mt-2">
              <Col span={8}>
                <Button type="primary" ghost block size="large" onClick={onRejectOrder}>
                  Reject
                </Button>
              </Col>
              <Col span={16}>
                <Button type="primary" block size="large" onClick={onAcceptOrder}>
                  Accept Order
                </Button>
              </Col>
            </Row>
          </div>
        )}
      </Space>
      <Divider className="my-3" />
      <Row align="middle" justify={chatType === 'sent' ? 'end' : 'start'}>
        {chatType === 'sent' && <BsCheck2All />}
        &nbsp;
        <Text className="text-small">{format(new Date(), 'hh:mm a, dd MMM yyyy')}</Text>
      </Row>
    </ChatView>
  );
};

export default OfferCard;
