import React, { useEffect, useState } from 'react';
import { Typography, Row, Col, Form, Input, Button, Divider, message, Carousel } from 'antd';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { RightOutlined } from '@ant-design/icons';
import { USER_URL, BUSINESS_URL } from 'constants/ApiConstants';
import { setAuth, isLoggedIn } from 'utils/auth';
import request from 'utils/request';
import { validateEmail, validatePhoneNumber, validatePassword } from './validation';
import FooterLinks from 'components/UtilComponents/FooterLinks';
import { posthog } from 'posthog-js';

const { Title, Text, Link, Paragraph } = Typography;

const Login = () => {
  let navigate = useNavigate();
  const [form] = Form.useForm();
  const [loginType, setLoginType] = useState('password');
  const [counter, setCounter] = useState(0);
  const [step, setStep] = useState('send-otp');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isLoggedIn()) checkSignUp();
  }, []);

  const checkSignUp = async () => {
    const businessRes = await request(`${BUSINESS_URL}/me?populate=true`, {
      method: 'get',
    });
    //Checking whether the business type is present or not to filter accounts with complete sign-up.
    if (!businessRes.business?.businessType) {
      window.location = '/signup';
    } else {
      const url = localStorage.getItem('copiedLink');
      if (url) {
        window.location = url;
        localStorage.removeItem('copiedLink');
      } else {
        window.location = '/';
      }
    }
  };

  useEffect(() => {
    setStep('send-otp');
    setCounter(0);
  }, [loginType]);

  useEffect(() => {
    const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  const onSubmit = (values) => {
    if (loginType === 'password') {
      onValidatePassword(values);
    } else if (step === 'send-otp') {
      onSendOTP(values);
    } else {
      onVerifyOTP(values);
    }
  };

  const onValidatePassword = async (values) => {
    setLoading(true);
    try {
      const res = await axios.post(`${USER_URL}/login`, {
        username: values.username,
        password: values.password,
        platform: 'Web',
      });
      if (res.data.status === 'success') {
        const { _id, token, aclGroup } = res.data;
        if (aclGroup !== 'business') {
          return message.error('Only business users can login to the portal');
        }
        setAuth({ _id, token, aclGroup });
        posthog.capture('$login', {
          login_type: 'email-password',
          $set: { lastLoginDate: new Date().toISOString() },
        });
        checkSignUp();
      } else {
        message.error(res.data.error);
      }
    } catch (err) {
      message.error(err.message);
    }
    setLoading(false);
  };

  const onSendOTP = async () => {
    setLoading(true);
    try {
      const res = await request(`${USER_URL}/send/otp`, {
        method: 'post',
        body: JSON.stringify({
          username: form.getFieldValue('username'),
        }),
      });
      if (res.status === 'error') {
        if (res.code === 1006) message.error('Account do not Exists');
        else console.log({ username: res.error });
      } else {
        message.success('OTP sent successfully to your Email Address and Phone Number');
        setStep('verify-otp');
        setCounter(59);
      }
      setTimeout(() => {
        setLoading(false);
      }, 3000);
    } catch (error) {
      console.error(error);
    }
  };

  const onVerifyOTP = async (values) => {
    setLoading(true);
    try {
      const res = await request(`${USER_URL}/login`, {
        method: 'post',
        body: JSON.stringify({
          username: values?.username,
          otp: values?.otp,
          platform: 'Web',
        }),
      });
      if (res.status === 'error') {
        if (res.code === 1007) message.error('Please provide valid OTP');
        else if (res.code === 1012) message.error('Account has been deactivated');
        else console.log({ otp: res.error });
      } else {
        if (res?.aclGroup !== 'business') {
          return message.error('Only business users can login to the portal');
        } else {
          await setAuth({
            token: res.token,
            expiryTimeStamp: res.expiryTimeStamp,
            validity: res.validity,
            aclGroup: res.aclGroup,
            _id: res._id,
          });
          posthog.capture('$login', {
            login_type: 'email-otp',
            $set: { lastLoginDate: new Date().toISOString() },
          });
          const url = localStorage.getItem('copiedLink');
          if (url) {
            window.location = url;
            localStorage.removeItem('copiedLink');
          } else {
            window.location = '/';
          }
        }
      }
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  return (
    <Row align="middle" justify="space-around" className="vh-100">
      <Col xs={24} sm={24} md={24} lg={12}>
        <Carousel arrows={true} autoplay pauseOnHover={false}>
          <img src={require('assets/images/slides/slide-1.jpg')} alt="Banner 1" />
          <img src={require('assets/images/slides/slide-2.jpg')} alt="Banner 2" />
          <img src={require('assets/images/slides/slide-3.jpg')} alt="Banner 3" />
          <img src={require('assets/images/slides/slide-4.jpg')} alt="Banner 4" />
        </Carousel>
      </Col>
      <Col xs={24} sm={24} md={24} lg={12} className="p-3 p-sm-5">
        <Title level={3} className="m-0 fw-bold">
          Login
        </Title>
        <Paragraph>Login with {loginType === 'password' ? 'Password' : 'OTP'}</Paragraph>

        <Form className="mt-5" form={form} layout="vertical" onFinish={onSubmit}>
          <Form.Item
            className="bg-transparent"
            name="username"
            rules={[
              {
                required: true,
                message: 'Please enter your Phone Number / Email Address',
              },
              () => ({
                validator(_, value) {
                  if (!value || validateEmail(value) || validatePhoneNumber(value)) {
                    return Promise.resolve();
                  }
                  return Promise.reject('Please enter a valid email or a 10-digit Phone Number');
                },
              }),
            ]}
            hasFeedback>
            <Input size="large" placeholder="Phone Number / Email Address" allowClear />
          </Form.Item>

          {loginType === 'password' && (
            <>
              <Form.Item
                className="bg-transparent"
                name="password"
                rules={[
                  {
                    required: true,
                    message: 'Please enter your password',
                  },
                  {
                    validator: validatePassword,
                  },
                ]}
                hasFeedback>
                <Input.Password
                  size="large"
                  placeholder="Password"
                  allowClear
                  iconRender={(visible) =>
                    visible ? (
                      <Text underline style={{ cursor: 'pointer' }}>
                        Hide
                      </Text>
                    ) : (
                      <Text underline style={{ cursor: 'pointer' }}>
                        Show
                      </Text>
                    )
                  }
                />
              </Form.Item>
              <Form.Item className="mt-0">
                <Link
                  onClick={() => {
                    posthog.capture(`$screenview | $forgot-password`, { $set: { lastActivityDate: new Date().toISOString() } });
                    navigate('/forgot-password');
                  }}
                  underline>
                  Forgot Password?
                </Link>
              </Form.Item>
            </>
          )}

          {loginType === 'otp' && step === 'verify-otp' && (
            <Form.Item
              className="bg-transparent"
              name="otp"
              rules={[
                {
                  required: true,
                  message: 'Please enter the OTP received',
                },
                {
                  message: 'Please enter a valid OTP',
                  pattern: new RegExp('^[0-9]{6}$'),
                },
              ]}
              hasFeedback
              extra={
                <Row className="mt-2" align="middle">
                  <Text>Didn't receive OTP? {counter > 0 && `Request again in ${counter}s`}</Text>
                  {counter === 0 && (
                    <Button type="link" size="middle" className="px-2" onClick={onSendOTP}>
                      Re-send OTP
                    </Button>
                  )}
                </Row>
              }>
              <Input size="large" placeholder="Enter 6 Digit OTP" allowClear />
            </Form.Item>
          )}

          <Form.Item>
            <Row justify="center" className="my-2">
              <Button type="primary" htmlType="submit" size="large" style={{ width: '250px' }} loading={loading}>
                {loginType === 'password' && 'Login'}
                {loginType === 'otp' && step === 'send-otp' && 'Request OTP'}
                {loginType === 'otp' && step === 'verify-otp' && 'Verify OTP'}
              </Button>
            </Row>
            <Row justify="center" className="my-2">
              <Button
                onClick={
                  loginType === 'password'
                    ? () => {
                        setLoginType('otp');
                        form.resetFields();
                      }
                    : () => {
                        setLoginType('password');
                        form.resetFields();
                      }
                }
                type="link"
                size="large"
                style={{ width: '250px' }}>
                Login with {loginType === 'password' ? 'OTP' : 'password'} instead <RightOutlined />
              </Button>
            </Row>
          </Form.Item>
          <Row align="middle" justify="center" className="mb-5">
            <Text>
              Don't have an account?{' '}
              <Link
                onClick={() => {
                  posthog.capture(`$screenview | $signup`, { $set: { lastActivityDate: new Date().toISOString() } });
                  navigate('/signup');
                }}
                underline>
                Sign Up
              </Link>
            </Text>
          </Row>
        </Form>

        <FooterLinks.DownloadApps />

        <Divider />

        <div className="mb-5">
          <Paragraph type="secondary" className="text-center">
            Copyrights &copy; {new Date().getFullYear()} Buyofuel - All rights reserved
          </Paragraph>
        </div>
      </Col>
    </Row>
  );
};

export default Login;
