import React, { useEffect, useRef, useState } from 'react';
import { isEmpty } from 'lodash';
import { UPLOAD_URL } from 'constants/ApiConstants';

const TreeNode = ({ data, handleButtonClick }) => {
  const handleNodeClick = (event, node) => {
    event.stopPropagation();
    handleButtonClick(node.location);
  };
  return (
    <ul>
      {data.map((node, index) => (
        <li key={index}>
          {node.number && (
            <div className="node-number">
              <p>{node.number}</p>
            </div>
          )}
          <a onClick={(event) => handleNodeClick(event, node)}>
            <img src={`${UPLOAD_URL}/${node.productImage}`} />
            <span style={{ color: 'black' }}>{node.productName}</span>
            <p style={{ color: 'black' }}>
              {node.quantity.toFixed(2)} {node.unit}
            </p>
            <p style={{ color: 'gray' }}>
              {node.city}, {node.state}
            </p>
          </a>
          {node.children && <TreeNode data={node.children} handleButtonClick={handleButtonClick} />}
        </li>
      ))}
    </ul>
  );
};

const CustomTree = ({ dispatch, handleButtonClick }) => {
  const divRef = useRef(null);
  const [treeData, setTreeData] = useState([]);

  useEffect(() => {
    if (!isEmpty(dispatch)) {
      const tree = [];
      const hederaData = dispatch.hederaMetadata.sourceChain;
      const sourceChains = hederaData.sourceChain;
      const buyerAddress = hederaData.deliveryAddress;
      const sellerAddress = hederaData.pickupAddress;
      const buyerCoordinates = buyerAddress.location.coordinates;
      const sellerCoordinates = sellerAddress.location.coordinates;
      //buyer
      const buyer = {
        number: 2,
        productName: dispatch.product.name,
        quantity: dispatch.dispatchedQty,
        unit: dispatch.subUnit,
        productImage: dispatch.product.image,
        city: buyerAddress.city,
        state: buyerAddress.state,
        location: [buyerCoordinates[1], buyerCoordinates[0]],
        children: [],
      };

      //buyofuel or lead seller
      const seller = {
        number: 1,
        productName: hederaData.productName,
        quantity: hederaData.quantity,
        unit: dispatch.subUnit,
        productImage: hederaData.productImage,
        city: sellerAddress.city,
        state: sellerAddress.state,
        location: [sellerCoordinates[1], sellerCoordinates[0]],
        children: [],
      };

      if (sourceChains.length > 0) {
        const groupedData = Object.values(
          sourceChains.reduce((accumulator, current) => {
            const key = `${current.city}_${current.state}_${current.productName}`;
            accumulator[key] = accumulator[key] || { ...current, quantity: 0 };
            accumulator[key].quantity += current.quantity;
            return accumulator;
          }, {})
        );
        if (groupedData.length > 0) {
          groupedData.forEach((e) => {
            const coordinates = e.location.coordinates;
            if (coordinates.length === 2) {
              seller.children.push({ ...e, unit: 'MT', location: [coordinates[1], coordinates[0]] });
            }
          });
        }
      }

      buyer.children.push(seller);
      tree.push(buyer);
      setTreeData(tree);
    }
  }, [dispatch]);

  useEffect(() => {
    const handleResize = () => {
      if (divRef.current) {
        const containerWidth = divRef.current.offsetWidth;
        const contentWidth = divRef.current.scrollWidth;
        const initialScrollPosition = (contentWidth - containerWidth) / 2;

        divRef.current.scrollLeft = initialScrollPosition;
      }
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [treeData]);

  return (
    <div className="tree-container">
      <div className="tree" ref={divRef}>
        {!isEmpty(treeData) && <TreeNode data={treeData} handleButtonClick={handleButtonClick} />}
      </div>
    </div>
  );
};

export default CustomTree;
