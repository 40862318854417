import React, { useState, useEffect } from 'react';
import { Avatar, Button, Checkbox, Calendar, Col, Divider, Form, Input, InputNumber, Modal, Radio, Row, Select, message, Typography, Upload } from 'antd';
import { CheckOutlined, ExclamationCircleOutlined, FileTextOutlined, LeftOutlined, PlusOutlined } from '@ant-design/icons';
import { keyBy, isEmpty } from 'lodash';
import axios from 'axios';
import moment from 'moment';
import { startOfDay, format } from 'date-fns';
import { BUSINESS_URL, PAYMENT_TERMS_URL, PRODUCT_URL, SAVED_ADDRESS_URL, PURCHASE_ORDER_URL } from 'constants/ApiConstants';
import ModalCloseIcon from 'components/UtilComponents/ModalCloseIcon';
import ErrorBoundary from 'components/Errors/ErrorBoundary';
import BackIcon from 'components/UtilComponents/BackIcon';
import { validateQuantity } from 'containers/validation';
import DetailsGrid from 'components/UtilComponents/DetailsGrid';
import ImageSelect from 'components/UtilComponents/ImageSelect';
import AddNewAddress from 'components/UtilComponents/AddNewAddress';
import { posthog } from 'posthog-js';
import { checkForAnomaly } from 'utils/anomaly';

const { Paragraph, Title, Text } = Typography;

const CreateNewPurchaseOrder = ({ visible, close }) => {
  const viewName = 'buy';
  const [form] = Form.useForm();
  const [step, setStep] = useState('select-product');
  const [loading, setLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [productsById, setProductsById] = useState({});
  const [product, setProduct] = useState(null);
  const [unit, setUnit] = useState('');
  const [subUnits, setSubUnits] = useState([]);
  const [savedAddresses, setSavedAddresses] = useState([]);
  const [savedAddressesById, setSavedAddressesById] = useState([]);
  const [paymentTerms, setPaymentTerms] = useState([]);
  const [paymentTermsById, setPaymentTermsById] = useState(null);
  const [deliveryAddress, setDeliveryAddress] = useState(null);
  const [unitRefreshing, setUnitRefreshing] = useState(false);
  const [showAddAddress, setShowAddAddress] = useState(false);
  const [warningVisible, setWarningVisible] = useState(false);

  useEffect(() => {
    onLoad();
    form.resetFields();
    setWarningVisible(false);
  }, []);

  useEffect(() => {
    if (product) {
      onLoadUnit();
    }
  }, [product]);

  const onCloseAddAddress = (reload) => {
    if (reload) {
      onLoad();
    }
    setShowAddAddress(!showAddAddress);
  };

  const handleFromToValidation = () => {
    const fromQty = form.getFieldValue('fromQty');
    const toQty = form.getFieldValue('toQty');
    if (fromQty !== undefined && fromQty !== null && toQty !== undefined && toQty !== null && fromQty > toQty) {
      setWarningVisible(true);
      return Promise.reject();
    } else {
      setWarningVisible(false);
      return Promise.resolve();
    }
  };

  const onClickPrev = () => {
    if (step === 'review-details') return setStep('collect-details');
    if (step === 'collect-details') {
      const updatedValues = {};
      Object.keys(form.getFieldsValue()).forEach((field) => {
        updatedValues[field] = field === 'subUnit' ? form.getFieldValue(field) : undefined;
      });
      form.setFieldsValue(updatedValues);
      setWarningVisible(false);
      return setStep('select-product');
    }
  };

  const getTitle = () => {
    if (step === 'select-product') return 'Request New Purchase Order';
    if (step === 'collect-details') {
      return (
        <>
          <BackIcon onClick={onClickPrev} className="mr-3" />
          {` Purchase Order for '${product.name}'`}
        </>
      );
    }
    return (
      <>
        <BackIcon onClick={onClickPrev} className="mr-3" />
        {' Review Purchase Order'}
      </>
    );
  };

  const onLoad = async () => {
    setLoading(true);
    try {
      const businessRes = await axios.get(`${BUSINESS_URL}/me?populate=true`);

      const addressRes = await axios.get(`${SAVED_ADDRESS_URL}`, {
        headers: {
          'X-API-Filters': JSON.stringify({
            business: ['me'],
            status: ['Active'],
          }),
        },
      });

      const termRes = await axios.get(`${PAYMENT_TERMS_URL}`);

      if (businessRes.data.status === 'error') {
        message.error(businessRes.data.error);
      } else if (addressRes.data.status === 'error') {
        message.error(addressRes.data.error);
      } else if (termRes.data.status === 'error') {
        message.error(termRes.data.error);
      } else {
        setProducts(businessRes.data.business.buying);
        setProductsById(keyBy(businessRes.data.business.buying, '_id'));
        setSavedAddresses(addressRes.data.savedaddresses);
        setPaymentTerms(termRes.data.paymentterms);
        if (addressRes.data.savedaddresses.length > 0) {
          form.setFields([{ name: 'deliveryAddressId', value: addressRes.data.savedaddresses[0]?._id }]);
          setDeliveryAddress(addressRes.data.savedaddresses[0]?._id);
          setSavedAddressesById(keyBy(addressRes.data.savedaddresses, '_id'));
        }
        if (termRes.data.paymentterms.length > 0) {
          form.setFields([{ name: 'paymentTerm', value: termRes?.data?.paymentterms[0]?._id }]);
          setPaymentTermsById(keyBy(termRes.data.paymentterms, '_id'));
        }
      }
    } catch (err) {
      message.error(err.message);
    }
    setLoading(false);
  };

  const onLoadUnit = async () => {
    setUnitRefreshing(true);
    try {
      const unitRes = await axios.get(`${PRODUCT_URL}/${product._id}?populate=true`);
      if (unitRes.data.status === 'error') {
        message.error(unitRes.data.error);
      } else {
        const subUnit = unitRes.data.product.unit.subUnits[0]?._id;
        setUnit(subUnit);
        form.setFields([{ name: 'subUnit', value: subUnit }]);
        setSubUnits(unitRes.data.product.unit.subUnits);
      }
    } catch (error) {
      message.error(error.message);
    }
    setUnitRefreshing(false);
  };

  const onClickNext = () => {
    if (step === 'select-product') return setStep('collect-details');
    if (step === 'collect-details') return setStep('review-details');
    if (step === 'review-details') return onSubmit();
  };

  const getFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const onSubmit = async () => {
    if (btnLoading) return;
    setBtnLoading(true);
    try {
      const values = form.getFieldsValue(true);
      const detectedAnomaly = checkForAnomaly('purchase-order', { ...values, product: productsById[values?.product], subUnits });
      if (detectedAnomaly) {
        setBtnLoading(false);
        return message.error(detectedAnomaly);
      }
      const res = await axios.post(`${PURCHASE_ORDER_URL}`, {
        purchaseOrderNo: values.purchaseOrderNo,
        purchaseOrderDate: new Date(values.purchaseOrderDate?.format('YYYY-MM-DD')),
        product: values.product,
        subUnit: values.subUnit,
        currency: values.currency,
        fromQty: values.fromQty,
        toQty: values.quantityRangeEnabled ? values.toQty : 0,
        price: values.price * 100,
        deliveryAddressId: values.deliveryAddressId,
        paymentTerm: values.paymentTerm,
        qualityTesting: values.qualityTesting ? 'Requested' : 'Not Requested',
        remark: values.remark,
      });

      if (res.data.status === 'success') {
        message.success('Purchase Order created successfully');
        posthog.capture(`$${viewName} | $purchase-order-created`, {
          data: {
            purchaseOrderNo: values.purchaseOrderNo,
            purchaseOrderDate: new Date(values.purchaseOrderDate?.format('YYYY-MM-DD')),
            product: product.name,
            subUnit: values.subUnit,
            currency: values.currency,
            fromQty: values.fromQty,
            toQty: values.quantityRangeEnabled ? values.toQty : 0,
            price: values.price,
            city: savedAddressesById[values.deliveryAddressId].city,
            state: savedAddressesById[values.deliveryAddressId].state,
            qualityTesting: values.qualityTesting ? 'Requested' : 'Not Requested',
            remark: values.remark,
          },
          $set: {
            lastActivityDate: new Date().toISOString(),
          },
        });

        if (!isEmpty(values?.purchaseOrderFile)) {
          const files = new FormData();
          files.append('purchaseOrderFile', values?.purchaseOrderFile[0].originFileObj);
          await axios.post(`${PURCHASE_ORDER_URL}/upload/${res?.data?.purchaseorder?._id}`, files);
        }

        close(true);
        setStep('select-product');
        setWarningVisible(false);
        form.resetFields();
      } else {
        message.error(res.data.error);
      }
    } catch (err) {
      message.error(err.message);
    }
    setBtnLoading(false);
  };

  const confirmOnClose = () => {
    if ((!loading && form.isFieldsTouched()) || step === 'review-details') {
      const values = form.getFieldsValue();
      Modal.confirm({
        title: 'Unsaved Data',
        icon: <ExclamationCircleOutlined />,
        content: 'This form has unsaved data. Are you sure you want to close the screen?',
        okText: 'Yes',
        onOk: () => {
          form.resetFields();
          setStep('select-product');
          setWarningVisible(false);
          close();
          posthog.capture(`$${viewName} | $create-purchase-order-attempted`, {
            data: {
              purchaseOrderNo: values.purchaseOrderNo ? values.purchaseOrderNo : null,
              purchaseOrderDate: values.purchaseOrderDate ? new Date(values.purchaseOrderDate?.format('YYYY-MM-DD')) : null,
              product: product ? product.name : null,
              subUnit: values.subUnit ? values.subUnit : null,
              currency: values.currency ? values.currency : null,
              fromQty: values.fromQty ? values.fromQty : null,
              toQty: values.quantityRangeEnabled ? values.toQty : 0,
              price: values.price ? values.price : null,
              city: savedAddressesById[values.deliveryAddressId].city,
              state: savedAddressesById[values.deliveryAddressId].state,
              qualityTesting: values.qualityTesting ? 'Requested' : 'Not Requested',
              remark: values.remark ? values.remark : null,
            },
            $set: {
              lastActivityDate: new Date().toISOString(),
            },
          });
        },
        cancelText: 'No',
      });
    } else {
      setStep('select-product');
      setLoading(false);
      setWarningVisible(false);
      form.resetFields();
      close();
    }
  };

  useEffect(() => {
    const modalBody = document.querySelector(`.ant-modal-body`);
    if (modalBody) {
      modalBody.scrollTop = 0;
    }
  }, [step]);

  return (
    <Modal
      title={getTitle()}
      open={visible}
      onCancel={confirmOnClose}
      footer={null}
      closeIcon={<ModalCloseIcon />}
      maskClosable={false}
      width={480}
      bodyStyle={{ height: '70vh', overflow: 'scroll' }}
      centered>
      <ErrorBoundary>
        <Form form={form} layout="vertical" onFinish={step === 'select-product' || step === 'collect-details' ? onClickNext : onSubmit} requiredMark={false} scrollToFirstError>
          {step === 'select-product' && (
            <>
              <Form.Item noStyle shouldUpdate={(prevValues, currValues) => prevValues.product !== currValues.product}>
                {() => (
                  <Form.Item
                    label="Select the product"
                    className="bg-transparent"
                    name="product"
                    rules={[
                      {
                        required: true,
                        message: 'Please select the Product',
                      },
                    ]}
                    hasFeedback>
                    <Row gutter={[16, 16]} justify="start">
                      {products.map((product) => {
                        return (
                          <Col key={product._id}>
                            <ImageSelect
                              {...product}
                              selectedProduct={form.getFieldValue('product')}
                              onSelect={() => {
                                setProduct(product);
                                form.setFieldValue('product', product._id);
                                onClickNext();
                              }}
                            />
                          </Col>
                        );
                      })}
                    </Row>
                  </Form.Item>
                )}
              </Form.Item>
            </>
          )}
          {step === 'collect-details' && (
            <>
              <Form.Item
                label="Purchase Order No"
                className="bg-transparent"
                name="purchaseOrderNo"
                rules={[
                  {
                    required: true,
                    message: 'Please enter the purchase order no.',
                  },
                ]}
                hasFeedback>
                <Input size="large" placeholder="Purchase Order No" className="w-100" />
              </Form.Item>

              <Form.Item
                name="subUnit"
                label="Unit"
                initialValue={subUnits[0]?._id}
                rules={[
                  {
                    required: true,
                    message: 'Please select a valid unit',
                  },
                ]}
                hasFeedback>
                <Select
                  placeholder="Select Unit"
                  size="large"
                  showSearch
                  filterOption={(inputValue, option) => option.children?.toLowerCase().includes(inputValue?.toLowerCase())}
                  onChange={(unit) => setUnit(unit)}
                  loading={unitRefreshing}>
                  {subUnits?.map((subUnit) => (
                    <Select.Option key={subUnit?._id} value={subUnit?._id}>
                      {subUnit?._id}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item noStyle shouldUpdate={(prevValues, currValues) => prevValues.quantityRangeEnabled !== currValues.quantityRangeEnabled}>
                {({ getFieldValue }) =>
                  getFieldValue('quantityRangeEnabled') === false ? (
                    <>
                      <Form.Item
                        label={`Quantity Needed (${unit})`}
                        className="bg-transparent"
                        name="fromQty"
                        rules={[
                          {
                            validator: (_, value) => validateQuantity(value),
                          },
                        ]}
                        hasFeedback>
                        <InputNumber precision={3} controls={false} size="large" min={1} placeholder="Quantity Needed" className="w-100" />
                      </Form.Item>
                    </>
                  ) : (
                    <>
                      <Paragraph>Quantity Needed ({unit})</Paragraph>
                      <Row gutter={16} align="middle" className="mt-2">
                        <Col span={12}>
                          <Form.Item
                            className="bg-transparent"
                            name="fromQty"
                            dependencies={['toQty']}
                            rules={[
                              {
                                validator: handleFromToValidation,
                              },
                              {
                                validator: (_, value) => validateQuantity(value),
                              },
                            ]}
                            hasFeedback>
                            <InputNumber precision={3} controls={false} size="large" min={1} placeholder="From" className="w-100" />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            className="bg-transparent"
                            name="toQty"
                            dependencies={['fromQty']}
                            rules={[
                              {
                                validator: handleFromToValidation,
                              },
                              {
                                validator: (_, value) => validateQuantity(value),
                              },
                            ]}
                            hasFeedback>
                            <InputNumber precision={3} controls={false} size="large" placeholder="To" className="w-100" />
                          </Form.Item>
                        </Col>
                      </Row>
                      {warningVisible && <div style={{ color: 'red', marginTop: '-5px', marginBottom: '5px' }}>Set the From Quantity less than or equal to To Quantity</div>}
                    </>
                  )
                }
              </Form.Item>

              <Form.Item
                className="bg-transparent"
                name="quantityRangeEnabled"
                valuePropName="checked"
                initialValue={false}
                onChange={() => {
                  form.setFieldsValue({ toQty: null }), setWarningVisible(false);
                }}>
                <Checkbox>Range</Checkbox>
              </Form.Item>

              <Form.Item
                name="currency"
                label="Currency"
                initialValue={'INR'}
                rules={[
                  {
                    required: true,
                    message: 'Please select your preferred currency',
                  },
                ]}
                hasFeedback>
                <Select placeholder="Select Currency" size="large">
                  <Select.Option key={'INR'} value="INR">
                    ₹ INR
                  </Select.Option>
                  <Select.Option key={'USD'} value="USD">
                    $ USD
                  </Select.Option>
                </Select>
              </Form.Item>

              <Form.Item
                label={`Price (${unit})`}
                className="bg-transparent"
                name="price"
                rules={[
                  {
                    required: true,
                    message: 'Please enter your price',
                  },
                ]}
                hasFeedback>
                <InputNumber precision={3} size="large" min={1} max={1000000} placeholder="Price" className="w-100" />
              </Form.Item>

              <Form.Item
                name="deliveryAddressId"
                label={
                  <>
                    <Row align="middle" justify="space-between">
                      <Col span={20}>
                        <Text>Delivery Address</Text>
                      </Col>
                      <Col span={4}>
                        <Button type="link" icon={<PlusOutlined />} onClick={() => onCloseAddAddress()}>
                          Add New Address
                        </Button>
                      </Col>
                    </Row>
                  </>
                }
                rules={[
                  {
                    required: true,
                    message: 'Please select a valid Delivery Address',
                  },
                ]}>
                <Select
                  size="large"
                  showSearch
                  filterOption={(inputValue, option) => {
                    const { label, line1, city, state, pincode } = option;
                    const searchValue = inputValue?.toLowerCase();
                    return [label, line1, city, state, pincode].some((value) => value?.toLowerCase().includes(searchValue));
                  }}
                  optionLabelProp="label"
                  value={deliveryAddress}
                  onChange={(deliveryAddress) => {
                    setDeliveryAddress(deliveryAddress);
                    form.setFieldsValue({ deliveryAddress });
                  }}
                  menuItemSelectedIcon={<CheckOutlined />}>
                  {savedAddresses.map((savedAddress) => (
                    <Select.Option key={savedAddress._id} value={savedAddress._id} label={savedAddress?.name}>
                      <div>
                        <h5 className="mt-0">{savedAddress?.name}</h5>
                        <p className="mt-0 mb-0 text-wrap">
                          {savedAddress?.line1}, {savedAddress?.line2 ? savedAddress?.line2 + ',' : ''} {savedAddress?.city}, {savedAddress?.state} - {savedAddress?.pincode}
                        </p>
                      </div>
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                label="Purchase Order Date"
                className="bg-transparent"
                name="purchaseOrderDate"
                initialValue={moment(startOfDay(new Date()))}
                rules={[
                  {
                    required: true,
                    message: 'Please select an purchase order date',
                  },
                ]}>
                <Calendar validRange={[moment(startOfDay(new Date())), moment().add(1, 'years')]} fullscreen={false} />
              </Form.Item>

              <Form.Item label="Quality Remarks" className="bg-transparent" name="remark">
                <Input.TextArea rows={3} maxLength={200} showCount />
              </Form.Item>

              <Form.Item name="purchaseOrderFile" getValueFromEvent={getFile}>
                <Upload maxCount={1} beforeUpload={() => false} defaultFileList={form.getFieldValue('purchaseOrderFile') || []} accept="application/pdf">
                  <Button size="large" icon={<FileTextOutlined />}>
                    PO Attachment (Optional)
                  </Button>
                </Upload>
              </Form.Item>

              <Divider />

              <Form.Item
                label="Terms of Payment"
                className="bg-transparent"
                name="paymentTerm"
                initialValue={paymentTerms[0]?._id}
                rules={[
                  {
                    required: true,
                    message: 'Please select Payment Terms',
                  },
                ]}>
                <Radio.Group size="large">
                  {paymentTerms?.map((paymentterm) => {
                    return (
                      <Radio className="w-100" key={paymentterm?._id} value={paymentterm?._id} style={{ paddingTop: 10, paddingBottom: 10 }}>
                        {paymentterm?.name}
                      </Radio>
                    );
                  })}
                </Radio.Group>
              </Form.Item>

              <Form.Item className="bg-transparent" name="qualityTesting" valuePropName="checked" initialValue={false}>
                <Checkbox>Request Quality Testing</Checkbox>
              </Form.Item>
            </>
          )}
          {step === 'review-details' && (
            <>
              <Row gutter={[16, 16]} className="mt-2">
                <Col span={12}>
                  <Paragraph type="secondary">Product</Paragraph>
                  <Title level={5} className="mb-2">
                    {productsById[form.getFieldValue('product')]?.name} ({unit})
                  </Title>
                </Col>
                <Col span={12}>
                  <Paragraph type="secondary">Currency</Paragraph>
                  <Title level={5}>{form.getFieldValue('currency') === 'INR' ? `INR (₹)` : `USD ($)`}</Title>
                </Col>
              </Row>

              <Divider />

              <Row gutter={[16, 16]} className="mt-2">
                <Col span={12}>
                  <Paragraph type="secondary">Purchase Order No.</Paragraph>
                  <Title level={5} className="mb-2">
                    {form.getFieldValue('purchaseOrderNo')}
                  </Title>
                </Col>
                <Col span={12}>
                  <Paragraph type="secondary">Purchase Order Date</Paragraph>
                  <Title level={5} className="mb-2">
                    {format(new Date(form.getFieldValue('purchaseOrderDate')._d), 'dd MMM yyyy')}
                  </Title>
                </Col>
              </Row>

              <Divider />

              <DetailsGrid icon={<Avatar src={require('assets/images/icon-cash.png')} shape="square" size="default" />} description="Price & Quantity" />
              <Row gutter={[16, 16]} className="mt-2">
                <Col span={12}>
                  <DetailsGrid title="Price" description={`${form.getFieldValue('currency') === 'USD' ? `$` : `₹`} ${form.getFieldValue('price')} / ${form.getFieldValue('subUnit')}`} />
                </Col>
                <Col span={12}>
                  <DetailsGrid
                    title="Quantity"
                    description={
                      !form.getFieldValue('quantityRangeEnabled')
                        ? form.getFieldValue('fromQty') + ` ${form.getFieldValue('subUnit')}`
                        : `${form.getFieldValue('fromQty')} to ${form.getFieldValue('toQty')}` + ` ${form.getFieldValue('subUnit')}`
                    }
                  />
                </Col>
              </Row>

              <Divider />

              <DetailsGrid icon={<Avatar src={require('assets/images/icon-delivery.png')} shape="square" size="default" />} description="Delivery" />
              <Row gutter={[16, 16]} className="mt-2">
                <Col span={12}>
                  <DetailsGrid
                    title="Delivery Location"
                    description={savedAddressesById[deliveryAddress]?.name}
                    description2={`${savedAddressesById[deliveryAddress]?.line1}, ${savedAddressesById[deliveryAddress]?.line2 ? savedAddressesById[deliveryAddress]?.line2 + ',' : ''} ${
                      savedAddressesById[deliveryAddress]?.city
                    }, ${savedAddressesById[deliveryAddress]?.state}, ${savedAddressesById[deliveryAddress]?.pincode}`}
                  />
                </Col>
              </Row>

              <Divider />

              <Paragraph type="secondary">Additional Information</Paragraph>
              {form.getFieldValue('paymentTerm') && (
                <Paragraph>
                  &bull; <strong>Payment Terms:</strong> {paymentTermsById[form.getFieldValue('paymentTerm')]?.name}
                </Paragraph>
              )}
              <Paragraph>
                &bull; <strong>Quality Testing:</strong> {form.getFieldValue('qualityTesting') ? 'Requested' : 'Not Requested'}
              </Paragraph>

              <Divider />
              {form.getFieldValue('remark') && (
                <>
                  <Paragraph type="secondary">Quality Remarks</Paragraph>
                  <Paragraph>&bull; {form.getFieldValue('remark')}</Paragraph>
                  <Divider />
                </>
              )}
              <Form.Item className="mt-4">
                <Button type="link" onClick={() => setStep('collect-details')} icon={<LeftOutlined />}>
                  Edit Purchase Order
                </Button>
              </Form.Item>
            </>
          )}
          {step !== 'select-product' && (
            <Form.Item className="mt-4">
              <Button type="primary" size="large" block htmlType="submit" loading={loading}>
                {step === 'collect-details' && 'Next'}
                {step === 'review-details' && 'Request Purchase Order'}
              </Button>
            </Form.Item>
          )}
        </Form>
      </ErrorBoundary>
      <AddNewAddress visible={showAddAddress} close={onCloseAddAddress} />
    </Modal>
  );
};

export default CreateNewPurchaseOrder;
