import React, { useEffect, useState } from 'react';
import { Avatar, Button, Card, Col, Divider, List, Row, Typography, message, Progress } from 'antd';
import axios from 'axios';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import ContentLoader from 'react-content-loader';
import { PURCHASE_ORDER_URL, UPLOAD_URL, MAXIMUM_ACTIVE_ITEMS } from 'constants/ApiConstants';
import DetailsGrid from 'components/UtilComponents/DetailsGrid';
import EmptyContent from 'components/UtilComponents/EmptyContent';
import IconEnquiry from 'assets/images/enquiry.png';
import { getBreakPoint } from 'utils/layout';
import ErrorBoundary from 'components/Errors/ErrorBoundary';
import CreateNewPurchaseOrder from 'components/Modals/Buy/CreateNewPurchaseOrder';
import { isEmpty } from 'lodash';

const { Text, Title, Paragraph } = Typography;

const RequestedOrders = ({ tab }) => {
  const isMobile = !getBreakPoint().includes('sm');
  const navigate = useNavigate();
  const [showPurchaseOrder, setShowPurchaseOrder] = useState(false);
  const [loading, setLoading] = useState(false);
  const [purchaseorders, setPurchaseOrders] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 8,
    total: 0,
    size: 'middle',
    showSizeChanger: false,
    className: isMobile && 'custom-pagination',
  });

  useEffect(() => {
    if (tab === 'requested-orders') {
      onLoadOrders();
    } else {
      setPagination({ ...pagination, current: 1 });
    }
  }, [tab, pagination.current]);

  const onLoadOrders = async () => {
    setLoading(true);
    try {
      let currentPage = pagination.current;
      const localData = await JSON.parse(localStorage.getItem('filters'));
      if (!isEmpty(localData)) {
        currentPage = localData.current;
      }

      const res = await axios.get(`${PURCHASE_ORDER_URL}?page=${currentPage}&limit=${pagination.pageSize}&populate=true`, {
        headers: {
          'X-API-Filters': JSON.stringify({ buyer: ['me'], status: ['Requested'] }),
          'X-API-Sort': JSON.stringify({ createdAt: 'desc' }),
        },
      });

      if (res.data.status === 'error') {
        message.error(res.data.error);
      } else {
        setPurchaseOrders(res.data.purchaseorders);
        setPagination({
          ...pagination,
          current: currentPage,
          total: res.data.count,
        });
      }
      localStorage.removeItem('filters');
    } catch (err) {
      message.error(err.message);
    }
    setLoading(false);
  };

  const onViewOrders = (orderId) => {
    localStorage.setItem('filters', JSON.stringify({ current: pagination.current }));
    navigate(`/buy/purchaseorders/requested-orders/${orderId}`);
  };

  const onCreateNewPurchaseOrder = (reload) => {
    if (reload) {
      onLoadOrders();
    }
    setShowPurchaseOrder(!showPurchaseOrder);
  };

  if (loading) {
    return (
      <ContentLoader width="100%" height="70vh" backgroundColor="#d9d9d9" foregroundColor="#ededed">
        <rect x="0" y="25" rx="10" ry="10" width="48%" height="211" />
        <rect x="50%" y="25" rx="10" ry="10" width="50%" height="211" />
        <rect x="0" y="250" rx="10" ry="10" width="48%" height="211" />
        <rect x="50%" y="250" rx="10" ry="10" width="50%" height="211" />
      </ContentLoader>
    );
  }

  return (
    <ErrorBoundary>
      {purchaseorders?.length > 0 ? (
        <>
          <List
            className="mt-2 mb-5"
            header={null}
            footer={null}
            grid={{ gutter: [16, 4], column: isMobile ? 1 : 2 }}
            dataSource={purchaseorders}
            pagination={{ ...pagination, onChange: (page) => setPagination({ ...pagination, current: page }) }}
            renderItem={(order) => {
              return (
                <List.Item key={order?._id}>
                  <Card size={isMobile ? 'small' : 'default'}>
                    <Row align="middle" justify="flex-start" className="mb-3">
                      <Col span={18}>
                        <Row gutter={10} justify="flex-start" wrap={false}>
                          <Col>
                            <Avatar src={`${UPLOAD_URL}/${order?.product?.image}`} shape="square" size="large" />
                          </Col>
                          <Col>
                            <Title level={5} className="fw-medium">
                              {order?.product?.name}
                            </Title>
                            <Text>
                              {order?.fromQty} {order?.toQty > 0 ? ` - ${order?.toQty}` : ''} {order?.subUnit}
                            </Text>
                          </Col>
                        </Row>
                      </Col>
                      <Col flex={1} className="d-flex justify-content-end">
                        <Button type="primary" onClick={() => onViewOrders(order?._id)}>
                          View
                        </Button>
                      </Col>
                    </Row>

                    <DetailsGrid
                      icon={<Avatar src={require('assets/images/icon-quote-received.png')} shape="square" size="small" />}
                      title="PO Number"
                      description={<Text strong>{order?.purchaseOrderNo}</Text>}
                    />

                    <Divider dashed={true} className="my-3" />
                    <Row gutter={16} className="mt-3">
                      <Col span={12}>
                        {order.toQty ? (
                          <DetailsGrid
                            icon={<Avatar src={require('assets/images/total-quantity.png')} shape="square" size="small" />}
                            title="Total Quantity"
                            description={
                              <Text strong>
                                {order?.fromQty} {order?.toQty > 0 ? ` - ${order?.toQty}` : ''} {order?.subUnit}
                              </Text>
                            }
                          />
                        ) : (
                          <DetailsGrid
                            icon={<Avatar src={require('assets/images/total-quantity.png')} shape="square" size="small" />}
                            title="Total Quantity"
                            description={
                              <Text strong>
                                {order?.fromQty}
                                {order?.subUnit}
                              </Text>
                            }
                          />
                        )}
                      </Col>
                      <Col>
                        <DetailsGrid
                          icon={<Avatar src={require('assets/images/icon-calendar.png')} shape="square" size="small" />}
                          title="PO Date:"
                          description={<Text strong>{format(new Date(order?.purchaseOrderDate), 'dd MMM yyyy')}</Text>}
                        />
                      </Col>
                    </Row>
                    <Divider dashed={true} className="my-3" />
                    <Row className="mt-3"></Row>

                    <Row gutter={16} className="mt-3">
                      <Col span={12}>
                        <Progress
                          percent={order?.metadata?.percentCompleted}
                          status="normal"
                          format={() => `${order?.metadata?.percentCompleted}% Completed`}
                          strokeColor={order?.metadata?.percentCompleted >= 100 ? '#53c41c' : '#1890ff'}
                        />
                      </Col>
                    </Row>
                  </Card>
                </List.Item>
              );
            }}
          />
          <Button type="primary" size="large" className="px-5 mb-2" onClick={() => onCreateNewPurchaseOrder()} disabled={purchaseorders.length >= MAXIMUM_ACTIVE_ITEMS}>
            Request Purchase Order
          </Button>
          <Paragraph type="secondary" className="text-small">
            {MAXIMUM_ACTIVE_ITEMS - purchaseorders.length > 0 ? MAXIMUM_ACTIVE_ITEMS - purchaseorders.length : 0} Purchase Order left.
          </Paragraph>
        </>
      ) : (
        <EmptyContent
          source={IconEnquiry}
          title={'No Requested purchase Orders yet'}
          subTitle={'Begin a new order for speedy and personalized service. Your satisfaction is our priority!'}
          onActionPress={() => onCreateNewPurchaseOrder()}
          actionText={'Request Purchase Order'}
        />
      )}
      <CreateNewPurchaseOrder visible={showPurchaseOrder} close={onCreateNewPurchaseOrder} />
    </ErrorBoundary>
  );
};

export default RequestedOrders;
