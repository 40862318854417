import React from 'react';
import { PageHeader, Tabs } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import RequestedOrders from './RequestedOrders';
import CreatedOrders from './CreatedOrders';
import CompletedOrders from './CompletedOrders';
import ClosedOrders from './ClosedOrders';
import BackIcon from 'components/UtilComponents/BackIcon';

const PurchaseOrders = () => {
  const { tab } = useParams();
  const navigate = useNavigate();

  const tabItems = [
    // remember to pass the key prop
    {
      label: 'Requested',
      key: 'requested-orders',
      children: <RequestedOrders tab={tab} />,
    },
    {
      label: 'Active',
      key: 'active-orders',
      children: <CreatedOrders tab={tab} />,
    },

    {
      label: 'Completed',
      key: 'completed-orders',
      children: <CompletedOrders tab={tab} />,
    },
    {
      label: 'Closed',
      key: 'closed-orders',
      children: <ClosedOrders tab={tab} />,
    },
  ];

  return (
    <div className="app-container">
      <PageHeader onBack={() => navigate('/buy')} backIcon={<BackIcon />} title="Purchase Orders" className="px-0" />

      <Tabs type="line" items={tabItems} defaultActiveKey={tab} onChange={(key) => navigate(`/buy/purchaseorders/${key}`)} />
    </div>
  );
};

export default PurchaseOrders;
