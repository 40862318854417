import React from 'react';
import { Typography, Layout, Divider, Space, Image } from 'antd';
import { Outlet } from 'react-router-dom';
import ErrorBoundary from 'components/Errors/ErrorBoundary';
import { getBreakPoint } from 'utils/layout';
import MobileNavBar from 'components/Layout/MobileNavBar';
import MenuContent from 'components/Layout/MenuContent';

const { Content, Sider } = Layout;
const { Link, Paragraph } = Typography;

const MainLayout = ({ business }) => {
  const isMobile = !getBreakPoint().includes('lg');

  return (
    <Layout>
      {isMobile && <MobileNavBar business={business} />}

      {!isMobile && (
        <Sider
          width={225}
          style={{
            overflow: 'auto',
            height: '100vh',
            position: 'fixed',
            zIndex: 2,
            left: 0,
            top: 0,
            bottom: 0,
          }}>
          <Image width={150} style={{ margin: '30px 25px' }} preview={false} src={require('assets/images/buyofuel-logo.png')} />

          <MenuContent business={business} />

          <div style={{ padding: '15px', position: 'absolute', left: 0, bottom: 0 }}>
            <Divider />
            <Space direction="vertical">
              <Paragraph type="secondary" className="text-small">
                &copy; {new Date().getFullYear()} Buyofuel. Supported browsers are Chrome, Firefox, Safari
              </Paragraph>
              <Space split={<Divider type="vertical" />}>
                <Link href="https://buyofuel.com/privacy-policy" target="_blank" underline type="secondary" className="text-small">
                  Privacy
                </Link>
                <Link href="https://buyofuel.com/" target="_blank" underline type="secondary" className="text-small">
                  FAQ
                </Link>
                <Link href="https://buyofuel.com/contact/" target="_blank" underline type="secondary" className="text-small">
                  Contact
                </Link>
              </Space>
            </Space>
          </div>
        </Sider>
      )}

      <Layout style={{ marginLeft: !isMobile ? 225 : 0, minHeight: '100%' }}>
        <Content>
          <ErrorBoundary>
            <Outlet />
          </ErrorBoundary>
        </Content>
      </Layout>
    </Layout>
  );
};

export default MainLayout;
