import { Typography, Button, Modal, Space, Image } from 'antd';
import React, { useState } from 'react';
import Tour from 'reactour';
const { Text } = Typography;
import ExploreBanner from 'assets/images/explore-banner2.png';
import Paragraph from 'antd/lib/typography/Paragraph';

const AppTour = ({ type, introModal, setIntroModal }) => {
  const [finalSteps, setFinalSteps] = useState([]);
  const [isTourOpen, setIsTourOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);

  const initializeSteps = async () => {
    const steps = [];
    if (type === 'Buy') {
      steps.push(
        {
          selector: '.tour-create-rfq',
          content: (
            <>
              <Text strong>Create RFQ</Text>
              <Paragraph>Can't Find What You Need? Post Your Requirements with 'Create Request for Quote' to notify sellers.</Paragraph>
            </>
          ),
        },
        {
          selector: '.tour-my-rfqs',
          content: (
            <>
              <Text strong>My RFQs</Text>
              <Paragraph>You can find your posted RFQ’s here. Feel free to check it anytime you want!</Paragraph>
            </>
          ),
        },
        {
          selector: '.tour-my-offers',
          content: (
            <>
              <Text strong>My Offers</Text>
              <Typography.Paragraph>View quotes for your RFQ, including price, quality, and delivery date.</Typography.Paragraph>
            </>
          ),
        },
        {
          selector: '.tour-purchase-orders',
          content: (
            <>
              <Text strong>Purchase Orders</Text>
              <Paragraph>View Your Purchase Order Details, Including Delivery Status, Schedule and More.</Paragraph>
            </>
          ),
        },
        {
          selector: '.tour-buy-ongoing-payments',
          content: (
            <>
              <Text strong>Ongoing Payments</Text>
              <Paragraph>View Your Trade Payments Here, Including Overdue or Completed Payments.</Paragraph>
            </>
          ),
        },
        {
          selector: '.tour-buy-ongoing-dispatches',
          content: (
            <>
              <Text strong>Ongoing Dispatches</Text>
              <Paragraph>View information about your ongoing product dispatches, including invoices and E-way bills.</Paragraph>
              <br />
              <Paragraph type="secondary">That is the last step, Continue exploring!</Paragraph>
            </>
          ),
        }
      );
    }
    if (type === 'Sell') {
      steps.push(
        {
          selector: '.tour-create-listing',
          content: (
            <>
              <Text strong>Create Listing</Text>
              <Paragraph>Post Your Product: Use 'Create Listing' to list your product for sale.</Paragraph>
            </>
          ),
        },
        {
          selector: '.tour-my-listings',
          content: (
            <>
              <Text strong>My Listings</Text>
              <Paragraph>Find your posted product listings here, available whenever you need them!</Paragraph>
            </>
          ),
        },
        {
          selector: '.tour-my-quotes',
          content: (
            <>
              <Text strong>My Quotes</Text>
              <Paragraph>Explore buyer bids for your listing, detailing quantity, location, and price.</Paragraph>
            </>
          ),
        },
        {
          selector: '.tour-orders',
          content: (
            <>
              <Text strong>Orders</Text>
              <Paragraph>Check the status of your order, payments, and other details conveniently here.</Paragraph>
            </>
          ),
        },
        {
          selector: '.tour-sell-ongoing-payments',
          content: (
            <>
              <Text strong>Ongoing Payments</Text>
              <Paragraph>View Your Trade Payments Here, Including Overdue or Completed Payments.</Paragraph>
            </>
          ),
        },
        {
          selector: '.tour-sell-ongoing-dispatches',
          content: (
            <>
              <Text strong>Ongoing Dispatches</Text>
              <Paragraph>View information about your ongoing product dispatches, including invoices and E-way bills.</Paragraph>
              <br />
              <Paragraph type="secondary">That is the last step, Continue exploring!</Paragraph>
            </>
          ),
        }
      );
    }
    setFinalSteps(steps);
    setIsTourOpen(true);
    setIntroModal(false);
  };

  return (
    <>
      <Modal
        open={introModal}
        footer={null}
        closable={false}
        maskClosable={false}
        width={400}
        bodyStyle={{
          height: '70vh',
          paddingTop: '35px',
          paddingBottom: '30px',
          overflow: 'hidden',
        }}
        centered>
        <div className="flex-column">
          <Space size={20} direction="vertical">
            <Image src={ExploreBanner} preview={false} />
            <div className="d-flex flex-column align-items-center">
              <Text strong style={{ fontSize: '24px' }}>
                Welcome to Buyofuel
              </Text>
              <Text style={{ fontSize: '16px', marginBottom: '12px' }}>India's Leading Biofuel marketplace</Text>
              <Text type="secondary" className="text-center" style={{ marginBottom: '10px' }}>
                Congratulations on taking the first step towards the Biofuel journey with us!
              </Text>
            </div>
            <div className="d-flex flex-column">
              <Button
                className="w-100"
                onClick={() => {
                  initializeSteps();
                }}
                size="large"
                type="primary">
                Start Tour
              </Button>
              <Button className="mt-2 w-100" onClick={() => setIntroModal(false)} htmlType="submit" size="large" type="link">
                Skip Tour
              </Button>
            </div>
          </Space>
        </div>
      </Modal>
      <Tour
        disableInteraction
        getCurrentStep={(curr) => setCurrentStep(curr)}
        prevButton={currentStep === 0 ? () => {} : <Button>Back</Button>}
        lastStepNextButton={<Button type="primary">Complete</Button>}
        nextButton={<Button type="primary">Next</Button>}
        showNavigation={false}
        closeWithMask={false}
        accentColor="#1e75d9"
        maskSpace={35}
        rounded={10}
        steps={finalSteps}
        scrollDuration={100}
        isOpen={isTourOpen}
        badgeContent={(curr, tot) => `${curr} of ${tot}`}
        onRequestClose={() => {
          document.documentElement.style.overflow = 'auto';
          setCurrentStep(0);
          setIsTourOpen(false);
        }}
        startAt={0}
      />
    </>
  );
};

export default AppTour;
