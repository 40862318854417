import React, { useEffect, useState } from 'react';
import { Avatar, Button, Card, Col, Divider, List, Row, Typography, message, Progress } from 'antd';
import axios from 'axios';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import ContentLoader from 'react-content-loader';
import DetailsGrid from 'components/UtilComponents/DetailsGrid';
import { BUSINESS_URL, PURCHASE_ORDER_URL, UPLOAD_URL } from 'constants/ApiConstants';
import EmptyContent from 'components/UtilComponents/EmptyContent';
import IconEnquiry from 'assets/images/enquiry.png';
import CreateNewOpenRFQ from 'components/Modals/Buy/CreateNewOpenRFQ';
import { getBreakPoint } from 'utils/layout';
import CreateNewPurchaseOrder from 'components/Modals/Buy/CreateNewPurchaseOrder';
import { isEmpty } from 'lodash';

const { Text, Title } = Typography;

const CreatedOrders = ({ tab }) => {
  const isMobile = !getBreakPoint().includes('sm');
  const navigate = useNavigate();
  const [showOpenRFQ, setShowOpenRFQ] = useState(false);
  const [showPurchaseOrder, setShowPurchaseOrder] = useState(false);
  const [loading, setLoading] = useState(false);
  const [purchaseorders, setPurchaseOrders] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 8,
    total: 0,
    size: 'middle',
    showSizeChanger: false,
    className: isMobile && 'custom-pagination',
  });

  useEffect(() => {
    if (tab === 'active-orders') {
      onLoadOrders();
    } else {
      setPagination({ ...pagination, current: 1 });
    }
  }, [tab, pagination.current]);

  const onLoadOrders = async () => {
    setLoading(true);
    try {
      // TODO: Added business url temporarily. Remove once buyer: ['me'] filter is implemented for fetch listing offer route
      const businessRes = await axios.get(`${BUSINESS_URL}/me`);

      let currentPage = pagination.current;
      const localData = await JSON.parse(localStorage.getItem('filters'));
      if (!isEmpty(localData)) {
        currentPage = localData.current;
      }

      const res = await axios.get(`${PURCHASE_ORDER_URL}?page=${currentPage}&limit=${pagination.pageSize}&populate=true`, {
        headers: {
          'X-API-Filters': JSON.stringify({ buyer: [businessRes?.data?.business?._id], status: ['Created'] }),
          'X-API-Sort': JSON.stringify({ createdAt: 'desc' }),
        },
      });

      if (res.data.status === 'error') {
        message.error(res.data.error);
      } else {
        setPurchaseOrders(res.data.purchaseorders);
        setPagination({
          ...pagination,
          current: currentPage,
          total: res.data.count,
        });
      }
      localStorage.removeItem('filters');
    } catch (err) {
      message.error(err.message);
    }
    setLoading(false);
  };

  const onCreateNewPurchaseOrder = () => {
    setShowPurchaseOrder(!showPurchaseOrder);
  };

  const onViewOrders = (orderId) => {
    localStorage.setItem('filters', JSON.stringify({ current: pagination.current }));
    navigate(`/buy/purchaseorders/created-orders/${orderId}`);
  };

  const onCreateNewOpenRFQ = () => {
    setShowOpenRFQ(!showOpenRFQ);
  };

  if (loading) {
    return (
      <ContentLoader width="100%" height="70vh" backgroundColor="#d9d9d9" foregroundColor="#ededed">
        <rect x="0" y="25" rx="10" ry="10" width="48%" height="211" />
        <rect x="50%" y="25" rx="10" ry="10" width="50%" height="211" />
        <rect x="0" y="250" rx="10" ry="10" width="48%" height="211" />
        <rect x="50%" y="250" rx="10" ry="10" width="50%" height="211" />
      </ContentLoader>
    );
  }

  return (
    <>
      {purchaseorders?.length > 0 ? (
        <List
          className="mt-2 mb-5"
          header={null}
          footer={null}
          grid={{ gutter: [16, 4], column: isMobile ? 1 : 2 }}
          dataSource={purchaseorders}
          pagination={{ ...pagination, onChange: (page) => setPagination({ ...pagination, current: page }) }}
          renderItem={(order) => {
            return (
              <List.Item key={order?._id}>
                <Card size={isMobile ? 'small' : 'default'}>
                  <Row align="middle" justify="flex-start" className="mb-3">
                    <Col span={18}>
                      <Row gutter={10} justify="flex-start" wrap={false}>
                        <Col>
                          <Avatar src={`${UPLOAD_URL}/${order?.product?.image}`} shape="square" size="large" />
                        </Col>
                        <Col>
                          <Title level={5} className="fw-medium">
                            {order?.product?.name}
                          </Title>
                          <Text>
                            {order?.fromQty} {order?.toQty > 0 ? ` - ${order?.toQty}` : ''} {order?.subUnit}
                          </Text>
                        </Col>
                      </Row>
                    </Col>
                    <Col flex={1} className="d-flex justify-content-end">
                      <Button type="primary" onClick={() => onViewOrders(order?._id)}>
                        View
                      </Button>
                    </Col>
                  </Row>

                  <DetailsGrid
                    icon={<Avatar src={require('assets/images/icon-quote-received.png')} shape="square" size="small" />}
                    title="PO Number"
                    description={<Text strong>{order?.purchaseOrderNo}</Text>}
                  />

                  <Divider dashed={true} className="my-3" />
                  <Row gutter={16} className="mt-3">
                    <Col span={12}>
                      {order.toQty ? (
                        <DetailsGrid
                          icon={<Avatar src={require('assets/images/total-quantity.png')} shape="square" size="small" />}
                          title="Total Quantity"
                          description={
                            <Text strong>
                              {order?.fromQty} {order?.toQty > 0 ? ` - ${order?.toQty}` : ''} {order?.subUnit}
                            </Text>
                          }
                        />
                      ) : (
                        <DetailsGrid
                          icon={<Avatar src={require('assets/images/total-quantity.png')} shape="square" size="small" />}
                          title="Total Quantity"
                          description={
                            <Text strong>
                              {order?.fromQty}
                              {order?.subUnit}
                            </Text>
                          }
                        />
                      )}
                    </Col>
                    <Col span={12}>
                      <DetailsGrid
                        icon={<Avatar src={require('assets/images/dispatched-quantity.png')} shape="square" size="small" />}
                        title="Dispatch Quantity"
                        description={
                          <Text strong>
                            {order?.metadata?.dispatchedQty}
                            {order?.subUnit}
                          </Text>
                        }
                      />
                    </Col>
                  </Row>
                  <Divider dashed={true} className="my-3" />
                  <Row gutter={16} className="mt-3">
                    <Col span={12}>
                      <Text type="secondary">PO Date: {format(new Date(order?.purchaseOrderDate), 'dd MMM yyyy')}</Text>
                    </Col>
                  </Row>

                  <Row gutter={16} className="mt-3">
                    <Col span={12}>
                      <Progress
                        percent={Math.min(order?.metadata?.percentCompleted, 100)}
                        status="normal"
                        format={() => `${order?.metadata?.percentCompleted}% Completed`}
                        strokeColor={order?.metadata?.percentCompleted >= 100 ? '#53c41c' : '#1890ff'}
                      />
                    </Col>
                  </Row>
                </Card>
              </List.Item>
            );
          }}
        />
      ) : (
        <EmptyContent
          source={IconEnquiry}
          title={'No orders created yet'}
          subTitle={'Create a new order for fast service. Your satisfaction matters!'}
          onActionPress={() => onCreateNewPurchaseOrder()}
          actionText={'Request Purchase Order'}
        />
      )}
      <CreateNewOpenRFQ visible={showOpenRFQ} close={onCreateNewOpenRFQ} />
      <CreateNewPurchaseOrder visible={showPurchaseOrder} close={onCreateNewPurchaseOrder} />
    </>
  );
};

export default CreatedOrders;
