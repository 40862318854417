import React, { useEffect, useState } from 'react';
import { Avatar, Button, Card, Col, Divider, List, Row, Typography, message } from 'antd';
import axios from 'axios';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import ContentLoader from 'react-content-loader';
import CompanyName from 'components/UtilComponents/CompanyName';
import DetailsGrid from 'components/UtilComponents/DetailsGrid';
import NumberText from 'components/UtilComponents/NumberText';
import { OPEN_MERCHANT_NAME, ORDER_URL, PAYMENT_ENABLED } from 'constants/ApiConstants';
import { formatRefNo } from 'utils/formatter';
import EmptyContent from 'components/UtilComponents/EmptyContent';
import IconOrders from 'assets/images/orders.png';
import CreateNewOpenRFQ from 'components/Modals/Buy/CreateNewOpenRFQ';
import { getBreakPoint } from 'utils/layout';
import { isEmpty } from 'lodash';

const { Text, Title, Paragraph } = Typography;

const OngoingOrders = ({ tab }) => {
  const isMobile = !getBreakPoint().includes('sm');
  const isDesktop = !getBreakPoint().includes('xl');
  const navigate = useNavigate();
  const [showOpenRFQ, setShowOpenRFQ] = useState(false);
  const [loading, setLoading] = useState(false);
  const [yetToExecute, setYetToExecute] = useState([]);
  const [inExecution, setInExecution] = useState([]);

  const [yetPagination, setYetPagination] = useState({
    current: 1,
    pageSize: 8,
    total: 0,
    size: 'middle',
    showSizeChanger: false,
    className: isMobile && 'custom-pagination',
  });

  const [inPagination, setInPagination] = useState({
    current: 1,
    pageSize: 8,
    total: 0,
    size: 'middle',
    showSizeChanger: false,
    className: isMobile && 'custom-pagination',
  });

  useEffect(() => {
    if (tab === 'ongoing-orders') {
      onLoadYetToExecuteOrders();
    }
  }, [tab, yetPagination.current]);

  useEffect(() => {
    if (tab === 'ongoing-orders') {
      onLoadInExecutionOrders();
    } else {
      setInPagination({ ...inPagination, current: 1 });
    }
  }, [tab, inPagination.current]);

  const onLoadYetToExecuteOrders = async () => {
    setLoading(true);
    try {
      const res = await axios.get(`${ORDER_URL}?page=${yetPagination.current}&limit=${yetPagination.pageSize}&populate=true`, {
        headers: {
          'X-API-Filters': JSON.stringify({ buyer: ['me'], status: ['Placed'] }),
          'X-API-Sort': JSON.stringify({ createdAt: 'desc' }),
        },
      });

      if (res.data.status === 'error') {
        message.error(res.data.error);
      } else {
        setYetToExecute(res.data.orders);
        setYetPagination({
          ...yetPagination,
          total: res.data.count,
        });
      }
    } catch (err) {
      message.error(err.message);
    }
    setLoading(false);
  };

  const onLoadInExecutionOrders = async () => {
    setLoading(true);
    try {
      let currentPage = inPagination.current;
      const localData = await JSON.parse(localStorage.getItem('filters'));
      if (!isEmpty(localData)) {
        currentPage = localData.current;
      }

      const res = await axios.get(`${ORDER_URL}?page=${currentPage}&limit=${inPagination.pageSize}&populate=true`, {
        headers: {
          'X-API-Filters': JSON.stringify({ buyer: ['me'], status: ['In Progress'] }),
          'X-API-Sort': JSON.stringify({ createdAt: 'desc' }),
        },
      });

      if (res.data.status === 'error') {
        message.error(res.data.error);
      } else {
        setInExecution(res.data.orders);
        setInPagination({
          ...inPagination,
          current: currentPage,
          total: res.data.count,
        });
      }
      localStorage.removeItem('filters');
    } catch (err) {
      message.error(err.message);
    }
    setLoading(false);
  };

  const onViewOrders = (orderId) => {
    localStorage.setItem('filters', JSON.stringify({ current: inPagination.current }));
    navigate(`/buy/orders/ongoing-orders/${orderId}`);
  };

  const onCreateNewOpenRFQ = () => {
    setShowOpenRFQ(!showOpenRFQ);
  };

  if (loading) {
    return (
      <ContentLoader width="100%" height="70vh" backgroundColor="#d9d9d9" foregroundColor="#ededed">
        <rect x="0" y="25" rx="10" ry="10" width="48%" height="211" />
        <rect x="50%" y="25" rx="10" ry="10" width="50%" height="211" />
        <rect x="0" y="250" rx="10" ry="10" width="48%" height="211" />
        <rect x="50%" y="250" rx="10" ry="10" width="50%" height="211" />
      </ContentLoader>
    );
  }

  return (
    <>
      {yetToExecute.length === 0 && inExecution.length === 0 && <EmptyContent source={IconOrders} title={'No Ongoing Orders'} subTitle={'There are no ongoing orders found right now.'} />}
      {yetToExecute.length > 0 && (
        <>
          <Paragraph type="secondary" className="fw-medium">
            To Be Executed
          </Paragraph>
          <List
            className="mt-2 mb-4"
            header={null}
            footer={null}
            grid={{ gutter: [16, 4], column: isMobile ? 1 : 2 }}
            dataSource={yetToExecute}
            pagination={{ ...yetPagination, onChange: (page) => setYetPagination({ ...yetPagination, current: page }) }}
            renderItem={(order) => {
              const { product } = order;
              return (
                <List.Item>
                  <Card size={isMobile ? 'small' : 'default'}>
                    <Row justify="flex-start" align="top">
                      <Col span={24}>
                        <Title level={5} className="fw-medium">
                          {product?.name}
                        </Title>
                        <Text>
                          {order?.qty || '∞'} {order?.subUnit} | {formatRefNo(order?.orderRefNo)}
                        </Text>
                        <CompanyName name={OPEN_MERCHANT_NAME} verified={true} />
                      </Col>
                    </Row>

                    <Row align="middle" className="mt-3">
                      <Avatar src={require('assets/images/icon-awaiting-seller-decision.png')} shape="square" size={18} />
                      <Text className="ml-2">Seller yet to accept the order</Text>
                    </Row>
                  </Card>
                </List.Item>
              );
            }}
          />
        </>
      )}

      {inExecution.length > 0 && (
        <>
          <Paragraph type="secondary" className="fw-medium">
            Orders in Execution
          </Paragraph>
          <List
            className="mt-2 mb-4"
            header={null}
            footer={null}
            grid={{ gutter: [16, 4], column: isMobile ? 1 : 2 }}
            dataSource={inExecution}
            pagination={{ ...inPagination, onChange: (page) => setInPagination({ ...inPagination, current: page }) }}
            renderItem={(order) => {
              const { product } = order;
              return (
                <List.Item key={order?._id}>
                  <Card size={isMobile ? 'small' : 'default'}>
                    <Row justify="flex-start">
                      <Col flex={1} span={16}>
                        <Title level={5} className="fw-medium">
                          {product?.name}
                        </Title>
                        <Text>
                          {order?.qty || '∞'} {order?.subUnit} | {formatRefNo(order?.orderRefNo)}
                        </Text>
                        <CompanyName name={OPEN_MERCHANT_NAME} verified={true} />
                      </Col>
                      <Col flex={1} className="d-flex justify-content-end">
                        <Button type="primary" onClick={() => onViewOrders(order?._id)}>
                          View
                        </Button>
                      </Col>
                    </Row>

                    <Row gutter={16} className="mt-3">
                      <Col span={isDesktop ? 24 : 12}>
                        <DetailsGrid icon={<Avatar src={require('assets/images/icon-order-status.png')} shape="square" size="small" />} title={'Order Status'} description={order?.status} />
                      </Col>
                      <Col span={isDesktop ? 24 : 12}>
                        <DetailsGrid
                          icon={<Avatar src={require('assets/images/icon-calendar.png')} shape="square" size="small" />}
                          title={'Expected Delivery Date'}
                          description={order?.deliveryOn ? format(new Date(order?.deliveryOn), 'dd MMM yyyy') : ''}
                        />
                      </Col>
                    </Row>

                    <Divider dashed={true} className="my-2" />

                    <Row align="middle">
                      {order?.paymentPriceBasis?.pendingReceivable?.value ? (
                        <>
                          <Text>
                            <NumberText value={order?.paymentPriceBasis?.pendingReceivable?.value / 100} prefix={order?.currency} />
                            &nbsp;Payment Pending
                          </Text>
                          {PAYMENT_ENABLED && ( // TODO: add payment support
                            <Button type="link" size="small" onClick={() => {}}>
                              Pay Now
                            </Button>
                          )}
                        </>
                      ) : (
                        <Text>No Pending Payments</Text>
                      )}
                    </Row>
                  </Card>
                </List.Item>
              );
            }}
          />
        </>
      )}
      <CreateNewOpenRFQ visible={showOpenRFQ} close={onCreateNewOpenRFQ} />
    </>
  );
};

export default OngoingOrders;
