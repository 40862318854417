import React, { useEffect, useState } from 'react';
import { Button, Form, Input, message, Modal, Select } from 'antd';
import { BUSINESS_URL } from 'constants/ApiConstants';
import ErrorBoundary from 'components/Errors/ErrorBoundary';
import ModalCloseIcon from 'components/UtilComponents/ModalCloseIcon';
import axios from 'axios';

const ModifyBusinessUser = ({ selected, visible, close, save, business }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (selected) {
      form.setFieldsValue({
        name: selected?.user?.name,
        email: selected?.user?.email,
        phone: selected?.user?.phone,
        role: selected?.role,
      });
    } else {
      form.resetFields();
    }
  }, [selected]);

  const onSubmitForm = (values) => {
    if (selected) {
      onUpdateUser(values);
    } else {
      onAddUser(values);
    }
  };

  const onAddUser = async (values) => {
    setLoading(true);
    try {
      const res = await axios.post(`${BUSINESS_URL}/${business._id}/user`, {
        name: values.name,
        email: values.email,
        phone: values.phone,
        businessRole: values.role,
      });
      if (res.data.status === 'error') {
        message.error(res.data.error);
      } else {
        message.success('New user created and added to your Business');
        save();
      }
    } catch (err) {
      message.error('Oops unable to process request at the time');
    }
    setLoading(false);
  };

  const onUpdateUser = async (values) => {
    setLoading(true);
    try {
      const res = await axios.put(`${BUSINESS_URL}/${business._id}/user/${selected?.user?._id}`, {
        name: values.name,
        email: values.email,
        phone: values.phone,
        businessRole: values.role,
      });
      if (res.data.status === 'error') {
        message.error(res.data.error);
      } else {
        message.success('User updated successfully');
        save();
      }
    } catch (err) {
      message.error('Oops unable to process request at the time');
    }
    setLoading(false);
  };

  const onCancel = () => {
    form.resetFields();
    close();
  };

  return (
    <Modal destroyOnClose={true} title={selected ? 'Update User' : 'Add User'} open={visible} onCancel={onCancel} footer={null} closeIcon={<ModalCloseIcon />}>
      <ErrorBoundary>
        <Form form={form} layout="vertical" onFinish={onSubmitForm}>
          <Form.Item
            name="name"
            label="Name"
            rules={[
              {
                required: true,
                message: 'Please enter name',
              },
            ]}>
            <Input type="text" size="large" />
          </Form.Item>
          <Form.Item
            name="email"
            label="Email Address"
            rules={[
              {
                required: true,
                message: 'Please enter email address',
              },
            ]}>
            <Input type="email" size="large" />
          </Form.Item>
          <Form.Item
            name="phone"
            label="Phone Number"
            rules={[
              {
                required: true,
                message: 'Please enter phone number',
              },
            ]}>
            <Input type="tel" size="large" />
          </Form.Item>
          <Form.Item
            name="role"
            label="Role"
            rules={[
              {
                required: true,
                message: 'Please select role',
              },
            ]}>
            <Select size="large" placeholder="Role" showSearch>
              <Select.Option value="Administrator">Administrator</Select.Option>
              {business?.businessType !== 'seller' && <Select.Option value="Purchase Manager">Purchase Manager</Select.Option>}
              {business?.businessType !== 'consumer' && <Select.Option value="Sales Manager">Sales Manager</Select.Option>}
              <Select.Option value="Payment Manager">Payment Manager</Select.Option>
              <Select.Option value="Sustainability Manager">Sustainability Manager</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item className="mt-4">
            <Button size="large" type="primary" htmlType="submit" block loading={loading}>
              {selected ? 'Update User' : 'Add User'}
            </Button>
          </Form.Item>
        </Form>
      </ErrorBoundary>
    </Modal>
  );
};

export default ModifyBusinessUser;
