import React, { useEffect, useState } from 'react';
import { PageHeader, Row, Typography, message, Tag, List, Button, Dropdown, Modal } from 'antd';
import axios from 'axios';
import { BUSINESS_URL } from 'constants/ApiConstants';
import ContentLoader from 'react-content-loader';
import { DeleteOutlined } from '@ant-design/icons';
import ModifyBusinessUser from 'components/Modals/ModifyBusinessUser';

const MyTeam = () => {
  const [business, setBusiness] = useState(null);
  const [modifyVisible, setModifyVisible] = useState(false);
  const [selected, setSelected] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    onLoad();
  }, []);

  const onLoad = async () => {
    if (loading) return;
    setLoading(true);
    try {
      const res = await axios.get(`${BUSINESS_URL}/me?&populate=true`);
      if (res.data.status === 'error') {
        message.error(res.data.error);
      } else {
        setBusiness(res.data.business);
      }
    } catch (err) {
      message.error(err.message);
    }
    setLoading(false);
  };

  const onAddUser = () => {
    setModifyVisible(true);
    setSelected(null);
  };

  const onUpdateUser = (user) => {
    setModifyVisible(true);
    setSelected(user);
  };

  const onDeleteUser = (user) => {
    Modal.confirm({
      title: 'Are you sure you want to delete user?',
      icon: <DeleteOutlined />,
      okText: 'Yes',
      onOk: async () => {
        try {
          const res = await axios.delete(`${BUSINESS_URL}/${business._id}/user/${user._id}`);
          if (res.data.status === 'error') {
            message.error(res.data.error);
          } else {
            message.success('User delete successfully');
            onSaveUser();
          }
        } catch (err) {
          message.error('Oops unable to process request at the time');
        }
      },
      cancelText: 'No',
    });
  };

  const onCloseUser = () => {
    setModifyVisible(false);
    setSelected(null);
  };

  const onSaveUser = () => {
    onCloseUser();
    onLoad();
  };

  if (loading) {
    return (
      <div className="app-container">
        <PageHeader onBack={() => null} backIcon={null} title="Team Settings" className="py-4 page-header" />
        <ContentLoader viewBox="0 0 320 200" backgroundColor="#d9d9d9" foregroundColor="#ededed">
          <rect x="0" y="20" rx="2" ry="2" width="30" height="30" />
          <rect x="35" y="25" rx="1" ry="1" width="100" height="8" />
          <rect x="35" y="37" rx="1" ry="1" width="35" height="7" />
          <rect x="78" y="37" rx="1" ry="1" width="35" height="7" />
          <rect x="0" y="70" rx="0" ry="0" width="320" height="0.5" />
          <rect x="0" y="85" rx="1" ry="1" width="45" height="9" />
          <rect x="0" y="100" rx="4" ry="4" width="50" height="65" />
          <rect x="55" y="100" rx="4" ry="4" width="50" height="65" />
          <rect x="110" y="100" rx="4" ry="4" width="50" height="65" />
          <rect x="165" y="100" rx="4" ry="4" width="50" height="65" />
          <rect x="220" y="100" rx="4" ry="4" width="50" height="65" />
          <rect x="275" y="100" rx="4" ry="4" width="50" height="65" />
        </ContentLoader>
      </div>
    );
  }

  const users = business?.users || [];
  return (
    <div className="app-container">
      <PageHeader onBack={() => null} backIcon={null} title="Team Settings" className="py-sm-4 page-header" />
      <div className="mt-5">
        <Row justify="space-between" className="mt-2">
          <Typography.Title level={4}>Users ({business?.users.length})</Typography.Title>
          <Button color="primary" onClick={onAddUser}>
            Add User
          </Button>
        </Row>
        <List>
          {users.map(({ user, role }) => {
            return (
              <List.Item key={user._id}>
                <List.Item.Meta
                  title={
                    <span>
                      {user?.name}
                      <Tag color="blue" style={{ marginLeft: 10 }}>
                        {role}
                      </Tag>
                    </span>
                  }
                  description={user?.email}
                />
                {role !== 'Owner' && (
                  <div>
                    <Dropdown.Button
                      menu={{
                        items: [
                          {
                            key: 'delete',
                            label: 'Delete',
                            icon: <DeleteOutlined />,
                          },
                        ],
                        onClick: (menu) => {
                          if (menu.key === 'delete') {
                            onDeleteUser(user);
                          }
                        },
                      }}
                      onClick={() => {
                        onUpdateUser({ user, role });
                      }}>
                      Edit
                    </Dropdown.Button>
                  </div>
                )}
              </List.Item>
            );
          })}
        </List>
      </div>
      <ModifyBusinessUser selected={selected} visible={modifyVisible} business={business} close={onCloseUser} save={onSaveUser} />
    </div>
  );
};

export default MyTeam;
