import React from 'react';
import { Menu, Avatar, Typography } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import IconBuy from 'assets/images/nav-buy.png';
import IconSell from 'assets/images/nav-sell.png';
import IconPayments from 'assets/images/nav-payments.png';
import IconAccount from 'assets/images/nav-account.png';
import IconTeam from 'assets/images/nav-team.png';
import IconSustainabilityReport from 'assets/images/nav-sustainabilityReport.png';
import IconDispatch from 'assets/images/nav-dispatch.png';
import IconBuyActive from 'assets/images/nav-buy-active.png';
import IconSellActive from 'assets/images/nav-sell-active.png';
import IconPaymentsActive from 'assets/images/nav-payments-active.png';
import IconAccountActive from 'assets/images/nav-account-active.png';
import IconTeamActive from 'assets/images/nav-team-active.png';
import IconSustainabilityReportActive from 'assets/images/nav-sustainabilityReport-active.png';
import IconDispatchActive from 'assets/images/nav-dispatch-active.png';
import { posthog } from 'posthog-js';
import { getAuth } from 'utils/auth';
import { find } from 'lodash';

const { Text } = Typography;

const MenuContent = ({ business, onClickMenu = () => {} }, ...props) => {
  const location = useLocation();
  let navigate = useNavigate();
  let index = location.pathname.slice(1).indexOf('/');

  let defaultSelected = index === -1 ? location.pathname.slice(1) : location.pathname.slice(1, 1 + index);
  const userId = getAuth()?._id;
  const user = find(business.users, (u) => String(u.user._id) === String(userId));

  const items = [];
  if (business?.businessType !== 'seller' && ['Owner', 'Administrator', 'Purchase Manager'].includes(user?.role)) {
    items.push({
      label: (
        <Text className="tour-buy-dashboard" strong={defaultSelected === 'buy'}>
          Buy
        </Text>
      ),
      key: 'buy',
      icon: <Avatar src={defaultSelected === 'buy' ? IconBuyActive : IconBuy} shape="square" size="small" />,
    });
  }
  if (business?.businessType !== 'consumer' && ['Owner', 'Administrator', 'Sales Manager'].includes(user?.role)) {
    items.push({
      label: (
        <Text className="tour-sell-dashboard" strong={defaultSelected === 'sell'}>
          Sell
        </Text>
      ),
      key: 'sell',
      icon: <Avatar src={defaultSelected === 'sell' ? IconSellActive : IconSell} shape="square" size="small" />,
    });
  }
  if (['Owner', 'Administrator', 'Purchase Manager', 'Sales Manager'].includes(user?.role)) {
    items.push({
      label: (
        <Text className="tour-dispatches-home" strong={defaultSelected === 'dispatches'}>
          Dispatches
        </Text>
      ),
      key: 'dispatches',
      icon: <Avatar src={defaultSelected === 'dispatches' ? IconDispatchActive : IconDispatch} shape="square" size="small" />,
    });
  }
  if (['Owner', 'Administrator', 'Payment Manager'].includes(user?.role)) {
    items.push({
      label: (
        <Text className="tour-payments-home" strong={defaultSelected === 'payments'}>
          Payments
        </Text>
      ),
      key: 'payments',
      icon: <Avatar src={defaultSelected === 'payments' ? IconPaymentsActive : IconPayments} shape="square" size="small" />,
    });
  }
  if (['Owner', 'Administrator', 'Sustainability Manager'].includes(user?.role)) {
    items.push({
      label: (
        <Text className="tour-sustainability-report-home" strong={defaultSelected === 'sustainability-report'}>
          Sustainability Report
        </Text>
      ),
      key: 'sustainability-report',
      icon: <Avatar src={defaultSelected === 'sustainability-report' ? IconSustainabilityReportActive : IconSustainabilityReport} shape="square" size="small" />,
    });
  }
  items.push({
    label: (
      <Text className="tour-my-account" strong={defaultSelected === 'my-account'}>
        My Account
      </Text>
    ),
    key: 'my-account',
    icon: <Avatar src={defaultSelected === 'my-account' ? IconAccountActive : IconAccount} shape="square" size="small" />,
  });
  if (['Owner', 'Administrator'].includes(user?.role)) {
    items.push({
      label: (
        <Text className="tour-manage-team" strong={defaultSelected === 'my-team'}>
          Manage Team
        </Text>
      ),
      key: 'my-team',
      icon: <Avatar src={defaultSelected === 'my-team' ? IconTeamActive : IconTeam} shape="square" size="small" />,
    });
  }

  return (
    <Menu
      {...props}
      theme="light"
      items={items}
      onClick={(values) => {
        posthog.capture(`$screenview | $${values.key}`, { $set: { lastActivityDate: new Date().toISOString() } });
        navigate(values.key);
        onClickMenu();
      }}
      defaultSelectedKeys={[defaultSelected]}
      selectedKeys={[defaultSelected]}
    />
  );
};

export default MenuContent;
