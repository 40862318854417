import React, { useState } from 'react';
import { Button, Divider, Form, Input, InputNumber, Modal, Row, Typography, Upload, message, Tag } from 'antd';
import { LeftOutlined, ExclamationCircleOutlined, FileImageOutlined, FileTextOutlined } from '@ant-design/icons';
import axios from 'axios';
import { each, isEmpty } from 'lodash';
import ErrorBoundary from 'components/Errors/ErrorBoundary';
import BackIcon from 'components/UtilComponents/BackIcon';
import ModalCloseIcon from 'components/UtilComponents/ModalCloseIcon';
import { formatRefNo } from 'utils/formatter';
import { DISPATCH_URL, UPLOAD_URL } from 'constants/ApiConstants';
import { useEffect } from 'react';
import { checkForAnomaly } from 'utils/anomaly';

const { Paragraph, Title, Text } = Typography;

const EditDispatch = ({ order, visible, close, mode, selected }) => {
  const [form] = Form.useForm();
  const [step, setStep] = useState('collect-details');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!selected) {
      form.resetFields();
    } else {
      setTimeout(() => {
        form.setFields([
          { name: 'order', value: formatRefNo(selected?.order?.orderRefNo) },
          { name: 'purchaseBillNo', value: selected?.purchaseBillNo },
          { name: 'dispatchedQty', value: selected?.dispatchedQty },
          { name: 'vehicleNo', value: selected?.vehicleNo },
          { name: 'dispatchFrom', value: selected?.dispatchFrom },
          { name: 'notes', value: selected?.notes },
        ]);
      }, 1);
    }
  }, [selected]);

  const totalQty = order?.qty;
  const deliveredQty = order?.metadata?.dispatchedQty || 0;
  const remainingQty = totalQty - deliveredQty > 0 ? totalQty - deliveredQty : 0;

  const onClickPrev = () => {
    if (step === 'review-details') return setStep('collect-details');
  };

  const onClickNext = () => {
    if (step === 'collect-details') return setStep('review-details');
    if (step === 'review-details') return onSubmit();
  };

  const getTitle = () => {
    if (step === 'collect-details') {
      return <Title level={5}>Edit Dispatch</Title>;
    }
    return (
      <>
        <BackIcon onClick={onClickPrev} className="mr-3" />
        {' Review Dispatch Details'}
      </>
    );
  };

  const onSubmit = async () => {
    if (loading) return;
    setLoading(true);
    try {
      const values = form.getFieldsValue(true);
      const detectedAnomaly = checkForAnomaly('dispatch', { ...values, subUnit: order?.subUnit, type: 'Purchase' });
      if (detectedAnomaly) {
        setLoading(false);
        return message.error(detectedAnomaly);
      }
      const res = await axios.put(`${DISPATCH_URL}/${selected?._id}`, {
        purchaseBillNo: values.purchaseBillNo,
        dispatchedQty: values.dispatchedQty,
        dispatchedOn: new Date(),
        dispatchFrom: values.dispatchFrom,
        vehicleNo: values.vehicleNo,
        notes: values.notes,
        transitStatus: selected?.transitStatus,
      });

      if (res.data.status === 'success') {
        message.success('Dispatch details updated successfully');

        // upload related files for created dispatch
        const files = new FormData();
        if (!isEmpty(values?.invoice)) files.append('purchase.invoice', values?.invoice[0].originFileObj);
        if (!isEmpty(values?.ewayBill)) files.append('purchase.ewayBill', values?.ewayBill[0].originFileObj);
        if (!isEmpty(values?.weightSlip)) files.append('purchase.weightSlip', values?.weightSlip[0].originFileObj);
        if (!isEmpty(values?.qualityReport)) files.append('purchase.qualityReport', values?.qualityReport[0].originFileObj);
        if (!isEmpty(values?.attachments))
          each(values?.attachments, (attachment) => {
            files.append('purchase.attachments', attachment?.originFileObj);
          });

        await axios.post(`${DISPATCH_URL}/upload/${selected?._id}`, files);

        setStep('collect-details');
        form.resetFields();
        close(true);
      } else {
        message.error(res.data.error);
      }
    } catch (err) {
      message.error(err.message);
    }
    setLoading(false);
  };

  const getFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const confirmOnClose = () => {
    if ((!loading && form.isFieldsTouched()) || step === 'review-details') {
      Modal.confirm({
        title: 'Unsaved Data',
        icon: <ExclamationCircleOutlined />,
        content: 'This form has unsaved data. Are you sure you want to close the screen?',
        okText: 'Yes',
        onOk: () => {
          form.resetFields();
          setStep('collect-details');
          close(false);
        },
        cancelText: 'No',
      });
    } else {
      setStep('collect-details');
      close(false);
    }
  };

  return (
    <Modal title={getTitle()} open={visible} onCancel={confirmOnClose} footer={null} closeIcon={<ModalCloseIcon />} maskClosable={false} width={480} bodyStyle={{ height: '75vh', overflow: 'scroll' }}>
      <ErrorBoundary>
        <Form form={form} layout="vertical" onFinish={step === 'collect-details' ? onClickNext : onSubmit} requiredMark={false} scrollToFirstError={true}>
          {step === 'collect-details' && (
            <>
              {mode == 'create-dispatch' && (
                <Form.Item
                  name="order"
                  valuePropName="value"
                  label="Order No"
                  rules={[
                    {
                      required: true,
                      message: 'Please select a valid Order No',
                    },
                  ]}>
                  <Input size="large" disabled style={{ marginTop: '8px' }} />
                </Form.Item>
              )}
              <Form.Item
                name="purchaseBillNo"
                label="Purchase / E-way Bill No"
                rules={[
                  {
                    required: true,
                    message: 'Please enter the purchase bill number',
                  },
                ]}
                hasFeedback>
                <Input maxLength={25} size="large" />
              </Form.Item>

              <Form.Item
                name="dispatchedQty"
                label={`Dispatched Quantity (${selected?.subUnit})`}
                className="bg-transparent"
                rules={[
                  {
                    required: true,
                    message: 'Please enter valid dispatched quantity',
                  },
                  {
                    validator: (_, value) => {
                      if (value <= remainingQty * 1.25) {
                        return Promise.resolve();
                      }
                      return Promise.reject(`Dispatched Quantity should be lesser than ${(remainingQty * 1.25).toFixed(3)} ${order?.subUnit ? `(${order?.subUnit})` : ''}`);
                    },
                  },
                ]}
                hasFeedback>
                <InputNumber precision={3} className="w-100" size="large" min={1} controls={false} />
              </Form.Item>

              <Form.Item
                name="vehicleNo"
                label="Vehicle No"
                className="bg-transparent"
                rules={[
                  {
                    required: true,
                    message: 'Please enter the vehicle no',
                  },
                ]}
                hasFeedback>
                <Input size="large" maxLength={15} />
              </Form.Item>

              <Form.Item
                name="dispatchFrom"
                label="Dispatch Area Pincode"
                className="bg-transparent"
                rules={[
                  {
                    required: true,
                    pattern: /^[0-9]*$/,
                    message: 'Please enter the Dispatch From Pincode',
                  },
                  () => ({
                    validator(_, value) {
                      if (value.toString()?.length > 0) {
                        if (value.toString()?.length !== 6) {
                          return Promise.reject('Please enter a valid 6 digit Pin Code');
                        }
                        return Promise.resolve();
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
                hasFeedback>
                <Input size="large" maxLength={12} />
              </Form.Item>

              <Form.Item name="invoice" label="Invoice" getValueFromEvent={getFile}>
                <Upload maxCount={1} beforeUpload={() => false} defaultFileList={form.getFieldValue('invoice') || []} accept=".jpg, .jpeg, .png, .pdf">
                  <Button size="large" icon={<FileImageOutlined />}>
                    Upload Invoice
                  </Button>
                </Upload>
              </Form.Item>
              {selected?.purchase?.invoice && (
                <Button href={`${UPLOAD_URL}/${selected.purchase.invoice}`} type="link" target="_blank" style={{ marginTop: -24, marginBottom: 10 }}>
                  View Existing Invoice
                </Button>
              )}

              <Form.Item name="ewayBill" label="E-Way Bill" getValueFromEvent={getFile}>
                <Upload maxCount={1} beforeUpload={() => false} defaultFileList={form.getFieldValue('ewayBill') || []} accept=".jpg, .jpeg, .png, .pdf">
                  <Button size="large" icon={<FileImageOutlined />}>
                    Upload Eway Bill
                  </Button>
                </Upload>
              </Form.Item>
              {selected?.purchase?.ewayBill && (
                <Button href={`${UPLOAD_URL}/${selected.purchase.ewayBill}`} type="link" target="_blank" style={{ marginTop: -24, marginBottom: 10 }}>
                  View Existing E-Way Bill / GRN Receipt
                </Button>
              )}

              <Form.Item name="weightSlip" label="Weight Slip" getValueFromEvent={getFile}>
                <Upload maxCount={1} beforeUpload={() => false} defaultFileList={form.getFieldValue('weightSlip') || []} accept=".jpg, .jpeg, .png, .pdf">
                  <Button size="large" icon={<FileTextOutlined />}>
                    Upload Weight Slip
                  </Button>
                </Upload>
              </Form.Item>
              {selected?.purchase?.weightSlip && (
                <Button href={`${UPLOAD_URL}/${selected.purchase.weightSlip}`} type="link" target="_blank" style={{ marginTop: -24, marginBottom: 10 }}>
                  View Existing Weight Slip
                </Button>
              )}
              <Form.Item name="qualityReport" label="Quality Report" getValueFromEvent={getFile}>
                <Upload maxCount={1} beforeUpload={() => false} defaultFileList={form.getFieldValue('qualityReport') || []} accept=".jpg, .jpeg, .png, .pdf">
                  <Button size="large" icon={<FileTextOutlined />}>
                    Upload Quality Report
                  </Button>
                </Upload>
              </Form.Item>
              {selected?.purchase?.qualityReport && (
                <Button href={`${UPLOAD_URL}/${selected.purchase.qualityReport}`} type="link" target="_blank" style={{ marginTop: -24, marginBottom: 10 }}>
                  View Existing Quality Report
                </Button>
              )}

              <Form.Item name="attachments" label="Attachments" getValueFromEvent={getFile}>
                <Upload multiple beforeUpload={() => false} defaultFileList={form.getFieldValue('attachments') || []} accept=".jpg, .jpeg, .png, .pdf">
                  <Button size="large" icon={<FileTextOutlined />}>
                    Upload Additional Files
                  </Button>
                </Upload>
              </Form.Item>
              {selected?.purchase?.attachments && (
                <div style={{ marginTop: -14, marginBottom: 10 }}>
                  {selected?.purchase.attachments.map((attachment, index) => (
                    <Button href={`${UPLOAD_URL}/${attachment}`} type="link" target="_blank">
                      View Existing Attachment {index + 1}
                    </Button>
                  ))}
                  <Tag color="red" style={{ marginTop: 5 }}>
                    * Existing attachments will be replaced if you upload new ones
                  </Tag>
                </div>
              )}

              <Form.Item label="Additional Information" className="bg-transparent" name="notes">
                <Input.TextArea size="large" rows={3} maxLength={300} showCount allowClear />
              </Form.Item>
            </>
          )}

          {/* Review the details before submitting */}
          {step === 'review-details' && (
            <>
              <>
                <Row justify="space-between" className="mt-3">
                  <Text>Purchase Bill No:</Text>
                  <Text>{form.getFieldValue('purchaseBillNo')}</Text>
                </Row>

                <Row justify="space-between" className="my-1">
                  <Text>Dispatched Quantity:</Text>
                  <Text>
                    {form.getFieldValue('dispatchedQty')} {order?.subUnit}
                  </Text>
                </Row>

                {form.getFieldValue('vehicleNo') && (
                  <Row justify="space-between" className="my-1">
                    <Text>Vehicle No:</Text>
                    <Text>{form.getFieldValue('vehicleNo')}</Text>
                  </Row>
                )}
                {form.getFieldValue('dispatchFrom') && (
                  <Row justify="space-between" className="my-1">
                    <Text>Dispatch Area Pincode:</Text>
                    <Text>{form.getFieldValue('dispatchFrom')}</Text>
                  </Row>
                )}
              </>

              <Divider />

              {form.getFieldValue('notes') && (
                <>
                  <Paragraph>
                    <strong>Additional Information:</strong>
                  </Paragraph>
                  <Paragraph>&bull; {form.getFieldValue('notes')}</Paragraph>
                  <Divider />
                </>
              )}

              <Form.Item className="mt-4">
                <Button type="link" onClick={() => setStep('collect-details')} icon={<LeftOutlined />}>
                  Edit Details
                </Button>
              </Form.Item>
            </>
          )}

          <Form.Item className="mt-4">
            <Button type="primary" size="large" block htmlType="submit" loading={loading}>
              {step === 'collect-details' && 'Next'}
              {step === 'review-details' && 'Edit Dispatch'}
            </Button>
          </Form.Item>
        </Form>
      </ErrorBoundary>
    </Modal>
  );
};

export default EditDispatch;
