import React, { useEffect, useState } from 'react';
import { Checkbox, Col, Row, Card, Divider, Select } from 'antd';
import { isEmpty } from 'lodash';
const CheckboxGroup = Checkbox.Group;
const NotificationCheckbox = ({ label, field, extra, form, isMobile, selected = [], selectAllComm = {}, disabled, onUpdate, frequency, setFrequency, isFrequency }) => {
  const [checkedList, setCheckedList] = useState([]);
  const [indeterminate, setIndeterminate] = useState(null);
  const [checkAll, setCheckAll] = useState(null);
  const options = [
    { label: 'Email', value: 'email' },
    { label: 'Push', value: 'push' },
    { label: 'Whatsapp', value: 'whatsapp' },
  ];

  useEffect(() => {
    setIndeterminate(selected?.length > 0 && selected?.length < options.length);
    setCheckAll(selected?.length === options.length);
  }, [selected]);

  useEffect(() => {
    if (!isEmpty(selectAllComm)) {
      const alreadySelected = form.getFieldValue(field);
      const already = alreadySelected.reduce((obj, key) => {
        obj[key] = true;
        return obj;
      }, {});
      const newObject = { ...already, ...selectAllComm };
      const trueValuesArray = Object.keys(newObject).filter((key) => newObject[key] === true);
      setCheckedList(trueValuesArray);
    }
  }, [selectAllComm]);

  useEffect(() => {
    form.setFieldsValue({ [field]: checkedList });
    onUpdate();
  }, [checkedList]);

  const onChange = (list) => {
    setCheckedList(list);
    setIndeterminate(list.length > 0 && list.length < options.length);
    setCheckAll(list.length === options.length);
  };

  const onCheckAllChange = (e) => {
    setCheckedList(e.target.checked ? options.map((option) => option.value) : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  return (
    <>
      {isMobile ? (
        <Card
          title={
            <div>
              <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll} disabled={disabled}>
                <b>{label}</b>
              </Checkbox>
              {extra && <p style={{ color: 'grey', fontSize: '12px' }}>{extra}</p>}
            </div>
          }
          extra={
            isFrequency && (
              <Select disabled={disabled} value={frequency} onChange={(e) => setFrequency(e)}>
                <Select.Option value={'Instant'}>Instant</Select.Option>
                <Select.Option value={'Weekly'}>Weekly</Select.Option>
                <Select.Option value={'Monthly'}>Monthly</Select.Option>
                <Select.Option value={'Quarterly'}>Quarterly</Select.Option>
              </Select>
            )
          }>
          <CheckboxGroup
            style={{
              width: '100%',
            }}
            disabled={disabled}
            value={selected}
            onChange={onChange}>
            {options &&
              options.map((el, index) => (
                <>
                  <Row>
                    <Col span={22}>{el.label}</Col>
                    <Col span={2}>
                      <Checkbox value={el.value} />
                    </Col>
                  </Row>
                  {index < options.length - 1 && <Divider dashed={true} />}
                </>
              ))}
          </CheckboxGroup>
        </Card>
      ) : (
        <Row gutter={16}>
          <Col xs={8}>
            <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll} disabled={disabled}>
              <b>{label}</b>
            </Checkbox>
            <p style={{ color: 'grey', fontSize: '12px' }}>{extra}</p>
            {isFrequency && (
              <Select disabled={disabled} value={frequency} onChange={(e) => setFrequency(e)}>
                <Select.Option value={'Instant'}>Instant</Select.Option>
                <Select.Option value={'Weekly'}>Weekly</Select.Option>
                <Select.Option value={'Monthly'}>Monthly</Select.Option>
                <Select.Option value={'Quarterly'}>Quarterly</Select.Option>
              </Select>
            )}
          </Col>
          <Col xs={16}>
            <CheckboxGroup
              style={{
                width: '100%',
              }}
              disabled={disabled}
              value={selected}
              onChange={onChange}>
              <Row>
                {options &&
                  options.map((el) => (
                    <Col span={8}>
                      <Checkbox value={el.value} />
                    </Col>
                  ))}
              </Row>
            </CheckboxGroup>
          </Col>
        </Row>
      )}
    </>
  );
};
export default NotificationCheckbox;
