import React, { useEffect, useState } from 'react';
import { Avatar, Button, Col, Divider, Modal, PageHeader, Row, Timeline, Typography, message, Card, Tag, DatePicker, Tooltip, Form, Upload, Alert } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { format } from 'date-fns';
import { startCase, toLower, isEmpty, keyBy, each } from 'lodash';
import { EditOutlined, FileImageOutlined, FileTextOutlined, RightOutlined } from '@ant-design/icons';
import { BsCheckCircleFill, BsFillXCircleFill, BsTruck } from 'react-icons/bs';
import BackIcon from 'components/UtilComponents/BackIcon';
import CompanyName from 'components/UtilComponents/CompanyName';
import DetailsGrid from 'components/UtilComponents/DetailsGrid';
import NumberText from 'components/UtilComponents/NumberText';
import Loader from 'components/UtilComponents/Loader';
import ModalCloseIcon from 'components/UtilComponents/ModalCloseIcon';
import { BUSINESS_URL, DISPATCH_URL, OPEN_MERCHANT_NAME, ORDER_URL, UPLOAD_URL } from 'constants/ApiConstants';
import { formatRefNo } from 'utils/formatter';
import { Colors } from 'constants/ColorConstants';
import UpdateOrderStatus from 'components/Modals/Sell/UpdateOrderStatus';
import { AccessDenied, PageNotFound } from 'components/Errors';
import { getBreakPoint } from 'utils/layout';
import { HiOutlineExternalLink } from 'react-icons/hi';
import moment from 'moment';
import { FaTruckLoading } from 'react-icons/fa';
import { TbTruckDelivery } from 'react-icons/tb';
import EditDispatch from 'components/Modals/Sell/EditDispatch';

const { Title, Text, Paragraph } = Typography;

const OrderDetails = () => {
  const isMobile = !getBreakPoint().includes('md');
  const { tab, id: orderId } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [accessDenied, setAccessDenied] = useState(false);
  const [notFound, setNotFound] = useState(false);
  // const [completeLoading, setCompletedLoading] = useState(false);
  const [order, setOrder] = useState({});
  const [dispatches, setDispatches] = useState([]);
  const [dispatchVisible, setDispatchVisible] = useState(false);
  const totalQty = order?.qty * (order?.duration || 1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [status, setStatus] = useState('All');
  const [dispatchesLoading, setDispatchesLoading] = useState(false);
  const [selected, setSelected] = useState([]);
  const [editDispatchVisible, setEditDispatchVisible] = useState(false);
  const [dispatch, setDispatch] = useState([]);
  const { CheckableTag } = Tag;
  const [form] = Form.useForm();
  const [dispatchCount, setDispatchCount] = useState({
    All: 0,
    'In Preparation': 0,
    Dispatched: 0,
    Completed: 0,
    'On Hold': 0,
    Cancelled: 0,
  });

  const showModal = (dispatch) => {
    setIsModalOpen(true);
    setDispatch(dispatch);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onViewDispatch = (id) => {
    navigate(`/sell/orders/dispatch/${id}`);
  };

  const onOpenEditDispatch = (dispatch) => {
    setSelected(dispatch);
    setEditDispatchVisible(true);
  };

  const onCloseDispatch = (reload) => {
    if (reload) {
      onLoadDispatches();
      onLoadOrder();
      setSelected(null);
    }
    setEditDispatchVisible(false);
  };

  useEffect(() => {
    onLoadOrder();
    onLoadDispatches();
  }, [status, orderId]);

  useEffect(() => {
    onLoadOrder();
    onLoadDispatches();
  }, []);

  const tabOptions = [
    {
      key: 'All',
      label: 'All',
    },
    {
      key: 'In Preparation',
      label: 'Ongoing',
    },
    {
      key: 'Dispatched',
      label: 'Dispatched',
    },
    {
      key: 'Completed',
      label: 'Completed',
    },
    {
      key: 'Cancelled',
      label: 'Cancelled',
    },
  ];

  const getColor = (status) => {
    if (status === 'Awaiting Loading') return 'gold';
    if (status === 'Loaded') return 'green';
    if (status === 'In Transit') return 'yellow';
    if (status === 'Goods Delivered') return 'green';
    if (status === 'Goods Rejected') return 'red';
    return 'gold';
  };

  const onLoadOrder = async () => {
    try {
      const businessRes = await axios.get(`${BUSINESS_URL}/me`);
      const res = await axios.get(`${ORDER_URL}/${orderId}?&populate=true`);

      // if orderId is invalid, display order not found component.
      if (res.data.code === 1006 || res.data.code === 1007) {
        setNotFound(true);
        return setLoading(false);
      }

      // if seller is different from current business, then access should be denied.
      if (res.data.order?.seller?._id !== businessRes.data?.business?._id) {
        setAccessDenied(true);
        return setLoading(false);
      }
      if (res.data.status === 'error') {
        message.error(res.data.error);
      } else {
        setOrder(res.data.order);
      }
    } catch (err) {
      message.error(err.message);
    }
    setLoading(false);
  };

  const onLoadDispatches = async () => {
    setDispatchesLoading(true);
    try {
      const xApiFilters = { order: [orderId] };
      const dispatchRes = await axios.get(`${DISPATCH_URL}?populate=true`, {
        headers: {
          'X-Api-Filters': JSON.stringify(xApiFilters),
          'X-Api-Sort': JSON.stringify({ createdAt: 'desc' }),
        },
      });

      if (dispatchRes.data.status === 'error') {
        message.error(dispatchRes.data.error);
      } else {
        const { dispatches } = dispatchRes.data;
        setDispatches(dispatches.filter((d) => status === 'All' || d.status === status));
        setDispatchCount({
          All: dispatches.length,
          'In Preparation': dispatches.filter((d) => d.status === 'In Preparation').length,
          Dispatched: dispatches.filter((d) => d.status === 'Dispatched').length,
          Completed: dispatches.filter((d) => d.status === 'Completed').length,
          'On Hold': dispatches.filter((d) => d.status === 'On Hold').length,
          Cancelled: dispatches.filter((d) => d.status === 'Cancelled').length,
        });
      }
    } catch (err) {
      message.error(err.message);
    }
    setDispatchesLoading(false);
  };

  //TODO: It will be added in the future
  // const onOrderComplete = () => {
  //   Modal.confirm({
  //     title: `Are you sure you want to complete and close the order now?`,
  //     content: `Total: ${totalQty || '∞'} ${order?.subUnit} | Sent: ${order?.metadata?.dispatchedQty} ${order?.subUnit}`,
  //     bodyStyle: { backgroundColor: '#fff', borderRadius: 10 },
  //     icon: null,
  //     closable: true,
  //     closeIcon: <ModalCloseIcon />,
  //     cancelText: 'Cancel',
  //     cancelButtonProps: {
  //       type: 'primary',
  //       ghost: true,
  //     },
  //     onCancel() {},
  //     okText: 'Complete & Close',
  //     okButtonProps: {
  //       loading: completeLoading,
  //     },
  //     onOk: async () => {
  //       if (completeLoading) return;
  //       setCompletedLoading(true);
  //       try {
  //         const res = await axios.put(`${ORDER_URL}/${order?._id}`, {
  //           metadata: {
  //             completedBySeller: true,
  //           },
  //         });

  //         if (res.data.status === 'error') {
  //           message.error(res.data.error);
  //         } else {
  //           message.success('Order Completed');
  //           onLoadOrder();
  //         }
  //       } catch (err) {
  //         message.error(err.message);
  //       }
  //       setCompletedLoading(false);
  //     },
  //   });
  // };

  const getStatus = (dispatch) => {
    if (dispatch.status === 'In Preparation') {
      return 'Goods are in preparation and will be shipped soon.';
    } else if (dispatch.status === 'Dispatched') {
      return (
        <>
          Dispatched Quantity: {dispatch?.dispatchedQty} {order?.subUnit}
        </>
      );
    } else if (dispatch.status === 'Completed') {
      return (
        <>
          Dispatched Quantity: {dispatch?.dispatchedQty} {order?.subUnit}
        </>
      );
    } else if (dispatch.status === 'On Hold') {
      return `Your order is currently on hold. Please contact us if you have any questions`;
    } else if (dispatch.status === 'Cancelled') {
      return `Dispatch of ${dispatch.dispatchedQty} ${order?.subUnit} has been cancelled. Please contact us if you have any questions`;
    }
    return '';
  };

  const onOpenDispatch = (reload) => {
    if (reload) {
      onLoadDispatches();
      onLoadOrder();
    }
    setDispatchVisible(!dispatchVisible);
  };

  const getTitle = () => {
    if (isMobile) return `${order?.product?.name} - ${totalQty || '∞'} ${order?.subUnit}`;
    return `${order?.product?.name} - ${totalQty || '∞'} ${order?.subUnit} / ${order?.frequency} | ${formatRefNo(order?.orderRefNo)}`;
  };

  const TransitDatePicker = ({ dispatch, onChange = () => {} }) => {
    const transitUpdates = keyBy(dispatch?.transitUpdates, 'status');
    const currentTransit = transitUpdates[dispatch?.transitStatus];
    const minDate = currentTransit?.updatedOn ? new Date(currentTransit.updatedOn) : null;
    if (minDate) {
      if (dispatch?.transitStatus === 'Awaiting Loading') {
        minDate.setMinutes(minDate.getMinutes() + 5);
      } else {
        minDate.setMinutes(minDate.getMinutes() + 15);
      }
    }
    const maxDate = moment().endOf('day').toDate();
    const [transitDate, setTransitDate] = useState(minDate ? moment(minDate) : moment());
    return (
      <DatePicker
        showTime
        value={transitDate}
        onChange={(value) => {
          setTransitDate(value);
          onChange(value);
        }}
        allowClear={false}
        format="DD/MM/yyyy hh:mm A"
        disabledDate={(date) => {
          if (minDate) {
            return date.toDate() < minDate || date.toDate() > maxDate;
          }
          return date.toDate() > maxDate;
        }}
        style={{ width: '100%' }}
      />
    );
  };
  const getFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const onSwitch = (dispatch, type, mode) => {
    const transitUpdates = keyBy(dispatch?.transitUpdates, 'status');
    const currentTransit = transitUpdates[dispatch?.transitStatus];
    let transitDate = currentTransit?.updatedOn ? new Date(currentTransit.updatedOn) : null;
    if (transitDate) {
      if (type === 'move to loaded') {
        transitDate.setMinutes(transitDate.getMinutes() + 5);
      } else {
        transitDate.setMinutes(transitDate.getMinutes() + 15);
      }
    }

    Modal.confirm({
      title: `Are you sure you want to ${type} - ${formatRefNo(dispatch?.dispatchRefNo, 'BFD')}?`,
      content: (
        <>
          <span>{mode === 'transitStatus' && <TransitDatePicker dispatch={dispatch} onChange={(value) => (transitDate = value)} />}</span>
          {type === 'move to transit' && !dispatch?.purchase ? (
            <Alert className="m-2" message={`Add at least one attachment before moving to 'In Transit'`} banner type="error" />
          ) : type === 'move to transit' && dispatch?.purchase ? (
            <Alert className="m-2" message={`Review attached files before moving to 'In Transit'`} banner type="info" showIcon />
          ) : null}

          <Form form={form} layout="vertical" requiredMark={false} scrollToFirstError={true}>
            <Form.Item name="invoice" label="Invoice" getValueFromEvent={getFile}>
              <Upload maxCount={1} beforeUpload={() => false} defaultFileList={form.getFieldValue('invoice') || []} accept=".jpg, .jpeg, .png, .pdf">
                <Button size="large" icon={<FileImageOutlined />}>
                  Upload Invoice
                </Button>
              </Upload>
            </Form.Item>
            {dispatch?.purchase?.invoice && (
              <Button href={`${UPLOAD_URL}/${dispatch.purchase.invoice}`} type="link" target="_blank" style={{ marginTop: -24, marginBottom: 10 }}>
                View Existing Invoice
              </Button>
            )}

            <Form.Item name="ewayBill" label="E-Way Bill / GRN Receipt" getValueFromEvent={getFile}>
              <Upload maxCount={1} beforeUpload={() => false} defaultFileList={form.getFieldValue('ewayBill') || []} accept=".jpg, .jpeg, .png, .pdf">
                <Button size="large" icon={<FileImageOutlined />}>
                  Upload Eway Bill
                </Button>
              </Upload>
            </Form.Item>
            {dispatch?.purchase?.ewayBill && (
              <Button href={`${UPLOAD_URL}/${dispatch.purchase.ewayBill}`} type="link" target="_blank" style={{ marginTop: -24, marginBottom: 10 }}>
                View Existing E-Way Bill / GRN Receipt
              </Button>
            )}

            <Form.Item name="weightSlip" label="Weight Slip" getValueFromEvent={getFile}>
              <Upload maxCount={1} beforeUpload={() => false} defaultFileList={form.getFieldValue('weightSlip') || []} accept=".jpg, .jpeg, .png, .pdf">
                <Button size="large" icon={<FileTextOutlined />}>
                  Upload Weight Slip
                </Button>
              </Upload>
            </Form.Item>
            {dispatch?.purchase?.weightSlip && (
              <Button href={`${UPLOAD_URL}/${dispatch.purchase.weightSlip}`} type="link" target="_blank" style={{ marginTop: -24, marginBottom: 10 }}>
                View Existing Weight Slip
              </Button>
            )}

            <Form.Item name="qualityReport" label="Quality Report" getValueFromEvent={getFile}>
              <Upload maxCount={1} beforeUpload={() => false} defaultFileList={form.getFieldValue('qualityReport') || []} accept=".jpg, .jpeg, .png, .pdf">
                <Button size="large" icon={<FileTextOutlined />}>
                  Upload Quality Report
                </Button>
              </Upload>
            </Form.Item>
            {dispatch?.purchase?.qualityReport && (
              <Button href={`${UPLOAD_URL}/${dispatch.purchase.qualityReport}`} type="link" target="_blank" style={{ marginTop: -24, marginBottom: 10 }}>
                View Existing Quality Report
              </Button>
            )}

            <Form.Item name="attachments" label="Attachments" getValueFromEvent={getFile}>
              <Upload multiple beforeUpload={() => false} defaultFileList={form.getFieldValue('attachments') || []} accept=".jpg, .jpeg, .png, .pdf">
                <Button size="large" icon={<FileTextOutlined />}>
                  Upload Additional Files
                </Button>
              </Upload>
            </Form.Item>
            {dispatch?.purchase?.attachments && (
              <div style={{ marginTop: -14, marginBottom: 10 }}>
                {dispatch?.purchase.attachments.map((attachment, index) => (
                  <Button href={`${UPLOAD_URL}/${attachment}`} type="link" target="_blank">
                    View Existing Attachment {index + 1}
                  </Button>
                ))}
                <Tag color="red" style={{ marginTop: 5 }}>
                  * Existing attachments will be replaced if you upload new ones
                </Tag>
              </div>
            )}
          </Form>
        </>
      ),
      okText: 'Confirm',
      cancelText: 'Cancel',
      onCancel: () => {
        form.resetFields();
      },
      width: '450px',
      onOk: async () => {
        try {
          let toUpdate;
          if (type === 'move to transit') toUpdate = { transitStatus: 'In Transit', dispatchFrom: dispatch?.dispatchFrom };

          if (mode === 'transitStatus') {
            toUpdate.transitDate = transitDate;
          }

          const values = form.getFieldsValue(true);
          const files = new FormData();
          if (!isEmpty(values?.invoice)) files.append('purchase.invoice', values?.invoice[0].originFileObj);
          if (!isEmpty(values?.ewayBill)) files.append('purchase.ewayBill', values?.ewayBill[0].originFileObj);
          if (!isEmpty(values?.weightSlip)) files.append('purchase.weightSlip', values?.weightSlip[0].originFileObj);
          if (!isEmpty(values?.qualityReport)) files.append('purchase.qualityReport', values?.qualityReport[0].originFileObj);
          if (!isEmpty(values?.attachments))
            each(values.attachments, (attachment) => {
              files.append('purchase.attachments', attachment.originFileObj);
            });

          if (type === 'move to transit' && !dispatch?.purchase && isEmpty(values)) {
            message.error('Error: At least one attachment is needed before moving to transit');

            return Promise.reject();
          } else {
            const res = await axios.put(`${DISPATCH_URL}/${dispatch._id}`, toUpdate);
            await axios.post(`${DISPATCH_URL}/upload/${dispatch?._id}`, files);

            if (res.data.status === 'error') {
              message.error(res.data.error);
            } else {
              message.success('Dispatch transit status successfully updated');
              onLoadDispatches();
              form.resetFields();
            }
          }
        } catch (error) {
          message.error('An error occurred. Please try again.');
        }
      },
    });
  };

  const onChangeTransitStatus = (dispatch) => {
    const status = dispatch?.transitStatus;
    let type = null;
    if (status === 'Awaiting Loading') {
      type = 'move to transit';
    }
    if (type) {
      onSwitch(dispatch, type, 'transitStatus');
    }
  };

  if (loading) return <Loader />;

  if (notFound) return <PageNotFound />;

  if (accessDenied) return <AccessDenied />;

  return (
    <>
      <div className="app-container">
        <PageHeader onBack={() => navigate(-1)} backIcon={<BackIcon />} title={getTitle()} subTitle={!isMobile && startCase(toLower(tab)).slice(0, -1)} className="px-0" />
      </div>
      <Divider className="my-2" />
      <div className="app-container">
        {isMobile && (
          <div className="my-3">
            <Paragraph>Buyer</Paragraph>
            <CompanyName name={order?.enquiryType === 'Direct Enquiry' ? order?.seller?.name : OPEN_MERCHANT_NAME} verified={true} />
          </div>
        )}
        <Row gutter={[16, 16]}>
          <Col xs={12} sm={12} md={6} lg={6}>
            <DetailsGrid
              icon={<Avatar src={require('assets/images/icon-quantity.png')} shape="square" size="small" />}
              title="Dispatched Quantity"
              description={`${order?.metadata?.dispatchedQty} of ${totalQty || '∞'} ${order?.subUnit}`}
            />
          </Col>
          <Col xs={12} sm={12} md={6} lg={6}>
            <DetailsGrid title="Order Status" description={order?.status} />
          </Col>
          <Col xs={12} sm={12} md={6} lg={6}>
            <DetailsGrid
              icon={<Avatar src={require('assets/images/icon-cash.png')} shape="square" size="small" />}
              title="Payment Pending"
              description={<NumberText prefix={order?.currency} value={order?.paymentPriceBasis?.pendingPayable?.value / 100} />}
            />
          </Col>
          <Col xs={12} sm={12} md={6} lg={6}>
            <DetailsGrid title="Bill Amount" description={<NumberText prefix={order?.currency} value={order?.paymentPriceBasis?.payable?.value / 100} />} />
          </Col>
        </Row>

        <Divider />

        <div className="d-flex w-100 vh-100 flex-column align-items-center">
          {order?.status === 'In Progress' ? (
            <Row>
              <Col span={12} offset={4}>
                <Title level={5} className="fw-bold">
                  Track Dispatch
                </Title>
              </Col>
              <Col span={4} offset={4}>
                <Row>
                  <Col>
                    <Button type="primary" ghost onClick={() => onOpenDispatch()}>
                      Create Dispatch
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          ) : (
            <Title level={5} className="fw-bold">
              Track Dispatch
            </Title>
          )}
          <Row className="mt-4" wrap={false}>
            {tabOptions.map((tab) => (
              <>
                <CheckableTag
                  key={tab.key}
                  checked={status === tab.key}
                  onChange={() => {
                    setStatus(tab.key);
                  }}>
                  {tab.label} ({dispatchCount[tab.key]})
                </CheckableTag>
              </>
            ))}
          </Row>
          <Col xs={24} sm={24} mg={12} lg={24}>
            {!dispatchesLoading && dispatches.length > 0 && (
              <Timeline className="mt-5">
                {dispatches.map((dispatch) => {
                  const icon =
                    dispatch?.status === 'Accepted' ? (
                      <BsCheckCircleFill style={{ backgroundColor: 'transparent' }} />
                    ) : ['Cancelled', 'Rejected'].includes(dispatch?.status) ? (
                      <BsFillXCircleFill style={{ backgroundColor: 'transparent' }} />
                    ) : (
                      <BsTruck style={{ backgroundColor: 'transparent' }} />
                    );
                  const color = dispatch?.status === 'Accepted' ? '#0A8A85' : ['Cancelled', 'Rejected'].includes(dispatch?.status) ? '#ff4d4f' : Colors.primary;
                  const icons = dispatch?.transitStatus === 'Awaiting Loading' ? <FaTruckLoading className="mr-2" /> : <TbTruckDelivery className="mr-2" />;
                  const tooltipData = dispatch?.transitStatus === 'Awaiting Loading' && 'Move to In Transit';
                  return (
                    <Timeline.Item color={color} dot={icon} key={dispatch?._id}>
                      <Card>
                        <Tag className="rounded px-2 border-0" color={getColor(dispatch?.transitStatus)} icon={<BsTruck className="mr-2" />}>
                          {dispatch?.transitStatus}
                        </Tag>
                        {['Finding Transport', 'Transport Allocated', 'Awaiting Loading', 'Loaded'].includes(dispatch?.transitStatus) && dispatch.status !== 'On Hold' && (
                          <>
                            <Tooltip title={'Edit Dispatch'}>
                              <Button shape="circle" onClick={() => onOpenEditDispatch(dispatch)}>
                                <EditOutlined />
                              </Button>
                            </Tooltip>
                          </>
                        )}
                        <br />
                        <Paragraph>{getStatus(dispatch)}</Paragraph>
                        <Divider className="my-2" />
                        <Row>
                          <Col xs={24} sm={24} md={24} lg={24}>
                            {dispatch?.purchaseBillNo && (
                              <>
                                <Text type="secondary">Purchase Bill No: </Text>
                                <DetailsGrid description={dispatch?.purchaseBillNo} />
                              </>
                            )}
                          </Col>
                        </Row>
                        <Row className="my-2">
                          <Paragraph className="text-small" type="secondary">{`Created at: ${format(new Date(dispatch?.createdAt), 'dd MMM yyyy, hh:mm a')} `}</Paragraph>
                        </Row>
                        {!isEmpty(dispatch?.purchase) && (
                          <Button type="link" className="pl-0" onClick={() => showModal(dispatch)}>
                            View Attachments
                            <HiOutlineExternalLink size={16} />
                          </Button>
                        )}
                        <Button type="primary" onClick={() => onViewDispatch(dispatch?._id)}>
                          View
                        </Button>
                        {['Finding Transport', 'Transport Allocated', 'Awaiting Loading', 'Loaded'].includes(dispatch?.transitStatus) && dispatch.status !== 'On Hold' && (
                          <Button icon={icons} type="default" onClick={() => onChangeTransitStatus(dispatch)} className="ml-2 px-2">
                            {tooltipData}
                          </Button>
                        )}
                      </Card>
                    </Timeline.Item>
                  );
                })}
              </Timeline>
            )}
            {dispatchesLoading && <Loader />}
            {!dispatchesLoading && dispatches?.length === 0 && (
              <div className="mt-5">
                <Text>No {status === 'In Preparation' ? 'Ongoing' : status} Dispatches</Text>
              </div>
            )}

            {/* TODO: It will be enable later  */}
            {/* {status === 'In Progress' && (
              <div className="my-4">
                <Paragraph>Order Completion:</Paragraph>
                <Button type="primary" className="my-2" onClick={onOrderComplete}>
                  Complete Order
                </Button>
              </div>
            )} */}
            {!isEmpty(dispatch?.purchase) && (
              <Modal title="View Attachments" open={isModalOpen} footer={null} onOk={handleOk} onCancel={handleCancel} width={350} closeIcon={<ModalCloseIcon />}>
                <Card>
                  {dispatch?.purchase?.invoice && (
                    <Col span={24}>
                      <Button type="link" href={`${UPLOAD_URL}/${dispatch?.purchase?.invoice}`} target="_blank">
                        View Invoice
                        <RightOutlined />
                      </Button>
                    </Col>
                  )}
                  {dispatch?.purchase?.ewayBill && (
                    <Col span={24}>
                      <Button type="link" href={`${UPLOAD_URL}/${dispatch?.purchase?.ewayBill}`} target="_blank">
                        View E-way Bill
                        <RightOutlined />
                      </Button>
                    </Col>
                  )}
                  {dispatch?.purchase?.weightSlip && (
                    <Col span={24}>
                      <Button type="link" href={`${UPLOAD_URL}/${dispatch?.purchase?.weightSlip}`} target="_blank">
                        View Weight Slip
                        <RightOutlined />
                      </Button>
                    </Col>
                  )}
                  {dispatch?.purchase?.qualityReport && (
                    <Col span={24}>
                      <Button type="link" href={`${UPLOAD_URL}/${dispatch?.purchase?.qualityReport}`} target="_blank">
                        View Quality Report
                        <RightOutlined />
                      </Button>
                    </Col>
                  )}
                  {dispatch?.purchase?.attachments?.length > 0 &&
                    dispatch?.purchase?.attachments.map((attachment, index) => {
                      return (
                        <Col span={24} key={attachment}>
                          <Button type="link" href={`${UPLOAD_URL}/${attachment}`} target="_blank">
                            View Attachment {index + 1}
                            <RightOutlined />
                          </Button>
                        </Col>
                      );
                    })}
                </Card>
              </Modal>
            )}
            <UpdateOrderStatus order={order} visible={dispatchVisible} close={onOpenDispatch} />
            <EditDispatch order={order} visible={editDispatchVisible} close={onCloseDispatch} selected={selected} />
          </Col>
        </div>
      </div>
    </>
  );
};

export default OrderDetails;
