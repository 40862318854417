import React, { useEffect, useState } from 'react';
import { Avatar, Button, Calendar, Col, Divider, Form, Input, InputNumber, Modal, Radio, Row, Select, Tag, Typography, Upload, message } from 'antd';
import { InfoCircleOutlined, LeftOutlined, PlusOutlined, CheckOutlined, ExclamationCircleOutlined, FileImageOutlined, FileTextOutlined } from '@ant-design/icons';
import ErrorBoundary from 'components/Errors/ErrorBoundary';
import BackIcon from 'components/UtilComponents/BackIcon';
import DetailsGrid from 'components/UtilComponents/DetailsGrid';
import ModalCloseIcon from 'components/UtilComponents/ModalCloseIcon';
import NumberText from 'components/UtilComponents/NumberText';
import AddNewAddress from 'components/UtilComponents/AddNewAddress';
import { format, startOfDay } from 'date-fns';
import moment from 'moment';
import { COMMON_URL, OPEN_RFQ_QUOTE_URL, PAYMENT_TERMS_URL, SAVED_ADDRESS_URL } from 'constants/ApiConstants';
import axios from 'axios';
import { isEmpty, keyBy, uniqueId } from 'lodash';
import { posthog } from 'posthog-js';
import { formatPrice } from 'utils/formatter';
import { checkForAnomaly } from 'utils/anomaly';

const { Paragraph, Text } = Typography;

const SendQuote = ({ visible, close, openRfq = {} }) => {
  const viewName = 'sell | open-rfq-details';
  const [form] = Form.useForm();
  const [uid, setUid] = useState(null);
  const [step, setStep] = useState('collect-details');
  const [loading, setLoading] = useState(false);
  const [transportType, setTransportType] = useState('');
  const [savedAddresses, setSavedAddresses] = useState([]);
  const [paymentTerms, setPaymentTerms] = useState([]);
  const [pickupAddress, setPickupAddress] = useState(null);
  const [paymentTermsById, setPaymentTermsById] = useState(null);
  const [savedAddressesById, setSavedAddressesById] = useState([]);
  const [showAddAddress, setShowAddAddress] = useState(false);
  const [predictedPrice, setPredictedPrice] = useState({});
  const [businessState, setBusinessState] = useState(null);

  useEffect(() => {
    setUid(uniqueId('dd_'));
    onLoad();
  }, [visible]);

  useEffect(() => {
    if (step) {
      const drawerBody = document.querySelector(`.${uid} .ant-modal-body`);
      if (drawerBody) {
        drawerBody.scrollTop = 0;
      }
    }
  }, [step, visible]);

  useEffect(() => {
    if (businessState && !isEmpty(openRfq)) {
      onLoadPredictedPrice();
    }
  }, [businessState, pickupAddress]);

  const onLoadPredictedPrice = async () => {
    setLoading(true);
    try {
      const stats = await axios.post(`${COMMON_URL}/price-predict/stats`, {
        state: businessState,
        product_id: openRfq?.product?._id,
        unit: openRfq?.subUnit,
        enquiryType: 'OpenRequirementQuote',
      });

      if (stats.data.status === 'error') {
        message.error(stats.data.error);
      } else {
        setPredictedPrice(stats.data.stats);
      }
    } catch (err) {
      message.error(err?.message);
    }
    setLoading(false);
  };

  const onLoad = async () => {
    setLoading(true);
    try {
      const addressRes = await axios.get(`${SAVED_ADDRESS_URL}`, {
        headers: {
          'X-API-Filters': JSON.stringify({
            business: ['me'],
            status: ['Active'],
          }),
        },
      });

      const termRes = await axios.get(`${PAYMENT_TERMS_URL}`);

      if (addressRes.data.status === 'error') {
        message.error(addressRes.data.error);
      } else if (termRes.data.status === 'error') {
        message.error(termRes.data.error);
      } else {
        setBusinessState(addressRes.data.savedaddresses[0].state);
        setSavedAddresses(addressRes.data.savedaddresses);
        setPaymentTerms(termRes.data.paymentterms);
        if (addressRes.data.savedaddresses.length > 0) {
          form.setFields([{ name: 'pickupAddressId', value: addressRes.data.savedaddresses[0]?._id }]);
          setPickupAddress(addressRes.data.savedaddresses[0]?._id);
          setSavedAddressesById(keyBy(addressRes.data.savedaddresses, '_id'));
        }
        if (termRes.data.paymentterms.length > 0) {
          form.setFields([{ name: 'paymentTerm', value: termRes?.data?.paymentterms[0]?._id }]);
          setPaymentTermsById(keyBy(termRes.data.paymentterms, '_id'));
        }
      }
    } catch (err) {
      message.error(err.message);
    }
    setLoading(false);
  };

  const onClickPrev = () => {
    if (step === 'review-details') return setStep('collect-details');
  };

  const onClickNext = () => {
    if (step === 'collect-details') return setStep('review-details');
    if (step === 'review-details') return onSubmit();
  };

  const getTitle = () => {
    if (step === 'collect-details') return `Send Quote for ${openRfq?.product?.name}`;
    return (
      <>
        <BackIcon onClick={onClickPrev} className="mr-3" />
        {' Review Quote'}
      </>
    );
  };

  /* Create Open Requirement Quote on Submit */
  const onSubmit = async () => {
    if (loading) return;
    setLoading(true);
    try {
      const values = form.getFieldsValue(true);
      const anomalyForSentQuote = checkForAnomaly('send-quote', { product: openRfq?.product, subUnits: openRfq?.unit?.subUnits, subUnit: openRfq?.subUnit, price: values?.purchasingPrice });
      if (anomalyForSentQuote) {
        setLoading(false);
        return message.error(anomalyForSentQuote);
      }
      const res = await axios.post(`${OPEN_RFQ_QUOTE_URL}`, {
        enquiry: openRfq._id,
        qty: values.qty,
        duration: 0,
        purchasingPrice: values.purchasingPrice * 100,
        gstPercentage: values.gstPercentage === 0 ? 0 : openRfq?.product?.metadata?.gstPercentage,
        transportType: values.transportType,
        transportCharges: values.transportType === 'Extra' ? values.transportCharges * 100 : 0,
        pickupAddressId: values.pickupAddressId,
        paymentTerm: values.paymentTerm,
        expectedDeliveryOn: new Date(values.expectedDeliveryOn?.format('YYYY-MM-DD')),
        remark: values.remark,
      });

      if (res.data.status === 'success') {
        message.success('Open Requirement Quote sent successfully');
        posthog.capture(`$${viewName} | $open-rfq-quote-created`, {
          data: {
            rfqId: openRfq?._id,
            product: openRfq?.product?.name,
            quantity: values.qty,
            duration: 0,
            purchasingPrice: values.purchasingPrice,
            gstPercentage: values.gstPercentage,
            transportType: values.transportType,
            transportCharges: values.transportType === 'Extra' ? values.transportCharges : 0,
            city: savedAddressesById[values.pickupAddressId]?.city,
            state: savedAddressesById[values.pickupAddressId]?.state,
          },
          $set: {
            lastActivityDate: new Date().toISOString(),
          },
        });

        // upload related files for openrequirementquote
        const files = new FormData();
        if (!isEmpty(values?.productImageFile)) files.append('productImageFile', values?.productImageFile[0].originFileObj);
        if (!isEmpty(values?.qualityReportFile)) files.append('qualityReportFile', values?.qualityReportFile[0].originFileObj);

        await axios.post(`${OPEN_RFQ_QUOTE_URL}/upload/${res?.data?.openrequirementquote?._id}`, files);

        close(true);
      } else {
        message.error(res.data.error);
      }
    } catch (err) {
      message.error(err.message);
    }
    setLoading(false);
  };

  const onCloseAddAddress = (reload) => {
    if (reload) {
      onLoad();
    }
    setShowAddAddress(!showAddAddress);
  };

  const getFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const confirmOnClose = () => {
    if ((!loading && form.isFieldsTouched()) || step === 'review-details') {
      const values = form.getFieldsValue();
      Modal.confirm({
        title: 'Unsaved Data',
        icon: <ExclamationCircleOutlined />,
        content: 'This form has unsaved data. Are you sure you want to close the screen?',
        okText: 'Yes',
        onOk: () => {
          form.resetFields();
          setStep('collect-details');
          close();
          posthog.capture(`$${viewName} | $send-quote-attempted`, {
            data: {
              rfqId: openRfq?._id,
              product: openRfq?.product?.name,
              quantity: values.qty ? values.qty : null,
              duration: null,
              purchasingPrice: values.purchasingPrice ? values.purchasingPrice : null,
              gstPercentage: values.gstPercentage ? values.gstPercentage : null,
              transportType: values.transportType ? values.transportType : null,
              transportCharges: values.transportCharges ? values.transportCharges : null,
              city: savedAddressesById[values.pickupAddressId]?.city,
              state: savedAddressesById[values.pickupAddressId]?.state,
            },
            $set: {
              lastActivityDate: new Date().toISOString(),
            },
          });
        },
        cancelText: 'No',
      });
    } else {
      form.resetFields();
      setStep('collect-details');
      close();
    }
  };

  return (
    <Modal className={uid} title={getTitle()} open={visible} onCancel={confirmOnClose} footer={null} closeIcon={<ModalCloseIcon />} width={480} bodyStyle={{ height: '70vh', overflow: 'scroll' }}>
      <ErrorBoundary>
        <Form form={form} layout="vertical" onFinish={step === 'collect-details' ? onClickNext : onSubmit} requiredMark={false} scrollToFirstError={true}>
          {step === 'collect-details' && (
            <>
              <Form.Item
                name="purchasingPrice"
                label={
                  <>
                    <span>
                      Price ({openRfq?.currency}) / {openRfq?.subUnit}
                    </span>
                    {!isEmpty(predictedPrice) ? (
                      <Tag style={{ marginLeft: '5px' }} color="green">
                        Avg. market price is {formatPrice(predictedPrice.median, openRfq?.currency)} / {openRfq?.subUnit} in {businessState}
                      </Tag>
                    ) : (
                      ''
                    )}
                  </>
                }
                rules={[
                  {
                    required: true,
                    message: 'Please enter a value',
                  },
                ]}
                hasFeedback>
                <InputNumber precision={3} className="w-100" prefix={openRfq?.currency === 'USD' ? '$' : '₹'} min={1} max={1000000} size="large" controls={false} />
              </Form.Item>

              <Form.Item
                label={`Quantity Available (${openRfq?.subUnit})`}
                className="bg-transparent"
                name="qty"
                rules={[
                  {
                    required: true,
                    message: 'Please enter the quantity available',
                  },
                  {
                    type: 'number',
                    max: openRfq?.toQty > 0 ? openRfq?.toQty : openRfq?.fromQty,
                    message: `Quantity must be less than or equal to ${openRfq?.toQty > 0 ? openRfq?.toQty : openRfq?.fromQty} ${openRfq?.subUnit}`,
                  },
                ]}
                hasFeedback
                extra={`Required Qty: ${openRfq?.fromQty}${openRfq?.toQty > 0 ? ` - ${openRfq?.toQty}` : ''} ${openRfq?.subUnit}`}>
                <InputNumber controls={false} precision={3} className="w-100" size="large" min={1} />
              </Form.Item>

              <Form.Item
                name="gstPercentage"
                label="GST"
                rules={[
                  {
                    required: true,
                    message: 'Please select a valid GST',
                  },
                ]}
                hasFeedback>
                <Select>
                  {openRfq?.product?.metadata?.gstPercentage <= 0 ? (
                    <Select.Option value={0}>0% - GST Not Applied</Select.Option>
                  ) : (
                    <>
                      <Select.Option value={0}>{openRfq?.product?.metadata?.gstPercentage + '%'} Included</Select.Option>
                      <Select.Option value={1}>{openRfq?.product?.metadata?.gstPercentage + '%'} Extra</Select.Option>
                    </>
                  )}
                </Select>
              </Form.Item>

              <Form.Item
                name="transportType"
                label="Transport"
                rules={[
                  {
                    required: true,
                    message: 'Please select a valid transport type',
                  },
                ]}
                hasFeedback>
                <Select size="large" onChange={setTransportType}>
                  <Select.Option value="Included">Included</Select.Option>
                  <Select.Option value="Excluded">Excluded</Select.Option>
                  <Select.Option value="Extra">Extra</Select.Option>
                </Select>
              </Form.Item>
              {transportType === 'Extra' && (
                <Form.Item
                  name="transportCharges"
                  label={`Transport Charges (per ${openRfq?.product ? openRfq?.subUnit : 'Unit'})`}
                  rules={[
                    {
                      required: true,
                      pattern: /^[1-9][0-9.]*$/,
                      message: `Please enter a valid Transport Charges per ${openRfq?.product ? openRfq?.subUnit : 'Unit'}`,
                    },
                  ]}
                  hasFeedback>
                  <InputNumber precision={3} className="w-100" size="large" min={1} placeholder={`Transport Charges (per ${openRfq?.product ? openRfq?.subUnit : 'Unit'})`} />
                </Form.Item>
              )}
              <Form.Item
                name="pickupAddressId"
                label={
                  <>
                    <Row align="middle">
                      <Col xs={12} sm={20}>
                        <Text>Pickup Address</Text>
                      </Col>
                      <Col xs={12} sm={4}>
                        <Button type="link" icon={<PlusOutlined />} onClick={() => onCloseAddAddress()}>
                          Add New Address
                        </Button>
                      </Col>
                    </Row>
                  </>
                }
                rules={[
                  {
                    required: true,
                    message: 'Please select a valid Pickup Address',
                  },
                ]}
                hasFeedback>
                <Select
                  size="large"
                  showSearch
                  filterOption={(inputValue, option) => {
                    const { label, line1, city, state, pincode } = option;
                    const searchValue = inputValue?.toLowerCase();
                    return [label, line1, city, state, pincode].some((value) => value?.toLowerCase().includes(searchValue));
                  }}
                  optionLabelProp="label"
                  value={pickupAddress}
                  onChange={(pickupAddress) => {
                    setBusinessState(savedAddressesById[pickupAddress].state);
                    setPickupAddress(pickupAddress);
                    setPredictedPrice({});
                    form.setFieldsValue({ pickupAddress });
                  }}
                  menuItemSelectedIcon={<CheckOutlined />}>
                  {savedAddresses.map((savedAddress) => (
                    <Select.Option key={savedAddress._id} value={savedAddress._id} label={savedAddress?.name}>
                      <div>
                        <h5 className="mt-0">{savedAddress?.name}</h5>
                        <p className="mt-0 mb-0 text-wrap">
                          {savedAddress?.line1}, {savedAddress?.line2 ? savedAddress?.line2 + ',' : ''} {savedAddress?.city}, {savedAddress?.state} - {savedAddress?.pincode}
                        </p>
                      </div>
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              {!isEmpty(predictedPrice) && pickupAddress ? (
                <Tag style={{ marginBottom: '10px' }} color="green">
                  Avg. market price is {formatPrice(predictedPrice.median, openRfq?.currency)} / {openRfq?.subUnit} in {businessState}
                </Tag>
              ) : (
                ''
              )}
              <Form.Item
                label="Expected Delivery Date From Your Side"
                className="bg-transparent"
                name="expectedDeliveryOn"
                initialValue={moment(startOfDay(new Date()))}
                rules={[
                  {
                    required: true,
                    message: 'Please select the delivery starting date',
                  },
                ]}>
                <Calendar validRange={[moment(startOfDay(new Date())), moment().add(1, 'years')]} fullscreen={false} />
              </Form.Item>

              <Form.Item
                label="Terms of Payment"
                className="bg-transparent"
                name="paymentTerm"
                rules={[
                  {
                    required: true,
                    message: 'Please select Payment Terms',
                  },
                ]}>
                <Radio.Group size="large">
                  {paymentTerms?.map((paymentterm) => {
                    return (
                      <Radio className="w-100" key={paymentterm?._id} value={paymentterm?._id} style={{ paddingTop: 10, paddingBottom: 10 }}>
                        {paymentterm?.name}
                      </Radio>
                    );
                  })}
                </Radio.Group>
              </Form.Item>

              <Divider />

              <Form.Item label="Quality Remarks" className="bg-transparent" name="remark">
                <Input.TextArea size="large" rows={4} maxLength={200} showCount allowClear />
              </Form.Item>

              <Form.Item name="productImageFile" getValueFromEvent={getFile}>
                <Upload maxCount={1} beforeUpload={() => false} defaultFileList={form.getFieldValue('productImageFile') || []} accept="image/*">
                  <Button size="large" icon={<FileImageOutlined />}>
                    Upload Product Image (Optional)
                  </Button>
                </Upload>
              </Form.Item>

              <Form.Item name="qualityReportFile" getValueFromEvent={getFile}>
                <Upload maxCount={1} beforeUpload={() => false} defaultFileList={form.getFieldValue('qualityReportFile') || []} accept="application/pdf">
                  <Button size="large" icon={<FileTextOutlined />}>
                    Upload Quality Report (Optional)
                  </Button>
                </Upload>
              </Form.Item>
            </>
          )}

          {/* Review the details before submitting */}
          {step === 'review-details' && (
            <>
              <DetailsGrid icon={<Avatar src={require('assets/images/icon-cash.png')} shape="square" size="default" />} description="Pricing & Quantity" />
              <>
                <Row justify="space-between" className="mt-3">
                  <Text>Price</Text>
                  <NumberText bold value={form.getFieldValue('purchasingPrice')} prefix={openRfq?.currency === 'USD' ? '$' : '₹'} suffix={`/ ${openRfq?.subUnit}`} />
                </Row>

                <Row justify="space-between" className="my-1">
                  <Text>Quantity</Text>
                  <NumberText bold value={form.getFieldValue('qty')} prefix={openRfq?.currency === 'USD' ? '$' : '₹'} suffix={`${openRfq?.subUnit} / ${openRfq?.frequency}`} />
                </Row>

                <Row justify="space-between" className="my-1">
                  <Text>GST</Text>
                  <Text strong>{form.getFieldValue('gstPercentage') > 0 ? `${openRfq?.product?.metadata?.gstPercentage} % Extra` : 'Included'}</Text>
                </Row>

                <Row justify="space-between" className="my-1">
                  <Text>Delivery Charges</Text>
                  <Text strong>
                    {form.getFieldValue('transportType') === 'Extra'
                      ? `${openRfq?.currency === 'USD' ? '$ ' : '₹ '} ${form.getFieldValue('transportCharges')} / ${openRfq?.product ? openRfq?.subUnit : ''}`
                      : form.getFieldValue('transportType')}
                  </Text>
                </Row>

                <Divider className="my-0" />
                <Tag icon={<InfoCircleOutlined />} color="#1A75DA" className="my-3">
                  Final invoice amount will be calculated once the quote is accepted
                </Tag>
              </>

              <Divider className="mt-0" />

              <DetailsGrid icon={<Avatar src={require('assets/images/icon-delivery.png')} shape="square" size="default" />} description="Delivery" />
              <Row gutter={[16, 16]} className="mt-2">
                <Col span={12}>
                  {form.getFieldValue('expectedDeliveryOn') && <DetailsGrid title="Start Deliveries By" description={format(new Date(form.getFieldValue('expectedDeliveryOn')?._d), 'dd MMM yyyy')} />}
                </Col>
                <Col span={12}>
                  <DetailsGrid
                    title="Pickup Location"
                    description={savedAddressesById[pickupAddress]?.name}
                    description2={`${savedAddressesById[pickupAddress]?.line1}, ${savedAddressesById[pickupAddress]?.line2 ? savedAddressesById[pickupAddress]?.line2 + ',' : ''} ${
                      savedAddressesById[pickupAddress]?.city
                    }, ${savedAddressesById[pickupAddress]?.state}, ${savedAddressesById[pickupAddress]?.pincode}`}
                  />
                </Col>
                <Col span={12}>
                  <DetailsGrid title="Delivery Location" description={`${openRfq?.deliveryAddress?.city}, ${openRfq?.deliveryAddress?.state}`} />
                </Col>
              </Row>

              <Divider />

              <Paragraph type="secondary">Additional Information</Paragraph>
              {form.getFieldValue('paymentTerm') && (
                <Paragraph>
                  &bull; <strong>Payment Terms of Payment:</strong> {paymentTermsById[form.getFieldValue('paymentTerm')]?.name}
                </Paragraph>
              )}
              {form.getFieldValue('remark') && (
                <Paragraph>
                  &bull; <strong>Quality Remarks:</strong> {form.getFieldValue('remark')}
                </Paragraph>
              )}

              <Divider />

              <Form.Item className="mt-4">
                <Button type="link" onClick={() => setStep('collect-details')} icon={<LeftOutlined />}>
                  Edit Quote
                </Button>
              </Form.Item>
            </>
          )}

          <Form.Item className="mt-4">
            <Button type="primary" size="large" block htmlType="submit" loading={loading}>
              {step === 'collect-details' && 'Next'}
              {step === 'review-details' && 'Send Quote'}
            </Button>
          </Form.Item>
        </Form>

        <AddNewAddress visible={showAddAddress} close={onCloseAddAddress} />
      </ErrorBoundary>
    </Modal>
  );
};

export default SendQuote;
