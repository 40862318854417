import React, { useEffect, useState } from 'react';
import { Alert, Avatar, Badge, Button, Col, Divider, Modal, PageHeader, Row, Space, Typography, Upload, message } from 'antd';
import { RightOutlined, EditTwoTone, InfoCircleOutlined } from '@ant-design/icons';
import ImgCrop from 'antd-img-crop';
import axios from 'axios';
import { isEmpty, startCase, debounce, find } from 'lodash';
import { format } from 'date-fns';
import { ImLocation } from 'react-icons/im';
import { FaPhoneAlt, FaRegCalendarAlt } from 'react-icons/fa';
import { MdEmail } from 'react-icons/md';
import { AiFillDelete } from 'react-icons/ai';
import { GiCash } from 'react-icons/gi';
import { IoIosArrowForward } from 'react-icons/io';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';
import { BUSINESS_URL, UPLOAD_URL } from 'constants/ApiConstants';
import DetailsGrid from 'components/UtilComponents/DetailsGrid';
import Product from 'components/Cards/Product';
import ModifyProducts from 'components/Modals/ModifyProducts';
import ChangePhoneNumber from 'components/Modals/ChangePhoneNumber';
import ChangePassword from 'components/Modals/ChangePassword';
import ChangeEmailAddress from 'components/Modals/ChangeEmailAddress';
import FooterLinks from 'components/UtilComponents/FooterLinks';
import SavedAddressess from 'components/Modals/SavedAddressess';
import VirtualAccountDetails from 'components/Modals/VirtualAccountDetails';
import ModalCloseIcon from 'components/UtilComponents/ModalCloseIcon';
import ContentLoader from 'react-content-loader';
import { clearAuth, getAuth } from 'utils/auth';
import { motion } from 'framer-motion';
import { posthog } from 'posthog-js';
import { useNavigate } from 'react-router-dom';
import { FaIndustry } from 'react-icons/fa';
import AddIndustryType from 'components/Modals/AddIndustryType';

const { Title, Text, Paragraph } = Typography;
const { confirm } = Modal;

const MyAccount = () => {
  const [showMore, setShowMore] = useState(false);
  const [business, setBusiness] = useState(null);
  const [savedAddressVisible, setSavedAddressVisible] = useState(false);
  const [changePassVisible, setChangePassVisible] = useState(false);
  const [changeEmailVisible, setChangeEmailVisible] = useState(false);
  const [changePhoneVisible, setChangePhoneVisible] = useState(false);
  const [modifyProductsType, setModifyProductsType] = useState(null);
  const [accountDetailsVisible, setAccountDetailsVisible] = useState(false);
  const [deleteAccountVisible, setDeleteAccountVisible] = useState(false);
  const [deletingLoader, setDeletingLoader] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [logoUpdate, setLogoUpdate] = useState(false);
  const navigate = useNavigate();
  const [addIndustryTypeVisible, setAddIndustryTypeVisible] = useState(false);

  const onShowMore = () => {
    setShowMore(!showMore);
  };

  const showAddIndustryType = () => {
    setAddIndustryTypeVisible(!addIndustryTypeVisible);
  };

  const showSavedAddress = () => {
    setSavedAddressVisible(!savedAddressVisible);
  };

  const showChangePass = () => {
    setChangePassVisible(!changePassVisible);
  };

  const showChangeEmail = (reload) => {
    if (reload) {
      onLoad();
    }
    setChangeEmailVisible(!changeEmailVisible);
  };

  const showChangePhone = (reload) => {
    if (reload) {
      onLoad();
    }
    setChangePhoneVisible(!changePhoneVisible);
  };

  const showModifyProducts = (type, reload = false) => {
    if (reload) {
      onLoad();
    }
    setModifyProductsType(type ? type : null);
  };

  const showAccountDetails = (reload = false) => {
    if (reload) {
      onLoad();
    }
    setAccountDetailsVisible(!accountDetailsVisible);
  };

  const showNotificationPerference = () => {
    navigate('/my-account/notification-preference');
  };

  useEffect(() => {
    onLoad();
  }, []);

  const onLoad = async () => {
    if (loading) return;
    setLoading(true);
    try {
      const res = await axios.get(`${BUSINESS_URL}/me?&populate=true`);
      if (res.data.status === 'error') {
        message.error(res.data.error);
      } else {
        setBusiness(res.data.business);
      }
    } catch (err) {
      message.error(err.message);
    }
    setLoading(false);
  };

  const onLogoChange = debounce(async (e) => {
    if (logoUpdate) return;
    setLogoUpdate(true);
    try {
      const fileList = e.fileList;
      const files = new FormData();
      if (!isEmpty(fileList)) {
        files.append('logo', fileList[0].originFileObj);
      }
      const res = await axios.post(`${BUSINESS_URL}/upload/${business?._id}`, files);
      if (res.data.status === 'error') {
        message.error(res.data.error);
      } else {
        onLoad();
        message.success('Successfully Updated Business Logo');
      }
    } catch (err) {
      message.error(err.message);
    }
    setLogoUpdate(false);
  }, 300);

  const onAccountDelete = async () => {
    setDeletingLoader(true);
    try {
      const res = await axios.delete(`${BUSINESS_URL}/${business?._id}`, {
        deleted: true,
      });
      if (res.data.status === 'error') {
        message.error(res.data.error);
      } else {
        message.success('Your account has been deleted');
        clearAuth();
        window.location = '/auth/login';
      }
    } catch (err) {
      message.error(err.message);
    }
    setDeletingLoader(false);
  };

  const showConfirm = () => {
    confirm({
      title: 'Do you want to log out?',
      icon: null,
      cancelText: 'Cancel',
      cancelButtonProps: {
        type: 'primary',
        ghost: true,
      },
      okText: 'Log Out',

      onOk() {
        clearAuth();
        window.location = '/login';
        posthog.reset(true);
      },

      onCancel() {
        console.log('Cancel');
      },
    });
  };

  if (loading || logoUpdate) {
    return (
      <div className="app-container">
        <PageHeader onBack={() => null} backIcon={null} title="Account Settings" className="py-4 page-header" />
        <ContentLoader viewBox="0 0 320 200" backgroundColor="#d9d9d9" foregroundColor="#ededed">
          <rect x="0" y="20" rx="2" ry="2" width="30" height="30" />
          <rect x="35" y="25" rx="1" ry="1" width="100" height="8" />
          <rect x="35" y="37" rx="1" ry="1" width="35" height="7" />
          <rect x="78" y="37" rx="1" ry="1" width="35" height="7" />
          <rect x="0" y="70" rx="0" ry="0" width="320" height="0.5" />
          <rect x="0" y="85" rx="1" ry="1" width="45" height="9" />
          <rect x="0" y="100" rx="4" ry="4" width="50" height="65" />
          <rect x="55" y="100" rx="4" ry="4" width="50" height="65" />
          <rect x="110" y="100" rx="4" ry="4" width="50" height="65" />
          <rect x="165" y="100" rx="4" ry="4" width="50" height="65" />
          <rect x="220" y="100" rx="4" ry="4" width="50" height="65" />
          <rect x="275" y="100" rx="4" ry="4" width="50" height="65" />
        </ContentLoader>
      </div>
    );
  }

  const userId = getAuth()?._id;
  const user = find(business?.users, (u) => String(u.user._id) === String(userId));
  return (
    <div className="app-container">
      <PageHeader onBack={() => null} backIcon={null} title="Account Settings" className="py-sm-4 page-header" />

      <Row align="middle" justify="space-between" wrap={false} className="my-5">
        <Col>
          <Row align="middle">
            <Col style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
              {['Owner', 'Administrator'].includes(user?.role) ? (
                <ImgCrop rotate showReset={true} showGrid={true} modalTitle="Update Business Logo" modalOk="Update">
                  <Upload listType="picture-circle" onChange={onLogoChange} maxCount={1} showUploadList={false} accept="image/*">
                    <Badge offset={[-10, 90]} count={<EditTwoTone style={{ fontSize: '20px' }} />} style={{ backgroundColor: '#fff', padding: '10px', borderRadius: '30px', cursor: 'pointer' }}>
                      <Avatar
                        className="shadow-sm p-1"
                        shape="square"
                        src={business?.logo ? `${UPLOAD_URL}/${business.logo}` : null}
                        size={100}
                        alt={business?.name}
                        style={{ backgroundColor: business?.logo ? '#fff' : '#1a75da' }}>
                        {business?.name ? business?.name[0] : ''}
                      </Avatar>
                    </Badge>
                  </Upload>
                </ImgCrop>
              ) : (
                <Avatar
                  className="shadow-sm p-1"
                  shape="square"
                  src={business?.logo ? `${UPLOAD_URL}/${business.logo}` : null}
                  size={100}
                  alt={business?.name}
                  style={{ backgroundColor: business?.logo ? '#fff' : '#1a75da' }}>
                  {business?.name ? business?.name[0] : ''}
                </Avatar>
              )}
            </Col>
            <Col className="ml-3">
              <Title level={5} className="fw-bold">
                {business?.name}
              </Title>
              <Paragraph type="secondary">
                <Space split={<Divider type="vertical" />}>
                  <span>{startCase(business?.businessType)}</span>
                  <span>
                    {business?.status === 'Verified' ? business?.status : 'Yet to be Verified'}{' '}
                    {business?.status === 'Verified' && <Avatar src={require('assets/images/icon-verified.png')} size={18} />}
                  </span>
                </Space>
              </Paragraph>

              {showMore && (
                <Button type="link" className="p-0" onClick={onShowMore}>
                  Less Info &nbsp;
                  <BsChevronUp />
                </Button>
              )}
              {!showMore && (
                <Button type="link" className="p-0" onClick={onShowMore}>
                  More Info &nbsp;
                  <BsChevronDown />
                </Button>
              )}
            </Col>
          </Row>
        </Col>
      </Row>

      {showMore && (
        <>
          <Divider />
          <h2>Account Information</h2>
          <Row gutter={[16, 32]} className="my-5">
            <Col xs={24} sm={12} md={8} lg={8}>
              <DetailsGrid icon={<FaRegCalendarAlt size={24} />} title="Member Since" description={business?.createdAt ? format(new Date(business?.createdAt), 'dd MMM yyyy') : 'N/A'} />
            </Col>
            <Col xs={24} sm={12} md={8} lg={8}>
              <DetailsGrid icon={<ImLocation size={24} />} title="Location" description={`${business?.address?.city}, ${business?.address?.state}`} />
            </Col>
            {business?.businessType === 'consumer' && (
              <Col xs={24} sm={12} md={8} lg={8}>
                <DetailsGrid
                  icon={<FaIndustry size={24} />}
                  title="Industry Type"
                  description={
                    business?.metadata?.industryType ? (
                      business?.metadata?.industryType
                    ) : (
                      <Button
                        type="link"
                        onClick={() => {
                          showAddIndustryType(business);
                        }}>
                        Edit
                        <RightOutlined />
                      </Button>
                    )
                  }
                />
              </Col>
            )}
            <Col xs={24} sm={12} md={8} lg={8}>
              <DetailsGrid icon={<FaPhoneAlt size={20} />} title="Phone Number" description={business?.phone} />
            </Col>
            <Col xs={24} sm={12} md={8} lg={8}>
              <DetailsGrid icon={<MdEmail size={24} />} title="Email Address" description={business?.email} />
            </Col>
            <Col xs={24} sm={12} md={8} lg={8}>
              <DetailsGrid icon={<GiCash size={24} />} title="GST Number" description={business?.gstNumber} />
            </Col>
            {['Owner'].includes(user?.role) && (
              <Col xs={24} sm={12} md={8} lg={8}>
                <DetailsGrid
                  icon={<AiFillDelete size={24} />}
                  title="Delete Account"
                  description={
                    <Button
                      style={{ marginLeft: '-16px', marginTop: '-10px' }}
                      type="link"
                      danger
                      onClick={() => {
                        setDeleteAccountVisible(true);
                      }}>
                      Delete your account?
                    </Button>
                  }
                />
              </Col>
            )}
          </Row>
        </>
      )}
      <Divider />

      {['Owner', 'Administrator', 'Purchase Manager', 'Sales Manager'].includes(user?.role) && (
        <div className="mt-5">
          <Title level={5}>My Products</Title>
          {business?.buying.length > 0 && ['Owner', 'Administrator', 'Purchase Manager'].includes(user?.role) && (
            <>
              <Row justify="space-between" className="mt-2">
                <Text strong>Buy ({business?.buying.length})</Text>
                <Button
                  type="link"
                  onClick={() => {
                    showModifyProducts('buying');
                  }}>
                  Edit
                  <RightOutlined />
                </Button>
              </Row>
              <Row wrap={false} gutter={[16, 16]} style={{ overflowX: 'auto' }} className="mt-2">
                {business?.buying.map((data) => {
                  return (
                    <Col key={data._id}>
                      <Product data={data} />
                    </Col>
                  );
                })}
              </Row>
            </>
          )}
          {business?.selling.length > 0 && ['Owner', 'Administrator', 'Sales Manager'].includes(user?.role) && (
            <>
              <Row justify="space-between" className="mt-2">
                <Text strong>Sell ({business?.selling.length})</Text>
                <Button
                  type="link"
                  onClick={() => {
                    showModifyProducts('selling');
                  }}>
                  Edit
                  <RightOutlined />
                </Button>
              </Row>
              <Row wrap={false} gutter={[16, 16]} style={{ overflowX: 'auto' }} className="mt-2">
                {business?.selling.map((data) => {
                  return (
                    <Col key={data._id}>
                      <Product data={data} />
                    </Col>
                  );
                })}
              </Row>
            </>
          )}
        </div>
      )}
      <div className="mt-5">
        <Title level={5}>Account Settings</Title>
        <Row gutter={16} className="mt-3">
          <Col xs={24} sm={12} md={12} lg={12}>
            <Button type="text" block onClick={showSavedAddress}>
              <Row wrap={false} align="middle" justify="space-between">
                <Text>Saved Addresses</Text>
                <IoIosArrowForward />
              </Row>
            </Button>
            <Divider className="my-2" />
          </Col>
          <Col xs={24} sm={12} md={12} lg={12}>
            <Button type="text" block onClick={() => showChangePhone()}>
              <Row wrap={false} align="middle" justify="space-between">
                <Text>Change Phone Number</Text>
                <IoIosArrowForward />
              </Row>
            </Button>
            <Divider className="my-2" />
          </Col>
          <Col xs={24} sm={12} md={12} lg={12}>
            <Button type="text" block onClick={showChangePass}>
              <Row wrap={false} align="middle" justify="space-between">
                <Text>Change Password</Text>
                <IoIosArrowForward />
              </Row>
            </Button>
            <Divider className="my-2" />
          </Col>
          <Col xs={24} sm={12} md={12} lg={12}>
            <Button type="text" block onClick={() => showChangeEmail()}>
              <Row wrap={false} align="middle" justify="space-between">
                <Text>Change Email Address</Text>
                <IoIosArrowForward />
              </Row>
            </Button>
            <Divider className="my-2" />
          </Col>
          <Col xs={24} sm={12} md={12} lg={12}>
            <Button type="text" block onClick={() => showNotificationPerference()}>
              <Row wrap={false} align="middle" justify="space-between">
                <Text>Notification Preferences</Text>
                <IoIosArrowForward />
              </Row>
            </Button>
            <Divider className="my-2" />
          </Col>
          <Col xs={24} sm={12} md={12} lg={12}>
            <Button type="text" block onClick={showConfirm}>
              <Row wrap={false} align="middle" justify="space-between">
                <Text>Log Out</Text>
                <IoIosArrowForward />
              </Row>
            </Button>
            <Divider className="my-2" />
          </Col>
        </Row>
      </div>
      {['Owner', 'Administrator', 'Payment Manager'].includes(user?.role) && (
        <div className="mt-5">
          <Title level={5}>Payment Settings</Title>
          <Row gutter={16} className="mt-3">
            <Col xs={24} sm={12} md={12} lg={12}>
              <Button type="text" block onClick={() => showAccountDetails()}>
                <Row wrap={false} align="middle" justify="space-between">
                  <Text>Withdrawal Account Details (for receiving)</Text>
                  <IoIosArrowForward />
                </Row>
              </Button>
            </Col>
          </Row>
        </div>
      )}

      <Divider />

      <div className="my-5">
        <Paragraph strong>Links</Paragraph>
        <FooterLinks justify="space-between" />
      </div>

      {/* Modals */}
      <Modal
        title="Are you sure you want to delete your account?"
        open={deleteAccountVisible}
        onOk={onAccountDelete}
        okText={deletingLoader ? 'Deleting..' : 'Delete'}
        closeIcon={<ModalCloseIcon />}
        okButtonProps={{ loading: deletingLoader }}
        okType="danger"
        onCancel={() => {
          setDeleteAccountVisible(false), setDeletingLoader(false), setError(null);
        }}>
        <Alert
          type="error"
          icon={<InfoCircleOutlined />}
          showIcon
          description="All your data except Ongoing / Completed Orders will be removed from our servers. You cannot use the same Email or Phone Number to signup again."
        />
        <motion.div
          initial={{ opacity: 0, marginBottom: 0 }}
          animate={{
            opacity: error ? 1 : 0,
            marginTop: error ? 20 : 0,
            marginBottom: error ? 40 : 0,
          }}>
          <Alert type={'error'} showIcon message={error}></Alert>
        </motion.div>
      </Modal>
      <AddIndustryType visible={addIndustryTypeVisible} close={showAddIndustryType} business={business} save={onLoad} />
      <SavedAddressess visible={savedAddressVisible} close={showSavedAddress} />
      <ChangePassword visible={changePassVisible} close={showChangePass} />
      <ChangeEmailAddress user={user} visible={changeEmailVisible} close={showChangeEmail} />
      <ChangePhoneNumber user={user} visible={changePhoneVisible} close={showChangePhone} />
      <ModifyProducts business={business} type={modifyProductsType} visible={modifyProductsType !== null} close={showModifyProducts} />
      <VirtualAccountDetails business={business} visible={accountDetailsVisible} close={showAccountDetails} />
    </div>
  );
};

export default MyAccount;
