import React, { useEffect, useState } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { PageNotFound } from './components/Errors';
import Login from './containers/Login';
import Signup from './containers/Signup';
import ForgotPassword from './containers/ForgotPassword';
import MainLayout from './containers/MainLayout';

import Buy from './containers/Buy/BuyDashboard';
import OpenRFQs from './containers/Buy/MyRFQs/OpenRFQs';
import DirectRFQs from './containers/Buy/MyRFQs/DirectRFQs';
import QuotesReceived from './containers/Buy/MyRFQs/QuotesReceived';
import BuyOrders from './containers/Buy/Orders/Orders';
import BuyOrderDetails from './containers/Buy/Orders/OrderDetails';
import AllListings from './containers/Buy/AllListings';
import RecommendedSellers from './containers/Buy/RecommendedSellers';
import ListingDetails from './containers/Buy/ListingDetails';
import SellerProfile from './containers/Buy/SellerProfile';
import PurchaseOrders from './containers/Buy/PurchaseOrders/PurchaseOrders';
import POSchedules from './containers/Buy/POSchedules/POSchedule';
import PurchaseOrderDetails from './containers/Buy/PurchaseOrders/PurchaseOrderDetails';
import MyOffers from './containers/Buy/MyOffers/Offers';

import Sell from './containers/Sell/SellDashboard';
import MyListings from './containers/Sell/MyListings/MyListings';
import WholesaleListingDetails from './containers/Sell/MyListings/WholesaleListingDetails';
import MyQuotes from './containers/Sell/MyQuotes/Quotes';
import SellOrders from './containers/Sell/Orders/Orders';
import SellOrderDetails from './containers/Sell/Orders/OrderDetails';
import SellDirectRFQs from './containers/Sell/DirectRFQs/RFQs';
import AllOpenRFQs from './containers/Sell/AllOpenRFQs';
import OpenRFQDetails from './containers/Sell/OpenRFQDetails';
import DispatchSchedules from './containers/Sell/DispatchSchedules/DispatchSchedules';

import Payments from './containers/Payments/Payments';
import MyAccount from './containers/MyAccount/MyAccount';
import SustainabilityReport from './containers/SustainabilityReport/SustainabilityReport';
import { clearAuth, getAuth, isLoggedIn } from './utils/auth';
import RedirectIf from './components/UtilComponents/RedirectIf';
import axios from 'axios';
import { Avatar, message } from 'antd';
import { API_VERSION, BUSINESS_URL } from './constants/ApiConstants';

import { posthog } from 'posthog-js';
import { find, isEmpty } from 'lodash';
import Notification from 'containers/MyAccount/Notification-perference/Notification';
import MyBuyDispatch from 'containers/Buy/Dispatch/MyDispatch';
import POScheduleDetails from 'containers/Buy/POSchedules/POScheduleDetails';
import MySellDispatch from 'containers/Sell/Dispatch/MyDispatch';
import MyTeam from 'containers/MyTeam/MyTeam';
import Dispatches from 'containers/Dispatch/Dispatches';

const App = () => {
  const auth = getAuth();
  const location = useLocation();
  const [loading, setLoading] = useState(auth != null ? true : false);
  const [business, setBusiness] = useState({});
  const [role, setRole] = useState(null);

  axios.defaults.headers.common['X-API-Version'] = parseInt(API_VERSION || '0', 10);
  if (auth != null) {
    axios.defaults.headers.common.Authorization = auth.token;
  }

  useEffect(() => {
    axios.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        if (error.response && 401 === error.response.status) {
          clearAuth();
          setTimeout(() => {
            window.location.href = '/login';
          }, 200);
        } else {
          return Promise.reject(error);
        }
      }
    );
  }, []);

  const onLoadBusiness = async () => {
    setLoading(true);
    try {
      const businessRes = await axios.get(`${BUSINESS_URL}/me?populate=true`);

      if (businessRes.data.status === 'success') {
        const business = businessRes.data.business;
        const userId = getAuth()?._id;
        const businessUser = find(businessRes.data.business.users, (u) => u.user._id === userId);
        const user = businessUser.user;
        const role = businessUser.role;
        setBusiness(businessRes.data.business);
        setRole(role);
        if (user && business) {
          posthog.identify(business.name, {
            id: user._id,
            name: user.name,
            email: user.email,
            phone: user.phone,
            role: user.aclGroup,
            emailVerified: isEmpty(user.verifyEmailToken),
            businessId: business._id,
            businessName: business.name,
            businessEmail: business.email,
            businessPhone: business.phone,
            businessGST: business.gstNumber,
            businessType: business.businessType,
            businessStatus: business.status,
          });
        }
      } else if (businessRes.data.status === 'error') {
        clearAuth();
      }
    } catch (err) {
      message.error(err.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (auth !== null) {
      onLoadBusiness();
    }
  }, []);

  if (loading) {
    return (
      <div className="vh-100 d-flex flex-column align-items-center justify-content-center">
        <Avatar src={require('./assets/images/buyofuel-logo.png')} style={{ width: 250, height: 60 }} shape="square" />
      </div>
    );
  }

  const ProtectedRoute = ({ state }) => {
    const locatePath = state?.pathname;
    if (locatePath) {
      localStorage.setItem('copiedLink', locatePath);
    }
    return <Navigate to="/login" />;
  };

  const getDefaultRoute = () => {
    if (role === 'Purchase Manager') {
      return 'buy';
    }
    if (role === 'Sales Manager') {
      return 'sell';
    }
    if (role === 'Payment Manager') {
      return 'payments';
    }
    if (role === 'Sustainability Manager') {
      return 'sustainability-report';
    }
    return business?.businessType === 'seller' ? 'sell' : 'buy';
  };

  return (
    <div className="app h-100">
      <Routes>
        {/* ~~~~~ Authentication Routes ~~~~~ */}
        <Route path="/login" element={<RedirectIf condition={isLoggedIn() && typeof business?.businessType !== 'undefined'} ifComponent={<Navigate to="/" />} elseComponent={<Login />} />} />
        <Route path="/signup" element={<RedirectIf condition={isLoggedIn() && typeof business?.businessType !== 'undefined'} ifComponent={<Navigate to="/" />} elseComponent={<Signup />} />} />
        <Route path="/forgot-password" element={<RedirectIf condition={isLoggedIn()} ifComponent={<Navigate to="/" />} elseComponent={<ForgotPassword />} />} />

        {/* ~~~~~ App Routes ~~~~~ */}
        <Route
          path="/"
          element={
            <RedirectIf
              condition={isLoggedIn() && typeof business?.businessType !== 'undefined'}
              ifComponent={<MainLayout business={business} />}
              elseComponent={<ProtectedRoute state={location} />}
            />
          }>
          {isLoggedIn() && typeof business?.businessType !== 'undefined' && <Route index element={<Navigate to={getDefaultRoute()} replace />} />}

          {/* ~~~~~ Buy Routes ~~~~~ */}
          {/** Hide the buy screens when the business type is seller **/}
          {business.businessType !== 'seller' && ['Owner', 'Administrator', 'Purchase Manager'].includes(role) && (
            <Route path="buy">
              <Route index element={business?.businessType === 'seller' ? <Navigate to="/sell" replace /> : <Buy />} />
              <Route path="orders" element={<Navigate to="ongoing-orders" />} />
              <Route path="orders/:tab" element={<BuyOrders />} />
              <Route path="orders/:tab/:id" element={<BuyOrderDetails />} />
              <Route path="offers" element={<Navigate to="pending-offers" />} />
              <Route path="offers/:tab" element={<MyOffers />} />

              <Route path="purchaseorders" element={<Navigate to="created-orders" />} />
              <Route path="purchaseorders/:tab" element={<PurchaseOrders />} />
              <Route path="purchaseorders/:tab/:purchaseOrderId" element={<PurchaseOrderDetails />} />
              <Route path="purchaseorders/dispatch/:purchaseOrderId" element={<MyBuyDispatch />} />

              <Route path="po-schedules" element={<Navigate to="created-orders" />} />
              <Route path="po-schedules/:tab" element={<POSchedules />} />
              <Route path="po-schedules/:tab/:poScheduleId" element={<POScheduleDetails />} />

              <Route path="rfqs" element={<Navigate to="open-rfq" />} />
              <Route path="rfqs/open-rfqs" element={<OpenRFQs />} />
              <Route path="rfqs/direct-rfqs" element={<DirectRFQs />} />
              <Route path="rfqs/open-rfq/:id" element={<QuotesReceived />} />

              <Route path="listings" element={<AllListings />} />
              <Route path="listing/:id" element={<ListingDetails />} />
              <Route path="sellers" element={<RecommendedSellers />} />
              <Route path="seller-profile/:id" element={<SellerProfile />} />
              <Route path="*" element={<PageNotFound />} />
            </Route>
          )}

          {/* ~~~~~ Sell Routes ~~~~~ */}
          {/** Hide the sell screens when the business type is consumer **/}
          {business.businessType !== 'consumer' && ['Owner', 'Administrator', 'Sales Manager'].includes(role) && (
            <Route path="sell">
              <Route index element={business?.businessType === 'consumer' ? <Navigate to="/buy" replace /> : <Sell />} />
              <Route path="my-listings" element={<Navigate to="wholesale" />} />
              <Route path="my-listings/:tab" element={<MyListings />} />
              <Route path="my-listings/:tab/:id" element={<WholesaleListingDetails />} />

              <Route path="direct-rfqs" element={<SellDirectRFQs />} />

              <Route path="quotes" element={<Navigate to="pending-quotes" />} />
              <Route path="quotes/:tab" element={<MyQuotes />} />

              <Route path="orders" element={<Navigate to="received-orders" />} />
              <Route path="orders/:tab" element={<SellOrders />} />
              <Route path="orders/:tab/:id" element={<SellOrderDetails />} />
              <Route path="orders/dispatch/:orderId" element={<MySellDispatch />} />

              <Route path="dispatch-schedules" element={<Navigate to="active" />} />
              <Route path="dispatch-schedules/:tab" element={<DispatchSchedules />} />

              <Route path="open-rfqs" element={<AllOpenRFQs />} />
              <Route path="open-rfqs/*" element={<Navigate to="" />} />
              <Route path="open-rfq/:id" element={<OpenRFQDetails />} />
              <Route path="open-rfq/:id/*" element={<Navigate to="open-rfqs" />} />
              <Route path="*" element={<PageNotFound />} />
            </Route>
          )}

          {/* ~~~~~ Payments Routes ~~~~~ */}
          {['Owner', 'Administrator', 'Payment Manager'].includes(role) && (
            <Route path="payments">
              <Route index element={<Navigate to="upcoming-payments" />} />
              <Route path=":tab" element={<Payments />} />
            </Route>
          )}

          {/* ~~~~ Dispatches Routes ~~~~ */}
          {['Owner', 'Administrator', 'Purchase Manager', 'Sales Manager'].includes(role) && (
            <Route path="dispatches">
              <Route index element={<Navigate to="ongoing-dispatches" />} />
              <Route path=":tab" element={<Dispatches />} />
            </Route>
          )}

          {/* ~~~~~ My Account Routes ~~~~~ */}
          <Route path="my-account">
            <Route index element={<MyAccount />} />
            <Route path="notification-preference" element={<Notification />} />
          </Route>

          {/* ~~~~~ My Team Routes ~~~~~ */}
          {['Owner', 'Administrator'].includes(role) && (
            <Route path="my-team">
              <Route index element={<MyTeam />} />
            </Route>
          )}

          {/* ~~~~~ Sustainability Report Routes ~~~~~ */}
          {['Owner', 'Administrator', 'Sustainability Manager'].includes(role) && <Route path="sustainability-report" element={<SustainabilityReport />} />}
          <Route path="*" element={<PageNotFound />} />
        </Route>

        {/* ~~~~~ Error Pages ~~~~~ */}
        <Route path="*" element={<PageNotFound />} />

        {/* Temporary Routes */}
        <Route path="/auth/*" element={<Navigate to="/login" />} />
        <Route path="/app/*" element={<Navigate to="/" />} />
      </Routes>
    </div>
  );
};

export default App;
