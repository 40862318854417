import React, { useState } from 'react';
import { Button, Divider, Form, Input, Modal, message } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import ErrorBoundary from 'components/Errors/ErrorBoundary';
import ModalCloseIcon from 'components/UtilComponents/ModalCloseIcon';
import axios from 'axios';
import { USER_URL } from 'constants/ApiConstants';

const ChangePassword = ({ visible, close }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const onSubmit = async (values) => {
    if (loading) return;
    setLoading(true);
    try {
      const res = await axios.put(`${USER_URL}/password/change`, {
        current: values.currentPass,
        new: values.newPass,
        confirm: values.confirmPass,
      });

      if (res.data.status === 'success') {
        form.resetFields();
        message.success('Password changed successfully');
        close();
      } else {
        message.error(res.data.error);
      }
    } catch (err) {
      message.error(err.message);
    }
    setLoading(false);
  };

  const confirmOnClose = () => {
    if (!loading && form.isFieldsTouched()) {
      Modal.confirm({
        title: 'Unsaved Data',
        icon: <ExclamationCircleOutlined />,
        content: 'This form has unsaved data. Are you sure you want to close the screen?',
        okText: 'Yes',
        onOk: () => {
          form.resetFields();
          close();
        },
        cancelText: 'No',
      });
    } else {
      close();
    }
  };

  return (
    <Modal destroyOnClose={true} title="Change Password" open={visible} onCancel={confirmOnClose} footer={null} closeIcon={<ModalCloseIcon />}>
      <ErrorBoundary>
        <Form form={form} layout="vertical" onFinish={onSubmit}>
          <Form.Item
            className="bg-transparent"
            name="currentPass"
            rules={[
              {
                required: true,
                message: 'Please enter your current password',
              },
            ]}
            hasFeedback>
            <Input.Password size="large" placeholder="Current Password" />
          </Form.Item>

          <Form.Item
            className="bg-transparent"
            name="newPass"
            dependencies={['currentPass']}
            rules={[
              {
                required: true,
                message: 'Please enter a new password',
              },
              {
                message: 'Please enter a strong password with atleast 8 characters, one number and one special character.',
                pattern: new RegExp('^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})'),
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (value && getFieldValue('currentPass') === value) {
                    return Promise.reject(new Error('Your new password cannot be the same as your current password'));
                  }
                  return Promise.resolve();
                },
              }),
            ]}
            hasFeedback>
            <Input.Password size="large" placeholder="New Password" />
          </Form.Item>

          <Form.Item
            className="bg-transparent"
            name="confirmPass"
            dependencies={['newPass']}
            rules={[
              {
                required: true,
                message: 'Please re-enter your new password',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('newPass') === value) {
                    return Promise.resolve();
                  }

                  return Promise.reject(new Error('The new passwords that you entered do not match!'));
                },
              }),
            ]}
            hasFeedback>
            <Input.Password size="large" placeholder="Re-enter New Password" />
          </Form.Item>

          <Divider className="mt-5" />

          <Form.Item shouldUpdate className="mt-4">
            {() => (
              <Button size="large" type="primary" block htmlType="submit">
                Change Password
              </Button>
            )}
          </Form.Item>
        </Form>
      </ErrorBoundary>
    </Modal>
  );
};

export default ChangePassword;
