import React, { useState } from 'react';
import { Divider, PageHeader, Tabs, Switch } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import UpcomingPayments from './UpcomingPayments';
import CompletedPayments from './CompletedPayments';

const Payments = () => {
  const { tab } = useParams();
  const navigate = useNavigate();
  const [showLedger, setShowLedger] = useState(JSON.parse(localStorage.getItem('BuyofuelWebInsights')) || false);

  const tabItems = [
    // remember to pass the key prop
    {
      label: 'Upcoming',
      key: 'upcoming-payments',
      children: <UpcomingPayments tab={tab} showLedger={showLedger} />,
    },
    {
      label: 'Completed',
      key: 'completed-payments',
      children: <CompletedPayments tab={tab} showLedger={showLedger} />,
    },
  ];

  const onToggleInsights = (status) => {
    localStorage.setItem('BuyofuelWebInsights', JSON.stringify(status));
    setShowLedger(status);
  };

  return (
    <div className="app-container">
      <PageHeader
        onBack={() => null}
        backIcon={null}
        title={
          <div className=".d-inline" style={{ marginLeft: '10 px' }}>
            Payments
            <Switch
              checkedChildren="Ledger View"
              unCheckedChildren="Card View"
              onChange={onToggleInsights}
              style={{
                marginLeft: '10px',
                border: '0',
                backgroundImage: 'linear-gradient(to right, #3db7f6, #033d7a)',
                color: 'white',
              }}
              defaultChecked={showLedger}
            />
          </div>
        }
        className="py-sm-4 page-header"
      />

      <Tabs className="my-4" type="line" items={tabItems} defaultActiveKey={tab} onChange={(key) => navigate(`/payments/${key}`)} />

      <Divider />
    </div>
  );
};

export default Payments;
