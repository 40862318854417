import React from 'react';
import { PageHeader, Tabs } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import WholesaleListings from './WholesaleListings';
import BackIcon from 'components/UtilComponents/BackIcon';

const MyListings = () => {
  const { tab } = useParams();
  const navigate = useNavigate();

  const tabItems = [
    // remember to pass the key prop
    {
      label: 'Wholesale',
      key: 'wholesale',
      children: <WholesaleListings />,
    },
    /* {
      label: "Retail",
      key: "retail",
      children: <RetailListings />, // must be imported before using
    }, */
  ];

  return (
    <div className="app-container">
      <PageHeader onBack={() => navigate('/sell')} backIcon={<BackIcon />} title="My Listings" className="px-0" />

      <Tabs type="line" items={tabItems} defaultActiveKey={tab} onChange={(key) => navigate(`/sell/my-listings/${key}`)} />
    </div>
  );
};

export default MyListings;
