import React, { useEffect, useState } from 'react';
import { Alert, Button, Calendar, Form, Modal, message } from 'antd';
import axios from 'axios';
import moment from 'moment';
import { addDays, startOfDay } from 'date-fns';
import ModalCloseIcon from 'components/UtilComponents/ModalCloseIcon';
import { LISTING_URL } from 'constants/ApiConstants';

const ExtendListingExpiration = ({ visible, close, listing }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (listing && new Date() < new Date(listing?.preferredDeliveryOn)) {
      form.setFields([{ name: 'preferredDeliveryOn', value: moment(listing?.preferredDeliveryOn) }]);
    }
  }, [listing]);

  const onRepost = async (values) => {
    if (loading) return;
    setLoading(true);
    try {
      const res = await axios.put(`${LISTING_URL}/${listing._id}`, {
        metadata: {
          expiresOn: addDays(new Date(), 7),
        },
        preferredDeliveryOn: values.preferredDeliveryOn,
        status: 'Created',
      });

      if (res.data.status === 'error') {
        message.error(res.data.error);
      } else {
        message.success('Expiry Extended Successfully');
        close(true);
      }
    } catch (err) {
      message.error(err.message);
    }
    setLoading(false);
  };

  return (
    <div>
      <Modal
        title={`${listing?.status === 'Expired' ? 'Repost Listing' : 'Extend Expiry'} for ${listing?.product?.name} | ${listing?.qty} ${listing?.subUnit}`}
        footer={null}
        open={visible}
        onCancel={() => {
          close();
          form.resetFields();
        }}
        closeIcon={<ModalCloseIcon />}
        bodyStyle={{ height: '75vh', overflow: 'scroll' }}
        centered>
        <Form form={form} layout="vertical" requiredMark={false} onFinish={onRepost}>
          <Form.Item
            label={listing?.frequency === 'Once' ? 'Change Expected Delivery Date' : 'Change Start Delivery By'}
            name="preferredDeliveryOn"
            className="bg-transparent"
            rules={[
              {
                required: true,
                message: `Please select ${listing?.frequency === 'Once' ? 'an expected delivery date' : 'start delivery by'}`,
              },
            ]}>
            <Calendar validRange={[moment(startOfDay(new Date())), moment().add(1, 'years')]} fullscreen={false} />
          </Form.Item>
          <Alert type="info" showIcon message="Expiry date will be updated to 7 days from now" />
          <Form.Item className="mt-2">
            <Button type="primary" size="large" block htmlType="submit" loading={loading}>
              Update Delivery Date
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default ExtendListingExpiration;
