import React from 'react';
import { PageHeader, Tabs } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import ReceivedOrders from './ReceivedOrders';
import OngoingOrders from './OngoingOrders';
import CompletedOrders from './CompletedOrders';
import DroppedOrders from './DroppedOrders';
import BackIcon from 'components/UtilComponents/BackIcon';
import ErrorBoundary from 'components/Errors/ErrorBoundary';

const Orders = () => {
  const { tab } = useParams();
  const navigate = useNavigate();

  const tabItems = [
    // remember to pass the key prop
    {
      label: 'Received',
      key: 'received-orders',
      children: <ReceivedOrders tab={tab} />,
    },
    {
      label: 'Active',
      key: 'active-orders',
      children: <OngoingOrders tab={tab} />,
    },
    {
      label: 'Completed',
      key: 'completed-orders',
      children: <CompletedOrders tab={tab} />,
    },
    {
      label: 'Cancelled',
      key: 'cancelled-orders',
      children: <DroppedOrders tab={tab} />,
    },
  ];

  return (
    <div className="app-container">
      <PageHeader onBack={() => navigate('/sell')} backIcon={<BackIcon />} title="Orders" className="px-0" />

      <ErrorBoundary>
        <Tabs type="line" items={tabItems} defaultActiveKey={tab} onChange={(key) => navigate(`/sell/orders/${key}`)} />
      </ErrorBoundary>
    </div>
  );
};

export default Orders;
