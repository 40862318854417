import React, { useState } from 'react';
import { Avatar, Col, List, Row, Tag, Typography } from 'antd';
import { BsChatFill, BsLightningChargeFill, BsCheck2All } from 'react-icons/bs';
import CompanyName from 'components/UtilComponents/CompanyName';
import DirectRFQsChat from './DirectRFQsChat';

const { Paragraph } = Typography;

const DirectRFQsCard = ({ data, currentChat, onClick }) => {
  const getIcon = ({ stage, type }) => {
    if (type === 'Direct') {
      if (stage === 'RFQ') return <BsCheck2All />;
      if (stage === 'Quote') return <BsChatFill />;
      if (stage === 'Offer') return <BsCheck2All />;
      if (stage === 'Order') return <BsCheck2All />;
    } else if (type === 'Express') {
      return <BsLightningChargeFill />;
    }
  };

  const getBackgroundColor = ({ stage, type }) => {
    if (type === 'Direct') {
      if (stage === 'RFQ') return '#F5EBAF';
      if (stage === 'Quote') return '#B5EFDC';
      if (stage === 'Offer') return '#F5EBAF';
      if (stage === 'Order') return '#E4EDF7';
    } else if (type === 'Express') {
      return '#E4EDF7';
    }
  };

  const getColor = ({ stage, type }) => {
    if (type === 'Direct') {
      if (stage === 'RFQ') return '#B38C00';
      if (stage === 'Quote') return '#0A8A85';
      if (stage === 'Offer') return '#B38C00';
      if (stage === 'Order') return '#1A75DA';
    } else if (type === 'Express') {
      return '#1A75DA';
    }
  };

  return (
    <List.Item
      onClick={() => onClick(data)}
      className="m-0 p-3 cursor-pointer"
      style={{
        borderTopLeftRadius: 10,
        borderBottomLeftRadius: 10,
        backgroundColor: currentChat === data ? '#eeeeee' : 'transparent',
      }}>
      <div className="mb-2">
        <Text>Biodiesel B100</Text>&nbsp;
        <Tag
          className="rounded pt-1"
          color={getBackgroundColor({ type: 'Direct', stage: 'RFQ' })}
          style={{ color: getColor({ type: 'Direct', stage: 'RFQ' }) }}
          icon={getIcon({ type: 'Direct', stage: 'RFQ' })}>
          &nbsp;RFQ
        </Tag>
        <Paragraph>8 kL</Paragraph>
      </div>
      <List.Item.Meta avatar={<Avatar size={48} src={require('assets/images/dummy-company.png')} />} title={<CompanyName name={'Raw Carbon Inc.'} verified />} description={'Erode, Tamil Nadu'} />
      {/* <div className="d-flex justify-content-end">{format(new Date(), "dd-MMM-yyyy")}</div> */}
    </List.Item>
  );
};

const DirectRFQs = () => {
  const [currentChat, setCurrentChat] = useState(1);

  return (
    <div>
      <Row>
        <Col span={8} className="chatlist-scrollbar border-right" style={{ height: 600, overflowY: 'auto' }}>
          <List
            itemLayout="horizontal"
            header={null}
            footer={null}
            grid={{ column: 1 }}
            dataSource={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
            loading={false}
            renderItem={(item, index) => <DirectRFQsCard key={index} data={item} currentChat={currentChat} onClick={() => setCurrentChat(item)} />}
          />
        </Col>
        <Col span={16}>
          <DirectRFQsChat />
        </Col>
      </Row>
    </div>
  );
};

export default DirectRFQs;
