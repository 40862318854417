import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Col, Divider, Drawer, List, PageHeader, Radio, Row, Space, Tag, Typography, message, Input } from 'antd';
import { useNavigate } from 'react-router-dom';
import BackIcon from 'components/UtilComponents/BackIcon';
import ListingCard from 'components/Cards/ListingCard';
import axios from 'axios';
import { BUSINESS_URL, LISTING_URL } from 'constants/ApiConstants';
import { CloseCircleOutlined, FilterOutlined, SearchOutlined } from '@ant-design/icons';
import { isEmpty, map } from 'lodash';
import ContentLoader from 'react-content-loader';
import CreateNewOpenRFQ from '../../components/Modals/Buy/CreateNewOpenRFQ';
import { getBreakPoint } from 'utils/layout';
import ModalCloseIcon from 'components/UtilComponents/ModalCloseIcon';
import ErrorBoundary from 'components/Errors/ErrorBoundary';
import { posthog } from 'posthog-js';

const { CheckableTag } = Tag;
const { Text, Paragraph } = Typography;

const AllListings = () => {
  const viewName = 'buy';
  const isMobile = !getBreakPoint().includes('lg');
  const navigate = useNavigate();
  const [business, setBusiness] = useState(null);
  const [listings, setListings] = useState([]);
  const [count, setCount] = useState(0);
  const [showOpenRFQ, setShowOpenRFQ] = useState(false);
  const [search, setSearch] = useState('');
  const [searchText, setSearchText] = useState('');
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 18,
    total: 0,
    size: 'middle',
    showSizeChanger: false,
    className: isMobile && 'custom-pagination',
  });

  useEffect(() => {
    setPagination({
      ...pagination,
      className: isMobile && 'custom-pagination',
    });
  }, [isMobile]);

  const [filters, setFilters] = useState({
    products: [],
    frequency: [],
    pickupAddress: null,
  });
  const [sort, setSort] = useState({
    qty: null,
    createdAt: 'desc',
  });
  const [loading, setLoading] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [filtersLoading, setFiltersLoading] = useState(false);

  useEffect(() => {
    if (business) {
      onLoad();
    }
  }, [business, filters, sort, search, pagination?.current]);

  useEffect(() => {
    posthog.capture(`$${viewName} | $all-listings`, {
      $set: {
        lastActivityDate: new Date().toISOString(),
      },
    });
    onLoadFilters();
  }, []);

  const onLoad = async () => {
    if (loading) return;
    setLoading(true);
    try {
      const xApiFilters = {
        status: ['Created'],
        seller: ['not-me'],
      };
      const xApiSort = {
        createdAt: 'desc',
      };
      if (!isEmpty(search)) {
        xApiFilters.search = [search];
      }

      if (filters.products.length > 0) {
        xApiFilters.product = filters.products;
      } else if (business && business?.buying?.length > 0) {
        xApiFilters.product = map(business?.buying, '_id');
      }
      if (filters.frequency.length > 0) xApiFilters.frequency = filters.frequency;
      if (filters.pickupAddress) xApiFilters.pickupAddress = [filters.pickupAddress];

      if (sort.qty) xApiSort.qty = sort.qty;
      if (sort.createdAt) xApiSort.createdAt = sort.createdAt;

      const res = await axios.get(`${LISTING_URL}?page=${pagination.current}&limit=${pagination.pageSize}&populate=true`, {
        headers: {
          'X-API-Filters': JSON.stringify(xApiFilters),
          'X-API-Sort': JSON.stringify(xApiSort),
        },
      });

      if (res.data.status === 'error') {
        message.error(res.data.error);
      } else {
        setListings(res.data.openlistings);
        setPagination({ ...pagination, total: res.data.count });
        setCount(res.data.count);
      }
      localStorage.removeItem('filters');
    } catch (err) {
      message.error(err.message);
    }
    setLoading(false);
  };

  const onLoadFilters = async () => {
    if (filtersLoading) return;
    setFiltersLoading(true);
    try {
      const res = await axios.get(`${BUSINESS_URL}/me?&populate=true`);
      if (res.data.status === 'error') {
        message.error(res.data.error);
      } else {
        setBusiness(res.data.business);
        const data = JSON.parse(localStorage.getItem('filters'));
        if (!isEmpty(data)) {
          setPagination(data?.pagination);
          setFilters(data?.filters);
          setSort(data?.sort);
          setSearch(data?.search);
        } else {
          setFilters({ ...filters, pickupAddress: res.data.business?.address?.state });
        }
      }
    } catch (err) {
      message.error(err.message);
    }
    setFiltersLoading(false);
  };

  const onProximityChange = (checkedValues) => {
    setFilters({ ...filters, pickupAddress: checkedValues.length > 0 ? checkedValues[0] : null });
  };

  const onShowFilter = () => {
    setShowFilter(!showFilter);
  };

  const onProductsChange = (product, checked) => {
    const { products } = filters;
    const updatedProducts = checked ? [...products, product] : products.filter((t) => t !== product);
    setFilters({ ...filters, products: updatedProducts });
  };

  const onProductsClear = () => setFilters({ ...filters, products: [] });

  const onFrequencyChange = (checkedValues) => {
    setFilters({ ...filters, frequency: checkedValues });
  };

  const onSortByQuantity = (e) => {
    setSort({
      ...sort,
      qty: e && e?.target?.value ? e.target.value : null,
    });
  };

  const onSortByDate = (e) => {
    setSort({
      ...sort,
      createdAt: e && e?.target?.value ? e.target.value : null,
    });
  };

  const onCreateNewOpenRFQ = (reload) => {
    if (reload) {
      onLoad();
    }
    setShowOpenRFQ(!showOpenRFQ);
  };

  const FilterComponent = () => {
    return (
      <>
        <>
          <Paragraph type="secondary" className="fw-medium">
            FILTERING OPTIONS
          </Paragraph>

          <div className="custom-tag tag-container my-3">
            <Paragraph type="secondary">Products to show</Paragraph>
            {filtersLoading && (
              <ContentLoader width="100%" height="80" backgroundColor="#d9d9d9" foregroundColor="#ededed">
                <rect x="0" y="0" rx="12" ry="12" width="30%" height="20" />
                <rect x="35%" y="0" rx="12" ry="12" width="45%" height="20" />
                <rect x="0%" y="30" rx="12" ry="12" width="40%" height="20" />
                <rect x="45%" y="30" rx="12" ry="12" width="50%" height="20" />
                <rect x="0%" y="60" rx="12" ry="12" width="50%" height="20" />
              </ContentLoader>
            )}
            {!filtersLoading &&
              business?.buying?.map((product) => (
                <CheckableTag className="mt-2" key={product._id} checked={filters?.products.indexOf(product._id) > -1} onChange={(checked) => onProductsChange(product._id, checked)}>
                  {product.name}
                </CheckableTag>
              ))}
            {filters.products.length > 0 && (
              <Button type="link" size="small" onClick={onProductsClear}>
                <CloseCircleOutlined /> Clear
              </Button>
            )}
          </div>

          <div className="my-3">
            <Paragraph type="secondary">Frequency</Paragraph>
            <Checkbox.Group
              value={filters.frequency}
              className="mt-2"
              options={[
                { label: 'Once', value: 'Once' },
                { label: 'Monthly', value: 'Monthly' },
              ]}
              onChange={onFrequencyChange}
            />
          </div>

          <div className="my-3">
            <Paragraph type="secondary">Proximity</Paragraph>
            <Checkbox.Group className="w-100 mt-2" onChange={onProximityChange} value={filters.pickupAddress}>
              <Row>
                <Col span={24}>
                  <Checkbox value={business?.address?.state}>Only show listings within my state</Checkbox>
                </Col>
              </Row>
            </Checkbox.Group>
          </div>
        </>

        <Divider />
        <>
          <Paragraph type="secondary" className="fw-medium">
            SORTING OPTIONS
          </Paragraph>

          <div className="my-4">
            <Paragraph type="secondary">Quantity</Paragraph>
            <Radio.Group className="mt-2" value={sort.qty} onChange={onSortByQuantity}>
              <Space direction="vertical" size="small">
                <Radio value="asc">Quantity - Low to High</Radio>
                <Radio value="desc">Quantity - High to Low</Radio>
                {sort.qty && (
                  <Button type="link" size="small" onClick={() => onSortByQuantity(null)}>
                    <CloseCircleOutlined /> Clear Sort
                  </Button>
                )}
              </Space>
            </Radio.Group>
          </div>

          <div className="my-4">
            <Paragraph type="secondary">Posted On</Paragraph>
            <Radio.Group className="mt-2" value={sort.createdAt} onChange={onSortByDate}>
              <Space direction="vertical" size="small">
                <Radio value="desc">Date - Newest</Radio>
                <Radio value="asc">Date - Oldest</Radio>
                {sort.createdAt && (
                  <Button type="link" size="small" onClick={() => onSortByDate(null)}>
                    <CloseCircleOutlined /> Clear Sort
                  </Button>
                )}
              </Space>
            </Radio.Group>
          </div>
        </>
      </>
    );
  };

  return (
    <ErrorBoundary>
      <PageHeader
        onBack={() => navigate(-1)}
        backIcon={<BackIcon />}
        title="Listings"
        extra={
          isMobile && (
            <Button type="primary" ghost size="middle" icon={<FilterOutlined />} onClick={onShowFilter}>
              Filters
            </Button>
          )
        }
      />

      <Divider className="m-0" />

      <Row>
        <Col xs={24} sm={24} md={24} lg={16} className="p-4">
          {(loading || filtersLoading) && (
            <ContentLoader width="100%" height="100vh" backgroundColor="#d9d9d9" foregroundColor="#ededed">
              <rect x="0" y="0" rx="5" ry="5" width="25%" height="30" />
              <rect x="0" y="40" rx="10" ry="10" width="30%" height="320" />
              <rect x="33%" y="40" rx="10" ry="10" width="30%" height="320" />
              <rect x="66%" y="40" rx="10" ry="10" width="33%" height="320" />
              <rect x="0%" y="380" rx="10" ry="10" width="30%" height="320" />
              <rect x="33%" y="380" rx="10" ry="10" width="30%" height="320" />
              <rect x="66%" y="380" rx="10" ry="10" width="33%" height="320" />
            </ContentLoader>
          )}
          {!loading && !filtersLoading && (
            <List
              header={
                <Row align="middle" justify="space-between" gutter={[12, 12]}>
                  <Col xs={{ order: 2 }} sm={{ order: 1 }}>
                    {count > 0 && (
                      <Text>
                        {count} {search ? `Results for "${search}"` : `results in total`}
                      </Text>
                    )}
                  </Col>
                  <Col xs={{ order: 1, span: 24 }} sm={{ order: 2, span: 8 }}>
                    <Input
                      prefix={<SearchOutlined />}
                      type="text"
                      size="large"
                      allowClear
                      placeholder="Search..."
                      value={searchText}
                      onChange={(e) => {
                        setSearchText(e.target.value);
                        if (e.target.value === '') {
                          setSearch('');
                        }
                      }}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          setSearch(searchText);
                        }
                      }}
                    />
                  </Col>
                </Row>
              }
              grid={{ gutter: [24, 12], column: isMobile ? 1 : 3 }}
              dataSource={listings}
              pagination={{ ...pagination, onChange: (page) => setPagination({ ...pagination, current: page }) }}
              renderItem={(listing) => (
                <List.Item>
                  <ListingCard listing={listing} filters={filters} sort={sort} search={search} pagination={pagination} />
                </List.Item>
              )}
            />
          )}
        </Col>

        {!isMobile && (
          <>
            <Col xs={0} sm={0} md={0} lg={8} flex={1} className="p-4">
              {FilterComponent()}
            </Col>
          </>
        )}
        <Drawer title="Filter & Sort" placement="bottom" open={showFilter} height={'60vh'} onClose={onShowFilter} closeIcon={<ModalCloseIcon />}>
          {FilterComponent()}
        </Drawer>
      </Row>
      <CreateNewOpenRFQ visible={showOpenRFQ} close={onCreateNewOpenRFQ} />
    </ErrorBoundary>
  );
};

export default AllListings;
