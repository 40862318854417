import React, { useEffect, useState } from 'react';
import { Avatar, Button, Calendar, Checkbox, Col, Divider, Form, Input, InputNumber, Modal, Radio, Row, Select, Tag, Typography, message, Card, Empty } from 'antd';
import { CheckOutlined, ExclamationCircleOutlined, InfoCircleOutlined, LeftOutlined, PlusOutlined } from '@ant-design/icons';
import ErrorBoundary from 'components/Errors/ErrorBoundary';
import BackIcon from 'components/UtilComponents/BackIcon';
import DetailsGrid from 'components/UtilComponents/DetailsGrid';
import NumberText from 'components/UtilComponents/NumberText';
import ModalCloseIcon from 'components/UtilComponents/ModalCloseIcon';
import AddNewAddress from 'components/UtilComponents/AddNewAddress';
import moment from 'moment';
import axios from 'axios';
import { keyBy, uniqueId } from 'lodash';
import { format, startOfDay } from 'date-fns';
import { validateQuantity } from 'containers/validation';
import { LISTING_OFFER_URL, PAYMENT_TERMS_URL, SAVED_ADDRESS_URL } from 'constants/ApiConstants';
import WarningIcon from 'assets/images/icon-error.png';
import { posthog } from 'posthog-js';
import { checkForAnomaly } from 'utils/anomaly';

const { Paragraph, Text } = Typography;

const CreateOrder = ({ listingOffers, listing, visible, close }) => {
  const viewName = 'buy | listing-details';
  const rejectedCount = listingOffers.reduce((count, offer) => {
    return offer.status === 'Created' || offer.status === 'Accepted' ? count + 1 : count;
  }, 0);
  const [form] = Form.useForm();
  const [uid, setUid] = useState(null);
  const [step, setStep] = useState(rejectedCount > 0 ? 'warning-page' : 'collect-details');
  const [loading, setLoading] = useState(false);
  const [savedAddresses, setSavedAddresses] = useState([]);
  const [savedAddressesById, setSavedAddressesById] = useState([]);
  const [paymentTerms, setPaymentTerms] = useState([]);
  const [paymentTermsById, setPaymentTermsById] = useState(null);
  const [deliveryAddress, setDeliveryAddress] = useState(null);
  const [showAddAddress, setShowAddAddress] = useState(false);

  useEffect(() => {
    setUid(uniqueId('dd_'));
    onLoad();
  }, []);

  useEffect(() => {
    if (step) {
      const drawerBody = document.querySelector(`.${uid} .ant-modal-body`);
      if (drawerBody) {
        drawerBody.scrollTop = 0;
      }
    }
  }, [step, visible]);

  const onLoad = async () => {
    setLoading(true);
    try {
      const addressRes = await axios.get(`${SAVED_ADDRESS_URL}`, {
        headers: {
          'X-API-Filters': JSON.stringify({
            business: ['me'],
            status: ['Active'],
          }),
        },
      });

      const termRes = await axios.get(`${PAYMENT_TERMS_URL}`);

      if (addressRes.data.status === 'error') {
        message.error(addressRes.data.error);
      } else if (termRes.data.status === 'error') {
        message.error(termRes.data.error);
      } else {
        setSavedAddresses(addressRes.data.savedaddresses);
        setPaymentTerms(termRes.data.paymentterms);
        if (addressRes.data.savedaddresses.length > 0) {
          form.setFields([{ name: 'deliveryAddressId', value: addressRes.data.savedaddresses[0]?._id }]);
          setDeliveryAddress(addressRes.data.savedaddresses[0]?._id);
          setSavedAddressesById(keyBy(addressRes.data.savedaddresses, '_id'));
        }
        if (termRes.data.paymentterms.length > 0) {
          form.setFields([{ name: 'paymentTerm', value: termRes?.data?.paymentterms[0]?._id }]);
          setPaymentTermsById(keyBy(termRes.data.paymentterms, '_id'));
        }
      }
    } catch (err) {
      message.error(err.message);
    }
    setLoading(false);
  };

  const onClickPrev = () => {
    if (step === 'review-details') return setStep('collect-details');
  };

  const onClickNext = () => {
    if (step === 'warning-page') return setStep('collect-details');
    if (step === 'collect-details') return setStep('review-details');
    if (step === 'review-details') return onSubmit();
  };

  const getTitle = () => {
    if (step === 'warning-page') return 'Confirm Creating Order';
    else if (step === 'collect-details') return 'Create Order';
    return (
      <>
        <BackIcon onClick={onClickPrev} className="mr-3" />
        {' Review Order'}
      </>
    );
  };

  const onSubmit = async () => {
    if (loading) return;
    setLoading(true);
    try {
      const values = form.getFieldsValue(true);
      const anomalyForPrice = checkForAnomaly('open-listing-offer', { product: listing?.product, subUnits: listing?.unit?.subUnits, subUnit: listing?.subUnit, price: values?.tradingPrice });
      if (anomalyForPrice) {
        setLoading(false);
        return message.error(anomalyForPrice);
      }
      const res = await axios.post(`${LISTING_OFFER_URL}`, {
        enquiry: listing._id,
        tradingPrice: values.tradingPrice * 100,
        qty: values.qty,
        duration: 0,
        deliveryAddressId: values.deliveryAddressId,
        paymentTerm: values.paymentTerm,
        expectedDeliveryOn: values.expectedDeliveryOn,
        qualityTesting: values.qualityTesting ? 'Requested' : 'Not Requested',
        remark: values.remark,
      });

      if (res.data.status === 'success') {
        message.success('Offer Sent Successfully');
        posthog.capture(`$${viewName} | $listing-offer-created`, {
          data: {
            listingId: listing?._id,
            product: listing?.product?.name,
            tradingPrice: values.tradingPrice,
            quantity: values.qty,
            duration: 0,
            qualityTesting: values.qualityTesting ? 'Requested' : 'Not Requested',
            expectedDeliveryOn: format(new Date(values.expectedDeliveryOn), 'dd MMM yyyy'),
            city: savedAddressesById[values.deliveryAddressId]?.city,
            state: savedAddressesById[values.deliveryAddressId]?.state,
          },
          $set: {
            lastActivityDate: new Date().toISOString(),
          },
        });
        close(true);
      } else {
        message.error(res.data.error);
      }
    } catch (err) {
      message.error(err.message);
    }
    setLoading(false);
  };

  const onCloseAddAddress = (reload) => {
    if (reload) {
      onLoad();
    }
    setShowAddAddress(!showAddAddress);
  };

  const confirmOnClose = () => {
    if ((!loading && form.isFieldsTouched()) || step === 'review-details') {
      const values = form.getFieldsValue();
      Modal.confirm({
        title: 'Unsaved Data',
        icon: <ExclamationCircleOutlined />,
        content: 'This form has unsaved data. Are you sure you want to close the screen?',
        okText: 'Yes',
        onOk: () => {
          form.resetFields();
          setStep(rejectedCount > 0 ? 'warning-page' : 'collect-details');
          close();
          posthog.capture(`$${viewName} | $send-offer-attempted`, {
            data: {
              listingId: listing?._id,
              product: listing?.product?.name,
              tradingPrice: values.tradingPrice ? values.tradingPrice : null,
              quantity: values.qty ? values.qty : null,
              duration: values.duration ? values.duration : null,
              qualityTesting: values.qualityTesting ? 'Requested' : 'Not Requested',
              expectedDeliveryOn: values.expectedDeliveryOn ? format(new Date(values.expectedDeliveryOn), 'dd MMM yyyy') : null,
              city: savedAddressesById[values.deliveryAddressId]?.city,
              state: savedAddressesById[values.deliveryAddressId]?.state,
            },
            $set: {
              lastActivityDate: new Date().toISOString(),
            },
          });
        },
        cancelText: 'No',
      });
    } else {
      form.resetFields();
      setStep(rejectedCount > 0 ? 'warning-page' : 'collect-details');
      close();
    }
  };

  return (
    <Modal
      className={uid}
      title={getTitle()}
      open={visible}
      onCancel={confirmOnClose}
      footer={null}
      closeIcon={<ModalCloseIcon />}
      width={480}
      bodyStyle={{ height: step === 'warning-page' ? '45vh' : '70vh', overflow: 'scroll' }}>
      <ErrorBoundary>
        <Form form={form} layout="vertical" onFinish={step === 'review-details' ? onSubmit : onClickNext} requiredMark={false}>
          {step === 'warning-page' && (
            <Card className="mt-5">
              <Empty
                image={WarningIcon}
                imageStyle={{ height: 80, marginTop: -60, marginBottom: 20 }}
                description={
                  <>
                    <Paragraph className="text-small">You have active orders for this Open Listing. Are you sure you want to create another</Paragraph>
                  </>
                }></Empty>
            </Card>
          )}
          {step === 'collect-details' && (
            <>
              <Form.Item
                name="tradingPrice"
                label={`Price Per ${listing?.subUnit} (${listing?.currency === 'USD' ? '$' : '₹'} - ${listing?.currency})`}
                rules={[
                  {
                    required: true,
                    pattern: /^[1-9][0-9.]*$/,
                    message: `Please enter a valid Price / ${listing?.subUnit ? listing?.subUnit : 'Unit'}`,
                  },
                ]}
                extra={`Listing Price : ${listing?.currency === 'USD' ? '$' : '₹'}${listing?.tradingPrice / 100} / ${listing?.subUnit} `}>
                <InputNumber precision={3} className="w-100" prefix={listing?.currency === 'USD' ? '$' : '₹'} min={1} size="large" max={1000000} controls={false} />
              </Form.Item>

              <Form.Item
                label="Quantity Needed"
                className="bg-transparent"
                name="qty"
                rules={[
                  {
                    validator: (_, value) => validateQuantity(value),
                  },
                  {
                    type: 'number',
                    max: listing?.qty,
                    message: `Maximum available quantity is ${listing?.qty} ${listing?.subUnit}`,
                  },
                ]}
                extra={`Available Qty: ${listing?.qty} ${listing?.subUnit}`}
                hasFeedback>
                <InputNumber precision={3} className="w-100" size="large" min={0} max={listing?.qty} addonAfter={`${listing?.subUnit} / ${listing?.frequency}`} />
              </Form.Item>

              <Form.Item
                name="deliveryAddressId"
                label={
                  <>
                    <Row align="middle">
                      <Col xs={12} sm={20}>
                        <Text>Delivery Address</Text>
                      </Col>
                      <Col xs={12} sm={4}>
                        <Button type="link" icon={<PlusOutlined />} onClick={() => onCloseAddAddress()}>
                          Add New Address
                        </Button>
                      </Col>
                    </Row>
                  </>
                }
                rules={[
                  {
                    required: true,
                    message: 'Please select a delivery location',
                  },
                ]}>
                <Select
                  size="large"
                  showSearch
                  filterOption={(inputValue, option) => {
                    const { label, line1, city, state, pincode } = option;
                    const searchValue = inputValue?.toLowerCase();
                    return [label, line1, city, state, pincode].some((value) => value?.toLowerCase().includes(searchValue));
                  }}
                  optionLabelProp="label"
                  value={deliveryAddress}
                  onChange={(deliveryAddress) => {
                    setDeliveryAddress(deliveryAddress);
                    form.setFieldsValue({ deliveryAddress });
                  }}
                  menuItemSelectedIcon={<CheckOutlined />}>
                  {savedAddresses.map((savedAddress) => (
                    <Select.Option key={savedAddress._id} value={savedAddress._id} label={savedAddress?.name}>
                      <div>
                        <h5 className="mt-0">{savedAddress?.name}</h5>
                        <p className="mt-0 mb-0 text-wrap">
                          {savedAddress?.line1}, {savedAddress?.line2 ? savedAddress?.line2 + ',' : ''} {savedAddress?.city}, {savedAddress?.state} - {savedAddress?.pincode}
                        </p>
                      </div>
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                label="Start Delivery By"
                className="bg-transparent"
                name="expectedDeliveryOn"
                initialValue={moment(startOfDay(new Date()))}
                rules={[
                  {
                    required: true,
                    message: 'Please select the delivery starting date',
                  },
                ]}>
                <Calendar validRange={[moment(startOfDay(new Date())), moment().add(1, 'years')]} fullscreen={false} />
              </Form.Item>

              <Form.Item label="Quality Remarks" className="bg-transparent" name="remark">
                <Input.TextArea rows={3} maxLength={200} showCount />
              </Form.Item>

              <Divider />

              <Form.Item
                label="Terms of Payment"
                className="bg-transparent"
                name="paymentTerm"
                rules={[
                  {
                    required: true,
                    message: 'Please select Payment Terms',
                  },
                ]}>
                <Radio.Group size="large">
                  {paymentTerms?.map((paymentterm) => {
                    return (
                      <Radio className="w-100" key={paymentterm?._id} value={paymentterm?._id} style={{ paddingTop: 10, paddingBottom: 10 }}>
                        {paymentterm?.name}
                      </Radio>
                    );
                  })}
                </Radio.Group>
              </Form.Item>

              <Form.Item label="Other Options">
                <Form.Item className="bg-transparent" name="qualityTesting" valuePropName="checked" initialValue={false}>
                  <Checkbox>Request Quality Testing</Checkbox>
                </Form.Item>
              </Form.Item>
            </>
          )}

          {/* Review the details before submitting */}
          {step === 'review-details' && (
            <>
              <DetailsGrid icon={<Avatar src={require('assets/images/icon-cash.png')} shape="square" size="default" />} description="Pricing & Quantity" />
              <>
                <Row justify="space-between" className="mt-3">
                  <Text>Requested Price</Text>
                  <NumberText bold value={form.getFieldValue('tradingPrice')} prefix={listing?.currency} suffix={`/ ${listing?.subUnit}`} />
                </Row>
                <Row justify="space-between" className="my-1">
                  <Text>Quantity</Text>
                  <NumberText bold value={form.getFieldValue('qty')} suffix={` ${listing?.subUnit} / ${listing?.frequency}`} />
                </Row>
                {/* {listing?.frequency === 'Monthly' && (
                  <Row justify="space-between" className="my-1">
                    <Text>Duration</Text>
                    <Text strong>{form.getFieldValue('duration')} Months</Text>
                  </Row>
                )} */}
                <Row justify="space-between" className="my-1">
                  <Text>GST</Text>
                  <Text strong>{listing?.gstPercentage > 0 ? `${listing?.gstPercentage} %` : 'Included'}</Text>
                </Row>
                <Row justify="space-between" className="my-1">
                  <Text>Delivery Charges</Text>
                  <Text strong>
                    {listing?.transportType === 'Extra' ? `${listing?.currency === 'USD' ? '$ ' : '₹ '} ${listing?.transportCharges / 100} / ${listing?.subUnit}` : listing?.transportType}
                  </Text>
                </Row>
                <Divider className="my-0" />
                <Tag icon={<InfoCircleOutlined />} color="#1A75DA" className="my-3">
                  Final invoice amount will be calculated after loading
                </Tag>
              </>

              <Divider />

              <DetailsGrid icon={<Avatar src={require('assets/images/icon-delivery.png')} shape="square" size="default" />} description="Delivery" />
              <Row gutter={[16, 24]} className="mt-3">
                <Col span={12}>
                  {form.getFieldValue('expectedDeliveryOn') && <DetailsGrid title="Start Deliveries By" description={format(new Date(form.getFieldValue('expectedDeliveryOn')._d), 'dd MMM yyyy')} />}
                </Col>
                <Col span={12}>
                  <DetailsGrid title="Duration" description={listing?.frequency === 'Once' ? 'Once' : `Monthly for ${form.getFieldValue('duration')} months`} />
                </Col>
                <Col span={12}>
                  <DetailsGrid title="Pickup Location" description={`${listing?.pickupAddress?.city}, ${listing?.pickupAddress?.state}`} />
                </Col>
                <Col span={12}>
                  <DetailsGrid
                    title="Delivery Location"
                    description={savedAddressesById[deliveryAddress]?.name}
                    description2={`${savedAddressesById[deliveryAddress]?.line1}, ${savedAddressesById[deliveryAddress]?.line2 ? savedAddressesById[deliveryAddress]?.line2 + ',' : ''} ${
                      savedAddressesById[deliveryAddress]?.city
                    }, ${savedAddressesById[deliveryAddress]?.state}, ${savedAddressesById[deliveryAddress]?.pincode}`}
                  />
                </Col>
              </Row>

              <Divider />

              <Paragraph type="secondary">Additional Information</Paragraph>
              {form.getFieldValue('paymentTerm') && (
                <Paragraph>
                  &bull; <strong>Payment Terms:</strong> {paymentTermsById[form.getFieldValue('paymentTerm')]?.name}
                </Paragraph>
              )}
              <Paragraph>
                &bull; <strong>Quality Testing:</strong> {form.getFieldValue('qualityTesting') ? 'Requested' : 'Not Requested'}
              </Paragraph>
              {form.getFieldValue('remark') && (
                <Paragraph>
                  &bull; <strong>Quality Remarks:</strong> {form.getFieldValue('remark')}
                </Paragraph>
              )}

              <Form.Item className="mt-4">
                <Button type="link" onClick={() => setStep('collect-details')} icon={<LeftOutlined />}>
                  Edit Order
                </Button>
              </Form.Item>
            </>
          )}

          <Form.Item className="mt-4">
            <Button type="primary" size="large" block htmlType="submit">
              {step === 'warning-page' && 'Continue'}
              {step === 'collect-details' && 'Next'}
              {step === 'review-details' && 'Place Order'}
            </Button>
          </Form.Item>
        </Form>
      </ErrorBoundary>

      <AddNewAddress visible={showAddAddress} close={onCloseAddAddress} />
    </Modal>
  );
};

export default CreateOrder;
