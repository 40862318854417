import React, { useEffect, useState } from 'react';
import { Avatar, Button, Card, Col, Divider, List, Modal, Row, Typography, message } from 'antd';
import axios from 'axios';
import { format } from 'date-fns';
import ContentLoader from 'react-content-loader';
import DetailsGrid from 'components/UtilComponents/DetailsGrid';
import NumberText from 'components/UtilComponents/NumberText';
import ModalCloseIcon from 'components/UtilComponents/ModalCloseIcon';
import { ORDER_URL, UPLOAD_URL } from 'constants/ApiConstants';
import CreateOpenListing from 'components/Modals/Sell/CreateOpenListing';
import IconOrders from 'assets/images/orders.png';
import EmptyContent from 'components/UtilComponents/EmptyContent';
import { getBreakPoint } from 'utils/layout';
import { formatRefNo } from 'utils/formatter';

const { Text, Title } = Typography;

const ReceivedOrdersCard = ({ order, onRefresh }) => {
  const isMobile = !getBreakPoint().includes('sm');
  const { product } = order;
  const [acceptLoading, setAcceptLoading] = useState(false);
  const [rejectLoading, setRejectLoading] = useState(false);

  const onConfirmAcceptOrder = () => {
    Modal.confirm({
      title: 'Are you sure you want to accept this order?',
      bodyStyle: { backgroundColor: '#fff', borderRadius: 10 },
      icon: null,
      closable: true,
      closeIcon: <ModalCloseIcon />,
      cancelText: 'Cancel',
      cancelButtonProps: {
        type: 'primary',
        ghost: true,
      },
      onCancel() {},
      okText: 'Accept',
      okButtonProps: {
        loading: acceptLoading,
      },
      onOk() {
        onAcceptOrder();
      },
    });
  };

  const onAcceptOrder = async () => {
    if (acceptLoading) return;
    setAcceptLoading(true);
    try {
      const res = await axios.put(`${ORDER_URL}/${order?._id}`, {
        status: 'In Progress',
      });

      if (res.data.status === 'error') {
        message.error(res.data.error);
      } else {
        message.success('Order Accepted Successfully');
        onRefresh();
      }
    } catch (err) {
      message.error(err.message);
    }
    setAcceptLoading(false);
  };

  const onConfirmRejectOrder = () => {
    Modal.confirm({
      title: 'Are you sure you want to reject this order?',
      bodyStyle: { backgroundColor: '#fff', borderRadius: 10 },
      icon: null,
      closable: true,
      closeIcon: <ModalCloseIcon />,
      cancelText: 'Cancel',
      cancelButtonProps: {
        type: 'primary',
        ghost: true,
      },
      onCancel() {},
      okText: 'Reject',
      okButtonProps: {
        loading: rejectLoading,
      },
      onOk() {
        onRejectOrder();
      },
    });
  };

  const onRejectOrder = async () => {
    if (rejectLoading) return;
    setRejectLoading(true);
    try {
      const res = await axios.put(`${ORDER_URL}/${order?._id}`, {
        status: 'Cancelled',
      });

      if (res.data.status === 'error') {
        message.error(res.data.error);
      } else {
        message.success('Order Rejected Successfully');
        onRefresh();
      }
    } catch (err) {
      message.error(err.message);
    }
    setRejectLoading(false);
  };

  return (
    <>
      <Card size={isMobile ? 'small' : 'default'}>
        <Row align="middle" justify="flex-start" className="mb-3">
          <Col span={18}>
            <Row gutter={10} justify="flex-start" wrap={false} className="mb-3">
              <Col>
                <Avatar src={`${UPLOAD_URL}/${order?.product?.image}`} shape="square" size="large" />
              </Col>
              <Col>
                <Title level={5} className="fw-medium">
                  {product?.name}
                </Title>
                <NumberText value={order?.purchasingPrice / 100} prefix={order?.currency} suffix={`/ ${order?.subUnit}`} />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row gutter={16} className="mt-3">
          <Col span={12}>
            <DetailsGrid
              icon={<Avatar src={require('assets/images/po-number.png')} shape="square" size="small" />}
              title="Order Number"
              description={<Text strong>{formatRefNo(order?.orderRefNo)}</Text>}
            />
          </Col>
          <Col span={12}>
            <DetailsGrid
              icon={<Avatar src={require('assets/images/total-quantity.png')} shape="square" size="small" />}
              title="Total Quantity"
              description={
                <Text strong>
                  {order?.qty || '∞'} {order?.subUnit}
                </Text>
              }
            />
          </Col>
        </Row>
        <Row gutter={16} className="my-3">
          <Col span={12}>
            <DetailsGrid
              icon={<Avatar src={require('assets/images/icon-location-dark.png')} shape="square" size="small" />}
              title={'Delivery Location'}
              description={`${order?.deliveryAddress?.city}, ${order?.deliveryAddress?.state}`}
            />
          </Col>
          <Col span={12}>
            <DetailsGrid
              icon={<Avatar src={require('assets/images/icon-calendar.png')} shape="square" size="small" />}
              title={'Expected Delivery Date'}
              description={format(new Date(order?.deliveryOn), 'dd MMM yyyy')}
            />
          </Col>
        </Row>

        {/* TODO: temporarily disabled */}
        {false && order?.enquiryType === 'Purchase Order' && (
          <Col flex={1} className="d-flex justify-content-end">
            <Button type="link" onClick={() => {}}>
              Download PO
            </Button>
          </Col>
        )}
        <Text type="secondary" className="text-small mt-2">
          Total : <NumberText value={order?.purchasingPriceBasis?.total?.value / 100} prefix={order?.currency} />
        </Text>
        <Divider dashed={true} className="my-2" />
        <Row gutter={16}>
          <Col span={12}>
            <Button type="primary" block onClick={onConfirmAcceptOrder}>
              Accept Order
            </Button>
          </Col>
          <Col span={12}>
            <Button type="primary" ghost block onClick={onConfirmRejectOrder}>
              Reject Order
            </Button>
          </Col>
        </Row>
      </Card>
    </>
  );
};

const ReceivedOrders = ({ tab }) => {
  const isMobile = !getBreakPoint().includes('sm');
  const [loading, setLoading] = useState(false);
  const [showOpenListing, setShowOpenListing] = useState(false);
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    if (tab === 'received-orders') {
      onLoadOrders();
    }
  }, [tab]);

  const onLoadOrders = async () => {
    setLoading(true);
    try {
      const res = await axios.get(`${ORDER_URL}?populate=true`, {
        headers: {
          'X-API-Filters': JSON.stringify({ seller: ['me'], status: ['Placed'] }),
          'X-API-Sort': JSON.stringify({ createdAt: 'desc' }),
        },
      });
      if (res.data.status === 'error') {
        message.error(res.data.error);
      } else {
        setOrders(res.data.orders);
      }
    } catch (err) {
      message.error(err.message);
    }
    setLoading(false);
  };

  const onCreateWholesaleOpenListing = () => {
    setShowOpenListing(!showOpenListing);
  };

  if (loading) {
    return (
      <ContentLoader width="100%" height="70vh" backgroundColor="#d9d9d9" foregroundColor="#ededed">
        <rect x="0" y="25" rx="10" ry="10" width="48%" height="211" />
        <rect x="50%" y="25" rx="10" ry="10" width="50%" height="211" />
        <rect x="0" y="250" rx="10" ry="10" width="48%" height="211" />
        <rect x="50%" y="250" rx="10" ry="10" width="50%" height="211" />
      </ContentLoader>
    );
  }

  return (
    <>
      {orders?.length > 0 ? (
        <List
          className="my-4"
          header={null}
          footer={null}
          grid={{ gutter: [16, 4], column: isMobile ? 1 : 2 }}
          dataSource={orders}
          pagination={orders?.length > 0}
          renderItem={(order) => {
            return (
              <List.Item key={order?._id}>
                {/* Component defined at the top of this file */}
                <ReceivedOrdersCard order={order} onRefresh={onLoadOrders} />
              </List.Item>
            );
          }}
        />
      ) : (
        <EmptyContent source={IconOrders} title={'No Received Orders'} subTitle={'There are no received orders found right now.'} />
      )}
      <CreateOpenListing visible={showOpenListing} close={onCreateWholesaleOpenListing} />
    </>
  );
};

export default ReceivedOrders;
