import React from 'react';
import { Card, Typography } from 'antd';
import { UPLOAD_URL } from 'constants/ApiConstants';

const { Paragraph } = Typography;

const Product = ({ data }) => {
  return (
    <Card style={{ width: 125 }} bodyStyle={{ padding: 10, minHeight: 60 }} bordered={false} cover={<img alt="example" style={{ width: 125, height: 125 }} src={`${UPLOAD_URL}/${data.image}`} />}>
      <Paragraph ellipsis={{ rows: 2 }}>{data.name}</Paragraph>
    </Card>
  );
};

export default Product;
