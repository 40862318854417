import React, { useEffect, useState } from 'react';
import { Button, Divider, List, Typography, message } from 'antd';
import axios from 'axios';
import ContentLoader from 'react-content-loader';
import CreateOpenListing from 'components/Modals/Sell/CreateOpenListing';
import MyListingCard from 'components/Cards/MyListingCard';
import { LISTING_URL, MAXIMUM_ACTIVE_ITEMS } from 'constants/ApiConstants';
import ListingIcon from 'assets/images/listings.png';
import EmptyContent from 'components/UtilComponents/EmptyContent';
import { getBreakPoint } from 'utils/layout';

const { Paragraph } = Typography;

const WholesaleListings = () => {
  const isMobile = !getBreakPoint().includes('sm');
  const [showOpenListing, setShowOpenListing] = useState(false);
  const [activeDataSource, setActiveDataSource] = useState([]);
  const [expiredDataSource, setExpiredDataSource] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    onLoad();
  }, []);

  const onLoad = async () => {
    if (loading) return;
    setLoading(true);
    try {
      const activeRes = await axios.get(`${LISTING_URL}?&populate=true`, {
        headers: {
          'X-API-Filters': JSON.stringify({
            seller: ['me'],
            status: ['Requested', 'Created'],
          }),
          'X-API-Sort': JSON.stringify({ createdAt: 'desc' }),
        },
      });
      const expiredRes = await axios.get(`${LISTING_URL}?&populate=true`, {
        headers: {
          'X-API-Filters': JSON.stringify({
            seller: ['me'],
            status: ['Expired'],
          }),
          'X-API-Sort': JSON.stringify({ createdAt: 'desc' }),
        },
      });
      if (activeRes.data.status === 'error') {
        message.error(activeRes.data.error);
      } else if (expiredRes.data.status === 'error') {
        message.error(expiredRes.data.error);
      } else {
        setActiveDataSource(activeRes.data.openlistings);
        setExpiredDataSource(expiredRes.data.openlistings);
      }
    } catch (err) {
      message.error(err.message);
    }
    setLoading(false);
  };

  const onCreateWholesaleOpenListing = (reload) => {
    if (reload) {
      onLoad();
    }
    setShowOpenListing(!showOpenListing);
  };

  if (loading) {
    return (
      <ContentLoader width="100%" height="70vh" backgroundColor="#d9d9d9" foregroundColor="#ededed">
        <rect x="0" y="25" rx="10" ry="10" width="48%" height="211" />
        <rect x="50%" y="25" rx="10" ry="10" width="50%" height="211" />
        <rect x="0" y="255" rx="10" ry="10" width="48%" height="211" />
        <rect x="50%" y="255" rx="10" ry="10" width="50%" height="211" />
      </ContentLoader>
    );
  }

  return (
    <div>
      {activeDataSource?.length > 0 && (
        <>
          <List
            className="my-4"
            grid={{ gutter: [24, 12], column: isMobile ? 1 : 2 }}
            dataSource={activeDataSource}
            pagination={false}
            renderItem={(listing) => <MyListingCard listing={listing} onRefresh={onLoad} />}
          />
          <Button type="primary" size="large" className="px-5 mb-2" onClick={() => onCreateWholesaleOpenListing()} disabled={activeDataSource.length >= MAXIMUM_ACTIVE_ITEMS}>
            Create New Open Listing
          </Button>
          <Paragraph type="secondary" className="text-small">
            {MAXIMUM_ACTIVE_ITEMS - activeDataSource.length > 0 ? MAXIMUM_ACTIVE_ITEMS - activeDataSource.length : 0} Open Listing left.
          </Paragraph>
        </>
      )}

      {activeDataSource.length === 0 && (
        <EmptyContent
          source={ListingIcon}
          title={'Sell what you have, Easily.'}
          subTitle={'Create an Open Listing and attract orders from buyers'}
          onActionPress={() => onCreateWholesaleOpenListing()}
          actionText={'Create Open Listing'}
        />
      )}

      {expiredDataSource.length > 0 && (
        <>
          <Divider />
          <Paragraph>Expired Open Listings</Paragraph>
          <List
            className="my-4"
            grid={{ gutter: [24, 8], column: isMobile ? 1 : 2 }}
            dataSource={expiredDataSource}
            pagination={true}
            renderItem={(listing) => <MyListingCard listing={listing} onRefresh={onLoad} />}
          />
        </>
      )}

      <CreateOpenListing visible={showOpenListing} close={onCreateWholesaleOpenListing} />
    </div>
  );
};

export default WholesaleListings;
