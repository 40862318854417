import { Alert, Button, Col, Divider, Drawer, Row, Typography, message } from 'antd';
import axios from 'axios';
import { NOTIFICATION_URL } from 'constants/ApiConstants';
// import { unionBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import Loader from 'components/UtilComponents/Loader';
import ModalCloseIcon from 'components/UtilComponents/ModalCloseIcon';
import EmptyContent from 'components/UtilComponents/EmptyContent';
import IconEmpty from 'assets/images/empty.png';
import TimeAgo from 'timeago-react';
import { useNavigate } from 'react-router-dom';
import { unionBy } from 'lodash';
import { getBreakPoint } from 'utils/layout';

const SellNotificationScreen = ({ visible, close }) => {
  const isMobile = !getBreakPoint().includes('sm');
  //   const [currentTab, setCurrentTab] = useState('all');
  const navigate = useNavigate();
  const [notifications, setNotifications] = useState([]);
  const [refreshing, setRefreshing] = useState(true);
  const limit = 10;
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [isHovered, setIsHovered] = useState({ id: '', isHovered: false });

  useEffect(() => {
    if (visible) {
      onLoadNotifications(page);
    }
  }, [visible]);

  const onCloseDrawer = () => {
    close();
  };

  //   const tabItems = [
  //     // remember to pass the key prop
  //     {
  //       label: `All (${count})`,
  //       key: 'all',
  //     },
  //     {
  //       label: `Payments (${count})`,
  //       key: 'payments',
  //     },

  //     {
  //       label: `Dispatches (${count})`,
  //       key: 'dispatches',
  //     },
  //     {
  //       label: `Orders (${count})`,
  //       key: 'orders',
  //     },
  //   ];

  const onLoadNotifications = async (page) => {
    setRefreshing(true);
    try {
      const res = await axios.get(`${NOTIFICATION_URL}/me?page=${page}&limit=${limit}`, {
        method: 'get',
        headers: {
          'X-API-Filters': JSON.stringify({
            notificationType: ['Sell'],
            channelType: ['FCM'], // only fetch push notifications
          }),
        },
      });
      if (res?.data.status === 'error') {
        message.error(res?.data.error);
      } else {
        const newNotifications = unionBy(notifications, res?.data?.notifications, '_id');
        setNotifications(newNotifications);
        setCount(res?.data?.count);
      }
    } catch (err) {
      Alert.alertWithType('error', 'Error', err.message);
    }
    setRefreshing(false);
  };

  const onLoadMore = () => {
    if (notifications.length < count) {
      setPage(page + 1);
      onLoadNotifications(page + 1);
    }
  };

  const onActionNavigate = (notification) => {
    const payload = notification?.metadata?.payload;
    const templateId = notification?.metadata?.templateId;

    const paths = {
      newRfq: '/sell/open-rfqs',
      offerReceived: payload?.link_to_listing ?? '/sell/my-listings',
      quoteAccepted: payload?.link_to_rfq ?? '/sell/quotes/accepted-quotes',
      quoteRejected: payload?.link_to_rfq ?? '/sell/quotes/rejected-quotes',
      dispatchCreated: payload?.dispatch_id ? `sell/orders/dispatch/${payload?.dispatch_id}` : '/dispatches/ongoing-dispatches',
      dispatchTransitUpdated: payload?.dispatch_id ? `sell/orders/dispatch/${payload?.dispatch_id}` : '/dispatches/ongoing-dispatches',
      dispatchCompleted: payload?.dispatch_id ? `sell/orders/dispatch/${payload?.dispatch_id}` : '/dispatches/completed-dispatches',
      orderConfirmed: payload.order_id ? `sell/orders/active-orders/${payload.order_id}` : '/sell/orders/active-orders',
      orderPartiallyFulfilled: payload.order_id ? `sell/orders/active-orders/${payload.order_id}` : '/sell/orders/active-orders',
      orderCompleted: payload.order_id ? `sell/orders/completed-orders/${payload.order_id}` : '/sell/orders/completed-orders',
      paymentIssued: '/payments',
      sustainabilityReport: '/sustainability-report',
      resetPassword: '/my-account',
    };
    if (templateId === 'newRfq' || templateId === 'paymentIssued' || templateId === 'sustainabilityReport' || templateId === 'resetPassword') {
      navigate(paths[templateId]);
    } else {
      window.open(paths[templateId], '_self');
    }
  };

  return (
    <Drawer width={isMobile ? '90%' : '35%'} headerStyle={{ margin: '0px', height: '0px', padding: '0px' }} closeIcon={false} onClose={onCloseDrawer} open={visible}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <h3>
          <b>Notifications</b>
          {notifications?.length > 0 && (
            <h6>
              {' '}
              Showing {notifications?.length}/{count} notifications
            </h6>
          )}
        </h3>
        <Button className="mb-3" type="text" onClick={onCloseDrawer}>
          <ModalCloseIcon />
        </Button>
      </div>

      <Divider style={{ margin: 0, padding: 0 }} />
      {/* <Tabs
        style={{ marginTop: '0px' }}
        type="line"
        items={tabItems}
        defaultActiveKey={currentTab}
        onChange={(e) => {
          if (e === 'close') {
            setCurrentTab('all');
            onCloseDrawer();
          } else setCurrentTab(e);
        }}
      /> */}
      {refreshing && page === 1 ? (
        <Loader />
      ) : notifications?.length <= 0 ? (
        <EmptyContent source={IconEmpty} title={'No Notifications to be Shown'} />
      ) : (
        <>
          {notifications?.map((notification, i) => {
            const title = notification?.metadata?.options?.FCM?.title;
            const body = notification?.metadata?.options?.FCM?.body;
            const createdAt = notification?.createdAt;
            const bulletColor = () => {
              switch (notification?.metadata?.templateId) {
                case 'newRfq':
                  return '#1A75DA'; //Primary
                case 'quoteRejected':
                case 'offerRejected':
                  return '#FC4922'; //Red
                case 'paymentIssued':
                case 'orderPartiallyFulfilled':
                  return '#EAC700'; //Yellow
                default:
                  return '#168f51'; //Green
              }
            };
            return (
              <>
                {i !== 0 ? <Divider className="my-0" /> : ''}
                <div
                  onMouseEnter={() => setIsHovered({ id: i, isHovered: true })}
                  onMouseLeave={() => setIsHovered({ id: '', isHovered: false })}
                  style={{ backgroundColor: isHovered.id === i && isHovered.isHovered ? '#e8f1fb' : 'white' }}
                  onClick={() => onActionNavigate(notification)}
                  className="d-flex flex-column py-3 cursor-pointer">
                  <Row align="center" wrap={false}>
                    <Col className="d-flex flex-column align-items-center justify-content-center ">
                      <div style={{ minWidth: '5px', minHeight: '5px', backgroundColor: bulletColor(), borderRadius: '50%', marginRight: '8px' }}></div>
                    </Col>
                    <Col>
                      <Row wrap={false} justify="space-between">
                        <Col span={18}>
                          <Typography.Text strong>{title}</Typography.Text>
                        </Col>
                        <Col span={6}>
                          <Typography.Text type="secondary" className="float-right">
                            <TimeAgo datetime={createdAt} locale="en_US" />
                          </Typography.Text>
                        </Col>
                      </Row>
                      <Typography.Text>{body}</Typography.Text>
                    </Col>
                  </Row>
                </div>
              </>
            );
          })}
          {notifications?.length > 0 && notifications?.length < count && !refreshing && (
            <div className="d-flex justify-content-center">
              <Button onClick={onLoadMore}>Load More</Button>
            </div>
          )}
          {refreshing && <Loader />}
        </>
      )}
    </Drawer>
  );
};

export default SellNotificationScreen;
