import { Avatar, Button, Card, Col, Collapse, Divider, message, PageHeader, Rate, Row, Steps, Tag, Typography } from 'antd';
import axios from 'axios';
import { MdCircle } from 'react-icons/md';
import { UpOutlined, DownOutlined } from '@ant-design/icons';
import { BUSINESS_URL, DISPATCH_URL, DTN_URL, UPLOAD_URL } from 'constants/ApiConstants';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
// import { Colors } from 'constants/ColorConstants';
import DetailsGrid from 'components/UtilComponents/DetailsGrid';
import { format } from 'date-fns';
import BackIcon from 'components/UtilComponents/BackIcon';
import { isEmpty } from 'lodash';
import BuyoTrace from './BuyoTrace';
import Loader from 'components/UtilComponents/Loader';
import { formatRefNo } from 'utils/formatter';
import { PageNotFound } from 'components/Errors';

const MyBuyDispatch = () => {
  const { Text, Title, Paragraph } = Typography;
  const id = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [openBuyoTrace, setOpenBuyoTrace] = useState(true);
  const [value, setValue] = useState(null);
  const [ratingValue, setRatingValue] = useState(false);
  const [dispatch, setDispatch] = useState(null);
  const [business, setBusiness] = useState([]);
  const [notFound, setNotFound] = useState(false);
  // const color = dispatch?.status === 'Accepted' ? '#0A8A85' : ['Cancelled', 'Rejected'].includes(dispatch?.status) ? '#ff4d4f' : Colors.primary;

  useEffect(() => {
    onLoadDispatch();
  }, [id]);

  useEffect(() => {
    onLoad();
  }, []);

  const onLoad = async () => {
    setLoading(true);
    try {
      const businessRes = await axios.get(`${BUSINESS_URL}/me`);
      if (businessRes.data.status === 'error') {
        message.error(businessRes.data.error);
      } else {
        setBusiness(businessRes.data.business);
      }
    } catch (err) {
      message.error(err.message);
    }
    setLoading(false);
  };

  const onLoadDispatch = async () => {
    setLoading(true);
    try {
      const dispatchRes = await axios.get(`${DISPATCH_URL}/${id.purchaseOrderId}?populate=true`);
      if (dispatchRes.data.code === 1006 || dispatchRes.data.code === 1007) {
        setNotFound(true);
        return setLoading(false);
      }
      if (dispatchRes.data.status === 'error') {
        message.error(dispatchRes.data.error);
      } else {
        setDispatch(dispatchRes.data.dispatch);
        setValue(dispatchRes.data.dispatch.metadata.dispatchRating);
        if (dispatchRes.data.dispatch.metadata.dispatchRating) setRatingValue(true);
      }
    } catch (error) {
      message.error(error.message);
    }
    setLoading(false);
  };

  const handleRating = async () => {
    const ratingResponse = await axios.put(`${DISPATCH_URL}/${dispatch._id}`, {
      metadata: {
        dispatchRating: value,
      },
    });
    if (ratingResponse.data.status === 'error') {
      message.error(ratingResponse.data.error);
    } else {
      message.success('Rating updated successfully');
      setRatingValue(true);
    }
  };

  const transitUpdates = dispatch?.transitUpdates?.map((transitUpdate) => ({
    title: transitUpdate?.status,
    description: format(new Date(transitUpdate?.updatedOn), 'dd MMM yyyy hh:mm a'),
    icon: <MdCircle />,
  }));

  if (loading) return <Loader />;

  if (notFound) return <PageNotFound />;

  return (
    <>
      <div className="app-container">
        <PageHeader
          onBack={() => navigate(-1)}
          backIcon={<BackIcon />}
          title={`Track Dispatch - ${formatRefNo(dispatch?.dispatchRefNo, 'BFD')} | ${dispatch?.product?.name}`}
          extra={
            <Row>
              <Col flex={1} className="d-flex flex-column align-items-end">
                <Text type="secondary">Tracking ID</Text>
                <Text copyable={{ text: `${DTN_URL}/track/${dispatch?.dispatchTrackingNo}?utm_source=share&utm_medium=web&utm_campaign=link_open&utm_content=${business._id}` }}>
                  {dispatch?.dispatchTrackingNo}
                </Text>
              </Col>
            </Row>
          }
          className="px-0"
        />
      </div>
      <Divider className="my-2" />
      <div className="app-container">
        <Card className="d-flex justify-content-center mx-sm-4 my-4">
          <Row wrap={false} align="middle" justify="center" className="mb-4">
            <Title level={5} className="fw-bold mr-8">
              Tracking Details
            </Title>
            <Tag color="#1a75da" className="ml-3">
              {dispatch?.transitStatus}
            </Tag>
          </Row>
          <div className="d-flex justify-content-center align-items-center">
            <Steps current={transitUpdates?.length} direction="vertical" items={transitUpdates} />
          </div>
        </Card>
        <Card className="mx-sm-4 my-4">
          <Collapse defaultActiveKey={['order-details']} ghost expandIconPosition="end">
            <Collapse.Panel header={<Title level={5}>Dispatch Details</Title>} key="order-details">
              <Row gutter={[24, 24]}>
                {dispatch?.metadata.purchaseOrderNo && (
                  <Col xs={24} sm={12} md={8} lg={8}>
                    <DetailsGrid icon={<Avatar src={require('assets/images/po-number.png')} shape="square" size="small" />} title="PO Number" description={`${dispatch?.metadata.purchaseOrderNo}`} />
                  </Col>
                )}
                <Col xs={24} sm={12} md={8} lg={8}>
                  <DetailsGrid
                    icon={<Avatar src={require('assets/images/dispatched-quantity.png')} shape="square" size="small" />}
                    title="Quantity"
                    description={`${dispatch?.receivedQty ? dispatch.receivedQty : dispatch?.dispatchedQty} ${dispatch?.subUnit}`}
                  />
                </Col>
                <Col xs={24} sm={12} md={8} lg={8}>
                  <DetailsGrid
                    icon={<Avatar src={require('assets/images/icon-quantity.png')} shape="square" size="small" />}
                    title="Vehicle No"
                    description={dispatch?.vehicleNo ? dispatch?.vehicleNo : '-'}
                  />
                </Col>
                <Col xs={24} sm={12} md={8} lg={8}>
                  <DetailsGrid
                    icon={<Avatar src={require('assets/images/icon-location-dark.png')} shape="square" size="small" />}
                    title="Dispatch Area Pincode"
                    description={dispatch?.dispatchFrom || '-'}
                  />
                </Col>
                {dispatch?.salesBillNo && (
                  <Col xs={24} sm={12} md={8} lg={8}>
                    <DetailsGrid icon={<Avatar src={require('assets/images/icon-quality.png')} shape="square" size="small" />} title="Sales Bill No" description={dispatch?.salesBillNo} />
                  </Col>
                )}
                {dispatch?.dispatchedOn && (
                  <Col xs={24} sm={12} md={8} lg={8}>
                    <DetailsGrid
                      icon={<Avatar src={require('assets/images/icon-calendar.png')} shape="square" size="small" />}
                      title="Dispatch Date"
                      description={`${format(new Date(dispatch?.dispatchedOn), 'dd MMM yyyy')}`}
                    />
                  </Col>
                )}
                {dispatch?.metadata.grnNumber && (
                  <Col xs={24} sm={12} md={8} lg={8}>
                    <DetailsGrid icon={<Avatar src={require('assets/images/po-number.png')} shape="square" size="small" />} title="GRN Number" description={`${dispatch?.metadata.grnNumber}`} />
                  </Col>
                )}
                {dispatch?.metadata.deliveryChallanNo && (
                  <Col xs={24} sm={12} md={8} lg={8}>
                    <DetailsGrid
                      icon={<Avatar src={require('assets/images/po-number.png')} shape="square" size="small" />}
                      title="Delivery Challan Number"
                      description={`${dispatch?.metadata.deliveryChallanNo}`}
                    />
                  </Col>
                )}

                <Col xs={24} sm={12} md={8} lg={8}>
                  <DetailsGrid
                    icon={<Avatar src={require('assets/images/icon-location-dark.png')} shape="square" size="small" />}
                    title="Delivery Address"
                    description={`${dispatch?.deliveryAddress?.line1}, ${dispatch?.deliveryAddress?.line2 ? dispatch?.deliveryAddress?.line2 + ',' : ''} ${dispatch?.deliveryAddress?.city}, ${
                      dispatch?.deliveryAddress?.state
                    }`}
                  />
                </Col>
                {dispatch?.notes && (
                  <Col xs={24} sm={12} md={8} lg={8}>
                    <DetailsGrid icon={<Avatar src={require('assets/images/po-number.png')} shape="square" size="small" />} title="Additional Information" description={dispatch?.notes} />
                  </Col>
                )}
              </Row>
            </Collapse.Panel>
          </Collapse>
        </Card>
        {!isEmpty(dispatch?.sales) && (
          <Card className="mx-sm-4 my-4">
            <Collapse defaultActiveKey={['attachments']} ghost expandIconPosition="end">
              <Collapse.Panel header={<Title level={5}>Attachment Details</Title>} key="attachments">
                {dispatch?.sales?.invoice && (
                  <Button type="link" href={`${UPLOAD_URL}/${dispatch?.sales?.invoice}`} className="pl-0" target="_blank">
                    <Avatar src={require('assets/images/dispatched-quantity.png')} shape="square" size="small" className="mr-2" />
                    Download Invoice
                  </Button>
                )}
                {dispatch?.sales?.ewayBill && (
                  <Button type="link" href={`${UPLOAD_URL}/${dispatch?.sales?.ewayBill}`} className="pl-0" target="_blank">
                    <Avatar src={require('assets/images/dispatched-quantity.png')} shape="square" size="small" className="mr-2" />
                    Download E-way Bill
                  </Button>
                )}
                {dispatch?.sales?.grnReceipt && (
                  <Button type="link" href={`${UPLOAD_URL}/${dispatch?.sales?.grnReceipt}`} className="pl-0" target="_blank">
                    <Avatar src={require('assets/images/dispatched-quantity.png')} shape="square" size="small" className="mr-2" />
                    Download GRN Receipt
                  </Button>
                )}
                {dispatch?.sales?.weightSlip && (
                  <Button type="link" href={`${UPLOAD_URL}/${dispatch?.sales?.weightSlip}`} className="pl-0" target="_blank">
                    <Avatar src={require('assets/images/dispatched-quantity.png')} shape="square" size="small" className="mr-2" />
                    Download Weight Slip
                  </Button>
                )}
                {dispatch?.sales?.moistureReport && (
                  <Button type="link" href={`${UPLOAD_URL}/${dispatch?.sales?.moistureReport}`} className="pl-0" target="_blank">
                    <Avatar src={require('assets/images/dispatched-quantity.png')} shape="square" size="small" className="mr-2" />
                    Download Moisture Report
                  </Button>
                )}
                {dispatch?.sales?.qualityReport && (
                  <Button type="link" href={`${UPLOAD_URL}/${dispatch?.sales?.qualityReport}`} className="pl-0" target="_blank">
                    <Avatar src={require('assets/images/dispatched-quantity.png')} shape="square" size="small" className="mr-2" />
                    Download Quality Report
                  </Button>
                )}
                {dispatch?.sales?.attachments?.length > 0 &&
                  dispatch?.sales?.attachments.map((attachment, index) => {
                    return (
                      <Button type="link" href={`${UPLOAD_URL}/${attachment}`} className="pl-0" target="_blank">
                        <Avatar src={require('assets/images/dispatched-quantity.png')} shape="square" size="small" className="mr-2" />
                        Download Attachment #{index + 1}
                      </Button>
                    );
                  })}
              </Collapse.Panel>
            </Collapse>
          </Card>
        )}
        {!isEmpty(dispatch) && dispatch.status === 'Completed' && dispatch.hederaMetadata && dispatch.hederaMetadata.status === 'success' && (
          <Card
            headStyle={{ marginBottom: !openBuyoTrace ? '-48px' : '0px' }}
            className="mx-sm-4 my-4"
            title={
              <Row gutter={[5, 5]} align="middle">
                <Col>BuyoTrace</Col>
                <Col style={{ fontSize: '13px' }}>(experimental)</Col>
              </Row>
            }
            extra={
              <Button type="text" onClick={() => setOpenBuyoTrace(!openBuyoTrace)}>
                {openBuyoTrace ? <UpOutlined /> : <DownOutlined />}
              </Button>
            }>
            {openBuyoTrace && <BuyoTrace dispatch={dispatch} />}
          </Card>
        )}
        <Card className="d-flex justify-content-center align-items-center mx-sm-4 my-4">
          <div className="d-flex justify-content-center align-items-center mx-sm-4 my-4 ">
            <Title strong level={5} className="fw-bold mr-8">
              Rate your delivery experience
            </Title>
          </div>
          {!ratingValue ? (
            <>
              <Row justify="center">
                <Rate onChange={setValue} value={value} />
              </Row>
              <div className="mx-sm-4 my-4">
                <Paragraph>Tap the stars to rate us on a scale of 1 to 5. Your feedback is valuable!</Paragraph>
              </div>
              <div className="m-auto w-50">
                <Button type="primary" size="large" className="w-100" onClick={handleRating}>
                  Submit
                </Button>
              </div>
            </>
          ) : (
            <div style={{ textAlign: 'center', color: '#1A75DA' }}>
              <Row justify="center">
                <Rate value={value} />
              </Row>
              <div className="mx-sm-4 my-4">
                <p>Thank you for the {value}/5 rating! </p>
              </div>
              <div className="mx-sm-4 my-4">
                <p>Always improving for you. Your feedback matters.</p>
              </div>
            </div>
          )}
        </Card>
      </div>
    </>
  );
};

export default MyBuyDispatch;
