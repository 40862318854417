import React, { useEffect, useState } from 'react';
import { Avatar, Button, Calendar, Checkbox, Col, Divider, Form, Input, InputNumber, Modal, Radio, Row, Select, Typography, Upload, message, Tag } from 'antd';
import { CheckOutlined, ExclamationCircleOutlined, FileImageOutlined, FileTextOutlined, LeftOutlined, PlusOutlined } from '@ant-design/icons';
import ErrorBoundary from 'components/Errors/ErrorBoundary';
import BackIcon from 'components/UtilComponents/BackIcon';
import DetailsGrid from 'components/UtilComponents/DetailsGrid';
import ModalCloseIcon from 'components/UtilComponents/ModalCloseIcon';
import ImageSelect from 'components/UtilComponents/ImageSelect';
import AddNewAddress from 'components/UtilComponents/AddNewAddress';
import { format, startOfDay } from 'date-fns';
import moment from 'moment';
import axios from 'axios';
import { BUSINESS_URL, COMMON_URL, LISTING_URL, PAYMENT_TERMS_URL, PRODUCT_URL, SAVED_ADDRESS_URL } from 'constants/ApiConstants';
import { isEmpty, keyBy } from 'lodash';
import { posthog } from 'posthog-js';
import { formatPrice } from 'utils/formatter';
import Loader from 'components/UtilComponents/Loader';
import { checkForAnomaly } from 'utils/anomaly';

const { Paragraph, Title, Text } = Typography;

const CreateOpenListing = ({ visible, close }) => {
  const viewName = 'sell';
  const [form] = Form.useForm();
  const [step, setStep] = useState('select-product');
  const [loading, setLoading] = useState(false);
  const [unitRefreshing, setUnitRefreshing] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [productsById, setProductsById] = useState([]);
  const [product, setProduct] = useState(null);
  const [unit, setUnit] = useState('');
  const [subUnits, setSubUnits] = useState([]);
  const [savedAddresses, setSavedAddresses] = useState([]);
  const [savedAddressesById, setSavedAddressesById] = useState([]);
  const [paymentTerms, setPaymentTerms] = useState([]);
  const [paymentTermsById, setPaymentTermsById] = useState(null);
  const [pickupAddress, setPickupAddress] = useState(null);
  const [transportType, setTransportType] = useState('');
  const [showAddAddress, setShowAddAddress] = useState(false);
  const [predictedPrice, setPredictedPrice] = useState({});
  const [businessState, setBusinessState] = useState(null);
  const [currency, setCurrency] = useState('INR');

  useEffect(() => {
    if (visible) {
      onLoad();
    } else {
      setProduct(null);
      setUnit(null);
      setSavedAddresses([]);
      setPredictedPrice({});
      setBusinessState(null);
    }
  }, [visible]);

  useEffect(() => {
    if (product) {
      onLoadUnit();
    }
  }, [product]);

  useEffect(() => {
    if (businessState && product && unit) {
      onLoadPredictedPrice();
    }
  }, [businessState, product, pickupAddress, unit]);

  const onLoad = async () => {
    setLoading(true);
    try {
      const businessRes = await axios.get(`${BUSINESS_URL}/me?populate=true`);

      const addressRes = await axios.get(`${SAVED_ADDRESS_URL}`, {
        headers: {
          'X-API-Filters': JSON.stringify({
            business: ['me'],
            status: ['Active'],
          }),
        },
      });

      const termRes = await axios.get(`${PAYMENT_TERMS_URL}`);

      if (businessRes.data.status === 'error') {
        message.error(businessRes.data.error);
      } else if (addressRes.data.status === 'error') {
        message.error(addressRes.data.error);
      } else if (termRes.data.status === 'error') {
        message.error(termRes.data.error);
      } else {
        setBusinessState(businessRes.data.business.address.state);
        setProducts(businessRes.data.business.selling);
        setProductsById(keyBy(businessRes.data.business.selling, '_id'));
        setSavedAddresses(addressRes.data.savedaddresses);
        setPaymentTerms(termRes.data.paymentterms);
        if (addressRes.data.savedaddresses.length > 0) {
          form.setFields([{ name: 'pickupAddressId', value: addressRes.data.savedaddresses[0]?._id }]);
          setPickupAddress(addressRes.data.savedaddresses[0]?._id);
          setSavedAddressesById(keyBy(addressRes.data.savedaddresses, '_id'));
        }
        if (termRes.data.paymentterms.length > 0) {
          form.setFields([{ name: 'paymentTerm', value: termRes?.data?.paymentterms[0]?._id }]);
          setPaymentTermsById(keyBy(termRes.data.paymentterms, '_id'));
        }
      }
    } catch (err) {
      message.error(err.message);
    }
    setLoading(false);
  };

  const onLoadUnit = async () => {
    setUnitRefreshing(true);
    try {
      const unitRes = await axios.get(`${PRODUCT_URL}/${product._id}?populate=true`);
      if (unitRes.data.status === 'error') {
        message.error(unitRes.data.error);
      } else {
        const subUnit = unitRes.data.product.unit.subUnits[0]?._id;
        setUnit(subUnit);
        form.setFields([{ name: 'subUnit', value: subUnit }]);
        setSubUnits(unitRes.data.product.unit.subUnits);
      }
    } catch (error) {
      message.error(error.message);
    }
    setUnitRefreshing(false);
  };

  const onLoadPredictedPrice = async () => {
    setUnitRefreshing(true);
    try {
      const stats = await axios.post(`${COMMON_URL}/price-predict/stats`, {
        state: businessState,
        product_id: product?._id,
        unit: unit,
        enquiryType: 'OpenListing',
      });

      if (stats.data.status === 'error') {
        message.error(stats.data.error);
      } else {
        setPredictedPrice(stats.data.stats);
      }
    } catch (err) {
      message.error(err?.message);
    }
    setUnitRefreshing(false);
  };

  /* Create Open Listing on Submit */
  const onSubmit = async () => {
    if (btnLoading) return;
    setBtnLoading(true);
    try {
      const values = form.getFieldsValue(true);
      const anomalyForPurchasingPrice = checkForAnomaly('open-listing', { product: productsById[values?.product], subUnits: subUnits, subUnit: values?.subUnit, price: values?.purchasingPrice });
      if (anomalyForPurchasingPrice) {
        setBtnLoading(false);
        return message.error(anomalyForPurchasingPrice);
      }
      const res = await axios.post(`${LISTING_URL}`, {
        product: values.product,
        subUnit: values.subUnit,
        currency: values.currency,
        qty: values.qty,
        frequency: values.frequency,
        duration: values.frequency === 'Monthly' ? values.duration : 0,
        purchasingPrice: values.purchasingPrice * 100,
        gstPercentage: values?.gst === 0 ? 0 : product?.metadata?.gstPercentage,
        transportType: values.transportType,
        transportCharges: values.transportType === 'Extra' ? values.transportCharges * 100 : 0,
        pickupAddressId: values.pickupAddressId,
        paymentTerm: values.paymentTerm,
        preferredDeliveryOn: new Date(values.preferredDeliveryOn?.format('YYYY-MM-DD')),
        remark: values.remark,
        metadata: {
          isNegotiable: values.isNegotiable,
        },
      });

      if (res.data.status === 'success') {
        message.success('Open Listing created successfully');
        posthog.capture(`$${viewName} | $open-listing-created`, {
          data: {
            product: product?.name,
            quantity: values.qty,
            subUnit: values.subUnit,
            currency: values.currency,
            purchasingPrice: values.purchasingPrice,
            frequency: values.frequency,
            duration: values.frequency === 'Monthly' ? values.duration : 0,
            transportType: values.transportType,
            preferredDeliveryOn: format(new Date(values.preferredDeliveryOn), 'dd MMM yyyy'),
            city: savedAddressesById[values.pickupAddressId]?.city,
            state: savedAddressesById[values.pickupAddressId]?.state,
          },
          $set: {
            lastActivityDate: new Date().toISOString(),
          },
        });

        // upload related files for openlisting
        const files = new FormData();
        if (!isEmpty(values?.productImageFile)) files.append('productImageFile', values?.productImageFile[0].originFileObj);
        if (!isEmpty(values?.qualityReportFile)) files.append('qualityReportFile', values?.qualityReportFile[0].originFileObj);

        await axios.post(`${LISTING_URL}/upload/${res?.data?.openlisting?._id}`, files);

        close(true);
        setStep('select-product');
        form.resetFields();
      } else {
        message.error(res.data.error);
      }
    } catch (err) {
      message.error(err.message);
    }
    setBtnLoading(false);
  };

  const onClickPrev = () => {
    if (step === 'review-details') return setStep('collect-details');
    if (step === 'collect-details') {
      const updatedValues = {};
      Object.keys(form.getFieldsValue()).forEach((field) => {
        updatedValues[field] = field === 'subUnit' ? form.getFieldValue(field) : undefined;
      });
      form.setFieldsValue(updatedValues);
      return setStep('select-product');
    }
  };

  const onClickNext = () => {
    if (step === 'select-product') return setStep('collect-details');
    if (step === 'collect-details') return setStep('review-details');
    if (step === 'review-details') return onSubmit();
  };

  const getTitle = () => {
    if (step === 'select-product') return 'Create New Open Listing';
    if (step === 'collect-details') {
      return (
        <>
          <BackIcon onClick={onClickPrev} className="mr-3" />
          {` Open Listing for '${product.name}'`}
        </>
      );
    }
    return (
      <>
        <BackIcon onClick={onClickPrev} className="mr-3" />
        {' Review Open Listing'}
      </>
    );
  };

  const onCloseAddAddress = (reload) => {
    if (reload) {
      onLoad();
    }
    setShowAddAddress(!showAddAddress);
  };

  const getFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const confirmOnClose = () => {
    if ((!loading && form.isFieldsTouched()) || step === 'review-details') {
      const values = form.getFieldsValue();
      Modal.confirm({
        title: 'Unsaved Data',
        icon: <ExclamationCircleOutlined />,
        content: 'This form has unsaved data. Are you sure you want to close the screen?',
        okText: 'Yes',
        onOk: () => {
          form.resetFields();
          setStep('select-product');
          close();
          posthog.capture(`$${viewName} | $create-open-listing-attempted`, {
            data: {
              product: product?.name,
              quantity: values.qty ? values.qty : null,
              subUnit: values.subUnit ? values.subUnit : null,
              currency: values.currency ? values.currency : null,
              purchasingPrice: values.purchasingPrice ? values.purchasingPrice : null,
              frequency: values.frequency ? values.frequency : null,
              duration: values.duration ? values.duration : null,
              transportType: values.transportType ? values.transportType : null,
              preferredDeliveryOn: values.preferredDeliveryOn ? format(new Date(values.preferredDeliveryOn), 'dd MMM yyyy') : null,
              city: savedAddressesById[values.pickupAddressId]?.city,
              state: savedAddressesById[values.pickupAddressId]?.state,
            },
            $set: {
              lastActivityDate: new Date().toISOString(),
            },
          });
        },
        cancelText: 'No',
      });
    } else {
      form.resetFields();
      setStep('select-product');
      close();
    }
  };

  useEffect(() => {
    const modalBody = document.querySelector(`.ant-modal-body`);
    if (modalBody) {
      modalBody.scrollTop = 0;
    }
  }, [step]);

  return (
    <Modal
      title={getTitle()}
      open={visible}
      onCancel={confirmOnClose}
      maskClosable={false}
      footer={null}
      closeIcon={<ModalCloseIcon />}
      width={480}
      bodyStyle={{ height: '70vh', overflow: 'scroll' }}
      centered>
      <ErrorBoundary>
        {loading && <Loader />}
        <Form form={form} layout="vertical" onFinish={step === 'select-product' || step === 'collect-details' ? onClickNext : onSubmit} requiredMark={false} scrollToFirstError>
          {!loading && step === 'select-product' && (
            <>
              <Form.Item noStyle shouldUpdate={(prevValues, currValues) => prevValues.product !== currValues.product}>
                {() => (
                  <Form.Item
                    label="Select the product you want to sell"
                    className="bg-transparent"
                    name="product"
                    rules={[
                      {
                        required: true,
                        message: 'Please select the product',
                      },
                    ]}
                    hasFeedback>
                    <Row gutter={[16, 16]} justify="start">
                      {products.map((product) => {
                        return (
                          <Col key={product._id}>
                            <ImageSelect
                              {...product}
                              selectedProduct={form.getFieldValue('product')}
                              onSelect={() => {
                                setProduct(product);
                                form.setFieldValue('product', product._id);
                                posthog.capture(`$${viewName} | $create-open-listing-attempted`, {
                                  data: {
                                    product: product?.name,
                                  },
                                  $set: {
                                    lastActivityDate: new Date().toISOString(),
                                  },
                                });
                                onClickNext();
                              }}
                            />
                          </Col>
                        );
                      })}
                    </Row>
                  </Form.Item>
                )}
              </Form.Item>
            </>
          )}

          {step === 'collect-details' && (
            <>
              <Form.Item name="productImageFile" getValueFromEvent={getFile}>
                <Upload maxCount={1} beforeUpload={() => false} defaultFileList={form.getFieldValue('productImageFile') || []} accept="image/*">
                  <Button size="large" icon={<FileImageOutlined />}>
                    Upload Product Image (Optional)
                  </Button>
                </Upload>
              </Form.Item>

              <Form.Item
                name="subUnit"
                label="Unit"
                rules={[
                  {
                    required: true,
                    message: 'Please select a valid unit',
                  },
                ]}
                hasFeedback>
                <Select
                  placeholder="Select Unit"
                  size="large"
                  showSearch
                  filterOption={(inputValue, option) => option.children?.toLowerCase().includes(inputValue?.toLowerCase())}
                  onChange={(unit) => {
                    setUnit(unit);
                    setPredictedPrice({});
                  }}
                  loading={unitRefreshing}>
                  {subUnits?.map((subUnit) => (
                    <Select.Option key={subUnit?._id} value={subUnit?._id}>
                      {subUnit?._id}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                label={`Quantity Available (${unit})`}
                className="bg-transparent"
                name="qty"
                rules={[
                  {
                    required: true,
                    message: 'Please enter the quantity available',
                  },
                ]}
                hasFeedback>
                <InputNumber precision={3} size="large" min={1} className="w-100" />
              </Form.Item>

              <Form.Item
                name="currency"
                label="Currency"
                initialValue={currency}
                rules={[
                  {
                    required: true,
                    message: 'Please select your preferred currency',
                  },
                ]}
                hasFeedback>
                <Select placeholder="Select Currency" size="large" onChange={(e) => setCurrency(e)}>
                  <Select.Option key={'INR'} value="INR">
                    ₹ INR
                  </Select.Option>
                  <Select.Option key={'USD'} value="USD">
                    $ USD
                  </Select.Option>
                </Select>
              </Form.Item>
              <Form.Item
                label={
                  <>
                    <span>Price ({unit})</span>
                    {!isEmpty(predictedPrice) ? (
                      <Tag style={{ marginLeft: '5px' }} color="green">
                        Avg. market price is {formatPrice(predictedPrice.median, currency)} / {unit} in {businessState}
                      </Tag>
                    ) : (
                      ''
                    )}
                  </>
                }
                className="bg-transparent"
                name="purchasingPrice"
                rules={[
                  {
                    required: true,
                    message: 'Please enter your price',
                  },
                ]}
                hasFeedback>
                <InputNumber precision={3} size="large" max={1000000} min={1} className="w-100" />
              </Form.Item>

              <Form.Item label="Other Options" noStyle>
                <Form.Item className="bg-transparent" name="isNegotiable" valuePropName="checked" initialValue={false}>
                  <Checkbox>Price is Negotiable</Checkbox>
                </Form.Item>
              </Form.Item>

              <Form.Item
                name="gstPercentage"
                label="GST"
                rules={[
                  {
                    required: true,
                    message: 'Please select a valid GST',
                  },
                ]}
                hasFeedback>
                <Select>
                  {product?.metadata?.gstPercentage <= 0 ? (
                    <Select.Option value={0}>0% - GST Not Applied</Select.Option>
                  ) : (
                    <>
                      <Select.Option value={0}>{product?.metadata?.gstPercentage + '%'} Included</Select.Option>
                      <Select.Option value={1}>{product?.metadata?.gstPercentage + '%'} Extra</Select.Option>
                    </>
                  )}
                </Select>
              </Form.Item>

              <Form.Item
                name="transportType"
                label="Transport"
                rules={[
                  {
                    required: true,
                    message: 'Please select a valid transport type',
                  },
                ]}
                hasFeedback>
                <Select size="large" onChange={setTransportType}>
                  <Select.Option value="Included">Included</Select.Option>
                  <Select.Option value="Excluded">Excluded</Select.Option>
                  <Select.Option value="Extra">Extra</Select.Option>
                </Select>
              </Form.Item>
              {transportType === 'Extra' && (
                <Form.Item noStyle shouldUpdate={(prevValues, currValues) => prevValues.currency !== currValues.currency}>
                  {({ getFieldValue }) => {
                    return (
                      <Form.Item
                        name="transportCharges"
                        label={`Transport Charges (${getFieldValue('currency')}) / ${unit}`}
                        rules={[
                          {
                            required: true,
                            pattern: /^[1-9][0-9.]*$/,
                            message: `Please enter a valid Transport Charges / ${unit}`,
                          },
                        ]}
                        hasFeedback>
                        <InputNumber precision={3} className="w-100" size="large" min={1} placeholder="Transport Charges" />
                      </Form.Item>
                    );
                  }}
                </Form.Item>
              )}

              <Form.Item
                label="Delivery Frequency"
                className="bg-transparent"
                name="frequency"
                rules={[
                  {
                    required: true,
                    message: 'Please select the delivery frequency',
                  },
                ]}
                hasFeedback>
                <Radio.Group
                  size="large"
                  options={[
                    {
                      label: 'Once',
                      value: 'Once',
                    },
                    {
                      label: 'Monthly',
                      value: 'Monthly',
                    },
                  ]}
                />
              </Form.Item>

              <Form.Item noStyle shouldUpdate={(prevValues, currValues) => prevValues.frequency !== currValues.frequency}>
                {({ getFieldValue }) =>
                  getFieldValue('frequency') === 'Monthly' ? (
                    <Form.Item
                      name="duration"
                      label="Duration (in months)"
                      rules={[
                        {
                          required: true,
                          message: 'Please enter a delivery duration',
                        },
                      ]}
                      hasFeedback>
                      <InputNumber className="w-100" min={2} size="large" controls={false} />
                    </Form.Item>
                  ) : null
                }
              </Form.Item>

              <Form.Item
                name="pickupAddressId"
                label={
                  <>
                    <Row align="middle" justify="space-between">
                      <Col xs={12} sm={20}>
                        <Text>Pickup Address</Text>
                      </Col>
                      <Col xs={12} sm={4}>
                        <Button type="link" icon={<PlusOutlined />} onClick={() => onCloseAddAddress()}>
                          Add New Address
                        </Button>
                      </Col>
                    </Row>
                  </>
                }
                rules={[
                  {
                    required: true,
                    message: 'Please select a valid pickup address',
                  },
                ]}
                hasFeedback>
                <Select
                  size="large"
                  showSearch
                  filterOption={(inputValue, option) => {
                    const { label, line1, city, state, pincode } = option;
                    const searchValue = inputValue?.toLowerCase();
                    return [label, line1, city, state, pincode].some((value) => value?.toLowerCase().includes(searchValue));
                  }}
                  optionLabelProp="label"
                  value={pickupAddress}
                  onChange={(pickupAddress) => {
                    setBusinessState(savedAddressesById[pickupAddress].state);
                    setPickupAddress(pickupAddress);
                    setPredictedPrice({});
                    form.setFieldsValue({ pickupAddress });
                  }}
                  menuItemSelectedIcon={<CheckOutlined />}>
                  {savedAddresses.map((savedAddress) => (
                    <Select.Option key={savedAddress._id} value={savedAddress._id} label={savedAddress?.name}>
                      <div>
                        <h5 className="mt-0">{savedAddress?.name}</h5>
                        <p className="mt-0 mb-0 text-wrap">
                          {savedAddress?.line1}, {savedAddress?.line2 ? savedAddress?.line2 + ',' : ''} {savedAddress?.city}, {savedAddress?.state} - {savedAddress?.pincode}
                        </p>
                      </div>
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              {!isEmpty(predictedPrice) && pickupAddress ? (
                <Tag style={{ marginBottom: '10px' }} color="green">
                  Avg. market price is {formatPrice(predictedPrice.median, currency)} / {unit} in {businessState}
                </Tag>
              ) : (
                ''
              )}
              <Form.Item
                label="Expected Delivery Date"
                className="bg-transparent"
                name="preferredDeliveryOn"
                initialValue={moment(startOfDay(new Date()))}
                rules={[
                  {
                    required: true,
                    message: 'Please select an expected delivery date',
                  },
                ]}>
                <Calendar validRange={[moment(startOfDay(new Date())), moment().add(1, 'years')]} fullscreen={false} />
              </Form.Item>

              <Form.Item label="Quality Remarks" className="bg-transparent" name="remark">
                <Input.TextArea rows={3} maxLength={200} showCount />
              </Form.Item>

              <Form.Item name="qualityReportFile" getValueFromEvent={getFile}>
                <Upload maxCount={1} beforeUpload={() => false} defaultFileList={form.getFieldValue('qualityReportFile') || []} accept="application/pdf">
                  <Button size="large" icon={<FileTextOutlined />}>
                    Upload Quality Report (Optional)
                  </Button>
                </Upload>
              </Form.Item>

              <Divider />

              <Form.Item
                label="Terms of Payment"
                className="bg-transparent"
                name="paymentTerm"
                rules={[
                  {
                    required: true,
                    message: 'Please select a preferred payment term',
                  },
                ]}>
                <Radio.Group size="large">
                  {paymentTerms?.map((paymentterm) => {
                    return (
                      <Radio className="w-100" key={paymentterm?._id} value={paymentterm?._id} style={{ paddingTop: 10, paddingBottom: 10 }}>
                        {paymentterm?.name}
                      </Radio>
                    );
                  })}
                </Radio.Group>
              </Form.Item>
            </>
          )}

          {step === 'review-details' && (
            <>
              <Row align="middle">
                <Col span={12}>
                  <Paragraph type="secondary">Product Available</Paragraph>
                  <Title level={5} className="mb-2">
                    {productsById[form.getFieldValue('product')]?.name} ({unit})
                  </Title>
                </Col>
                <Col span={12}>
                  <Paragraph type="secondary">Currency</Paragraph>
                  <Title level={5}>{form.getFieldValue('currency') === 'INR' ? `INR (₹)` : `USD ($)`}</Title>
                </Col>
              </Row>

              <Divider />

              <DetailsGrid icon={<Avatar src={require('assets/images/icon-quantity.png')} shape="square" size="default" />} description="Quantity" />
              <Row gutter={[16, 16]} className="mt-2">
                <Col span={12}>
                  <DetailsGrid title="Order Quantity" description={`${form.getFieldValue('qty')} ${form.getFieldValue('subUnit')}`} />
                </Col>
                <Col span={12}>
                  <DetailsGrid title="Duration" description={form.getFieldValue('frequency') === 'Once' ? 'Once' : `${form.getFieldValue('duration')} months`} />
                </Col>
              </Row>

              <Divider />

              <DetailsGrid icon={<Avatar src={require('assets/images/icon-cash.png')} shape="square" size="default" />} description="Pricing & GST" />
              <Row gutter={[16, 16]} className="mt-2">
                <Col span={12}>
                  <DetailsGrid title="Price" description={`${form.getFieldValue('currency') === 'USD' ? `$` : `₹`} ${form.getFieldValue('purchasingPrice')} / ${form.getFieldValue('subUnit')}`} />
                </Col>
                <Col span={12}>
                  <DetailsGrid title="GST" description={`${form.getFieldValue('gstPercentage') > 0 ? `${form.getFieldValue('gstPercentage')} % Extra` : 'Included'}`} />
                </Col>{' '}
                <Col span={12}>
                  <DetailsGrid
                    title="Transport Charges"
                    description={
                      form.getFieldValue('transportType') === 'Extra'
                        ? `${form.getFieldValue('transportType')} - ${form.getFieldValue('currency') === 'USD' ? `$` : `₹`} ${form.getFieldValue('transportCharges')} / ${form.getFieldValue(
                            'subUnit'
                          )}`
                        : form.getFieldValue('transportType')
                    }
                  />
                </Col>
              </Row>

              <Divider />

              <DetailsGrid icon={<Avatar src={require('assets/images/icon-delivery.png')} shape="square" size="default" />} description="Pickup" />
              <Row gutter={[16, 16]} className="mt-2">
                {form.getFieldValue('preferredDeliveryOn') && (
                  <Col span={12}>
                    <DetailsGrid title="Start Deliveries By" description={format(new Date(form.getFieldValue('preferredDeliveryOn')._d), 'dd MMM yyyy')} />
                  </Col>
                )}
                <Col span={12}>
                  <DetailsGrid
                    title="Pickup Location"
                    description={savedAddressesById[pickupAddress]?.name}
                    description2={`${savedAddressesById[pickupAddress]?.line1}, ${savedAddressesById[pickupAddress]?.line2 ? savedAddressesById[pickupAddress]?.line2 + ',' : ''} ${
                      savedAddressesById[pickupAddress]?.city
                    }, ${savedAddressesById[pickupAddress]?.state}, ${savedAddressesById[pickupAddress]?.pincode}`}
                  />
                </Col>
              </Row>

              <Divider />

              <Paragraph type="secondary">Additional Information</Paragraph>
              {form.getFieldValue('paymentTerm') && (
                <Paragraph>
                  &bull; <strong>Payment Terms:</strong> {paymentTermsById[form.getFieldValue('paymentTerm')]?.name}
                </Paragraph>
              )}
              <Paragraph>
                &bull; <strong>Is Negotiable?:</strong> {form.getFieldValue('isNegotiable') ? 'Yes' : 'No'}
              </Paragraph>

              <Divider />
              {form.getFieldValue('remark') && (
                <>
                  <Paragraph type="secondary">Quality Remarks</Paragraph>
                  <Paragraph>&bull; {form.getFieldValue('remark')}</Paragraph>
                  <Divider />
                </>
              )}

              <Form.Item className="mt-4">
                <Button type="link" onClick={() => setStep('collect-details')} icon={<LeftOutlined />}>
                  Edit Order
                </Button>
              </Form.Item>
            </>
          )}

          {step !== 'select-product' && (
            <Form.Item className="mt-4">
              <Button type="primary" size="large" block htmlType="submit" loading={btnLoading}>
                {step === 'collect-details' && 'Next'}
                {step === 'review-details' && 'Post Open Listing'}
              </Button>
            </Form.Item>
          )}
        </Form>
      </ErrorBoundary>

      <AddNewAddress visible={showAddAddress} close={onCloseAddAddress} />
    </Modal>
  );
};

export default CreateOpenListing;
