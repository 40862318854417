import React from 'react';
import { PageHeader, Tabs } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import ActiveSchedules from './ActiveSchedules';
import CompletedSchedules from './CompletedSchedules';
import BackIcon from 'components/UtilComponents/BackIcon';
import CancelledSchedules from './CancelledSchedules';

const POSchedule = () => {
  const { tab } = useParams();
  const navigate = useNavigate();

  const tabItems = [
    // remember to pass the key prop
    {
      label: 'Active',
      key: 'active-schedules',
      children: <ActiveSchedules tab={tab} />,
    },

    {
      label: 'Completed',
      key: 'completed-schedules',
      children: <CompletedSchedules tab={tab} />,
    },
    {
      label: 'Cancelled',
      key: 'cancelled-schedules',
      children: <CancelledSchedules tab={tab} />,
    },
  ];

  return (
    <div className="app-container">
      <PageHeader onBack={() => navigate('/buy')} backIcon={<BackIcon />} title="PO Schedules" className="px-0" />

      <Tabs type="line" items={tabItems} defaultActiveKey={tab} onChange={(key) => navigate(`/buy/po-schedules/${key}`)} />
    </div>
  );
};

export default POSchedule;
