import React, { useState } from 'react';
import { Button, Form, Input, Modal, Rate, Row, Typography, message } from 'antd';
import axios from 'axios';
import ErrorBoundary from 'components/Errors/ErrorBoundary';
import ModalCloseIcon from 'components/UtilComponents/ModalCloseIcon';
import CompanyName from 'components/UtilComponents/CompanyName';
import { OPEN_MERCHANT_NAME, ORDER_URL } from 'constants/ApiConstants';

const { Text } = Typography;

const RateOrder = ({ type, order, visible, close }) => {
  const tooltips = ['Terrible', 'Bad', 'Okay', 'Good', 'Great'];
  const [form] = Form.useForm();
  const [value, setValue] = useState(0);
  const [loading, setLoading] = useState();

  const onFinish = async (values) => {
    if (loading) return;
    setLoading(true);
    try {
      const metadata = {};
      if (type === 'seller') {
        metadata.sellerRating = values.rating;
        metadata.sellerReview = values.review;
      }
      if (type === 'buyer') {
        metadata.buyerRating = values.rating;
        metadata.buyerReview = values.review;
      }

      const res = await axios.put(`${ORDER_URL}/${order?._id}`, {
        metadata: {
          ...metadata,
        },
      });

      if (res.data.status === 'error') {
        message.error(res.data.error);
      } else {
        message.success('Thank you for your feedback!');
        close(true);
      }
    } catch (err) {
      message.error(err.message);
    }
    setLoading(false);
  };

  return (
    <Modal title={null} footer={null} open={visible} onCancel={() => close()} closeIcon={<ModalCloseIcon />} bodyStyle={{ height: '75vh' }}>
      <ErrorBoundary>
        <div className="d-flex flex-column align-items-center justify-content-center mt-5">
          <Text className="text-center my-3">Let us know about your experience by rating the {type || 'order'}</Text>
          <CompanyName name={order?.enquiryType === 'Direct Enquiry' ? (type === 'seller' ? order?.seller?.name : order?.buyer?.name) : OPEN_MERCHANT_NAME} verified={true} />

          {value ? (
            <Text strong style={{ color: '#EAC700' }} className="text-center mt-3">
              {tooltips[value - 1]}
            </Text>
          ) : (
            ''
          )}
          <Form form={form} name="ratingForm" onFinish={onFinish} className="w-100">
            <Row justify="center">
              <Form.Item name="rating">
                <Rate tooltips={tooltips} onChange={setValue} value={value} />
              </Form.Item>
            </Row>

            <Form.Item name="review">
              <Input.TextArea
                placeholder="Write a review (Optional)"
                rows={4}
                autoSize={{
                  minRows: 5,
                  maxRows: 5,
                }}
                showCount
                maxLength={300}
              />
            </Form.Item>

            <Form.Item shouldUpdate>
              {() => (
                <Button type="primary" size="large" block htmlType="submit" className="my-3" disabled={!form.getFieldValue('rating')} loading={loading}>
                  Submit
                </Button>
              )}
            </Form.Item>
          </Form>
        </div>
      </ErrorBoundary>
    </Modal>
  );
};

export default RateOrder;
