import React from 'react';
import { Card, Tag, Typography, Tooltip } from 'antd';
import { ClockCircleOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { UPLOAD_URL } from 'constants/ApiConstants';
import NumberText from 'components/UtilComponents/NumberText';
import TimeAgo from 'timeago-react';

const { Title, Paragraph } = Typography;

const ListingCard = ({ listing, filters, sort, search, pagination }) => {
  const navigate = useNavigate();

  return (
    <Card
      onClick={() => {
        localStorage.setItem('filters', JSON.stringify({ filters, sort, search, pagination }));
        navigate(`/buy/listing/${listing._id}`);
      }}
      hoverable
      bordered={false}
      bodyStyle={{ padding: '15px', cursor: 'pointer' }}
      cover={<img alt={listing.product?.name} style={{ width: '100%', height: 'auto' }} src={`${UPLOAD_URL}/${listing?.product?.image}`} />}>
      <Tooltip placement="right" title={'Expires on ' + new Date(listing.metadata?.expiresOn).toDateString()}>
        <Tag color="red" style={{ position: 'absolute', top: 10, right: 5 }}>
          <ClockCircleOutlined />
          &nbsp; <TimeAgo datetime={listing.metadata?.expiresOn} locale="en_US" />
        </Tag>
      </Tooltip>

      <div>
        <Title level={5} className="fw-medium">
          {listing.product?.name}
        </Title>
        <Paragraph>
          {listing.qty} {listing.subUnit} / {listing?.frequency} {listing?.frequency === 'Monthly' ? ` for ${listing?.duration} months` : ''}
        </Paragraph>
      </div>

      <div className="my-2">
        <Paragraph>
          <NumberText prefix={listing.currency} value={listing.tradingPrice / 100} suffix={`/ ${listing.subUnit}`} />
        </Paragraph>
        <small className="text-secondary">{listing.gstPercentage > 0 ? `+${listing.gstPercentage} % GST` : 'Inclusive of GST'}</small>
      </div>

      <div className="my-2">
        <Paragraph>
          {listing?.pickupAddress?.city}, {listing?.pickupAddress?.state}
        </Paragraph>
        <small className="text-secondary">Pickup Location</small>
      </div>
    </Card>
  );
};

export default ListingCard;
