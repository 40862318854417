import React from 'react';
import { Avatar, Button, Card, Col, Row, Typography } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import NumberText from 'components/UtilComponents/NumberText';
import IconPayment from 'assets/images/payment-process.png';

const { Paragraph, Title } = Typography;

const DashboardPaymentCard = ({ type, title, value, onClick }) => {
  const style =
    type === 'overdue'
      ? {
          padding: '15px 0',
          backgroundColor: '#FDEBE7',
          border: '1px solid #F25533',
          borderRadius: '10px',
          cursor: 'pointer',
        }
      : {
          padding: '15px 0',
          border: '1px solid #f1f1f1',
          borderRadius: '10px',
          cursor: 'pointer',
        };
  return (
    <Card bodyStyle={style} bordered={false} onClick={onClick}>
      <Row align="middle" justify="center">
        <Col className="mr-2">
          <Avatar shape="square" size={40} src={IconPayment} />
        </Col>
        <Col span={16}>
          <Paragraph type="secondary">{title}</Paragraph>
          <Title level={5}>
            <NumberText prefix={'INR'} value={value / 100} />
          </Title>
        </Col>
        <Col span={3}>
          <Button type="link" onClick={onClick}>
            <RightOutlined style={{ color: type === 'overdue' ? '#F25533' : '#2181DF' }} />
          </Button>
        </Col>
      </Row>
    </Card>
  );
};

export default DashboardPaymentCard;
