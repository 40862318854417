import { keyBy } from 'lodash';

const checkForAnomaly = (type, values) => {
  let detectedAnomaly = null;
  if (['create-rfq', 'send-quote', 'open-listing', 'open-listing-offer', 'purchase-order'].includes(type)) {
    const product = values.product;
    const subUnits = keyBy(values.subUnits, '_id');
    const subUnit = values.subUnit;
    const conversionRate = subUnits[subUnit]?.conversionRate || 0;
    const price = values.price * 100;
    const min = (product?.metadata?.minPrice || 0) * conversionRate;
    const max = (product?.metadata?.maxPrice || 0) * conversionRate;
    if (price > 0 && min >= 0 && max > 0) {
      if (price < min) {
        detectedAnomaly = 'Anomaly Detected! The price you have entered is less than nominal price!';
      } else if (price > max) {
        detectedAnomaly = 'Anomaly Detected! The price you have entered is more than nominal price!';
      }
    }
  }
  if (type === 'dispatch') {
    const qty = values.type === 'Purchase' ? values.dispatchedQty : values.receivedQty;
    const subUnit = values.subUnit;
    if (qty) {
      if (subUnit === 'KG' && (qty < 300 || qty > 50000)) {
        detectedAnomaly = `Anomaly Detected! The quantity you have entered is unlikely. Please ensure the quantity is entered in ${subUnit}!`;
      } else if (subUnit === 'MT' && (qty < 0.3 || qty > 50)) {
        detectedAnomaly = `Anomaly Detected! The quantity you have entered is unlikely. Please ensure the quantity is entered in ${subUnit}!`;
      } else if (subUnit === 'L' && (qty < 1 || qty > 50000)) {
        detectedAnomaly = `Anomaly Detected! The quantity you have entered is unlikely. Please ensure the quantity is entered in ${subUnit}!`;
      } else if (subUnit === 'KL' && (qty < 0.001 || qty > 50)) {
        detectedAnomaly = `Anomaly Detected! The quantity you have entered is unlikely. Please ensure the quantity is entered in ${subUnit}!`;
      }
    }
  }
  return detectedAnomaly;
};

export { checkForAnomaly };
