import React, { useEffect, useState } from 'react';
import { Alert, Avatar, Button, Col, Divider, Dropdown, Image, Modal, PageHeader, Row, Input, Skeleton, Space, Tag, Typography, message, Tooltip, Select } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { motion } from 'framer-motion';
import { format, differenceInDays } from 'date-fns';
import { InfoCircleOutlined, DownOutlined, UpOutlined, FileTextOutlined, FileImageOutlined, MoreOutlined, ClockCircleOutlined } from '@ant-design/icons';
import BackIcon from 'components/UtilComponents/BackIcon';
import DetailsGrid from 'components/UtilComponents/DetailsGrid';
import NumberText from 'components/UtilComponents/NumberText';
import ModalCloseIcon from 'components/UtilComponents/ModalCloseIcon';
import { BUSINESS_URL, OPEN_RFQ_QUOTE_URL, OPEN_RFQ_URL, PRODUCT_URL, UPLOAD_URL } from 'constants/ApiConstants';
import axios from 'axios';
import ContentLoader from 'react-content-loader';
import { AccessDenied, PageNotFound } from 'components/Errors';
import { getBreakPoint } from 'utils/layout';
import TimeAgo from 'timeago-react';
import ExtendRFQExpiration from 'components/Modals/Buy/ExtendRFQExpiration';
import { keyBy } from 'lodash';

const { Text, Title, Paragraph } = Typography;

const RequestedQuoteDetails = ({ openRfq, quote, onRefresh }) => {
  const showActions = openRfq?.status === 'Created' && quote.status === 'Created';

  const [showMore, setShowMore] = useState(false);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [closingReason, setClosingReason] = useState(null);
  const [error, setError] = useState(null);
  const [closingLoader, setClosingLoader] = useState(false);

  const onShowMore = () => setShowMore(!showMore);

  const onAcceptQuote = () => {
    Modal.confirm({
      title: `Are you sure you want to accept quote and place order?`,
      bodyStyle: { backgroundColor: '#fff', borderRadius: 10 },
      icon: null,
      closable: true,
      closeIcon: <ModalCloseIcon />,
      cancelText: 'Cancel',
      cancelButtonProps: {
        type: 'primary',
        ghost: true,
      },
      onCancel() {},
      okText: 'Accept',
      okButtonProps: {
        loading: loading,
      },
      onOk: async () => {
        if (loading) return;
        setLoading(true);
        try {
          const res = await axios.put(`${OPEN_RFQ_QUOTE_URL}/${quote?._id}`, {
            status: 'Accepted',
          });

          if (res.data.status === 'error') {
            message.error(res.data.error);
          } else {
            message.success('Quote Accepted and Order Placed Successfully');
            onRefresh();
          }
        } catch (err) {
          message.error(err.message);
        }
        setLoading(false);
      },
    });
  };

  const handleOk = () => {
    if (closingReason === null) {
      return setError('Please provide valid reason for rejection');
    }
    setClosingLoader(true);
    setTimeout(async () => {
      try {
        const res = await axios.put(`${OPEN_RFQ_QUOTE_URL}/${quote?._id}`, {
          status: 'Rejected',
          metadata: {
            closingReason: closingReason,
          },
        });
        if (res.data.status === 'error') {
          setError(res.data.error);
        } else {
          setVisible(false);
          message.success('Quote Rejected Successfully');
          onRefresh();
          setClosingReason(null);
          setError(null);
        }
      } catch (err) {
        setError(err.message);
      }
      setClosingLoader(false);
    }, 100);
  };
  const handleCancel = () => {
    setVisible(false);
    setClosingReason(null);
    setError(null);
    setClosingLoader(false);
  };

  return (
    <>
      <div className="my-5" style={{ transition: 'visibility 0s linear 0s, opacity 300ms' }}>
        <Row gutter={16}>
          <Col xs={24} sm={24} md={8} lg={8}>
            <Image alt={quote?.product?.name} height={250} src={`${UPLOAD_URL}/${quote?.product?.image}`} />
          </Col>

          <Col xs={24} sm={24} md={16} lg={16}>
            <Row gutter={[24, 24]} align="middle" className="my-4">
              <Col xs={12} sm={12} md={6} lg={12}>
                <DetailsGrid title="Price Quoted" description={<NumberText bold value={quote?.tradingPrice / 100} prefix={quote?.currency} suffix={`/ ${quote?.subUnit}`} />} />
              </Col>
              <Col xs={12} sm={12} md={6} lg={12}>
                <DetailsGrid
                  title="Quantity Available"
                  description={
                    <Text strong>
                      {quote?.qty} {quote?.subUnit}
                    </Text>
                  }
                />
              </Col>
              {!showMore && (
                <>
                  <Col xs={12} sm={12} md={6} lg={12}>
                    <Row wrap={false}>
                      <Col className={'ml-2'}>
                        {'Price Basis' && (
                          <Paragraph type="secondary" className="text-small">
                            {'Price Basis'}
                          </Paragraph>
                        )}
                        <Paragraph>&bull; {`GST - ${quote?.tradingPriceBasis?.gst.value ? ' Extra' : 'Included'}`}</Paragraph>
                        <Paragraph>
                          &bull; {`Delivery - ${quote?.transportType}`}
                          {quote?.transportType === 'Extra' ? <span>{`(${quote?.currency === 'INR' ? '₹' : '$'}${quote?.transportCharges / 100} / ${quote?.subUnit})`}</span> : ''}
                        </Paragraph>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12} sm={12} md={6} lg={12}>
                    <DetailsGrid title="Pickup Location" description={`${quote?.pickupAddress?.city}, ${quote?.pickupAddress?.state}`} />
                  </Col>
                </>
              )}
              <Col xs={12} sm={12} md={6} lg={12}>
                <DetailsGrid title="Delivery by" description={quote?.expectedDeliveryOn ? format(new Date(quote.expectedDeliveryOn), 'dd MMM yyyy') : ''} />
              </Col>

              <Col xs={12} sm={12} md={6} lg={12}>
                <DetailsGrid title="Quote On" description={quote?.createdAt ? format(new Date(quote.createdAt), 'dd MMM yyyy') : ''} />
              </Col>
            </Row>

            <Row align={'middle'}>
              <Col>
                <Button type="link" className="p-0" onClick={onShowMore}>
                  {showMore ? (
                    <>
                      Close Details <UpOutlined style={{ fontSize: 10 }} />
                    </>
                  ) : (
                    <>
                      View Details <DownOutlined style={{ fontSize: 10 }} />
                    </>
                  )}
                </Button>
              </Col>
            </Row>

            {showMore && (
              <>
                <div>
                  <DetailsGrid icon={<Avatar src={require('assets/images/icon-cash.png')} shape="square" size="small" />} description="Price Basis" />
                  <Row justify="space-between" className="mt-3">
                    <Text>Price Per Unit</Text>
                    <NumberText bold prefix={quote?.currency} value={quote?.tradingPrice / 100} suffix={`/ ${quote?.subUnit}`} />
                  </Row>
                  <Row justify="space-between" className="my-1">
                    <Text>Quantity</Text>
                    <Text strong>
                      {quote?.qty} {quote?.subUnit}
                    </Text>
                  </Row>
                  <Divider className="my-0" />
                  <Row justify="space-between" className="my-1">
                    <Text>{quote?.tradingPriceBasis?.price?.label}</Text>
                    <NumberText bold prefix={quote?.currency} value={quote?.tradingPriceBasis?.price?.value / 100} />
                  </Row>
                  {quote?.tradingPriceBasis?.deliveryCharges?.value > 0 ? (
                    <Row justify="space-between" className="my-1">
                      <Text>{quote?.tradingPriceBasis?.deliveryCharges?.label}</Text>
                      <NumberText bold prefix={quote?.currency} value={quote?.tradingPriceBasis?.deliveryCharges?.value / 100} />
                    </Row>
                  ) : (
                    ''
                  )}
                  {quote?.tradingPriceBasis?.gst?.value > 0 ? (
                    <Row justify="space-between" className="my-1">
                      <Text>{quote?.tradingPriceBasis?.gst?.label}</Text>
                      <NumberText bold prefix={quote?.currency} value={quote?.tradingPriceBasis?.gst?.value / 100} />
                    </Row>
                  ) : (
                    <Row justify="space-between" className="my-1">
                      <Text>{quote?.tradingPriceBasis?.gst?.label}</Text>
                      <Text strong>Included</Text>
                    </Row>
                  )}
                  <Divider className="my-0" />
                  <Row justify="space-between" className="my-1">
                    <Text>{quote?.tradingPriceBasis?.total?.label}</Text>
                    <NumberText bold prefix={quote?.currency} value={quote?.tradingPriceBasis?.total?.value / 100} />
                  </Row>
                  <Divider className="my-0" />
                  <Tag icon={<InfoCircleOutlined />} color="#B4B4B4" className="my-3">
                    Final invoice amount will be calculated after loading
                  </Tag>
                </div>

                <Space direction="vertical" size={'large'} className="w-100 my-2">
                  <DetailsGrid
                    icon={<Avatar src={require('assets/images/icon-cash.png')} shape="square" size="small" />}
                    title={<Text strong>Terms of Payment</Text>}
                    description={quote?.paymentTerm?.name}
                  />
                  <DetailsGrid icon={<Avatar src={require('assets/images/icon-delivery.png')} shape="square" size="small" />} description="Delivery" />
                  <Row wrap={false} style={{ marginTop: '-2%' }}>
                    <Col span={12}>
                      <DetailsGrid title="Pickup Location" description={`${quote?.pickupAddress?.city}, ${quote?.pickupAddress?.state}`} />
                    </Col>
                    <Col span={12}>
                      <DetailsGrid title="Delivery Charges" description={quote?.transportType} />
                    </Col>
                  </Row>
                  <DetailsGrid
                    icon={<Avatar src={require('assets/images/icon-quality.png')} shape="square" size="small" />}
                    title={<Text strong>Quality Remarks</Text>}
                    description={quote?.remark || 'N/A'}
                  />
                </Space>
                {quote?.qualityReportFile && (
                  <Button type="link" href={`${UPLOAD_URL}/${quote?.qualityReportFile}`} icon={<FileTextOutlined />} target="_blank">
                    View Quality Report
                  </Button>
                )}
                {quote?.productImageFile && (
                  <Button type="link" href={`${UPLOAD_URL}/${quote?.productImageFile}`} icon={<FileImageOutlined />} target="_blank">
                    View Product Image
                  </Button>
                )}
              </>
            )}

            <Divider className="my-3" />
            {showActions ? (
              <Row gutter={24} className="my-3">
                <Col span={12}>
                  <Button type="primary" block size="large" onClick={onAcceptQuote}>
                    Place Order
                  </Button>
                </Col>
                <Col span={12}>
                  <Button type="primary" ghost block size="large" onClick={() => setVisible(true)}>
                    Reject
                  </Button>
                </Col>
              </Row>
            ) : (
              <Alert
                type={quote.status === 'Rejected' ? 'error' : 'info'}
                showIcon
                message={quote.status === 'Accepted' ? 'Quote has been Accepted & Order has been Placed' : quote.status === 'Rejected' ? 'Quote has been Rejected' : 'Quote is Yet to be Accepted'}
              />
            )}
          </Col>
        </Row>
        <Divider />
      </div>
      <Modal
        title="Are you sure you want to reject the quote?"
        open={visible}
        onOk={handleOk}
        okType="danger"
        closeIcon={<ModalCloseIcon />}
        okButtonProps={{ loading: closingLoader }}
        okText={closingLoader ? 'Rejecting...' : 'Reject'}
        onCancel={handleCancel}>
        <div>
          <div>
            <Input.TextArea
              value={closingReason}
              maxLength={200}
              placeholder="Enter valid closing reason"
              onChange={(e) => {
                setClosingReason(e.target.value);
                setError(null);
              }}
            />
          </div>
          <motion.div
            initial={{ opacity: 0, marginBottom: 0 }}
            animate={{
              opacity: error ? 1 : 0,
              marginTop: error ? 20 : 0,
              marginBottom: error ? 40 : 0,
            }}>
            <Alert type={'error'} showIcon message={error}></Alert>
          </motion.div>
        </div>
      </Modal>
    </>
  );
};

const OpenRFQDetails = () => {
  const isMobile = !getBreakPoint().includes('lg');
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [accessDenied, setAccessDenied] = useState(false);
  const [openRfq, setOpenRfq] = useState(null);
  const [quotes, setQuotes] = useState([]);
  const [visible, setVisible] = useState(false);
  const [extendVisible, setExtendVisible] = useState(false);
  const [closingReason, setClosingReason] = useState(null);
  const [reason, setReason] = useState(null);
  const [error, setError] = useState(null);
  const [closingLoader, setClosingLoader] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [qualitiesById, setQualitiesById] = useState({});

  useEffect(() => {
    onLoad();
  }, []);
  useEffect(() => {
    if (openRfq?.productQuality) onLoadProduct();
  }, [openRfq]);

  const onLoad = async () => {
    setLoading(true);
    try {
      const businessRes = await axios.get(`${BUSINESS_URL}/me`);
      const res = await axios.get(`${OPEN_RFQ_URL}/${id}?&populate=true`);
      if (res.data.code === 1006 || res.data.code === 1007) {
        setNotFound(true);
        return setLoading(false);
      }
      if (businessRes?.data?.business?._id !== res.data.openrequirement?.buyer?._id) {
        setAccessDenied(true);
        return setLoading(false);
      }

      const quoteRes = await axios.get(`${OPEN_RFQ_QUOTE_URL}?&populate=true`, {
        headers: {
          'X-API-Filters': JSON.stringify({
            enquiry: [id],
          }),
        },
      });
      if (res.data.status === 'error') {
        message.error(res.data.error);
      } else if (quoteRes.data.status === 'error') {
        message.error(quoteRes.data.error);
      } else {
        setOpenRfq(res.data.openrequirement);
        setQuotes(quoteRes.data.openrequirementquotes);
      }
    } catch (err) {
      message.error(err.message);
    }
    setLoading(false);
  };

  const onLoadProduct = async () => {
    setLoading(true);
    try {
      const productRes = await axios.get(`${PRODUCT_URL}/${openRfq?.product?._id}?populate=true`);
      if (productRes.data.status === 'error') {
        message.error(productRes.data.error);
      } else {
        setQualitiesById(keyBy(productRes.data.product?.qualities, '_id'));
      }
    } catch (error) {
      message.error(error.message);
    }
    setLoading(false);
  };

  const handleDelete = () => {
    if (!reason) return setError('Please select valid closing reason');
    if (reason === 'Others' && !closingReason) return setError('Please provide valid closing reason');
    setClosingLoader(true);
    setTimeout(async () => {
      try {
        const res = await axios.put(`${OPEN_RFQ_URL}/${openRfq._id}`, {
          status: 'Closed',
          metadata: {
            closingReason: reason === 'Others' ? closingReason : reason,
          },
        });
        if (res.data.status === 'error') {
          setError(res.data.error);
        } else {
          setVisible(false);
          message.success('Open RFQ Deleted Successfully');
          navigate('/buy/rfqs/open-rfqs');
          setClosingReason(null);
          setReason(null);
          setError(null);
        }
      } catch (err) {
        setError(err.message);
      }
      setClosingLoader(false);
    }, 100);
  };

  const onRepost = (reload) => {
    if (reload) {
      onLoad();
    }
    setExtendVisible(!extendVisible);
  };

  const handleCancel = () => {
    setVisible(false);
    setClosingLoader(false);
    setClosingReason(null);
    setReason(null);
    setError(null);
  };

  const items = [
    {
      key: 'remove-open-rfq',
      label: (
        <Button type="text" onClick={() => setVisible(true)}>
          Remove Open RFQ
        </Button>
      ),
    },
  ];

  if (accessDenied) return <AccessDenied />;

  if (notFound) return <PageNotFound />;

  const isExpired = openRfq?.metadata?.expiresOn ? new Date(openRfq?.metadata?.expiresOn) < new Date() : true;
  const expiringIn = openRfq?.metadata?.expiresOn ? differenceInDays(new Date(openRfq?.metadata?.expiresOn), new Date()) : 0;

  return (
    <>
      <div className="app-container">
        <PageHeader
          onBack={() => navigate(-1)}
          backIcon={<BackIcon />}
          title={
            loading ? (
              <Skeleton.Input active={loading} size={'default'} shape={'default'} block={true} />
            ) : (
              <Row align="middle" wrap={false}>
                <Title level={4} className="fw-bold">
                  My Requirement
                </Title>
                {!isMobile && (
                  <>
                    &nbsp; | &nbsp;
                    <Avatar src={`${UPLOAD_URL}/${openRfq?.product?.image}`} size="default" className="mr-2" />
                    <Title level={4} className="fw-bold">
                      {openRfq?.product?.name} | {openRfq?.fromQty}
                      {openRfq?.toQty ? ` - ${openRfq?.toQty}` : ''} {openRfq?.subUnit}
                    </Title>
                    <Tooltip placement="right" title={'Expires on ' + new Date(openRfq?.metadata?.expiresOn).toDateString()}>
                      <Tag className="m-2" style={{ color: 'red', fontWeight: 'normal' }}>
                        <ClockCircleOutlined />
                        &nbsp; Expires&nbsp;
                        <TimeAgo datetime={openRfq?.metadata?.expiresOn} locale="en_US" />
                      </Tag>
                    </Tooltip>
                  </>
                )}
              </Row>
            )
          }
          footer={
            loading ? (
              <Skeleton.Input active={loading} size={'default'} shape={'default'} />
            ) : (
              isMobile && (
                <Row align="middle" wrap={false}>
                  <Avatar src={`${UPLOAD_URL}/${openRfq?.product?.image}`} size="default" className="mr-2" />
                  <Title level={4} className="fw-bold">
                    {openRfq?.product?.name}
                  </Title>
                </Row>
              )
            )
          }
          extra={
            openRfq?.status === 'Created' && (
              <Dropdown menu={{ items }} trigger="click">
                <Button type="text" icon={<MoreOutlined />} size="large" />
              </Dropdown>
            )
          }
          className="px-0"
        />
      </div>
      <Divider className="mt-1 mb-6" />
      <div className="app-container">
        {loading && (
          <ContentLoader width="100%" height="80vh" title="Loading..." backgroundColor="#d9d9d9" foregroundColor="#ededed">
            {/* First row */}
            <rect x="0" y="25" rx="5" ry="5" width="250" height="200" />
            <rect x="275" y="25" rx="0" ry="0" width="225" height="40" />
            <rect x="275" y="75" rx="0" ry="0" width="200" height="40" />
            <rect x="650" y="25" rx="0" ry="0" width="225" height="40" />
            <rect x="650" y="75" rx="0" ry="0" width="200" height="40" />
            {/* Second row */}
            <rect x="0" y="250" rx="5" ry="5" width="250" height="200" />
            <rect x="275" y="250" rx="0" ry="0" width="225" height="40" />
            <rect x="275" y="300" rx="0" ry="0" width="200" height="40" />
            <rect x="650" y="250" rx="0" ry="0" width="225" height="40" />
            <rect x="650" y="300" rx="0" ry="0" width="200" height="40" />
          </ContentLoader>
        )}
        {!loading && (
          <>
            {/* <Row gutter={16} align="middle" className="mb-2">
              <Col>
                <CompanyName showLogo={false} name={openRfq?.buyer?.name} verified={true} />
              </Col>
            </Row> */}

            <Row gutter={[32, 32]} className="mt-4">
              <Col xs={24} sm={12} md={12} lg={8}>
                <DetailsGrid
                  icon={<Avatar src={require('assets/images/icon-quantity.png')} shape="square" size="default" />}
                  title="Required Quantity"
                  description={`${openRfq?.fromQty}${openRfq?.toQty > 0 ? ` - ${openRfq?.toQty}` : ''} ${openRfq?.subUnit} / ${openRfq?.frequency}`}
                />
              </Col>

              <Col xs={24} sm={12} md={12} lg={8}>
                <DetailsGrid
                  icon={<Avatar src={require('assets/images/icon-delivery.png')} shape="square" size="default" />}
                  title="Delivery Location"
                  description={`${openRfq?.deliveryAddress?.city}, ${openRfq?.deliveryAddress?.state}`}
                />
              </Col>

              <Col xs={24} sm={12} md={12} lg={8}>
                <DetailsGrid
                  icon={<Avatar src={require('assets/images/icon-calendar.png')} shape="square" size="default" />}
                  title="Posted On"
                  description={openRfq?.createdAt ? format(new Date(openRfq?.createdAt), 'dd MMM yyyy') : '-'}
                />
              </Col>

              <Col xs={24} sm={24} md={12} lg={8}>
                <DetailsGrid
                  icon={<Avatar src={require('assets/images/icon-calendar.png')} shape="square" size="default" />}
                  title="Delivery By"
                  description={openRfq?.preferredDeliveryOn ? format(new Date(openRfq?.preferredDeliveryOn), 'dd MMM yyyy') : '-'}
                />
              </Col>

              <Col xs={24} sm={24} md={12} lg={8}>
                <DetailsGrid
                  icon={<Avatar src={require('assets/images/icon-cash.png')} shape="square" size="default" />}
                  title="Price"
                  description={<DetailsGrid description={<NumberText bold value={openRfq?.price / 100} prefix={openRfq?.currency} suffix={`/ ${openRfq?.subUnit}`} />} />}
                />
              </Col>

              <Col xs={24} sm={24} md={12} lg={8}>
                <DetailsGrid
                  icon={<Avatar src={require('assets/images/auto-price-update.png')} shape="square" size="default" />}
                  title="Auto Price Update"
                  description={openRfq?.metadata?.autoPriceUpdate ? 'Yes, Update the price automatically.' : 'No, I will update the price manually every time.'}
                />
              </Col>

              <Col xs={24} sm={24} md={12} lg={8}>
                <DetailsGrid icon={<Avatar src={require('assets/images/icon-quality.png')} shape="square" size="default" />} title="Quality Testing" description={openRfq?.qualityTesting} />
              </Col>

              <Col xs={24} sm={24} md={12} lg={8}>
                <DetailsGrid icon={<Avatar src={require('assets/images/trial-quantity.png')} shape="square" size="default" />} title="Trial Quantity" description={openRfq?.trialQuantity} />
              </Col>

              <Col xs={24} sm={24} md={12} lg={8}>
                <DetailsGrid icon={<Avatar src={require('assets/images/quality-remarks.png')} shape="square" size="default" />} title="Quality Remarks" description={openRfq?.remark || '-'} />
              </Col>

              <Col xs={24} sm={24} md={12} lg={8}>
                <DetailsGrid icon={<Avatar src={require('assets/images/icon-cash.png')} shape="square" size="default" />} title="Payment Terms" description={openRfq?.paymentTerm?.name} />
              </Col>

              <Col xs={24} sm={24} md={12} lg={16}>
                <DetailsGrid
                  icon={<Avatar src={require('assets/images/grade.png')} shape="square" size="default" />}
                  title="Grade"
                  description={
                    <>
                      <Text strong>{qualitiesById[openRfq?.productQuality]?.grade}</Text>
                      <ul>
                        {qualitiesById[openRfq?.productQuality]?.descriptions?.map((e) => (
                          <li>{e}</li>
                        ))}
                      </ul>
                    </>
                  }
                />
              </Col>
            </Row>
            {!isExpired && expiringIn <= 3 && (
              <Row gutter={16} className="mt-5" style={{ marginBottom: '50px' }}>
                <Col span={24}>
                  <Alert
                    className="py-1 px-2 text-small"
                    message={<Text type="danger">Expiring Soon</Text>}
                    type="error"
                    showIcon={true}
                    icon={<ClockCircleOutlined />}
                    action={
                      <Button type="link" size="small" onClick={() => onRepost()}>
                        Extend Expiration
                      </Button>
                    }
                  />
                </Col>
              </Row>
            )}
          </>
        )}
        {!loading && quotes?.length > 0 && (
          <>
            <Divider />
            <Title level={5}>
              Quote{openRfq?.metadata?.quotesReceived > 1 ? 's' : ''} Received ({openRfq?.metadata?.quotesReceived})
            </Title>
            {quotes.map((quote) => {
              // component defined at the top of this file
              return <RequestedQuoteDetails key={quote?._id} openRfq={openRfq} quote={quote} onRefresh={onLoad} />;
            })}
          </>
        )}
      </div>

      <Modal
        title="Are you sure you want to delete this Open RFQ?"
        open={visible}
        onOk={handleDelete}
        okText={closingLoader ? 'Deleting..' : 'Delete'}
        okType="danger"
        closeIcon={<ModalCloseIcon />}
        okButtonProps={{ loading: closingLoader }}
        onCancel={handleCancel}>
        <div>
          <div>
            <Alert
              className="mb-4"
              type="warning"
              showIcon
              description="Please note that once this Open RFQ is deleted, it won't be possible to accept any new quotes for it. This action is irreversible, so please proceed with caution."
            />
            <Select
              placeholder="Select Reason"
              size="large"
              style={{ width: '270px', marginBottom: '15px' }}
              value={reason}
              onChange={(reason) => {
                setError(null);
                setReason(reason);
              }}>
              <Select.Option value="Invalid Quantity / Invalid Price">Invalid Quantity / Invalid Price</Select.Option>
              <Select.Option value="Mistakenly Created">Mistakenly Created</Select.Option>
              <Select.Option value="Requirement Changes">Requirement Changes</Select.Option>
              <Select.Option value="Others">Others</Select.Option>
            </Select>
            {reason === 'Others' && (
              <Input.TextArea
                value={closingReason}
                maxLength={200}
                placeholder="Enter valid closing reason"
                onChange={(e) => {
                  setClosingReason(e.target.value);
                  setError(null);
                }}
              />
            )}
          </div>
          <motion.div
            initial={{ opacity: 0, marginBottom: 0 }}
            animate={{
              opacity: error ? 1 : 0,
              marginTop: error ? 20 : 0,
              marginBottom: error ? 40 : 0,
            }}>
            <Alert type={'error'} showIcon message={error}></Alert>
          </motion.div>
        </div>
      </Modal>
      <ExtendRFQExpiration visible={extendVisible} close={onRepost} rfq={openRfq} />
    </>
  );
};

export default OpenRFQDetails;
