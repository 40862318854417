import React, { useEffect, useState } from 'react';
import { List, message, Typography } from 'antd';
import Loader from 'components/UtilComponents/Loader';
import DispatchScheduleCard from 'components/Cards/DispatchScheduleCard';
import { getBreakPoint } from 'utils/layout';

const { Text } = Typography;

const CompletedSchedules = ({ tab }) => {
  const isMobile = !getBreakPoint().includes('sm');
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (tab === 'completed') {
      onLoad();
    }
  }, [tab]);

  const onLoad = async () => {
    setLoading(true);
    try {
      setData([{ _id: '12345' }, { _id: '12345' }]);
      // code goes here
    } catch (err) {
      message.error(err.message);
    }
    setLoading(false);
  };

  if (loading) return <Loader />;

  return (
    <>
      <Text type="secondary">
        {data.length} result{data.length > 1 && 's'} in total
      </Text>
      <List
        className="mt-3"
        header={null}
        footer={null}
        grid={{ gutter: [16, 8], column: isMobile ? 1 : 2 }}
        dataSource={data}
        pagination={false}
        split={false}
        renderItem={(item) => (
          <List.Item key={item?._id}>
            <DispatchScheduleCard type="sell" data={item} />
          </List.Item>
        )}
      />
    </>
  );
};

export default CompletedSchedules;
