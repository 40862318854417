import React, { useEffect, useState } from 'react';
import { Alert, Avatar, Button, Card, Col, Divider, List, Row, Tooltip, Typography } from 'antd';
import TimeAgo from 'timeago-react';
import { ClockCircleOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { differenceInDays, format } from 'date-fns';
import DetailsGrid from 'components/UtilComponents/DetailsGrid';
import { UPLOAD_URL } from 'constants/ApiConstants';
import ExtendListingExpiration from 'components/Modals/Sell/ExtendListingExpiration';
import { getBreakPoint } from 'utils/layout';
import { posthog } from 'posthog-js';

const { Text, Title } = Typography;

const MyListingCard = ({ listing, onRefresh = () => {} }) => {
  const viewName = 'sell | my-listings';
  const isMobile = !getBreakPoint().includes('lg');
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);

  const onViewWholesaleListing = () => navigate(`/sell/my-listings/wholesale/${listing._id}`);

  const isExpired = listing?.metadata?.expiresOn ? new Date(listing?.metadata?.expiresOn) < new Date() : true;
  const expiringIn = listing?.metadata?.expiresOn ? differenceInDays(new Date(listing?.metadata?.expiresOn), new Date()) : 0;
  const expiresOn = listing?.metadata?.expiresOn;
  const postedOn = listing?.createdAt ? format(new Date(listing?.createdAt), 'dd MMM yyyy') : '';

  const onRepost = (reload) => {
    if (reload) {
      onRefresh();
    }
    setVisible(!visible);
  };

  useEffect(() => {
    if (visible) {
      posthog.capture(`$${viewName} | $extend-open-listing-clicked`, {
        $set: {
          lastActivityDate: new Date().toISOString(),
        },
      });
    }
  }, [visible]);

  return (
    <List.Item>
      <Card size={isMobile ? 'small' : 'default'}>
        <Row justify="flex-start" align="middle">
          <Col span={16}>
            <Row gutter={10} justify="flex-start" wrap={false}>
              <Col>
                <Avatar src={`${UPLOAD_URL}/${listing?.product?.image}`} shape="square" size="large" />
              </Col>
              <Col>
                <Title level={5} className="fw-medium">
                  {listing?.product?.name}
                </Title>
                <Text>
                  {listing?.qty} {listing?.subUnit}
                </Text>
              </Col>
            </Row>
          </Col>
          <Col span={8} flex={1} className="d-flex justify-content-end">
            {isExpired ? (
              <Button type="link" onClick={() => onRepost()}>
                Repost
              </Button>
            ) : (
              <Button type="primary" onClick={onViewWholesaleListing}>
                View
              </Button>
            )}
          </Col>
        </Row>

        <Row gutter={16} className="mt-3">
          <Col
            style={{
              cursor: listing?.metadata?.offersReceived > 0 ? 'pointer' : 'default',
            }}
            onClick={listing?.metadata?.offersReceived > 0 && onViewWholesaleListing}
            span={12}>
            <DetailsGrid
              icon={<Avatar src={require('assets/images/icon-cart.png')} shape="square" size="small" />}
              title={'Total Received'}
              description={`${listing?.metadata?.offersReceived} Orders`}
            />
          </Col>
          <Col span={12}>
            <DetailsGrid
              icon={<Avatar src={require('assets/images/icon-location-dark.png')} shape="square" size="small" />}
              title={'Pickup Location'}
              description={`${listing?.pickupAddress?.city}, ${listing?.pickupAddress?.state}`}
            />
          </Col>
        </Row>

        <Divider dashed={true} className="my-3" />

        <Row gutter={16} align="middle" justify="space-between">
          <Col span={12}>
            <Text className="ml-1 text-small" type={isExpired && 'danger'}>
              <ClockCircleOutlined />{' '}
              {!isExpired ? (
                <Tooltip placement="right" title={new Date(expiresOn).toDateString()}>
                  Expires <TimeAgo datetime={expiresOn} locale="en_US" />
                </Tooltip>
              ) : (
                'Expired'
              )}
            </Text>
          </Col>
          <Col span={12}>
            <Text className="text-small float-right">Posted on {postedOn}</Text>
          </Col>
        </Row>
        {!isExpired && expiringIn <= 3 && (
          <Row gutter={16} className="mt-3">
            <Col span={24}>
              <Alert
                className="py-1 px-2 text-small"
                message={<Text type="danger">Expiring Soon</Text>}
                type="error"
                showIcon={true}
                icon={<ClockCircleOutlined />}
                action={
                  <Button type="link" size="small" onClick={() => onRepost()}>
                    Extend Expiration
                  </Button>
                }
              />
            </Col>
          </Row>
        )}
      </Card>
      <ExtendListingExpiration visible={visible} close={onRepost} listing={listing} />
    </List.Item>
  );
};

export default MyListingCard;
