import React, { useEffect, useState } from 'react';
import { Table, Tooltip, Typography } from 'antd';
import NumberText from 'components/UtilComponents/NumberText';
import { formatRefNo } from 'utils/formatter';
import { addDays, format } from 'date-fns';

const LedgerView = ({ collections, dispatches, payments }) => {
  const [columns, setColumns] = useState([]);
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    if (collections) {
      const columns = [
        {
          title: () => <b>PO Number</b>,
          dataIndex: 'purchaseOrderNo',
          key: 'purchaseOrderNo',
          render: (purchaseOrderNo, { dueDate }) => (
            <>
              <b>{purchaseOrderNo || 'N/A'}</b>
              <br />
              {dueDate && <span>Due Date: {format(new Date(dueDate), 'dd MMM yyyy')}</span>}
            </>
          ),
        },
        {
          title: () => <b>Total Amount</b>,
          dataIndex: 'dispatch',
          key: 'dispatch',
          render: (dispatch) => <NumberText value={(dispatch?.paymentPriceBasis?.receivable?.value || 0) / 100} prefix={'INR'} />,
        },
        {
          title: () => <b>Paid</b>,
          dataIndex: 'dispatch',
          key: 'dispatch',
          render: (dispatch) => <NumberText value={(dispatch?.paymentPriceBasis?.received?.value || 0) / 100} prefix={'INR'} />,
        },
        {
          title: () => <b>Balance</b>,
          key: 'dispatch',
          dataIndex: 'dispatch',
          render: (dispatch) => <NumberText value={(dispatch?.paymentPriceBasis?.pendingReceivable?.value || 0) / 100} prefix={'INR'} />,
        },
      ];

      setColumns(columns);
      setTableData([...collections]);
    }
  }, [collections]);

  useEffect(() => {
    if (dispatches) {
      const columns = [
        {
          title: () => <b>Dispatch No</b>,
          dataIndex: 'dispatch',
          key: 'dispatch',
          render: (dispatch, { dispatchRefNo, dispatchedOn, purchaseCreditTerm }) => (
            <>
              <b>{dispatchRefNo === 'Total' ? dispatchRefNo : formatRefNo(dispatchRefNo, 'BFD')}</b>
              <br />
              <span>
                Expected Payment Date:{' '}
                {addDays(new Date(dispatchedOn), purchaseCreditTerm || 0) > new Date() ? format(addDays(new Date(dispatchedOn), purchaseCreditTerm || 0), 'dd MMM yyyy') : 'Due soon'}
              </span>
            </>
          ),
        },
        {
          title: () => <b>Total Amount</b>,
          dataIndex: 'paymentPriceBasis',
          key: 'paymentPriceBasis',
          render: (paymentPriceBasis) => <NumberText value={(paymentPriceBasis?.payable?.value || 0) / 100} prefix={'INR'} />,
        },
        {
          title: () => <b>Paid</b>,
          dataIndex: 'paymentPriceBasis',
          key: 'paymentPriceBasis',
          render: (paymentPriceBasis) => <NumberText value={(paymentPriceBasis?.paid?.value || 0) / 100} prefix={'INR'} />,
        },
        {
          title: () => <b>Balance</b>,
          key: 'paymentPriceBasis',
          dataIndex: 'paymentPriceBasis',
          render: (paymentPriceBasis) => <NumberText value={(paymentPriceBasis?.pendingPayable?.value || 0) / 100} prefix={'INR'} />,
        },
      ];

      setColumns(columns);
      setTableData([...dispatches]);
    }
  }, [dispatches]);

  useEffect(() => {
    if (payments) {
      const columns = [
        {
          title: () => <b>Payment Date</b>,
          dataIndex: 'paymentDate',
          key: 'paymentDate',
          render: (paymentDate) => <b>{paymentDate ? format(new Date(paymentDate), 'dd MMM yyyy') : ''}</b>,
        },
        {
          title: () => <b>Payment ID</b>,
          dataIndex: 'paymentRefNo',
          key: 'paymentRefNo',
          render: (paymentRefNo, { advancedPaymentRefNo }) => <span>{advancedPaymentRefNo ? formatRefNo(advancedPaymentRefNo, 'BFAP') : formatRefNo(paymentRefNo, 'BFP')}</span>,
        },
        {
          title: () => <b>Transaction Ref No</b>,
          dataIndex: 'transactionRefNo',
          key: 'transactionRefNo',
          render: (transactionRefNo) => <span>{transactionRefNo}</span>,
        },
        {
          title: () => <b>Payment Mode</b>,
          dataIndex: 'paymentMode',
          key: 'paymentMode',
          render: (paymentMode, { paymentDate, paymentRefNo, advancedPaymentRefNo }) => <span>{paymentDate || paymentRefNo || advancedPaymentRefNo ? paymentMode : <b>{paymentMode}</b>}</span>,
        },
        {
          title: () => <b>Payment Received</b>,
          dataIndex: 'amount',
          key: 'amount',
          render: (amount) => <NumberText value={(amount || 0) / 100} prefix={'INR'} />,
        },
      ];

      setColumns(columns);
      setTableData([...payments]);
    }
  }, [payments]);

  const showSummary = () => {
    let totalPayment = null;
    let paidPayment = null;
    let pendingPayment = null;

    if (collections) {
      totalPayment = getTotal(collections, 'dispatch.paymentPriceBasis.receivable.value');
      paidPayment = getTotal(collections, 'dispatch.paymentPriceBasis.received.value');
      pendingPayment = getTotal(collections, 'dispatch.paymentPriceBasis.pendingReceivable.value');
    } else if (dispatches) {
      totalPayment = getTotal(dispatches, 'paymentPriceBasis.payable.value');
      paidPayment = getTotal(dispatches, 'paymentPriceBasis.paid.value');
      pendingPayment = getTotal(dispatches, 'paymentPriceBasis.pendingPayable.value');
    } else if (payments) {
      totalPayment = getTotal(payments, 'amount');
    }

    return (
      <>
        <Table.Summary.Row>
          <Table.Summary.Cell index={0}>
            <Typography.Text strong className="text-primary">
              <Tooltip title="Calculates entire ledger">Total </Tooltip>
            </Typography.Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={1}>
            <Typography.Text className="text-primary">
              <Tooltip title="Calculates entire ledger">{paidPayment !== null && !payments && <NumberText bold value={(totalPayment || 0) / 100} prefix={'INR'} />}</Tooltip>
            </Typography.Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={2}>
            <Typography.Text className="text-primary">{paidPayment !== null && <NumberText bold value={(paidPayment || 0) / 100} prefix={'INR'} />}</Typography.Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={3}>
            <Typography.Text className="text-primary">{pendingPayment !== null && <NumberText bold value={(pendingPayment || 0) / 100} prefix={'INR'} />}</Typography.Text>
          </Table.Summary.Cell>
          {pendingPayment === null && payments && (
            <Table.Summary.Cell index={3}>
              <Typography.Text className="text-primary">
                <Tooltip title="Calculates entire ledger">
                  <NumberText bold value={(totalPayment || 0) / 100} prefix={'INR'} />{' '}
                </Tooltip>
              </Typography.Text>
            </Table.Summary.Cell>
          )}
        </Table.Summary.Row>
      </>
    );
  };

  const getTotal = (data, path) => {
    if (data) return data.reduce((acc, item) => acc + getValueByPath(item, path), 0);
    else return 0;
  };

  const getValueByPath = (object, path) => {
    const keys = path.split('.');
    let value = object;
    keys.forEach((key) => {
      value = value?.[key];
    });
    return value || 0;
  };

  return (
    <Table
      style={{ marginBottom: 0, paddingBottom: 0 }}
      rowClassName={(record, index) => (index % 2 === 0 ? 'even-row' : 'odd-row')}
      size="large"
      bordered={false}
      showHeader={true}
      columns={columns}
      dataSource={tableData}
      summary={showSummary}
    />
  );
};

export default LedgerView;
