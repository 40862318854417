import React, { useState } from 'react';
import { Button, Card, Col, Divider, Tag, Typography, Modal, Row, Avatar, message, DatePicker, Tooltip, Form, Alert, Upload } from 'antd';
import DetailsGrid from 'components/UtilComponents/DetailsGrid';
import { DTN_URL, UPLOAD_URL, DISPATCH_URL, ORDER_URL } from 'constants/ApiConstants';
import { each, isEmpty, keyBy } from 'lodash';
import { BsTruck } from 'react-icons/bs';
import { formatRefNo } from 'utils/formatter';
import axios from 'axios';
import { HiOutlineExternalLink } from 'react-icons/hi';
import ModalCloseIcon from 'components/UtilComponents/ModalCloseIcon';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { FaTruckLoading } from 'react-icons/fa';
import { TbTruckDelivery } from 'react-icons/tb';
import { EditOutlined, FileImageOutlined, FileTextOutlined } from '@ant-design/icons';
import EditDispatch from 'components/Modals/Sell/EditDispatch';
import { useEffect } from 'react';
import OnReceivedDispatch from 'components/Modals/Buy/OnReceivedDispatch';

const { Text, Title } = Typography;
const DispatchDetails = ({ type, dispatch, tab, business, save }) => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selected, setSelected] = useState([]);
  const [editDispatchVisible, setEditDispatchVisible] = useState(false);
  const [order, setOrder] = useState({});
  const [form] = Form.useForm();

  const [dispatchData, setDispatchData] = useState('');
  const [viewReceiveDispatch, SetViewReceiveDispatch] = useState(false);

  const onViewDispatch = (id) => {
    if (type === 'received') navigate(`/buy/purchaseorders/dispatch/${id}`);
    else navigate(`/sell/orders/dispatch/${id}`);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const getColor = (status) => {
    if (status === 'Awaiting Loading') return 'gold';
    if (status === 'Loaded') return 'green';
    if (status === 'In Transit') return 'yellow';
    if (status === 'Goods Delivered') return 'green';
    if (status === 'Goods Rejected') return 'red';
    return 'gold';
  };

  const getFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  useEffect(() => {
    if (!isEmpty(selected)) {
      onLoadOrder(selected?.order?._id);
    }
  }, [selected]);

  const onLoadOrder = async (id) => {
    try {
      const res = await axios.get(`${ORDER_URL}/${id}?&populate=true`);
      if (res.data.status === 'error') {
        message.error(res.data.error);
      } else {
        setOrder(res.data.order);
      }
    } catch (err) {
      message.error(err.message);
    }
  };

  const TransitDatePicker = ({ dispatch, onChange = () => {} }) => {
    const transitUpdates = keyBy(dispatch?.transitUpdates, 'status');
    const currentTransit = transitUpdates[dispatch?.transitStatus];
    const minDate = currentTransit?.updatedOn ? new Date(currentTransit.updatedOn) : null;
    if (minDate) {
      if (dispatch?.transitStatus === 'Awaiting Loading') {
        minDate.setMinutes(minDate.getMinutes() + 5);
      } else {
        minDate.setMinutes(minDate.getMinutes() + 15);
      }
    }
    const maxDate = moment().endOf('day').toDate();
    const [transitDate, setTransitDate] = useState(minDate ? moment(minDate) : moment());

    return (
      <DatePicker
        showTime
        value={transitDate}
        onChange={(value) => {
          setTransitDate(value);
          onChange(value);
        }}
        allowClear={false}
        format="DD/MM/yyyy hh:mm A"
        disabledDate={(date) => {
          if (minDate) {
            return date.toDate() < minDate || date.toDate() > maxDate;
          }
          return date.toDate() > maxDate;
        }}
        style={{ width: '100%' }}
      />
    );
  };

  const onSwitch = (dispatch, type, mode) => {
    const transitUpdates = keyBy(dispatch?.transitUpdates, 'status');
    const currentTransit = transitUpdates[dispatch?.transitStatus];
    let transitDate = currentTransit?.updatedOn ? new Date(currentTransit.updatedOn) : null;
    if (transitDate) {
      if (type === 'move to loaded') {
        transitDate.setMinutes(transitDate.getMinutes() + 5);
      } else {
        transitDate.setMinutes(transitDate.getMinutes() + 15);
      }
    }

    Modal.confirm({
      title: `Are you sure you want to ${type} - ${formatRefNo(dispatch?.dispatchRefNo, 'BFD')}?`,
      content: (
        <>
          <span>{mode === 'transitStatus' && <TransitDatePicker dispatch={dispatch} onChange={(value) => (transitDate = value)} />}</span>
          {type === 'move to transit' && !dispatch?.purchase ? (
            <Alert className="m-2" message={`Add at least one attachment before moving to 'In Transit'`} banner type="error" />
          ) : type === 'move to transit' && dispatch?.purchase ? (
            <Alert className="m-2" message={`Review attached files before moving to 'In Transit'`} banner type="info" showIcon />
          ) : null}

          <Form form={form} layout="vertical" requiredMark={false} scrollToFirstError={true}>
            <Form.Item name="invoice" label="Invoice" getValueFromEvent={getFile}>
              <Upload maxCount={1} beforeUpload={() => false} defaultFileList={form.getFieldValue('invoice') || []} accept=".jpg, .jpeg, .png, .pdf">
                <Button size="large" icon={<FileImageOutlined />}>
                  Upload Invoice
                </Button>
              </Upload>
            </Form.Item>
            {dispatch?.purchase?.invoice && (
              <Button href={`${UPLOAD_URL}/${dispatch.purchase.invoice}`} type="link" target="_blank" style={{ marginTop: -24, marginBottom: 10 }}>
                View Existing Invoice
              </Button>
            )}

            <Form.Item name="ewayBill" label="E-Way Bill / GRN Receipt" getValueFromEvent={getFile}>
              <Upload maxCount={1} beforeUpload={() => false} defaultFileList={form.getFieldValue('ewayBill') || []} accept=".jpg, .jpeg, .png, .pdf">
                <Button size="large" icon={<FileImageOutlined />}>
                  Upload Eway Bill
                </Button>
              </Upload>
            </Form.Item>
            {dispatch?.purchase?.ewayBill && (
              <Button href={`${UPLOAD_URL}/${dispatch.purchase.ewayBill}`} type="link" target="_blank" style={{ marginTop: -24, marginBottom: 10 }}>
                View Existing E-Way Bill / GRN Receipt
              </Button>
            )}

            <Form.Item name="weightSlip" label="Weight Slip" getValueFromEvent={getFile}>
              <Upload maxCount={1} beforeUpload={() => false} defaultFileList={form.getFieldValue('weightSlip') || []} accept=".jpg, .jpeg, .png, .pdf">
                <Button size="large" icon={<FileTextOutlined />}>
                  Upload Weight Slip
                </Button>
              </Upload>
            </Form.Item>
            {dispatch?.purchase?.weightSlip && (
              <Button href={`${UPLOAD_URL}/${dispatch.purchase.weightSlip}`} type="link" target="_blank" style={{ marginTop: -24, marginBottom: 10 }}>
                View Existing Weight Slip
              </Button>
            )}

            <Form.Item name="qualityReport" label="Quality Report" getValueFromEvent={getFile}>
              <Upload maxCount={1} beforeUpload={() => false} defaultFileList={form.getFieldValue('qualityReport') || []} accept=".jpg, .jpeg, .png, .pdf">
                <Button size="large" icon={<FileTextOutlined />}>
                  Upload Quality Report
                </Button>
              </Upload>
            </Form.Item>
            {dispatch?.purchase?.qualityReport && (
              <Button href={`${UPLOAD_URL}/${dispatch.purchase.qualityReport}`} type="link" target="_blank" style={{ marginTop: -24, marginBottom: 10 }}>
                View Existing Quality Report
              </Button>
            )}

            <Form.Item name="attachments" label="Attachments" getValueFromEvent={getFile}>
              <Upload multiple beforeUpload={() => false} defaultFileList={form.getFieldValue('attachments') || []} accept=".jpg, .jpeg, .png, .pdf">
                <Button size="large" icon={<FileTextOutlined />}>
                  Upload Additional Files
                </Button>
              </Upload>
            </Form.Item>
            {dispatch?.purchase?.attachments && (
              <div style={{ marginTop: -14, marginBottom: 10 }}>
                {dispatch?.purchase.attachments.map((attachment, index) => (
                  <Button href={`${UPLOAD_URL}/${attachment}`} type="link" target="_blank">
                    View Existing Attachment {index + 1}
                  </Button>
                ))}
                <Tag color="red" style={{ marginTop: 5 }}>
                  * Existing attachments will be replaced if you upload new ones
                </Tag>
              </div>
            )}
          </Form>
        </>
      ),
      okText: 'Confirm',
      cancelText: 'Cancel',
      onCancel: () => {
        form.resetFields();
      },
      width: '450px',
      onOk: async () => {
        try {
          let toUpdate;
          if (type === 'move to transit') toUpdate = { transitStatus: 'In Transit', dispatchFrom: dispatch?.dispatchFrom };

          if (mode === 'transitStatus') {
            toUpdate.transitDate = transitDate;
          }

          const values = form.getFieldsValue(true);
          const files = new FormData();
          if (!isEmpty(values?.invoice)) files.append('purchase.invoice', values?.invoice[0].originFileObj);
          if (!isEmpty(values?.ewayBill)) files.append('purchase.ewayBill', values?.ewayBill[0].originFileObj);
          if (!isEmpty(values?.weightSlip)) files.append('purchase.weightSlip', values?.weightSlip[0].originFileObj);
          if (!isEmpty(values?.qualityReport)) files.append('purchase.qualityReport', values?.qualityReport[0].originFileObj);
          if (!isEmpty(values?.attachments))
            each(values.attachments, (attachment) => {
              files.append('purchase.attachments', attachment.originFileObj);
            });

          if (type === 'move to transit' && !dispatch?.purchase && isEmpty(values)) {
            message.error('Error: At least one attachment is needed before moving to transit');

            return Promise.reject();
          } else {
            const res = await axios.put(`${DISPATCH_URL}/${dispatch._id}`, toUpdate);
            await axios.post(`${DISPATCH_URL}/upload/${dispatch?._id}`, files);

            if (res.data.status === 'error') {
              message.error(res.data.error);
            } else {
              message.success('Dispatch transit status successfully updated');
              // onLoadDispatches();
              save();
              form.resetFields();
            }
          }
        } catch (error) {
          message.error('An error occurred. Please try again.');
        }
      },
    });
  };

  const onOpenEditDispatch = (dispatch) => {
    setSelected(dispatch);
    setEditDispatchVisible(true);
  };

  const onCloseDispatch = (reload) => {
    if (reload) {
      save();
      setSelected(null);
    }
    setEditDispatchVisible(false);
  };

  const onChangeTransitStatus = (dispatch) => {
    const status = dispatch?.transitStatus;
    let type = null;
    if (status === 'Awaiting Loading') {
      type = 'move to transit';
    }
    if (type) {
      onSwitch(dispatch, type, 'transitStatus');
    }
  };

  const getReceivedDispatch = (dispatch) => {
    setDispatchData(dispatch);
    SetViewReceiveDispatch(true);
  };

  const closeReceived = () => {
    setDispatchData(null);
    SetViewReceiveDispatch(false);
  };

  const icons = dispatch?.transitStatus === 'Awaiting Loading' ? <FaTruckLoading className="mr-2" /> : <TbTruckDelivery className="mr-2" />;
  const tooltipData = dispatch?.transitStatus === 'Awaiting Loading' && 'Move to In Transit';

  const attachmentKey = type === 'received' ? 'sales' : 'purchase';
  return (
    <Card>
      <Row justify="space-between">
        <Col xs={24} sm={24} md={24} lg={24} xl={16}>
          <Row gutter={6} justify="flex-start" wrap={false}>
            <Col>
              <Avatar src={`${UPLOAD_URL}/${dispatch?.product?.image}`} shape="square" size="large" />
            </Col>
            <Col>
              <Title level={5} className="fw-medium">
                {dispatch?.product?.name}
              </Title>
              <Tag className="rounded px-2 border-0" color={getColor(dispatch?.transitStatus)} icon={<BsTruck className="mr-2" />}>
                {dispatch?.transitStatus}
              </Tag>
            </Col>
          </Row>
        </Col>
        {type === 'sent' && (
          <>
            <Col xs={24} sm={24} md={24} lg={24} xl={9}>
              {['Finding Transport', 'Transport Allocated', 'Awaiting Loading', 'Loaded'].includes(dispatch?.transitStatus) && type === 'sent' && dispatch.status !== 'On Hold' && (
                <Button icon={icons} type="default" onClick={() => onChangeTransitStatus(dispatch)}>
                  {tooltipData}
                </Button>
              )}
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={3}>
              {['Finding Transport', 'Transport Allocated', 'Awaiting Loading', 'Loaded'].includes(dispatch?.transitStatus) && type === 'sent' && dispatch.status !== 'On Hold' && (
                <Tooltip title={'Edit Dispatch'}>
                  <Button shape="circle" onClick={() => onOpenEditDispatch(dispatch)}>
                    <EditOutlined />
                  </Button>
                </Tooltip>
              )}
            </Col>
          </>
        )}
        {type === 'received' && dispatch?.status !== 'Dispatched' && (
          <Col xs={24} sm={24} md={24} lg={8}>
            {dispatch?.dispatchTrackingNo && type === 'received' && (
              <DetailsGrid
                title="Tracking ID"
                description={
                  <Button
                    type="link"
                    className="pl-0"
                    onClick={() => window.open(`${DTN_URL}/track/${dispatch?.dispatchTrackingNo}?utm_source=app&utm_medium=web&utm_campaign=link_open&utm_content=${business._id}`)}>
                    {dispatch?.dispatchTrackingNo} <HiOutlineExternalLink size={16} />
                  </Button>
                }
              />
            )}
          </Col>
        )}
        {dispatch?.status === 'Dispatched' && type === 'received' && ['In Transit', 'Awaiting Unloading'].includes(dispatch?.transitStatus) && (
          <>
            <Col xs={24} sm={24} md={24} lg={8}>
              {dispatch?.dispatchTrackingNo && type === 'received' && (
                <DetailsGrid
                  title="Tracking ID"
                  description={
                    <Button
                      type="link"
                      className="pl-0"
                      onClick={() => window.open(`${DTN_URL}/track/${dispatch?.dispatchTrackingNo}?utm_source=app&utm_medium=web&utm_campaign=link_open&utm_content=${business._id}`)}>
                      {dispatch?.dispatchTrackingNo} <HiOutlineExternalLink size={16} />
                    </Button>
                  }
                />
              )}
            </Col>
          </>
        )}
      </Row>
      <Divider className="my-3" />
      <Row gutter={[24, 16]}>
        {tab == 'completed-dispatches' ? (
          <Col xs={24} sm={12} md={12} lg={12}>
            <DetailsGrid
              title={type === 'received' && dispatch?.receivedQty ? 'Received Quantity' : 'Quantity'}
              description={<Text strong>{`${type === 'received' && dispatch?.receivedQty ? dispatch.receivedQty : dispatch?.dispatchedQty} ${dispatch?.subUnit}`}</Text>}
            />
          </Col>
        ) : (
          <Col xs={24} sm={12} md={12} lg={12}>
            <DetailsGrid title="Quantity" description={<Text strong>{`${type === 'received' && dispatch?.receivedQty ? dispatch.receivedQty : dispatch?.dispatchedQty} ${dispatch?.subUnit}`}</Text>} />
          </Col>
        )}
        {type === 'received' && (
          <Col xs={24} sm={12} md={12} lg={12} className="d-flex justify-content-end">
            <DetailsGrid title="Sales Bill No" description={<Text strong>{dispatch?.salesBillNo || '-'}</Text>} />
          </Col>
        )}
        {type === 'sent' && (
          <Col xs={24} sm={12} md={12} lg={12} className="d-flex justify-content-end">
            <DetailsGrid title="Purchase Bill No" description={dispatch?.purchaseBillNo || '-'} />
          </Col>
        )}
      </Row>
      <Divider className="my-3" />

      <Row gutter={[24, 16]}>
        {type === 'received' && (
          <Col xs={24} sm={12} md={12} lg={12}>
            {type === 'received' && <DetailsGrid title="GRN Number" description={<Text strong>{dispatch?.metadata?.grnNumber || '-'}</Text>} />}
          </Col>
        )}
        <Col xs={24} sm={12} md={12} lg={12} className={type === 'received' && 'd-flex justify-content-end'}>
          {type === 'received' && !isEmpty(dispatch?.sales) && (
            <Button type="link" className="pl-0" onClick={showModal}>
              View Attachments <HiOutlineExternalLink size={16} />
            </Button>
          )}
          {type === 'sent' && !isEmpty(dispatch?.purchase) && (
            <Button type="link" className="pl-0" onClick={showModal}>
              View Attachments <HiOutlineExternalLink size={16} />
            </Button>
          )}
        </Col>
        {type === 'sent' && (
          <Col xs={24} sm={24} md={24} lg={12} className={type === 'sent' && 'd-flex justify-content-end'}>
            <Button type="primary" className="ml-0" onClick={() => onViewDispatch(dispatch?._id)}>
              View More
            </Button>
          </Col>
        )}
      </Row>
      {type === 'received' && <Divider className="my-3" />}
      <Row gutter={[24, 16]}>
        {type === 'received' && (
          <Col xs={24} sm={24} md={24} lg={12}>
            {type === 'received' && dispatch.status === 'Dispatched' && ['In Transit', 'Awaiting Unloading'].includes(dispatch?.transitStatus) && (
              <Button type="primary" ghost className=" ml-2" onClick={() => getReceivedDispatch(dispatch)}>
                Received?
              </Button>
            )}
          </Col>
        )}
        {type === 'received' && (
          <Col xs={24} sm={24} md={24} lg={12} className={type === 'received' && 'd-flex justify-content-end'}>
            <Button type="primary" className="ml-0" onClick={() => onViewDispatch(dispatch?._id)}>
              View More
            </Button>
          </Col>
        )}
      </Row>
      <Modal title="View Attachments" open={isModalOpen} footer={null} onOk={handleOk} onCancel={handleCancel} width={350} closeIcon={<ModalCloseIcon />}>
        <Card>
          {!dispatch ||
            !dispatch[attachmentKey] ||
            (isEmpty(dispatch[attachmentKey]) && (
              <Col span={24}>
                <DetailsGrid icon={<Avatar src={require('assets/images/warning.png')} shape="square" size="large" />} title="No Attachments Available" />
              </Col>
            ))}
          {dispatch[attachmentKey]?.invoice && (
            <Col span={24}>
              <Button type="link" href={`${UPLOAD_URL}/${dispatch[attachmentKey]?.invoice}`} target="_blank">
                Download Invoice&nbsp;
                <HiOutlineExternalLink size={16} />
              </Button>
            </Col>
          )}
          {dispatch[attachmentKey]?.ewayBill && (
            <Col span={24}>
              <Button type="link" href={`${UPLOAD_URL}/${dispatch[attachmentKey]?.ewayBill}`} target="_blank">
                Download E-way Bill&nbsp;
                <HiOutlineExternalLink size={16} />
              </Button>
            </Col>
          )}
          {dispatch[attachmentKey]?.grnReceipt && (
            <Col span={24}>
              <Button type="link" href={`${UPLOAD_URL}/${dispatch[attachmentKey]?.grnReceipt}`} target="_blank">
                Download GRN Receipt &nbsp;
                <HiOutlineExternalLink size={16} />
              </Button>
            </Col>
          )}
          {dispatch[attachmentKey]?.weightSlip && (
            <Col span={24}>
              <Button type="link" href={`${UPLOAD_URL}/${dispatch[attachmentKey]?.weightSlip}`} target="_blank">
                Download Weight Slip&nbsp;
                <HiOutlineExternalLink size={16} />
              </Button>
            </Col>
          )}
          {dispatch[attachmentKey]?.qualityReport && (
            <Col span={24}>
              <Button type="link" href={`${UPLOAD_URL}/${dispatch[attachmentKey]?.qualityReport}`} target="_blank">
                Download Quality Report&nbsp;
                <HiOutlineExternalLink size={16} />
              </Button>
            </Col>
          )}
          {dispatch[attachmentKey]?.moistureReport && (
            <Col span={24}>
              <Button type="link" href={`${UPLOAD_URL}/${dispatch[attachmentKey]?.moistureReport}`} target="_blank">
                Download Moisture Report &nbsp;
                <HiOutlineExternalLink size={16} />
              </Button>
            </Col>
          )}
          {dispatch[attachmentKey]?.attachments?.length > 0 &&
            dispatch[attachmentKey]?.attachments.map((attachment, index) => {
              return (
                <Col span={24} key={attachment}>
                  <Button type="link" href={`${UPLOAD_URL}/${attachment}`} target="_blank">
                    Download Attachment #{index + 1}&nbsp;
                    <HiOutlineExternalLink size={16} />
                  </Button>
                </Col>
              );
            })}
        </Card>
      </Modal>
      <EditDispatch order={order} visible={editDispatchVisible} close={onCloseDispatch} selected={selected} />
      <OnReceivedDispatch dispatch={dispatchData} visible={viewReceiveDispatch} save={save} close={closeReceived} />
    </Card>
  );
};

export default DispatchDetails;
