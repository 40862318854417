import React, { useEffect, useState } from 'react';
import { Card, Typography, Row, Col, Form, Input, Button, Checkbox, message, Carousel, Select, Alert, Avatar } from 'antd';
import { useNavigate } from 'react-router-dom';
import { RightOutlined } from '@ant-design/icons';
import ImageSelect from 'components/UtilComponents/ImageSelect';
import request from 'utils/request';
import { setAuth } from 'utils/auth';
import { COMMON_URL, USER_URL, BUSINESS_URL, PRODUCT_URL, SAVED_ADDRESS_URL } from 'constants/ApiConstants';
import axios from 'axios';
import { keyBy, map, startCase } from 'lodash';
import { validateName, validatePassword } from './validation';
import { IndustryType } from './industryType';
import { posthog } from 'posthog-js';

const { Title, Text, Link, Paragraph } = Typography;

const TYPE = [
  {
    key: 'buy',
    label: 'Buy',
    value: 'consumer',
  },
  {
    key: 'sell',
    label: 'Sell',
    value: 'seller',
  },
];

const Signup = () => {
  let navigate = useNavigate();
  const [form] = Form.useForm();
  const [counter, setCounter] = useState(0);
  const [step, setStep] = useState('create-account');

  const [loading, setLoading] = useState(false);
  const [businessLoading, setBusinessLoading] = useState(true);
  const [resendLoading, setResendLoading] = useState(false);
  const [error, setError] = useState(null);

  const [accountType, setAccountType] = useState([]);
  const [name, setName] = useState(null);
  const [businessId, setBusinessId] = useState(null);
  const [products, setProducts] = useState([]);

  const [gstStatus, setGstStatus] = useState(false);
  const [gstData, setGstData] = useState({});

  const [country, setCountry] = useState(null);
  const [countries, setCountries] = useState([]);
  const [countriesByName, setCountriesByName] = useState({});
  const [countriesById, setCountriesById] = useState({});

  const [state, setState] = useState(null);
  const [states, setStates] = useState([]);
  const [statesById, setStatesById] = useState({});
  const [statesByName, setStatesByName] = useState({});
  const [stateRefreshing, setStateRefreshing] = useState(false);

  const [cities, setCities] = useState([]);
  const [cityByName, setCityByName] = useState({});
  const [cityRefreshing, setCityRefreshing] = useState(false);

  const [industryType, setIndustryType] = useState(null);

  useEffect(() => {
    onLoadBusiness();
  }, []);

  useEffect(() => {
    if (step === 'save-address' || step === 'confirm-business') onLoadCountries();
    if (step === 'account-setup') onLoadProducts();
  }, [step]);

  useEffect(() => {
    onLoadStates();
  }, [country, countriesByName, countriesById]);

  useEffect(() => {
    onLoadCities();
  }, [state, statesByName, statesById]);

  const onLoadBusiness = async () => {
    setBusinessLoading(true);
    try {
      const res = await request(`${BUSINESS_URL}/me?populate=true`, {
        method: 'get',
      });
      if (res.status === 'error') {
        if (res.code !== 401) {
          // console.log(res.error);
        }
      } else {
        setBusinessId(res.business._id);
        setName(res.business.name);
        const user = res.business.users ? res.business.users[0].user : null;
        if (user?.verifyEmailToken) {
          setStep('verify-otp');
        } else if (!res.business.gstNumber && !res.business.address) {
          setStep('verify-gst');
        } else if (res?.business.buying.length === 0 && res?.business.selling.length === 0) {
          setStep('account-setup');
        } else {
          const url = localStorage.getItem('copiedLink');
          if (url) {
            window.location = url;
            localStorage.removeItem('copiedLink');
          } else {
            window.location = '/';
          }
          message.success('Sign up process already Completed');
        }
      }
    } catch (err) {
      setError(err.message);
    }
    setBusinessLoading(false);
  };

  const onLoadProducts = async () => {
    setLoading(true);
    setError(null);
    try {
      const res = await axios.get(PRODUCT_URL, {
        headers: {
          'X-API-Filters': JSON.stringify({ status: ['Active'] }),
        },
      });
      if (res.status === 'error') {
        setError(res.error);
      } else {
        setProducts(map(res?.data.products, (p) => ({ text: p.name, value: p._id, image: p?.image })));
      }
    } catch (err) {
      setError(err?.message);
    }
    setLoading(false);
  };

  const onLoadCountries = async () => {
    setLoading(true);
    setError(null);
    try {
      const res = await axios.get(`${COMMON_URL}/countries`);
      if (res.data.status === 'error') {
        setError(res.data.error);
      } else {
        setCountries(res.data.countries);
        setCountriesByName(keyBy(res.data.countries, 'name'));
        setCountriesById(keyBy(res.data.countries, 'isoCode'));
      }
    } catch (err) {
      setError(err?.message);
    }
    setLoading(false);
  };

  const onLoadStates = async () => {
    setStateRefreshing(true);
    setError(null);
    try {
      const newCountry = countriesByName[country]?.isoCode || country;
      const res = await axios.get(`${COMMON_URL}/states/${newCountry}`);
      if (res.data.status === 'error') {
        setError(res.data.error);
      } else {
        setStates(res.data.states);
        setStatesByName(keyBy(res.data.states, 'name'));
        setStatesById(keyBy(res.data.states, 'isoCode'));
      }
    } catch (err) {
      setError(err?.message);
    }
    setStateRefreshing(false);
  };

  const onLoadCities = async () => {
    setCityRefreshing(true);
    setError(null);
    try {
      const newCountry = countriesByName[country]?.isoCode || country;
      const newState = statesByName[state]?.isoCode || state;
      const res = await axios.get(`${COMMON_URL}/cities/${newCountry}/${newState}`);
      if (res.data.status === 'error') {
        setError(res.data.error);
      } else {
        setCities(res.data.cities);
        setCityByName(keyBy(res.data.cities, 'name'));
      }
    } catch (err) {
      setError(err?.message);
    }
    setCityRefreshing(false);
  };

  useEffect(() => {
    const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  const onSubmit = (values) => {
    if (step === 'create-account') onCreateAccount(values);
    if (step === 'verify-otp') onVerifyOTP(values);
    if (step === 'verify-gst') onVerifyGST(values);
    if (step === 'save-address') onSaveAddress(values);
    if (step === 'confirm-business') onConfirmBusiness(values);
    if (step === 'account-setup') onAccountSetup(values);
  };

  const onCreateAccount = async (values) => {
    setLoading(true);
    setError(null);
    try {
      const res = await request(`${USER_URL}`, {
        method: 'post',
        body: JSON.stringify({
          name: values.companyName,
          phone: values.phone,
          email: values.email,
          password: values.password,
          aclGroup: 'business',
          platform: 'Web',
        }),
      });
      if (res.status === 'error') {
        setError(res.error);
      } else {
        setAuth({
          token: res.token,
          expiryTimeStamp: res.expiryTimeStamp,
          validity: res.validity,
          aclGroup: res.aclGroup,
          _id: res._id,
        });
        const businessRes = await request(`${BUSINESS_URL}`, {
          method: 'post',
          body: JSON.stringify({
            name: values.companyName,
            phone: values.phone,
            email: values.email,
          }),
        });
        if (businessRes.status === 'error') {
          setError(businessRes.error);
        } else {
          posthog.capture('$signup | $create-account', {
            data: {
              userid: res._id,
              businessid: businessRes.business._id,
              name: values.companyName,
              phone: values.phone,
              email: values.email,
              platform: 'Web',
            },
            $set: { lastLoginDate: new Date().toISOString() },
          });
          setName(values.companyName);
          setBusinessId(businessRes.business._id);
          message.success(`OTP sent successfully on ${values.phone} and ${values.email}`);
          setStep('verify-otp');
        }
      }
    } catch (err) {
      setError(err.message);
    }
    setLoading(false);
  };

  const onResendOTP = async () => {
    setResendLoading(true);
    try {
      setCounter(59);
      const res = await request(`${USER_URL}/verify/resend`, {
        method: 'put',
        body: JSON.stringify({
          type: 'all',
        }),
      });
      if (res.status === 'error') {
        setError(res.error);
      } else {
        message.success('Account verification code has been resent to your phone number & email address');
      }
      form.resetFields(['otp']);
    } catch (err) {
      setError(err.message);
    }
    setResendLoading(false);
  };

  const onVerifyOTP = async (values) => {
    setLoading(true);
    setError(null);
    try {
      const res = await request(`${USER_URL}/verify/email/${values.otp}`, {
        method: 'put',
      });
      if (res.status === 'error') {
        message.error('Please provide a valid OTP');
        form.resetFields();
        if (res.code !== 1007) setError(res.error);
      } else {
        posthog.capture('$signup | $verify-otp', {
          data: {
            businessid: businessId,
            name: name,
            platform: 'Web',
          },
          $set: { lastLoginDate: new Date().toISOString() },
        });
        message.success('Account has been verified successfully');
        setStep('verify-gst');
      }
    } catch (err) {
      setError(err.message);
    }
    setLoading(false);
  };

  const onVerifyGST = async (values) => {
    setLoading(true);
    setError(null);
    try {
      const res = await axios.get(`${COMMON_URL}/gst/${values?.gstNumber.toUpperCase()}`);
      if (res.data.status === 'error') {
        setError('We are unable to verify the GST Number.');
        setLoading(false);
        return setGstStatus(false);
      } else if (res.data.status === 'success') {
        setGstData(res?.data?.details);
        setState(res?.data?.details?.address?.state);
        setCountry('India');
        setGstStatus(true);
        setStep('confirm-business');
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onConfirmBusiness = async (values) => {
    try {
      const res = await request(`${BUSINESS_URL}/${businessId}`, {
        method: 'put',
        body: JSON.stringify({
          name: gstStatus ? gstData?.legalName : name,
          metadata: { gstVerifiedByApi: true },
          gstNumber: values.gstNumber.toUpperCase(),
        }),
      });
      if (res.status === 'error') {
        setError(res.error);
      } else {
        posthog.capture(`$signup | $verify-gst`, {
          data: {
            businessid: businessId,
            name: name,
            gstNumber: values.gstNumber.toUpperCase(),
            platform: 'Web',
          },
          $set: { lastLoginDate: new Date().toISOString() },
        });
        await onSaveAddress(gstData?.address, values.gstNumber);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onSkipGSTVerification = () => {
    try {
      form.resetFields();
      if (step === 'save-address') setStep('verify-gst');
      else setStep('save-address');
    } catch (error) {
      console.error(error);
    }
  };

  const onSaveAddress = async (values, gstNumber) => {
    setLoading(true);
    setError(null);
    try {
      const address = {
        line1: values.line1,
        line2: values.line2 && values.line2,
        city: values.city,
        state: statesById[values.state]?.name || values.state,
        country: values.country || countriesById['IN']?.name,
        pincode: values.pincode,
      };
      let coordinates = cityByName[values.city];
      if (!coordinates) {
        const res = await axios.post(`${COMMON_URL}/geocode`, address);
        address.location = {
          type: 'Point',
          coordinates: res.data.coordinates,
        };
      } else {
        address.location = {
          type: 'Point',
          coordinates: [coordinates?.longitude, coordinates?.latitude],
        };
      }
      address.name = gstStatus ? gstData?.legalName : name;
      address.business = 'me';
      address.type = 'Registered';
      address.status = 'Active';

      const saveAddressRes = await request(`${SAVED_ADDRESS_URL}`, {
        method: 'post',
        body: JSON.stringify(address),
      });

      if (saveAddressRes.status === 'error') {
        setError(saveAddressRes.error);
      } else {
        const businessRes = await request(`${BUSINESS_URL}/${businessId}`, {
          method: 'put',
          body: JSON.stringify({
            address: saveAddressRes.savedaddress._id,
            gstNumber: gstNumber || 'NA',
            metadata: {
              gstVerifiedByApi: gstNumber ? true : false,
            },
          }),
        });

        if (businessRes.status === 'error') {
          setError(businessRes.error);
        } else {
          const address = saveAddressRes.savedaddress;
          posthog.capture(`$signup | $save-address`, {
            data: {
              businessid: businessId,
              name: name,
              address: {
                line1: address.line1,
                line2: address.line2 ? address.line2 : null,
                city: address.city,
                state: address.state,
                country: address.country,
                pincode: address.pincode,
              },
              platform: 'Web',
            },
            $set: { lastLoginDate: new Date().toISOString() },
          });
          if (gstStatus) {
            message.success('GST has been verified successfully, one of our agents will verify your account soon');
          } else {
            message.success('Address has been added successfully, one of our agents will verify your account soon');
          }
          setStep('account-setup');
        }
      }
    } catch (err) {
      setError(err.message);
    }
    setLoading(false);
  };
  const onAccountSetup = async (values) => {
    const businessType = values.accountType.length === 1 ? values.accountType[0] : values?.businessType;
    const specificIndustryType = businessType === 'consumer' ? (industryType ? (industryType === 'Other' ? startCase(values.specificIndustryType) : industryType) : '') : '';

    try {
      const res = await request(`${BUSINESS_URL}/${businessId}`, {
        method: 'put',
        body: JSON.stringify({
          businessType: businessType,
          buying: values.buyProducts,
          selling: values.sellProducts,
          metadata: {
            industryType: specificIndustryType,
          },
        }),
      });
      if (res.status === 'error') {
        setError(res.error);
      } else {
        posthog.capture(`$signup | $account-setup`, {
          data: {
            businessid: businessId,
            name: name,
            businessType: businessType,
            buying: values.buyProducts,
            selling: values.sellProducts,
            platform: 'Web',
          },
          $set: { lastLoginDate: new Date().toISOString() },
        });
        const url = localStorage.getItem('copiedLink');
        if (url) {
          window.location = url;
          localStorage.removeItem('copiedLink');
        } else {
          window.location = '/';
        }
      }
    } catch (error) {
      setError(error);
    }
  };

  const onSelectBuyProduct = (selectedProduct) => {
    const existingProducts = form.getFieldValue('buyProducts') || [];
    if (!existingProducts?.includes(selectedProduct)) {
      form.setFieldValue('buyProducts', [...existingProducts, selectedProduct]);
    } else {
      const newValues = existingProducts.filter((p) => p !== selectedProduct);
      form.resetFields(['buyProducts']);
      form.setFieldValue('buyProducts', [...newValues]);
    }
  };

  const onSelectSellProduct = (selectedProduct) => {
    const existingProducts = form.getFieldValue('sellProducts') || [];
    if (!existingProducts?.includes(selectedProduct)) {
      form.setFieldValue('sellProducts', [...existingProducts, selectedProduct]);
    } else {
      const newValues = existingProducts.filter((p) => p !== selectedProduct);
      form.resetFields(['sellProducts']);
      form.setFieldValue('sellProducts', [...newValues]);
    }
  };

  if (businessLoading) {
    return (
      <div className="vh-100 d-flex flex-column align-items-center justify-content-center">
        <Avatar src={require('assets/images/buyofuel-logo.png')} style={{ width: 250, height: 60 }} shape="square" />
      </div>
    );
  }

  return (
    <Row align="top" justify="space-around" className="vh-100">
      <Col xs={24} sm={24} md={24} lg={12}>
        <Carousel arrows={true} autoplay pauseOnHover={false}>
          <img src={require('assets/images/slides/slide-1.jpg')} alt="Banner 1" />
          <img src={require('assets/images/slides/slide-2.jpg')} alt="Banner 2" />
          <img src={require('assets/images/slides/slide-3.jpg')} alt="Banner 3" />
          <img src={require('assets/images/slides/slide-4.jpg')} alt="Banner 4" />
        </Carousel>
      </Col>

      <Col xs={24} sm={24} md={24} lg={12} className="p-3 p-sm-5" style={{ overflowY: 'scroll' }}>
        <Form
          form={form}
          layout="vertical"
          requiredMark={false}
          onFinish={onSubmit}
          initialValues={{
            accountType: [],
            buyProducts: [],
            sellProducts: [],
          }}>
          {/* Account Creation */}
          {step === 'create-account' && (
            <>
              <Row align="middle" justify="space-between">
                <Col>
                  <Title level={3} className="m-0 fw-bold">
                    Create New Account
                  </Title>
                </Col>
                <Col span={1}></Col>
              </Row>

              <div className="mt-5">
                <Form.Item
                  className="bg-transparent"
                  name="companyName"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter your company name',
                    },
                    {
                      validator: validateName,
                    },
                  ]}
                  hasFeedback>
                  <Input size="large" placeholder="Company Name" allowClear />
                </Form.Item>
                <Form.Item
                  className="bg-transparent"
                  name="phone"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter your phone number',
                    },
                    {
                      pattern: new RegExp('^[6-9][0-9]{9}$'),
                      message: 'Please enter a valid phone number',
                    },
                  ]}
                  hasFeedback>
                  <Input size="large" addonBefore="+91" placeholder="Phone Number" allowClear />
                </Form.Item>
                <Form.Item
                  className="bg-transparent"
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter your email address',
                    },
                    {
                      type: 'email',
                      message: 'Please enter a valid email address!',
                    },
                  ]}
                  hasFeedback>
                  <Input size="large" placeholder="Email Address" allowClear />
                </Form.Item>
                <Form.Item
                  className="bg-transparent"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter a valid password',
                      /* message:
                        "Please enter a strong password with atleast 8 characters, one number and one special character.",
                      pattern: new RegExp("^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"), */
                      /* pattern: new RegExp(
                    "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
                  ), */
                    },
                    {
                      validator: validatePassword,
                    },
                  ]}
                  hasFeedback>
                  <Input.Password
                    size="large"
                    placeholder="Password"
                    allowClear
                    iconRender={(visible) =>
                      visible ? (
                        <Text underline style={{ cursor: 'pointer' }}>
                          Hide
                        </Text>
                      ) : (
                        <Text underline style={{ cursor: 'pointer' }}>
                          Show
                        </Text>
                      )
                    }
                  />
                </Form.Item>
                <Form.Item
                  name="agreement"
                  valuePropName="checked"
                  rules={[
                    {
                      validator: (_, value) => (value ? Promise.resolve() : Promise.reject(new Error('Should accept agreement'))),
                    },
                  ]}>
                  <Checkbox>
                    I agree to Buyofuel's{' '}
                    <Link href="https://buyofuel.com/terms-and-conditions/" target="_blank" underline>
                      Terms and Conditions
                    </Link>{' '}
                    &{' '}
                    <Link href="https://buyofuel.com/privacy-policy" target="_blank" underline>
                      Privacy Policy
                    </Link>
                    .
                  </Checkbox>
                </Form.Item>
                <Form.Item>
                  <Row justify="center" className="my-2">
                    <Button type="primary" size="large" style={{ width: '250px' }} htmlType="submit">
                      Create Account
                    </Button>
                  </Row>
                </Form.Item>
                <Row align="middle" justify="center" className="mb-5">
                  <Text>
                    Already have an account?{' '}
                    <Link
                      onClick={() => {
                        posthog.capture(`$screenview | $login`, { $set: { lastActivityDate: new Date().toISOString() } });
                        navigate('/login');
                      }}
                      underline>
                      Login here
                    </Link>
                  </Text>
                </Row>
              </div>
            </>
          )}

          {/* OTP Verification */}
          {step === 'verify-otp' && (
            <>
              <Row align="middle" justify="space-between">
                <Col>
                  <Title level={3} className="m-0 fw-bold">
                    OTP Verification
                  </Title>
                </Col>
                <Col span={1}></Col>
              </Row>

              <div className="mt-5">
                <Form.Item
                  className="bg-transparent"
                  name="otp"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter the OTP you have received',
                    },
                    {
                      pattern: new RegExp('^[0-9]{6}$'),
                      message: 'Please enter a 6 digit valid OTP',
                    },
                  ]}
                  hasFeedback
                  extra={
                    <Row className="mt-2" align="middle">
                      <Text>Didn't receive OTP? {counter > 0 && `Request again in ${counter}s`}</Text>
                      {counter === 0 && (
                        <Button type="link" size="middle" className="px-2" onClick={onResendOTP} loading={resendLoading}>
                          Re-send OTP
                        </Button>
                      )}
                    </Row>
                  }>
                  <Input size="large" placeholder="Enter 6 Digit OTP Number" maxLength={6} allowClear />
                </Form.Item>
                <Form.Item>
                  <Row justify="center" className="my-2">
                    <Button type="primary" size="large" className="w-50" htmlType="submit">
                      Next
                    </Button>
                  </Row>
                </Form.Item>
              </div>
            </>
          )}

          {/* Business Verification */}
          {(step === 'verify-gst' || step === 'confirm-business') && (
            <>
              <Row align="middle" justify="space-between">
                <Col>
                  <Title level={3} className="m-0 fw-bold">
                    Business Verification
                  </Title>
                  {step === 'verify-gst' && <Paragraph>Please enter your GST number for verification</Paragraph>}
                  {step === 'confirm-business' && <Paragraph>Please check if the details about your business are right.</Paragraph>}
                </Col>
                <Col span={1}></Col>
              </Row>

              <div className="mt-5">
                <Form.Item
                  className="bg-transparent"
                  name="gstNumber"
                  rules={[
                    {
                      required: true,
                      pattern: /^[a-zA-Z0-9]*$/,
                      message: 'Please enter your valid GST Number',
                    },
                    {
                      len: 15,
                      message: 'GST Number must be exactly 15 characters',
                    },
                  ]}
                  hasFeedback>
                  <Input
                    onChange={() => {
                      setStep('verify-gst');
                      setGstData({});
                      setState(null);
                      setCountry(null);
                    }}
                    size="large"
                    maxLength={15}
                    placeholder="Your GST Number"
                    allowClear
                  />
                </Form.Item>
                {step === 'confirm-business' && (
                  <>
                    <div className="my-3">
                      <Paragraph type="secondary">Company's Legal Name</Paragraph>
                      <Paragraph>{gstData?.legalName}</Paragraph>
                    </div>
                    <div>
                      <Paragraph type="secondary">Company's Address</Paragraph>
                      <Paragraph>
                        {gstData?.address?.line1}, {gstData?.address?.line2},
                      </Paragraph>
                      <Paragraph>
                        {' '}
                        {gstData?.address?.city}, {gstData?.address?.state}
                      </Paragraph>
                    </div>
                    <Form.Item
                      className="my-4"
                      name="approvalForVerification"
                      valuePropName="checked"
                      rules={[
                        {
                          validator: (_, value) => (value ? Promise.resolve() : Promise.reject(new Error('Should accept agreement'))),
                        },
                      ]}>
                      <Checkbox>I approve that the above business is owned by me and agree to be verified by buyofuel.</Checkbox>
                    </Form.Item>
                  </>
                )}
                <Form.Item>
                  {step === 'verify-gst' && (
                    <Row justify="center" className="my-2">
                      <Button type="primary" size="large" className="w-50" htmlType="submit">
                        Verify GST
                      </Button>
                    </Row>
                  )}
                  {step === 'confirm-business' && (
                    <Row justify="center" className="my-2">
                      <Button type="primary" size="large" className="w-50" htmlType="submit">
                        Next
                      </Button>
                    </Row>
                  )}
                  <Row justify="center">
                    <Button type="link" size="large" className="w-75" onClick={onSkipGSTVerification}>
                      Skip & Enter address manually <RightOutlined />
                    </Button>
                  </Row>
                </Form.Item>
              </div>
            </>
          )}

          {/* Account Setup */}
          {step === 'account-setup' && (
            <>
              <Row align="middle" justify="space-between">
                <Col>
                  <Title level={3} className="m-0 fw-bold">
                    Setup your account
                  </Title>
                </Col>
                <Col span={1}></Col>
              </Row>

              <div className="mt-5">
                <Title level={4} className="fw-light">
                  You want to...
                </Title>
                <Paragraph>Select both if you want to buy and sell.</Paragraph>
                <Form.Item shouldUpdate>
                  {() => {
                    return (
                      <Form.Item name="accountType">
                        <Checkbox.Group
                          className="w-100"
                          onChange={(e) => {
                            setAccountType(e);
                            if (e[0] === 'consumer' && e.length === 1) form.setFieldsValue({ sellProducts: null });
                            else if (e[0] === 'seller' && e.length === 1) form.setFieldsValue({ buyProducts: null });
                            else if (e.length === 0) {
                              form.setFieldsValue({ buyProducts: null, sellProducts: null });
                            }
                          }}>
                          <Row gutter={[16, 8]}>
                            {TYPE.map((type) => {
                              const selected = form.getFieldValue('accountType') || [];
                              return (
                                <Col span={12} key={type.key}>
                                  <Card
                                    style={{
                                      border: selected.includes(type.value) ? '1px solid #1a75da' : 'none',
                                      backgroundColor: selected.includes(type.value) ? '#E8F1FB' : '#ffffff',
                                    }}
                                    bodyStyle={{
                                      padding: '2px',
                                      cursor: 'pointer',
                                      borderRadius: '20px',
                                    }}
                                    onClick={() => {
                                      setIndustryType(null);
                                      form.setFieldsValue({ industryType: null, specificIndustryType: null });

                                      const CV = type.value;
                                      const updatedAccountType = accountType.includes(CV) ? accountType.filter((type) => type !== CV) : [...accountType, CV];
                                      setAccountType(updatedAccountType);
                                      form.setFieldsValue({ buyProducts: null, sellProducts: null });
                                      if (updatedAccountType.length === 2) form.setFieldsValue({ accountType: ['consumer', 'seller'] });
                                      else form.setFieldsValue({ accountType: updatedAccountType });
                                    }}>
                                    <Row align="middle" className="p-3">
                                      <Col span={22}>
                                        <Text className=" fw-medium" ellipsis={true}>
                                          {type.label}
                                        </Text>
                                      </Col>
                                      <Col span={2}>
                                        <Checkbox value={type.value} checked={false} disabled={false} />
                                      </Col>
                                    </Row>
                                  </Card>
                                </Col>
                              );
                            })}
                          </Row>
                        </Checkbox.Group>
                      </Form.Item>
                    );
                  }}
                </Form.Item>
                {form.resetFields(['businessType'])}
                {accountType?.length === 1 && accountType[0] === 'consumer' && (
                  <Form.Item name="industryType">
                    <Select allowClear size="large" placeholder="Industry Type" showSearch onChange={setIndustryType}>
                      {IndustryType.map((type, index) => (
                        <Select.Option key={index} value={type}>
                          {type}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                )}
                {industryType === 'Other' && (
                  <Form.Item
                    name="specificIndustryType"
                    label="Specific Industry Type"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter a valid Specific Industry Type',
                      },
                    ]}>
                    <Input size="large" placeholder="Specific Industry Type" />
                  </Form.Item>
                )}

                {accountType?.length > 1 && (
                  <>
                    <Form.Item
                      name="businessType"
                      rules={[
                        {
                          required: true,
                          message: 'Please select a business type',
                        },
                      ]}>
                      <Select size="large" placeholder="Business Type">
                        <Select.Option value="manufacturer"> Manufacturer </Select.Option>
                        <Select.Option value="aggregator"> Aggregator </Select.Option>
                        <Select.Option value="trader"> Trader </Select.Option>
                      </Select>
                    </Form.Item>
                  </>
                )}

                <Form.Item noStyle shouldUpdate>
                  {() =>
                    form.getFieldValue('accountType')?.includes('consumer') && (
                      <>
                        <Title level={4} className="fw-light">
                          Select products you'd like to buy
                        </Title>
                        <Paragraph>Select all that apply. You can select upto 10.</Paragraph>

                        <Form.Item noStyle shouldUpdate>
                          {() => (
                            <Form.Item
                              className="bg-transparent mt-3"
                              name="buyProducts"
                              rules={[
                                {
                                  required: true,
                                  message: 'Please select at least 1 product',
                                },
                                () => ({
                                  validator(_, value) {
                                    if (value && value.length > 10) {
                                      return Promise.reject(new Error('You can choose upto 10 products'));
                                    }
                                    return Promise.resolve();
                                  },
                                }),
                              ]}
                              hasFeedback>
                              <Row gutter={[16, 16]} justify="start">
                                {products.map((data) => {
                                  return (
                                    <Col key={data.value}>
                                      <ImageSelect
                                        {...data}
                                        name={data.text}
                                        value={data.value}
                                        selectedProduct={form.getFieldValue('buyProducts') || []}
                                        onSelect={(val) => onSelectBuyProduct(val)}
                                      />
                                    </Col>
                                  );
                                })}
                              </Row>
                            </Form.Item>
                          )}
                        </Form.Item>
                      </>
                    )
                  }
                </Form.Item>

                <Form.Item noStyle shouldUpdate>
                  {() =>
                    form.getFieldValue('accountType')?.includes('seller') && (
                      <>
                        <Title level={4} className="fw-light">
                          Select products you'd like to sell
                        </Title>
                        <Paragraph>Select all that apply. You can select upto 10.</Paragraph>

                        <Form.Item noStyle shouldUpdate>
                          {() => (
                            <Form.Item
                              className="bg-transparent mt-3"
                              name="sellProducts"
                              rules={[
                                {
                                  required: true,
                                  message: 'Please select atleast 1 product',
                                },
                                () => ({
                                  validator(_, value) {
                                    if (value && value.length > 10) {
                                      return Promise.reject(new Error('You can choose upto 10 products'));
                                    }
                                    return Promise.resolve();
                                  },
                                }),
                              ]}
                              hasFeedback>
                              <Row gutter={[16, 16]} justify="start">
                                {products.map((data) => {
                                  return (
                                    <Col key={data.value}>
                                      <ImageSelect
                                        {...data}
                                        name={data.text}
                                        value={data.value}
                                        selectedProduct={form.getFieldValue('sellProducts') || []}
                                        onSelect={(value) => onSelectSellProduct(value)}
                                      />
                                    </Col>
                                  );
                                })}
                              </Row>
                            </Form.Item>
                          )}
                        </Form.Item>
                      </>
                    )
                  }
                </Form.Item>
                <Form.Item shouldUpdate>
                  {() =>
                    form.getFieldValue('accountType')?.length > 0 && (
                      <>
                        <Row justify="center" className="my-2">
                          <Button type="primary" size="large" style={{ width: '250px' }} htmlType="submit">
                            Confirm & Login
                          </Button>
                        </Row>
                      </>
                    )
                  }
                </Form.Item>
              </div>
            </>
          )}

          {/* Save Address */}
          {step === 'save-address' && (
            <>
              <Row align="middle" justify="space-between">
                <Col>
                  <Title level={3} className="m-0 fw-bold">
                    Company's Address
                  </Title>
                  <Paragraph>Please enter Company's Address</Paragraph>
                </Col>
                <Col span={1}></Col>
              </Row>
              <div className="mt-5">
                <Form.Item
                  className="bg-transparent"
                  name="line1"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter a valid address line',
                    },
                  ]}>
                  <Input size="large" placeholder="Address Line 1" maxLength={128} allowClear />
                </Form.Item>
                <Form.Item name="line2">
                  <Input size="large" placeholder="Address Line 2" maxLength={128} allowClear />
                </Form.Item>
                <Row gutter={8}>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    <Form.Item
                      name="country"
                      rules={[
                        {
                          required: true,
                          message: 'Please select your country',
                        },
                      ]}>
                      <Select
                        allowClear
                        size="large"
                        showSearch
                        placeholder="Country"
                        onChange={(country) => {
                          form.setFieldsValue({
                            state: null,
                            city: null,
                            pincode: null,
                          });
                          setCountry(country);
                        }}
                        filterOption={(inputValue, option) => option.value.toLowerCase().includes(inputValue.toLowerCase()) || option.children.toLowerCase().includes(inputValue.toLowerCase())}
                        loading={loading}>
                        {countries.map((country) => (
                          <Select.Option key={country?.isoCode} value={country?.isoCode}>
                            {country?.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    <Form.Item
                      name="state"
                      rules={[
                        {
                          required: true,
                          message: 'Please select your state',
                        },
                      ]}>
                      <Select
                        allowClear
                        size="large"
                        showSearch
                        placeholder="State"
                        onChange={(state) => {
                          form.setFieldsValue({ city: null, pincode: null });
                          setState(state);
                        }}
                        filterOption={(inputValue, option) => option.value.toLowerCase().includes(inputValue.toLowerCase()) || option.children.toLowerCase().includes(inputValue.toLowerCase())}
                        loading={stateRefreshing}>
                        {states.map((state) => (
                          <Select.Option value={state?.isoCode}>{state?.name}</Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={8}>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    <Form.Item
                      name="city"
                      rules={[
                        {
                          required: true,
                          message: 'Please select your city',
                        },
                      ]}>
                      <Select allowClear placeholder="City" size="large" showSearch loading={cityRefreshing}>
                        {cities.map((city) => (
                          <Select.Option value={city?.name}>{city?.name}</Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    <Form.Item
                      name="pincode"
                      rules={[
                        {
                          required: true,
                          pattern: /^[0-9]*$/,
                          message: 'Please enter a valid pincode',
                        },
                      ]}>
                      <Input placeholder="Pincode" size="large" maxLength={12} />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
              <Row justify="center" className="my-2">
                <Button type="primary" size="large" className="w-50" htmlType="submit">
                  Next
                </Button>
              </Row>
              <Row justify="center">
                <Button type="link" size="large" className="w-75" onClick={onSkipGSTVerification}>
                  Switch to GST Verification <RightOutlined />
                </Button>
              </Row>
            </>
          )}

          {error ? <Alert message="Error" description={error} type="error" className="mt-1" /> : null}
        </Form>
      </Col>
    </Row>
  );
};

export default Signup;
