import axios from 'axios';
const authKey = 'buyofuel:webauth';

const getAuth = () => {
  return JSON.parse(localStorage.getItem(authKey));
};

const setAuth = (object) => {
  localStorage.setItem(authKey, JSON.stringify(object));
  axios.defaults.headers.common.Authorization = object.token;
};

const clearAuth = () => {
  localStorage.removeItem(authKey);
  axios.defaults.headers.common.Authorization = null;
};

const isLoggedIn = () => {
  const auth = getAuth();
  if (auth) {
    return true;
  }
  return false;
};

export { getAuth, setAuth, clearAuth, isLoggedIn };
