import React from 'react';
import { Avatar } from 'antd';
import { Colors } from 'constants/ColorConstants';

const ModalCloseIcon = () => {
  return (
    <Avatar
      src={require('assets/images/icon-close-modal.png')}
      shape="circle"
      size="small"
      style={{
        backgroundColor: Colors.white,
        border: '1px solid #b4b4b4',
        padding: '6px',
      }}
    />
  );
};

export default ModalCloseIcon;
