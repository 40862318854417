import React, { useEffect, useState } from 'react';
import { Avatar, Button, Card, Col, Divider, List, Row, Tag, Typography, message, Modal, PageHeader, Dropdown, Space } from 'antd';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import IconSent from 'assets/images/icon-sent.png';
import IconReceived from 'assets/images/icon-received.png';
import DetailsGrid from 'components/UtilComponents/DetailsGrid';
import NumberText from 'components/UtilComponents/NumberText';
import { ADVANCED_PAYMENT_URL, BUSINESS_URL, PAYMENT_ENABLED, PAYMENT_URL, UPLOAD_URL } from 'constants/ApiConstants';
import { formatRefNo } from 'utils/formatter';
import EmptyContent from 'components/UtilComponents/EmptyContent';
import IconWallet from 'assets/images/wallet.png';
import { getBreakPoint } from 'utils/layout';
import { find, isEmpty } from 'lodash';
import { getAuth } from 'utils/auth';
import ModalCloseIcon from 'components/UtilComponents/ModalCloseIcon';
import ContentLoader from 'react-content-loader';
import { format } from 'date-fns';
import { HiOutlineExternalLink } from 'react-icons/hi';
import { DownOutlined } from '@ant-design/icons';
import LedgerView from './LedgerView';

const { Text, Title, Paragraph } = Typography;
const { CheckableTag } = Tag;

const CompletedPaymentsCard = ({ type, payment, currentPage, role }) => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };

  const onViewDispatch = () => {
    localStorage.setItem('filters', JSON.stringify({ current: currentPage, type: type }));
    let id = payment?.dispatch?._id;
    navigate(`/sell/orders/dispatch/${id}`);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <Card>
      <Row justify="flex-start">
        <Col xs={24} sm={24} md={24} lg={24} xl={12}>
          <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={6}>
              <Avatar src={type === 'sent' ? IconSent : IconReceived} size="large" style={{ backgroundColor: '#E8F1FB', padding: '2px' }} shape="square" />
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={18}>
              <Typography.Text>Payment ID</Typography.Text>
              <Row align="middle">
                <Title level={5} className="fw-bold">
                  {type == 'sent' ? formatRefNo(payment?.advancedPaymentRefNo, 'BFAP') : formatRefNo(payment?.paymentRefNo, 'BFP')}
                </Title>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={12}>
          <Typography.Text>{type === 'sent' ? 'Payment sent' : 'Payment Received'}</Typography.Text>
          <Row align="middle">
            <NumberText bold value={payment?.amount / 100} prefix={payment?.currency} />
          </Row>
        </Col>
      </Row>
      <Divider className="my-2" />
      <Row gutter={16} className="mt-3">
        {payment?.transactionRefNo ? (
          <Col xs={24} sm={24} md={24} lg={24} xl={12}>
            <Typography.Text>{'Transaction Ref No'}</Typography.Text>
            <Row align="middle">
              <Paragraph copyable type="secondary">
                {payment?.transactionRefNo}
              </Paragraph>
            </Row>
          </Col>
        ) : (
          <Col xs={24} sm={24} md={24} lg={24} xl={12}>
            <Typography.Text>{'Transaction Ref No'}</Typography.Text>
            <Row align="middle">-</Row>
          </Col>
        )}
        <Col xs={24} sm={24} md={24} lg={24} xl={12}>
          <DetailsGrid title="Payment Date" description={<Text strong>{format(new Date(payment?.paymentDate), 'dd MMM yyyy')}</Text>} />
        </Col>
      </Row>
      <Divider dashed={true} className="my-2" />
      <Row gutter={16}>
        {PAYMENT_ENABLED && (
          <Col span={12}>
            {/* TODO: Integration Pending */}
            <Button type="primary" onClick={() => {}}>
              Make Payment
            </Button>
          </Col>
        )}
        {type == 'received' && (
          <>
            {role !== 'Payment Manager' && (
              <Col span={12}>
                <Button type="link" onClick={onViewDispatch}>
                  View Dispatch
                </Button>
              </Col>
            )}
          </>
        )}
        <Col span={12}>
          <Button type="primary" onClick={showModal}>
            More Details
          </Button>
        </Col>
      </Row>
      <Modal open={isModalOpen} footer={null} onOk={handleOk} onCancel={handleCancel} closeIcon={<ModalCloseIcon />}>
        <PageHeader title={`Payment | Completed | ${type === 'sent' ? 'Sent' : 'Received'}`} />
        <Card>
          <Text strong>Payment Details</Text>
          <Row justify="space-between" className="mt-2">
            <Text>Payment Date</Text>
            <Text strong>{format(new Date(payment?.paymentDate), 'dd MMM yyyy')}</Text>
          </Row>
          <Row justify="space-between" className="mt-2">
            <Text>Payment Mode</Text>
            <Tag color="cyan"> {payment?.paymentMode}</Tag>
          </Row>
        </Card>
        {payment?.attachments?.length > 0 &&
          payment?.attachments.map((attachment, index) => {
            return (
              <Card className="mt-2">
                <Text strong>Attachment Details</Text>
                <Row justify="space-between" className="mt-2">
                  <Button type="link" href={`${UPLOAD_URL}/${attachment}`} target="_blank">
                    <Avatar src={require('assets/images/dispatched-quantity.png')} shape="square" size="small" className="mr-2" />
                    Download Attachment #{index + 1}&nbsp;
                    <HiOutlineExternalLink size={16} />
                  </Button>
                </Row>
              </Card>
            );
          })}
      </Modal>
    </Card>
  );
};

const CompletedPayments = ({ tab, showLedger }) => {
  const isMobile = !getBreakPoint().includes('md');
  const [dispatchesLoading, setDispatchLoading] = useState(false);
  const [type, setType] = useState('sent');
  const [business, setBusiness] = useState({});
  const [payments, setPayments] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 8,
    total: 0,
    size: 'middle',
    showSizeChanger: false,
    className: isMobile && 'custom-pagination',
  });

  const onLoadBusiness = async () => {
    setDispatchLoading(true);
    try {
      const businessRes = await axios.get(`${BUSINESS_URL}/me?populate=true`);

      if (businessRes.data.status === 'error') {
        message.error(businessRes.data.error);
      } else {
        setBusiness(businessRes.data.business);
        if (businessRes.data.business.businessType === 'seller') setType('received');
      }
    } catch (err) {
      message.error(err.message);
    }
    setDispatchLoading(false);
  };

  useEffect(() => {
    if (!isEmpty(business)) {
      if (type == 'sent') onLoadAdvancePayment();
      else if (type === 'received') onLoadPayment();
    }
  }, [business, type]);

  useEffect(() => {
    onLoadBusiness();
  }, []);

  const onLoadAdvancePayment = async () => {
    setPayments([]);
    setDispatchLoading(true);
    try {
      let currentPage = pagination.current;
      let currentType = type;
      const localData = await JSON.parse(localStorage.getItem('filters'));
      if (!isEmpty(localData)) {
        currentPage = localData.current;
        currentType = localData.type;
      }

      const xApiFilters = {
        paymentType: ['Receivable'],
        business: [business?._id],
      };
      const res = await axios.get(`${ADVANCED_PAYMENT_URL}?populate=true`, {
        headers: {
          'X-API-Filters': JSON.stringify(xApiFilters),
          'X-API-Sort': JSON.stringify({
            createdAt: 'desc',
          }),
        },
      });
      if (res.data.status === 'error') {
        message.error(res.data.error);
      } else {
        setPayments(res.data.advancedpayments);
        setPagination({
          ...pagination,
          current: currentPage,
          total: res.data.count,
        });
        setType(currentType);
      }
      localStorage.removeItem('filters');
    } catch (err) {
      message.error(err.message);
    }
    setDispatchLoading(false);
  };

  const onLoadPayment = async () => {
    setPayments([]);
    setDispatchLoading(true);
    try {
      let currentPage = pagination.current;
      let currentType = type;
      const localData = await JSON.parse(localStorage.getItem('filters'));
      if (!isEmpty(localData)) {
        currentPage = localData.current;
        currentType = localData.type;
      }

      const xApiFilters = { status: ['Added'], business: [business?._id], paymentType: ['Purchase'] };

      const res = await axios.get(`${PAYMENT_URL}?populate=true`, {
        headers: {
          'X-API-Filters': JSON.stringify(xApiFilters),
          'X-API-Sort': JSON.stringify({
            createdAt: 'desc',
          }),
        },
      });
      if (res.data.status === 'error') {
        message.error(res.data.error);
      } else {
        setPayments(res.data.payments);
        setPagination({
          ...pagination,
          current: currentPage,
          total: res.data.count,
        });
        setType(currentType);
      }
      localStorage.removeItem('filters');
    } catch (err) {
      message.error(err.message);
    }
    setDispatchLoading(false);
  };

  const menuOptions = [];
  const userId = getAuth()?._id;
  const user = find(business?.users, (u) => String(u.user._id) === String(userId));
  if (business?.businessType !== 'seller' && ['Owner', 'Administrator', 'Purchase Manager'].includes(user?.role)) {
    menuOptions.push({
      key: 'sent',
      label: 'Sent',
    });
  }
  if (business?.businessType !== 'consumer' && ['Owner', 'Administrator', 'Sales Manager'].includes(user?.role)) {
    menuOptions.push({
      key: 'received',
      label: 'Received',
    });
  }

  if (dispatchesLoading) {
    return (
      <ContentLoader width="100%" height="70vh" backgroundColor="#d9d9d9" foregroundColor="#ededed">
        <rect x="0" y="25" rx="10" ry="10" width="48%" height="211" />
        <rect x="50%" y="25" rx="10" ry="10" width="50%" height="211" />
        <rect x="0" y="250" rx="10" ry="10" width="48%" height="211" />
        <rect x="50%" y="250" rx="10" ry="10" width="50%" height="211" />
      </ContentLoader>
    );
  }

  const items = [
    { key: '7', label: 'After 7 days' },
    { key: '14', label: 'After 14 days' },
    { key: '21', label: 'After 21 days' },
  ];

  const onClick = ({ key }) => {
    console.log(key);
  };

  return (
    <div>
      <Row align="middle" justify="space-between" className="mb-4">
        <div className="tag-container">
          {menuOptions.length > 1 &&
            menuOptions.map((tag) => (
              <CheckableTag
                key={tag.key}
                checked={type === tag.key}
                onChange={() => {
                  setType(tag.key);
                  setPagination({ ...pagination, current: 1 });
                }}>
                {tag.label}
              </CheckableTag>
            ))}
        </div>
        <div>
          {false && (
            <Dropdown menu={{ items, onClick }} arrow placement="bottomRight">
              <a onClick={(e) => e.preventDefault()}>
                <Space>
                  All Filters
                  <DownOutlined />
                </Space>
              </a>
            </Dropdown>
          )}
          {/* {showLedger && payments.length > 0 && (
            <Button
              style={{ color: '#3183de', marginLeft: '10px', border: '1px solid #3183de' }}
              icon={<CloudDownloadOutlined style={{ color: '#3183de' }} />}
              size="medium"
              onClick={() => console.log('Export CSV')}>
              Export to CSV
            </Button>
          )} */}
        </div>
      </Row>
      {showLedger ? (
        payments?.length > 0 ? (
          <LedgerView payments={payments} />
        ) : (
          <EmptyContent source={IconWallet} title={'No Completed Payments'} subTitle={'No completed transactions to display.'} />
        )
      ) : payments?.length > 0 ? (
        <List
          footer={null}
          grid={{ gutter: [24, 12], column: isMobile ? 1 : 2 }}
          dataSource={payments}
          pagination={{ ...pagination, onChange: (page) => setPagination({ ...pagination, current: page }) }}
          renderItem={(payment) => (
            <List.Item key={payment?._id}>
              <CompletedPaymentsCard type={type} payment={payment} tab={tab} currentPage={pagination.current} role={user?.role} />
            </List.Item>
          )}
        />
      ) : (
        <EmptyContent source={IconWallet} title={'No Completed Payments'} subTitle={'No completed transactions to display.'} />
      )}
    </div>
  );
};

export default CompletedPayments;
