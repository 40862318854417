import React from 'react';
import { Typography } from 'antd';
import NumberFormat from 'react-number-format';
import { isNumber } from 'lodash';

const { Text } = Typography;

const NumberText = ({ bold = false, value, prefix = '', suffix = '' }) => {
  if (!isNumber(value)) value = 0;
  const prefixText = prefix === 'INR' ? '₹ ' : prefix === 'USD' ? '$ ' : '';
  return (
    <Text strong={bold}>
      <NumberFormat
        displayType="text"
        thousandSeparator={true}
        thousandsGroupStyle="lakh"
        prefix={prefixText}
        value={value}
        suffix={suffix ? ` ${suffix}` : ``}
        decimalScale={2}
        decimalSeparator="."
        fixedDecimalScale
      />
    </Text>
  );
};

export default NumberText;
