import React from 'react';
import { PageHeader, Tabs } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import CreatedQuotes from './CreatedQuotes';
import AcceptedQuotes from './AcceptedQuotes';
import RejectedQuotes from './RejectedQuotes';
import BackIcon from 'components/UtilComponents/BackIcon';
import ErrorBoundary from 'components/Errors/ErrorBoundary';

const Quotes = () => {
  const { tab } = useParams();
  const navigate = useNavigate();

  const tabItems = [
    // remember to pass the key prop
    {
      label: 'Pending',
      key: 'pending-quotes',
      children: <CreatedQuotes tab={tab} navigate={navigate} />,
    },
    {
      label: 'Accepted',
      key: 'accepted-quotes',
      children: <AcceptedQuotes tab={tab} navigate={navigate} />,
    },
    {
      label: 'Rejected',
      key: 'rejected-quotes',
      children: <RejectedQuotes tab={tab} navigate={navigate} />,
    },
  ];
  return (
    <div className="app-container">
      <PageHeader onBack={() => navigate('/sell')} backIcon={<BackIcon />} title="My Quotes" className="px-0" />

      <ErrorBoundary>
        <Tabs type="line" items={tabItems} defaultActiveKey={tab} onChange={(key) => navigate(`/sell/quotes/${key}`)} />
      </ErrorBoundary>
    </div>
  );
};

export default Quotes;
