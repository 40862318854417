import React from 'react';
import { Col, Row, Typography } from 'antd';

const { Text, Paragraph } = Typography;

const DetailsGrid = ({ icon = null, title, description, description2 }) => {
  return (
    <Row align={title ? 'top' : 'middle'} wrap={false}>
      {icon && <Col>{icon}</Col>}
      <Col className={icon && 'ml-2'}>
        {title && (
          <Paragraph type="secondary" className="text-small">
            {title}
          </Paragraph>
        )}
        <Text strong={title ? false : true}>{description}</Text>
        <br />
        {description2 && <Paragraph className="text-small">{description2}</Paragraph>}
      </Col>
    </Row>
  );
};

export default DetailsGrid;
