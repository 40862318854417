import React, { useEffect, useState, useRef } from 'react';
import { MapContainer, Polyline, TileLayer, Marker, Popup } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-cluster';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import { isEmpty } from 'lodash';
import BuyoTraceTree from './BuyoTraceTree';
import { Avatar, Button, Col, Divider, Row, Space, Table, Tag, Tooltip, Typography, message } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import { getBreakPoint } from 'utils/layout';
import { UPLOAD_URL } from 'constants/ApiConstants';
import axios from 'axios';
import { format } from 'date-fns';
import { EyeOutlined } from '@ant-design/icons';

const BuyoTrace = ({ dispatch }) => {
  const isMobile = !getBreakPoint().includes('lg');
  const mapRef = useRef(null);
  const mapBodyRefs = useRef(null);
  const [mapPoints, setMapPoints] = useState([]);
  const [initialCoordinates, setInitialCoordinates] = useState([]);
  const [positions, setPositions] = useState([]);
  const [verificationUrls, setVarificationUrls] = useState({});
  const [reloadMap, setReloadMap] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [verifiedData, setVerifiedData] = useState([]);

  const columns = [
    {
      title: 'Sequence Number',
      dataIndex: 'sequence_number',
      render: (sequence_number) => <span>{sequence_number}</span>,
      width: 100,
    },
    {
      title: 'Time',
      dataIndex: 'consensus_timestamp',
      render: (consensus_timestamp) => {
        const date = new Date(consensus_timestamp * 1000);
        return <span>{format(new Date(date), 'dd MMM yyyy')}</span>;
      },
      width: 130,
    },
    {
      title: 'Payer Account Id',
      dataIndex: 'payer_account_id',
      render: (payer_account_id) => <span>{payer_account_id}</span>,
      width: 150,
    },
    {
      title: 'Action',
      dataIndex: 'action',
      render: () => (
        <Tooltip title="View">
          <Button type="link" href={verificationUrls.hedera} target="_blank" icon={<EyeOutlined />} size="small" />
        </Tooltip>
      ),
      width: 50,
    },
  ];

  useEffect(() => {
    if (!isEmpty(dispatch)) {
      const hederaData = dispatch.hederaMetadata?.sourceChain;
      const sourceChains = hederaData.sourceChain;
      const buyerAddress = hederaData.deliveryAddress;
      const sellerAddress = hederaData.pickupAddress;
      const buyerCoordinates = buyerAddress.location.coordinates;
      const sellerCoordinates = sellerAddress.location.coordinates;
      setInitialCoordinates([sellerCoordinates[1], sellerCoordinates[0]]);
      setVarificationUrls(dispatch.hederaMetadata.verificationUrls);

      const markers = [];

      //buyer
      const buyer = {
        number: 2,
        productName: dispatch.product.name,
        quantity: dispatch.dispatchedQty,
        unit: dispatch.subUnit,
        productImage: dispatch.product.image,
        city: buyerAddress.city,
        state: buyerAddress.state,
        location: [buyerCoordinates[1], buyerCoordinates[0]],
      };

      //buyofuel or lead seller
      const seller = {
        number: 1,
        productName: hederaData.productName,
        quantity: hederaData.quantity,
        unit: dispatch.subUnit,
        productImage: hederaData.productImage,
        city: sellerAddress.city,
        state: sellerAddress.state,
        location: [sellerCoordinates[1], sellerCoordinates[0]],
      };

      markers.push(seller);
      markers.push(buyer);

      if (sourceChains.length > 0) {
        const groupedData = Object.values(
          sourceChains.reduce((accumulator, current) => {
            const key = `${current.city}_${current.state}_${current.productName}`;
            accumulator[key] = accumulator[key] || { ...current, quantity: 0 };
            accumulator[key].quantity += current.quantity;
            return accumulator;
          }, {})
        );
        if (groupedData.length > 0) {
          groupedData.forEach((e) => {
            const coordinates = e.location.coordinates;
            if (coordinates.length === 2) {
              markers.push({ ...e, unit: 'MT', location: [coordinates[1], coordinates[0]] });
            }
          });
        }
      }

      setMapPoints(markers);
      setReloadMap(false);
    }
  }, [dispatch]);

  useEffect(() => {
    if (!isEmpty(mapPoints)) {
      const linePositions = mapPoints.map((marker) => [mapPoints[0].location, marker.location]);
      setPositions(linePositions);
    }
  }, [mapPoints]);

  const handleButtonClick = (geocode) => {
    if (mapRef.current) {
      mapRef.current.flyTo(geocode, 12, { duration: 2 });
      mapBodyRefs.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
      setReloadMap(false);
    }
  };

  const handleReloadMap = () => {
    setReloadMap(!reloadMap);
  };

  const verifyData = async (verificationUrl) => {
    setLoading(true);
    try {
      const res = await axios.get(verificationUrl);
      if (res.status === 200) {
        setVerifiedData(res.data.messages);
        setShowTable(true);
      } else {
        message.error('Unable to verify the data.');
      }
    } catch (err) {
      message.error(err.message);
    }
    setLoading(false);
  };

  return (
    <div ref={mapBodyRefs}>
      {!isEmpty(mapPoints) && (
        <>
          <MapContainer key={reloadMap ? 'reload' : 'normal'} ref={mapRef} zoom={8} style={{ height: '500px', width: '100%', borderRadius: '10px' }} center={initialCoordinates}>
            <Button
              style={{
                position: 'absolute',
                top: '10px',
                right: '10px',
                zIndex: 1000,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '5px',
              }}
              onClick={handleReloadMap}>
              <ReloadOutlined />
            </Button>
            <TileLayer url="http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}" maxZoom={20} subdomains={['mt0', 'mt1', 'mt2', 'mt3']} />
            {!isEmpty(positions) && positions.map((position, index) => <Polyline key={index} positions={position} color={index === 1 ? 'royalblue' : 'white'} />)}
            <MarkerClusterGroup chunkedLoading>
              {mapPoints.map((e, i) => (
                <Marker key={i} position={e.location} icon={createNumberedIcon(i)}>
                  <Popup>
                    <div
                      style={{
                        display: 'flex',
                        gap: '15px',
                        alignItems: 'center',
                        height: '50px',
                      }}>
                      <Avatar src={`${UPLOAD_URL}/${e.productImage}`} alt="image" shape="square" size="large" />
                      <div>
                        <p>
                          {e.productName} {e.quantity.toFixed(2)} {e.unit}
                        </p>
                        <p style={{ color: 'gray' }}>
                          {e.city}, {e.state}
                        </p>
                      </div>
                    </div>
                  </Popup>
                </Marker>
              ))}
            </MarkerClusterGroup>
          </MapContainer>

          <Divider />

          <BuyoTraceTree dispatch={dispatch} handleButtonClick={handleButtonClick} />

          {!isEmpty(verificationUrls) && verificationUrls.hedera && !showTable && (
            <Row justify="center" className="mt-3 mb-3">
              <Button type="primary" loading={loading} onClick={() => verifyData(verificationUrls.hedera)} style={{ fontSize: isMobile ? '10px' : '15px' }}>
                Click here to verify this data
              </Button>
            </Row>
          )}
          {showTable && (
            <Table
              rowKey="_id"
              loading={loading}
              pagination={false}
              scroll={{ y: '60vh' }}
              style={{ border: '1px solid #eee' }}
              title={() => (
                <Row justify="space-between" align="center" style={{ borderBottom: '1px #eee solid' }}>
                  <Col>
                    <Space align="center" style={{ height: 50 }}>
                      <Typography.Text>
                        <Tag>Total of {verifiedData.length} records</Tag>
                      </Typography.Text>
                    </Space>
                  </Col>
                </Row>
              )}
              dataSource={verifiedData}
              columns={columns}
            />
          )}
        </>
      )}
    </div>
  );
};

const createNumberedIcon = (number) =>
  L.divIcon({
    html:
      number < 2
        ? `<div style="display: flex; align-items: center; justify-content: center; font-weight: bold; color:white; background-color: royalblue; border: 2px solid white; border-radius: 50%; width: 20px; height: 20px;">${
            number + 1
          }</div>`
        : `<div style="display: flex; align-items: center; justify-content: center; font-weight: bold; color:white; background-color: white; border: 2px solid white; border-radius: 50%; width: 10px; height: 10px;"></div>`,
    iconSize: [24, 24],
    className: '',
  });

export default BuyoTrace;
