import { ExclamationCircleOutlined, LeftOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, InputNumber, message, Modal, Row, Upload, Typography, DatePicker } from 'antd';
import axios from 'axios';
import ErrorBoundary from 'components/Errors/ErrorBoundary';
import BackIcon from 'components/UtilComponents/BackIcon';
import ModalCloseIcon from 'components/UtilComponents/ModalCloseIcon';
import { DISPATCH_URL, UPLOAD_URL } from 'constants/ApiConstants';
import { isEmpty, keyBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import moment from 'moment';

const OnReceivedDispatch = ({ dispatch, save, visible, close }) => {
  const { Text, Title } = Typography;
  const [form] = Form.useForm();
  const [receivedLoading, setReceivedLoading] = useState(false);
  const [step, setStep] = useState('collect-details');
  const transitUpdates = keyBy(dispatch?.transitUpdates, 'status');
  const currentTransit = transitUpdates[dispatch?.transitStatus];
  let transitDate = currentTransit?.updatedOn ? new Date(currentTransit.updatedOn) : null;
  if (transitDate) {
    transitDate.setMinutes(transitDate.getMinutes() + 15);
  }

  useEffect(() => {
    if (visible && dispatch) {
      form.setFields([
        { name: 'grnNumber', value: dispatch?.metadata?.grnNumber },
        { name: 'deliveryChallanNo', value: dispatch?.metadata?.deliveryChallanNo },
        { name: 'receivedQty', value: dispatch?.receivedQty > 0 ? dispatch?.receivedQty : null },
        { name: 'salesGrn', value: dispatch?.sales?.grnReceipt },
        { name: 'qualityReport', value: dispatch?.sales?.qualityReport },
      ]);
    }
  }, [visible, dispatch]);
  const onClickPrev = () => {
    if (step === 'review-details') return setStep('collect-details');
  };

  const onClickNext = () => {
    if (step === 'collect-details') return setStep('review-details');
    if (step === 'review-details') return onSubmit();
  };

  const getFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const TransitDatePicker = ({ dispatch, onChange = () => {} }) => {
    const transitUpdates = keyBy(dispatch?.transitUpdates, 'status');
    const currentTransit = transitUpdates[dispatch?.transitStatus];
    const minDate = currentTransit?.updatedOn ? new Date(currentTransit.updatedOn) : null;
    if (minDate) {
      minDate.setMinutes(minDate.getMinutes() + 15);
    }
    const maxDate = moment().endOf('day').toDate();
    const [transitDate, setTransitDate] = useState(minDate ? moment(minDate) : moment());

    return (
      <DatePicker
        size="large"
        showTime
        value={transitDate}
        onChange={(value) => {
          setTransitDate(value);
          onChange(value);
        }}
        allowClear={false}
        format="DD/MM/yyyy hh:mm A"
        disabledDate={(date) => {
          if (minDate) {
            return date.toDate() < minDate || date.toDate() > maxDate;
          }
          return date.toDate() > maxDate;
        }}
        style={{ width: '100%' }}
      />
    );
  };

  const onSubmit = async () => {
    if (receivedLoading) return;
    try {
      setReceivedLoading(true);
      const values = form.getFieldsValue(true);
      if (dispatch?.transitStatus !== 'Awaiting Unloading') {
        await axios.put(`${DISPATCH_URL}/${dispatch._id}`, {
          transitStatus: 'Awaiting Unloading',
          transitDate: values?.receivedOn,
        });
      }
      const data = {
        receivedOn: values?.receivedOn,
        transitDate: values?.receivedOn,
        receivedQty: values?.receivedQty,
        status: 'Completed',
        transitStatus: 'Goods Delivered',
        payableStatus: 'Cleared for Payment',
        metadata: {
          grnNumber: values?.grnNumber,
          deliveryChallanNo: values?.deliveryChallanNo,
        },
      };

      const imageData = new FormData();
      if (!isEmpty(values.salesGrn)) imageData.append('sales.grnReceipt', values?.salesGrn[0].originFileObj);
      if (!isEmpty(values.qualityReport)) imageData.append('sales.qualityReport', values?.qualityReport[0].originFileObj);
      if (!isEmpty(values.moistureReport)) imageData.append('sales.moistureReport', values?.moistureReport[0].originFileObj);

      const res = await axios.put(`${DISPATCH_URL}/${dispatch?._id}`, data);
      const uploadRes = await axios.post(`${DISPATCH_URL}/upload/${dispatch._id}`, imageData);

      if (res.data.status === 'error') {
        message.error(res.data.error);
      } else if (uploadRes.data.status === 'error') {
        message.error(uploadRes.data.error);
      } else {
        message.success('Dispatch has been Received');
        save();
        close(true);
        setStep('collect-details');
        form.resetFields();
      }
    } catch (err) {
      message.error(err.message);
    }
    setReceivedLoading(false);
  };

  const confirmOnClose = () => {
    if ((!receivedLoading && form.isFieldsTouched()) || step === 'review-details') {
      Modal.confirm({
        title: 'Unsaved Data',
        icon: <ExclamationCircleOutlined />,
        content: 'This form has unsaved data. Are you sure you want to close the screen?',
        okText: 'Yes',
        onOk: () => {
          form.resetFields();
          setStep('collect-details');
          close();
        },
        cancelText: 'No',
      });
    } else {
      setStep('collect-details');
      form.resetFields();
      close();
    }
  };

  const getTitle = () => {
    if (step === 'collect-details') {
      return <Title level={5}>Did you receive the dispatch?</Title>;
    }

    return (
      <>
        <BackIcon onClick={onClickPrev} className="mr-3" />
        {' Review Dispatch Details'}
      </>
    );
  };

  return (
    <Modal title={getTitle()} onCancel={confirmOnClose} open={visible} footer={null} closeIcon={<ModalCloseIcon />} maskClosable={false} width={480} bodyStyle={{ height: '75vh', overflow: 'scroll' }}>
      <ErrorBoundary>
        <Form form={form} layout="vertical" onFinish={step === 'collect-details' ? onClickNext : onSubmit} requiredMark={false} scrollToFirstError={true}>
          {step === 'collect-details' && (
            <Col>
              <Form.Item
                name="grnNumber"
                initialValue={dispatch?.metadata?.grnNumber ? dispatch.metadata.grnNumber : undefined}
                label="GRN Number"
                rules={[
                  {
                    required: true,
                    message: 'Please enter valid GRN Number',
                  },
                ]}>
                <Input size="large" maxLength={25} />
              </Form.Item>
              <Form.Item
                label="Delivery Challan Number"
                name="deliveryChallanNo"
                initialValue={dispatch?.metadata?.deliveryChallanNo ? dispatch.metadata.deliveryChallanNo : undefined}
                rules={[
                  {
                    required: true,
                    message: 'Please enter valid Delivery Challan Number',
                  },
                ]}>
                <Input size="large" maxLength={25} />
              </Form.Item>
              <Form.Item label="Received On" name="receivedOn" initialValue={transitDate}>
                <TransitDatePicker dispatch={dispatch} onChange={(value) => (transitDate = value)} />
              </Form.Item>
              <Form.Item
                name="receivedQty"
                label={`Received Quantity (${dispatch?.subUnit})`}
                extra={`Dispatched Quantity ${dispatch?.dispatchedQty} ${dispatch?.subUnit}`}
                initialValue={dispatch?.receivedQty ? dispatch?.receivedQty : dispatch?.dispatchedQty}
                rules={[
                  {
                    required: true,
                    message: 'Please enter valid received quantity',
                  },
                  {
                    validator(_, value) {
                      const dispatchedQty = dispatch?.dispatchedQty;
                      const maxReceivedQty = (dispatchedQty * 1.15).toFixed(3);
                      const minReceivedQty = (dispatchedQty * 0.85).toFixed(3);
                      if (value > 0 && (value <= minReceivedQty || value >= maxReceivedQty)) {
                        return Promise.reject(`Received quantity must be between ${minReceivedQty}  and ${maxReceivedQty} of dispatched quantity.`);
                      }
                      return Promise.resolve();
                    },
                  },
                ]}>
                <InputNumber size="large" precision={3} style={{ width: '100%' }} />
              </Form.Item>
              <Form.Item
                name="salesGrn"
                label="Upload GRN"
                getValueFromEvent={getFile}
                rules={[
                  {
                    required: true,
                    message: 'Please upload a valid GRN',
                  },
                ]}>
                <Upload maxCount={1} beforeUpload={() => false} accept="image/*, application/pdf" defaultFileList={form.getFieldValue('salesGrn') || []}>
                  <Button size="large" icon={<UploadOutlined />}>
                    Click to Upload
                  </Button>
                </Upload>
              </Form.Item>
              {dispatch?.sales?.grnReceipt && (
                <Button size="large" href={`${UPLOAD_URL}/${dispatch.sales.grnReceipt}`} type="link" target="_blank" style={{ marginTop: -24, marginBottom: 10 }}>
                  View Existing GRN Receipt
                </Button>
              )}

              <Form.Item name="qualityReport" label="Upload Quality Report" getValueFromEvent={getFile}>
                <Upload maxCount={1} beforeUpload={() => false} accept="image/*, application/pdf" defaultFileList={form.getFieldValue('qualityReport') || []}>
                  <Button size="large" icon={<UploadOutlined />}>
                    Click to Upload
                  </Button>
                </Upload>
              </Form.Item>
              {dispatch?.sales?.qualityReport && (
                <Button size="large" href={`${UPLOAD_URL}/${dispatch.sales.qualityReport}`} type="link" target="_blank" style={{ marginTop: -24, marginBottom: 10 }}>
                  View Existing Quality Report
                </Button>
              )}

              <Form.Item name="moistureReport" label="Moisture Report" getValueFromEvent={getFile}>
                <Upload maxCount={1} beforeUpload={() => false} accept="image/*, application/pdf" defaultFileList={form.getFieldValue('moistureReport') || []}>
                  <Button size="large" icon={<UploadOutlined />}>
                    Click to Upload
                  </Button>
                </Upload>
              </Form.Item>
              {dispatch?.sales?.moistureReport && (
                <Button size="large" href={`${UPLOAD_URL}/${dispatch.sales.moistureReport}`} type="link" target="_blank" style={{ marginTop: -24, marginBottom: 10 }}>
                  View Existing Moisture Report
                </Button>
              )}
            </Col>
          )}

          {/* Review the details before submitting */}
          {step === 'review-details' && (
            <>
              <>
                <Row justify="space-between" className="my-1">
                  <Text>GRN Number:</Text>
                  <Text>{form.getFieldValue('grnNumber')}</Text>
                </Row>
                <Row justify="space-between" className="my-1">
                  <Text>Delivery Challan Number:</Text>
                  <Text>{form.getFieldValue('deliveryChallanNo')}</Text>
                </Row>
                {form.getFieldValue('receivedQty') > 0 ? (
                  <Row justify="space-between" className="mt-3">
                    <Text>Received Qty:</Text>
                    <Text>
                      {form.getFieldValue('receivedQty')} {dispatch?.subUnit}
                    </Text>
                  </Row>
                ) : (
                  ''
                )}
              </>

              <Form.Item className="mt-4">
                <Button type="link" onClick={() => setStep('collect-details')} icon={<LeftOutlined />}>
                  Edit Details
                </Button>
              </Form.Item>
            </>
          )}

          <Form.Item className="mt-4">
            <Button type="primary" size="large" block htmlType="submit" loading={receivedLoading}>
              {step === 'collect-details' && 'Next'}
              {step === 'review-details' && 'Confirm'}
            </Button>
          </Form.Item>
        </Form>
      </ErrorBoundary>
    </Modal>
  );
};

export default OnReceivedDispatch;
