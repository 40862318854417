import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Alert, message, Select, Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { keyBy } from 'lodash';
import ModalCloseIcon from './ModalCloseIcon';
import { COMMON_URL, SAVED_ADDRESS_URL } from 'constants/ApiConstants';
import axios from 'axios';
import { motion } from 'framer-motion';

const AddNewAddress = (props) => {
  const { visible, close, selected } = props;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState(null);
  const [states, setStates] = useState([]);
  const [country, setCountry] = useState(null);
  const [countries, setCountries] = useState([]);
  const [countriesById, setCountriesById] = useState({});
  const [countriesByName, setCountriesByName] = useState({});
  const [statesByName, setStatesByName] = useState({});
  const [statesById, setStatesById] = useState({});
  const [cities, setCities] = useState([]);
  const [citiesByName, setCitiesByName] = useState({});
  const [countriesLoading, setCountriesLoading] = useState(false);
  const [statesLoading, setStatesLoading] = useState(false);
  const [citiesLoading, setCitiesLoading] = useState(false);
  const [error, setError] = useState(null);
  const [locateError, setLocateError] = useState(null);

  useEffect(() => {
    if (selected) {
      setCountry(countriesByName[selected?.country]?.isoCode);
      form.setFields([
        { name: 'name', value: selected?.name },
        { name: 'line1', value: selected?.line1 },
        { name: 'line2', value: selected?.line2 },
        { name: 'country', value: selected?.country },
        { name: 'state', value: selected?.state },
        { name: 'city', value: selected?.city },
        { name: 'pincode', value: selected?.pincode },
      ]);
    }
  }, [selected]);

  useEffect(() => {
    if (!visible) {
      form.resetFields();
      setError(null);
      setState(null);
      setCountry(null);
      setLocateError(null);
    }
  }, [visible]);

  useEffect(() => {
    onLoadCountries();
  }, []);

  useEffect(() => {
    if (country) onLoadStates();
  }, [country]);

  useEffect(() => {
    if (state) onLoadCities();
  }, [state]);

  useEffect(() => {
    if (statesByName) setState(statesByName[selected?.state]?.isoCode);
  }, [statesByName]);

  const onLoadCountries = async () => {
    setCountriesLoading(true);
    try {
      const res = await axios.get(`${COMMON_URL}/countries`);
      if (res.data.status === 'error') {
        setError(res.data.error);
      } else {
        setCountries(res.data.countries);
        setCountriesById(keyBy(res.data.countries, 'isoCode'));
        setCountriesByName(keyBy(res.data.countries, 'name'));
      }
    } catch (err) {
      setError(err?.message);
    }
    setCountriesLoading(false);
  };

  const onLoadStates = async () => {
    setStatesLoading(true);
    try {
      const res = await axios.get(`${COMMON_URL}/states/${country}`, {
        method: 'get',
      });
      if (res.data.status === 'error') {
        setError(res.data.error);
      } else {
        setStates(res.data.states);
        setStatesById(keyBy(res.data.states, 'isoCode'));
        setStatesByName(keyBy(res.data.states, 'name'));
      }
    } catch (err) {
      setError(err?.message);
    }
    setStatesLoading(false);
  };

  const onLoadCities = async () => {
    setCitiesLoading(true);
    try {
      const res = await axios.get(`${COMMON_URL}/cities/${country}/${state}`, {
        method: 'get',
      });
      if (res.data.status === 'error') {
        setError(res.data.error);
      } else {
        setCities(res.data.cities);
        setCitiesByName(keyBy(res.data.cities, 'name'));
      }
    } catch (err) {
      setError(err?.message);
    }
    setCitiesLoading(false);
  };

  const confirmOnClose = () => {
    if (!loading && form.isFieldsTouched()) {
      Modal.confirm({
        title: 'Unsaved Data',
        icon: <ExclamationCircleOutlined />,
        content: 'This form has unsaved data. Are you sure you want to close the screen?',
        okText: 'Yes',
        onOk: () => {
          form.resetFields();
          close();
        },
        cancelText: 'No',
      });
    } else {
      close();
    }
  };

  const onSubmit = async () => {
    if (loading) return;
    setLoading(true);
    setError(null);
    try {
      const values = form.getFieldsValue(true);
      const coordinate = citiesByName[values.city];
      const data = {
        name: values.name,
        line1: values.line1,
        line2: values.line2 && values.line2,
        city: values.city,
        state: statesById[values.state]?.name,
        country: countriesById[values.country]?.name,
        pincode: String(values.pincode),
        business: selected ? selected?.business?._id : 'me', // passing 'me' will take the businessId from session
        location: { type: 'Point', coordinates: [coordinate.longitude, coordinate.latitude] },
        status: 'Active',
      };
      if (!selected) {
        data.type = 'Saved';
      }
      let res = null;
      if (selected) {
        res = await axios.put(`${SAVED_ADDRESS_URL}/${selected?._id}`, data);
      } else {
        res = await axios.post(`${SAVED_ADDRESS_URL}`, data);
      }

      if (res.data.status === 'error') {
        message.error(res.data.error);
        return setLoading(false);
      }
      message.success(`${selected ? 'Address updated succesfully' : 'New Address has been added to your business'}`);
      close(true);
    } catch (err) {
      setError(err.message);
    }
    setLoading(false);
  };

  console.log(error);

  return (
    <Modal
      title={`${selected ? 'Edit' : 'Add'} Saved Address`}
      open={visible}
      onCancel={confirmOnClose}
      footer={null}
      closeIcon={<ModalCloseIcon />}
      width={480}
      bodyStyle={{ height: '70vh', overflow: 'scroll' }}
      centered>
      <Form form={form} name="addNewAddress" layout="vertical" onFinish={onSubmit} requiredMark={false}>
        <Form.Item
          name="name"
          label="Name (for your reference)"
          rules={[
            {
              required: true,
              message: 'Please enter a reference name',
            },
            {
              pattern: /^[a-zA-Z ]*$/,
              message: 'Please enter a valid reference name',
            },
          ]}>
          <Input size="large" />
        </Form.Item>

        <Form.Item
          name="line1"
          label="Address Line 1"
          rules={[
            {
              required: true,
              message: 'Please enter your address line 1',
            },
          ]}>
          <Input size="large" />
        </Form.Item>

        <Form.Item name="line2" label="Address Line 2">
          <Input size="large" />
        </Form.Item>

        <Form.Item
          name="country"
          label="Country"
          rules={[
            {
              required: true,
              message: 'Please select your country',
            },
          ]}>
          <Select
            size="large"
            showSearch
            onChange={(country) => {
              form.setFieldsValue({ state: null, city: null, pincode: null });
              setCountry(country);
            }}
            filterOption={(inputValue, option) => option.value?.toLowerCase().includes(inputValue?.toLowerCase()) || option.children?.toLowerCase().includes(inputValue?.toLowerCase())}
            loading={countriesLoading}>
            {countries.map((country) => (
              <Select.Option key={country?.isoCode} value={country?.isoCode}>
                {country?.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="state"
          label="State"
          rules={[
            {
              required: true,
              message: 'Please select your state',
            },
          ]}>
          <Select
            size="large"
            showSearch
            onChange={(state) => {
              form.setFieldsValue({ city: null, pincode: null });
              setState(state);
            }}
            filterOption={(inputValue, option) => option.value?.toLowerCase().includes(inputValue?.toLowerCase()) || option.children?.toLowerCase().includes(inputValue?.toLowerCase())}
            loading={statesLoading}>
            {states.map((state) => (
              <Select.Option key={state?.isoCode} value={state?.isoCode}>
                {state?.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="city"
          label="City"
          rules={[
            {
              required: true,
              message: 'Please select your city',
            },
          ]}>
          <Select
            size="large"
            showSearch
            loading={citiesLoading}
            onChange={() => {
              form.setFieldsValue({ pincode: null });
            }}>
            {cities.map((city) => (
              <Select.Option key={city?.name} value={city?.name}>
                {city?.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="pincode"
          label="Pincode"
          rules={[
            {
              required: true,
              message: 'Please enter your pincode',
            },
            {
              pattern: /^[0-9]*$/,
              message: 'Please enter a valid pincode',
            },
          ]}>
          <Input className="w-50" maxLength={9} size="large" />
        </Form.Item>

        <motion.div
          initial={{ opacity: 0, marginBottom: 0 }}
          animate={{
            opacity: locateError ? 1 : 0,
            marginTop: locateError ? 20 : 0,
            marginBottom: locateError ? 40 : 0,
          }}>
          <Alert type={'error'} showIcon message={locateError}></Alert>
        </motion.div>

        <Form.Item>
          <Button size="large" type="primary" block htmlType="submit" loading={loading}>
            Save Address
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddNewAddress;
