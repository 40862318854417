import React, { useState } from 'react';
import { Avatar, Button, Col, Divider, Image, Row, Space, Tag, Typography } from 'antd';
import { ClockCircleOutlined, DownOutlined, InfoCircleOutlined, UpOutlined } from '@ant-design/icons';
import { BsCheck2All } from 'react-icons/bs';
import { format } from 'date-fns';
import ChatView from 'components/UtilComponents/ChatView';
import DetailsGrid from 'components/UtilComponents/DetailsGrid';
import NumberText from 'components/UtilComponents/NumberText';

const { Title, Text, Paragraph } = Typography;

const QuoteCard = ({ chatType }) => {
  const [showMore, setShowMore] = useState(false);

  const onShowMore = () => setShowMore(!showMore);

  const onDownloadQuote = () => {};

  const onCounterOffer = () => {};

  const onPlaceOrder = () => {};

  const onRejectQuote = () => {};

  return (
    <ChatView chatType={chatType}>
      <Row>
        <Col span={16}>
          <Paragraph type="secondary">Quote for</Paragraph>
          <Title level={5} className="fw-semi-bold">
            Wood Chips
          </Title>
          <Paragraph>50 MT / Month</Paragraph>
          <DetailsGrid title="Price" description={<NumberText bold value={6000} suffix="/ MT" />} />
        </Col>
        <Col span={8}>
          <Image className="rounded" src={require('assets/images/biodiesel.jpg')} />
        </Col>
      </Row>

      <Space direction="vertical" size={'middle'} className="w-100">
        <Tag icon={<ClockCircleOutlined />}>Expires in 7 days</Tag>

        {!showMore && (
          <>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <DetailsGrid title="Delivery By" description={<Text strong>{format(new Date(), 'dd MMM yyyy')}</Text>} />
              </Col>
              <Col span={12}>
                <DetailsGrid title="Pickup Location" description={'Karur, TamilNadu'} description2={'1/175, Trichy Road, Sulur, Coimbatore'} />
              </Col>
            </Row>
          </>
        )}

        <Row justify="space-between">
          <Col span={12}>
            <Button type="link" className="p-0" onClick={onShowMore}>
              {!showMore ? (
                <>
                  More Info <DownOutlined style={{ fontSize: 10 }} />
                </>
              ) : (
                <>
                  Less Info <UpOutlined style={{ fontSize: 10 }} />
                </>
              )}
            </Button>
          </Col>

          <Col span={12}>
            <Button type="link" block onClick={onDownloadQuote}>
              Download Quote
            </Button>
          </Col>
        </Row>

        {showMore && (
          <>
            {chatType === 'received' && (
              <div>
                <DetailsGrid icon={<Avatar src={require('assets/images/icon-cash.png')} shape="square" size="small" />} description="Price Basis" />
                <Row justify="space-between" className="mt-3">
                  <Text>Price Per Unit</Text>
                  <NumberText bold value={6000} suffix={'/ MT'} />
                </Row>
                <Row justify="space-between" className="my-1">
                  <Text>Quantity</Text>
                  <Text strong>20 MT</Text>
                </Row>
                <Divider className="my-0" />
                <Row justify="space-between" className="my-1">
                  <Text>Total</Text>
                  <NumberText bold value={120000} />
                </Row>
                <Row justify="space-between" className="my-1">
                  <Text>GST (5 %)</Text>
                  <NumberText bold value={6000} />
                </Row>
                <Row justify="space-between" className="my-1">
                  <Text>Delivery Charges</Text>
                  <Text strong>Extra</Text>
                </Row>
                <Divider className="my-0" />
                <Row justify="space-between" className="my-1">
                  <Text>Quantity</Text>
                  <Text strong>20 MT</Text>
                </Row>
                <Row justify="space-between" className="my-1">
                  <Text>Grand Total (Approx.)</Text>
                  <NumberText bold value={120000} />
                </Row>
                <Divider className="my-0" />
                <Tag icon={<InfoCircleOutlined />} color="#B4B4B4" className="my-3">
                  Final invoice amount will be calculated after loading
                </Tag>

                <Paragraph strong>Terms of Payment</Paragraph>
                <Text>50% advance &amp; 50% before dispatch</Text>
                <Space direction="vertical" size={'middle'} className="w-100 my-2">
                  <DetailsGrid icon={<Avatar src={require('assets/images/icon-delivery.png')} shape="square" size="small" />} description="Delivery" />
                  <Row gutter={[16, 16]}>
                    <Col span={12}>
                      <DetailsGrid title="Start Deliveries By" description={format(new Date(), 'dd MMM yyyy')} />
                    </Col>
                    <Col span={12}>
                      <DetailsGrid title="Pickup Location" description={'Coimbatore, TamilNadu'} description2={'1/175, Trichy Road, Sulur, Coimbatore'} />
                    </Col>
                  </Row>
                  <DetailsGrid
                    icon={<Avatar src={require('assets/images/icon-quality.png')} shape="square" size="small" />}
                    title={<Text strong>Quality Remarks</Text>}
                    description="Lorem ipsum dolor sit amet, consectetur adipiscinglentesque vulputate porttitor orci sed accumsan. In vitae hendrerit dui. "
                  />
                </Space>
                <Divider className="my-4" />
                <Row gutter={[16, 16]}>
                  <Col span={8}>
                    <Button type="primary" ghost block onClick={onCounterOffer}>
                      Counter
                    </Button>
                  </Col>
                  <Col span={16}>
                    <Button type="primary" block onClick={onPlaceOrder}>
                      Place Order
                    </Button>
                  </Col>
                  <Col span={12}>
                    <Button type="link" ghost onClick={onRejectQuote}>
                      Reject Quote
                    </Button>
                  </Col>
                </Row>
              </div>
            )}
          </>
        )}
      </Space>
      <Divider className="my-3" />
      <Row align="middle" justify={chatType === 'sent' ? 'end' : 'start'}>
        {chatType === 'sent' && <BsCheck2All />}
        &nbsp;
        <Text className="text-small">{format(new Date(), 'hh:mm a, dd MMM yyyy')}</Text>
      </Row>
    </ChatView>
  );
};

export default QuoteCard;
