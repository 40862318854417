import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/scss/_global.scss';
import './App.less';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import posthog from 'posthog-js';
import { POSTHOG_HOST, POSTHOG_PROJECT_KEY } from 'constants/ApiConstants';

posthog.init(POSTHOG_PROJECT_KEY, { api_host: POSTHOG_HOST, autocapture: false });

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);
