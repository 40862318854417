import React, { useEffect, useState } from 'react';
import { Divider, List, message, Typography } from 'antd';
import axios from 'axios';
import { LISTING_OFFER_URL } from 'constants/ApiConstants';
import OfferDetails from './OffersDetails';
import IconOffers from 'assets/images/my-offers.png';
import EmptyContent from 'components/UtilComponents/EmptyContent';
import Loader from 'components/UtilComponents/Loader';

const { Text } = Typography;

const CreatedOffers = ({ tab, navigate }) => {
  const [listingOffers, setListingOffers] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (tab === 'pending-offers') {
      onLoadOffers();
    }
  }, [tab]);

  const onLoadOffers = async () => {
    setLoading(true);
    try {
      const res = await axios.get(`${LISTING_OFFER_URL}?populate=true`, {
        headers: {
          'X-API-Filters': JSON.stringify({ buyer: ['me'], status: ['Created'] }),
          'X-API-Sort': JSON.stringify({ createdAt: 'desc' }),
        },
      });
      if (res.data.status === 'error') {
        message.error(res.data.error);
      } else {
        setListingOffers(res.data.openlistingoffers);
      }
    } catch (err) {
      message.error(err.message);
    }
    setLoading(false);
  };

  if (loading) return <Loader />;

  return (
    <>
      {listingOffers?.length > 0 ? (
        <>
          <Text type="secondary">
            {listingOffers.length} result{listingOffers.length > 1 && 's'} in total
          </Text>
          <List
            header={null}
            footer={null}
            grid={{ gutter: 16, column: 1 }}
            dataSource={listingOffers}
            pagination={false}
            split={false}
            renderItem={(listingOffer) => (
              <List.Item key={listingOffer?._id}>
                <OfferDetails listingOffer={listingOffer} onRefresh={onLoadOffers} />
                <Divider />
              </List.Item>
            )}
          />
        </>
      ) : (
        <EmptyContent
          source={IconOffers}
          title={'No Pending Offers found'}
          subTitle={'Explore Open Listings and place orders.'}
          actionText={'View Listings'}
          onActionPress={() => navigate('/buy/listings')}
        />
      )}
    </>
  );
};

export default CreatedOffers;
