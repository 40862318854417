import React, { useEffect, useState } from 'react';
import { Alert, Avatar, Button, Col, Divider, Modal, PageHeader, Row, Timeline, Typography, message, Radio, Card, Tag } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { format } from 'date-fns';
import { startCase, toLower, isEmpty } from 'lodash';
import { RightOutlined } from '@ant-design/icons';
import { BsCheckCircleFill, BsFillXCircleFill, BsTruck } from 'react-icons/bs';
import BackIcon from 'components/UtilComponents/BackIcon';
import CompanyName from 'components/UtilComponents/CompanyName';
import DetailsGrid from 'components/UtilComponents/DetailsGrid';
import NumberText from 'components/UtilComponents/NumberText';
import Loader from 'components/UtilComponents/Loader';
import ModalCloseIcon from 'components/UtilComponents/ModalCloseIcon';
import { BUSINESS_URL, DISPATCH_URL, OPEN_MERCHANT_NAME, ORDER_URL, UPLOAD_URL } from 'constants/ApiConstants';
import { formatRefNo } from 'utils/formatter';
import { Colors } from 'constants/ColorConstants';
import { AccessDenied, PageNotFound } from 'components/Errors';
import { getBreakPoint } from 'utils/layout';

const { Title, Text, Paragraph } = Typography;
const statuses = {
  status: [{ status: 'In Preparation' }, { status: 'Dispatched' }, { status: 'Completed' }, { status: 'Cancelled' }, { status: 'On Hold' }],
};

const OrderDetails = () => {
  const isMobile = !getBreakPoint().includes('md');
  const { tab, id: orderId } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [accessDenied, setAccessDenied] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [receivedLoading, setReceivedLoading] = useState(false);
  const [completeLoading, setCompletedLoading] = useState(false);
  const [order, setOrder] = useState({});
  const [dispatches, setDispatches] = useState([]);
  const totalQty = order?.qty * (order?.duration || 1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { type } = useParams();
  const [status, setStatus] = useState('In Preparation');
  const mode = !type || type === 'status' ? 'status' : `${type}Status`;
  const statusList = statuses[mode];

  useEffect(() => {
    onLoadOrder();
  }, [orderId]);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const onViewDispatch = (id) => {
    navigate(`/buy/purchaseorders/dispatch/${id}`);
  };

  useEffect(() => {
    onLoadOrder();
  }, [status]);

  const onLoadOrder = async () => {
    setLoading(true);
    try {
      const businessRes = await axios.get(`${BUSINESS_URL}/me`);
      const res = await axios.get(`${ORDER_URL}/${orderId}?&populate=true`);

      // if orderId is invalid, display order not found component.
      if (res.data.code === 1006 || res.data.code === 1007) {
        setNotFound(true);
        return setLoading(false);
      }

      // if buyer is different from current business, then access should be denied.
      if (res.data.order?.buyer?._id !== businessRes.data?.business?._id) {
        setAccessDenied(true);
        return setLoading(false);
      }

      const dispatchRes = await axios.get(`${DISPATCH_URL}?populate=true`, {
        headers: {
          'X-Api-Filters': JSON.stringify({ order: [orderId], status: [status] }),
          'X-Api-Sort': JSON.stringify({ createdAt: 'asc' }),
        },
      });

      if (res.data.status === 'error') {
        message.error(res.data.error);
      } else if (dispatchRes.data.status === 'error') {
        message.error(dispatchRes.data.error);
      } else {
        setOrder(res.data.order);
        setDispatches(dispatchRes.data.dispatches);
      }
    } catch (err) {
      message.error(err.message);
    }
    setLoading(false);
  };

  const onOrderComplete = () => {
    Modal.confirm({
      title: `Are you sure you want to complete and close the order now?`,
      content: `Total: ${totalQty || '∞'} ${order?.subUnit} | Received: ${order?.metadata?.receivedQty} ${order?.subUnit}`,
      bodyStyle: { backgroundColor: '#fff', borderRadius: 10 },
      icon: null,
      closable: true,
      closeIcon: <ModalCloseIcon />,
      cancelText: 'Cancel',
      cancelButtonProps: {
        type: 'primary',
        ghost: true,
      },
      onCancel() {},
      okText: 'Complete & Close',
      okButtonProps: {
        loading: completeLoading,
      },
      onOk: async () => {
        if (completeLoading) return;
        setCompletedLoading(true);
        try {
          const res = await axios.put(`${ORDER_URL}/${order?._id}`, {
            metadata: {
              completedByBuyer: true,
            },
          });

          if (res.data.status === 'error') {
            message.error(res.data.error);
          } else {
            message.success('Order Completed');
            onLoadOrder();
          }
        } catch (err) {
          message.error(err.message);
        }
        setCompletedLoading(false);
      },
    });
  };

  const getStatus = (dispatch) => {
    if (dispatch.status === 'In Preparation') {
      return 'Your goods are in preparation and will be shipped soon.';
    } else if (dispatch.status === 'Dispatched') {
      return `Dispatched Quantity: ${(
        <b>
          {dispatch?.dispatchedQty} {order?.subUnit}
        </b>
      )}`;
    } else if (dispatch.status === 'Completed') {
      return `Completed delivery of ${dispatch.receivedQty} ${order?.subUnit} on ${format(new Date(dispatch.reachesOn), 'dd MMM yyyy')}`;
    } else if (dispatch.status === 'On Hold') {
      return `Your order is currently on hold. Please contact us if you have any questions`;
    } else if (dispatch.status === 'Cancelled') {
      return `Dispatch of ${dispatch.dispatchedQty} ${order?.subUnit} has been cancelled. Please contact us if you have any questions`;
    }
    return '';
  };

  const onDeliveredDispatch = (dispatch) => {
    Modal.confirm({
      title: `Did you receive the dispatch?`,
      content: `E-Way Bill No: ` + (dispatch.salesBillNo ?? formatRefNo(dispatch.dispatchRefNo, 'BFD')),
      bodyStyle: { backgroundColor: '#fff', borderRadius: 10 },
      icon: null,
      closable: true,
      closeIcon: <ModalCloseIcon />,
      cancelText: 'Cancel',
      cancelButtonProps: {
        type: 'primary',
        ghost: true,
      },
      onCancel() {},
      okText: 'Received',
      okButtonProps: {
        loading: receivedLoading,
      },
      onOk: async () => {
        if (receivedLoading) return;
        setReceivedLoading(true);
        try {
          const res = await axios.put(`${DISPATCH_URL}/${dispatch?._id}`, {
            receivedOn: new Date(),
            receivedQty: dispatch?.dispatchedQty,
            status: 'Completed',
            transitStatus: 'Goods Delivered',
          });

          if (res.data.status === 'error') {
            message.error(res.data.error);
          } else {
            message.success('Dispatch has been Received');
            onLoadOrder();
          }
        } catch (err) {
          message.error(err.message);
        }
        setReceivedLoading(false);
      },
    });
  };

  const getTitle = () => {
    if (isMobile) return `${order?.product?.name} - ${totalQty || '∞'} ${order?.subUnit}`;
    return `${order?.product?.name} - ${totalQty || '∞'} ${order?.subUnit} / ${order?.frequency} | ${formatRefNo(order?.orderRefNo)}`;
  };

  if (loading) return <Loader />;

  if (notFound) return <PageNotFound />;

  if (accessDenied) return <AccessDenied />;

  return (
    <>
      <div className="app-container">
        <PageHeader
          onBack={() => navigate(-1)}
          backIcon={<BackIcon />}
          title={getTitle()}
          subTitle={!isMobile && startCase(toLower(tab)).slice(0, -1)}
          footer={isMobile && `${order?.frequency} | ${formatRefNo(order?.orderRefNo)} | ${startCase(toLower(tab)).slice(0, -1)}`}
          extra={
            !isMobile && (
              <Col flex={1} className="d-flex flex-column align-items-end">
                <Paragraph>Supplier</Paragraph>
                <CompanyName name={order?.enquiryType === 'Direct Enquiry' ? order?.seller?.name : OPEN_MERCHANT_NAME} verified={true} />
              </Col>
            )
          }
          className="px-0"
        />
      </div>
      <Divider className="my-2" />
      <div className="app-container">
        {isMobile && (
          <div className="my-3">
            <Paragraph>Supplier</Paragraph>
            <CompanyName name={order?.enquiryType === 'Direct Enquiry' ? order?.seller?.name : OPEN_MERCHANT_NAME} verified={true} />
          </div>
        )}
        <Row gutter={[16, 16]} className="mt-4">
          <Col xs={12} sm={12} md={6} lg={6}>
            <DetailsGrid
              icon={<Avatar src={require('assets/images/icon-quantity.png')} shape="square" size="small" />}
              title="Dispatched Quantity"
              description={`${order?.metadata?.dispatchedQty} of ${totalQty || '∞'} ${order?.subUnit}`}
            />
          </Col>
          <Col xs={12} sm={12} md={6} lg={6}>
            <DetailsGrid title="Received Qty" description={`${order?.metadata?.receivedQty} of ${order?.metadata?.dispatchedQty} ${order?.subUnit}`} />
          </Col>
          <Col xs={12} sm={12} md={6} lg={6}>
            <DetailsGrid
              icon={<Avatar src={require('assets/images/icon-cash.png')} shape="square" size="small" />}
              title={`Total Bill (${order?.metadata?.receivedQty} ${order?.subUnit})`}
              description={<NumberText prefix={order?.currency} value={order?.paymentPriceBasis?.receivable?.value / 100} />}
            />
          </Col>
          <Col xs={12} sm={12} md={6} lg={6}>
            <DetailsGrid title="Payment Pending" description={<NumberText prefix={order?.currency} value={order?.paymentPriceBasis?.pendingReceivable?.value / 100} />} />
          </Col>
          <Col xs={12} sm={12} md={6} lg={6}>
            <DetailsGrid icon={<Avatar src={require('assets/images/icon-order-status.png')} shape="square" size="small" />} title="Order Status" description={order?.status} />
          </Col>
        </Row>

        <Divider />

        <div className="d-flex flex-column align-items-center">
          <Title level={5} className="fw-bold">
            Track Dispatch
          </Title>

          <div className="d-flex flex-column align-items-center m-4">
            <Radio.Group value={status} onChange={(e) => setStatus(e.target.value)} buttonStyle="solid" size="small">
              {statusList.map(({ status }) => (
                <Radio.Button value={status}>&emsp;{status}&nbsp;</Radio.Button>
              ))}
            </Radio.Group>
          </div>

          {dispatches.length > 0 ? (
            <Timeline className="my-5">
              {dispatches.map((dispatch) => {
                const icon =
                  dispatch?.status === 'Accepted' ? (
                    <BsCheckCircleFill style={{ backgroundColor: 'transparent' }} />
                  ) : ['Cancelled', 'Rejected'].includes(dispatch?.status) ? (
                    <BsFillXCircleFill style={{ backgroundColor: 'transparent' }} />
                  ) : (
                    <BsTruck style={{ backgroundColor: 'transparent' }} />
                  );
                const color = dispatch?.status === 'Accepted' ? '#0A8A85' : ['Cancelled', 'Rejected'].includes(dispatch?.status) ? '#ff4d4f' : Colors.primary;
                return (
                  <Timeline.Item color={color} dot={icon} key={dispatch?._id}>
                    <Card>
                      <Tag className="rounded px-2 border-0" color={dispatch?.transitStatus !== 'Awaiting Unloading' ? 'gold' : '#53EC62'} icon={<BsTruck className="mr-2" />}>
                        {dispatch?.transitStatus}
                      </Tag>
                      <Paragraph>
                        <a href="#" target="_blank">
                          {dispatch?.dispatchTrackingNo}
                        </a>
                      </Paragraph>
                      <Paragraph>
                        <Text strong>E-way Bill No: </Text>
                        <Text mark>{dispatch?.salesBillNo || formatRefNo(dispatch?.dispatchRefNo, 'BFD')} </Text>
                      </Paragraph>
                      <Paragraph>{getStatus(dispatch)}</Paragraph>
                      <Paragraph type="secondary">{format(new Date(dispatch.dispatchedOn), 'dd MMM yyyy, hh:mm a')}</Paragraph>

                      <Paragraph>
                        <Text strong>{`${dispatch?.dispatchedQty} ${order?.subUnit} `} </Text>
                        <Text>{` | Will reach on ${format(new Date(dispatch.reachesOn), 'dd MMM yyyy')}.`} </Text>
                      </Paragraph>
                      <Divider />
                      {dispatch?.salesBillNo && (
                        <>
                          <Text>Sales Bill No: </Text>
                          <DetailsGrid description={dispatch?.salesBillNo} />
                        </>
                      )}
                      {!isEmpty(dispatch?.sales) ? (
                        <Button type="link" onClick={showModal}>
                          View Attachments
                          <RightOutlined />
                        </Button>
                      ) : null}
                      <Button type="primary" onClick={() => onViewDispatch(dispatch?._id)}>
                        View
                      </Button>
                      {dispatch?.vehicleNo && (
                        <Paragraph>
                          <Text strong>Vehicle Number: </Text>
                          <Text>{dispatch?.vehicleNo}</Text>
                        </Paragraph>
                      )}
                      {dispatch?.dispatchFrom && (
                        <Paragraph>
                          <Text strong>Dispatch Area Pincode: </Text>
                          <Text>{dispatch?.dispatchFrom}</Text>
                        </Paragraph>
                      )}
                      {dispatch?.notes && (
                        <Paragraph>
                          <Text strong>Additional Information: </Text>
                          {dispatch?.notes}
                        </Paragraph>
                      )}

                      <Modal title="View Attachment" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                        {/* <Row> */}
                        {!isEmpty(dispatch?.sales) ? (
                          <Card>
                            {dispatch?.sales?.invoice && (
                              <Col span={24}>
                                <Button type="link" href={`${UPLOAD_URL}/${dispatch?.sales?.invoice}`} target="_blank">
                                  View Invoice
                                  <RightOutlined />
                                </Button>
                              </Col>
                            )}
                            {dispatch?.sales?.ewayBill && (
                              <Col span={24}>
                                <Button type="link" href={`${UPLOAD_URL}/${dispatch?.sales?.ewayBill}`} target="_blank">
                                  View E-way Bill
                                  <RightOutlined />
                                </Button>
                              </Col>
                            )}
                            {dispatch?.sales?.weightSlip && (
                              <Col span={24}>
                                <Button type="link" href={`${UPLOAD_URL}/${dispatch?.sales?.weightSlip}`} target="_blank">
                                  View Weight Slip
                                  <RightOutlined />
                                </Button>
                              </Col>
                            )}
                            {dispatch?.sales?.attachments?.length > 0 &&
                              dispatch?.sales?.attachments.map((attachment, index) => {
                                return (
                                  <Col span={24} key={attachment}>
                                    <Button type="link" href={`${UPLOAD_URL}/${attachment}`} target="_blank">
                                      View Attachment {index + 1}
                                      <RightOutlined />
                                    </Button>
                                  </Col>
                                );
                              })}
                          </Card>
                        ) : null}
                      </Modal>
                      {order?.status === 'In Progress' && ['Dispatched'].includes(dispatch?.status) && (
                        <>
                          <Button type="primary" ghost onClick={() => onDeliveredDispatch(dispatch)}>
                            Received?
                          </Button>
                        </>
                      )}
                      {/* </Row> */}
                    </Card>
                  </Timeline.Item>
                );
              })}
            </Timeline>
          ) : (
            <Text>No Dispatch Yet</Text>
          )}

          {!order.metadata?.completedByBuyer && (
            <>
              <Paragraph>Have you received the order?</Paragraph>
              <Button type="primary" className="my-2" onClick={onOrderComplete}>
                Complete Order
              </Button>
              {order.metadata?.completedBySeller && <Paragraph>Seller has completed order from their end</Paragraph>}
            </>
          )}

          {order.metadata?.completedByBuyer && !order.metadata?.completedBySeller && <Alert message="Seller is yet to complete order from their end" type="info" showIcon />}

          <Divider />
        </div>
      </div>
    </>
  );
};

export default OrderDetails;
