import React from 'react';
import { Avatar, Button, Card, Col, Divider, Row, Space, Typography } from 'antd';
import { StarFilled } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import CompanyName from 'components/UtilComponents/CompanyName';

const { Text, Paragraph } = Typography;

const RecommendedSellerCard = () => {
  const navigate = useNavigate();

  const onViewProfile = (sellerId) => navigate(`/buy/seller-profile/${sellerId}`);

  const onSendRFQ = () => {};

  return (
    <Card hoverable bodyStyle={{ padding: '15px' }}>
      <Row gutter={16} align="middle" wrap={false}>
        <Col>
          <Avatar src={require('assets/images/dummy-company.png')} shape="circle" size={100} />
        </Col>
        <Col span={14}>
          <CompanyName showLogo={false} name={'Jayadev Biofuels'} verified={true} />
          <Paragraph className="text-secondary text-small">Coimbatore, India</Paragraph>

          <Row align="middle" className="my-2">
            <StarFilled style={{ color: '#EDC200' }} />
            <Text className="mx-1 text-small">4.5/5</Text>
            <Text className="mx-1 text-secondary text-small">(25 Reviews)</Text>
          </Row>

          <Divider className="my-2" />

          <Paragraph className="text-secondary">Available Products</Paragraph>
          <Paragraph ellipsis={{ rows: 1 }}>Cashew shell, Wood chips, Saw dust, Bio diesel, Bio CNG</Paragraph>
        </Col>
        <Col flex={1} className="d-flex flex-column justify-content-around">
          <Space direction="vertical">
            <Button
              type="primary"
              ghost
              block
              size="large"
              onClick={() => onViewProfile('1')} // replace 1 with sellerId
            >
              View Profile
            </Button>
            <Button type="primary" block size="large" onClick={onSendRFQ}>
              Send RFQ
            </Button>
          </Space>
        </Col>
      </Row>
    </Card>
  );
};

export default RecommendedSellerCard;
