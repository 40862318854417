import React, { useState } from 'react';
import { Button, Col, Drawer, Image, Layout, Row } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import MenuContent from './MenuContent';

const { Header } = Layout;

const MobileNavBar = (props) => {
  const [menuVisible, setMenuVisible] = useState(false);

  const onClickMenu = () => {
    setMenuVisible(!menuVisible);
  };

  return (
    <>
      <Header style={{ position: 'sticky', top: 0, zIndex: 1, width: '100%' }}>
        <Row justify="space-between">
          <Col>
            <Image src={require('assets/images/buyofuel-logo.png')} width={150} preview={false} className="ml-2" />
          </Col>
          <Col>
            <Button type="text" size="large" icon={<MenuOutlined />} onClick={onClickMenu} />
          </Col>
        </Row>
      </Header>

      <Drawer open={menuVisible} onClose={onClickMenu} width="300" placement="left" closable={false}>
        <Image src={require('assets/images/buyofuel-logo.png')} width={150} preview={false} className="mb-4" />
        <MenuContent {...props} onClickMenu={onClickMenu} />
      </Drawer>
    </>
  );
};

export default MobileNavBar;
