import React, { useState } from 'react';
import { Button, Divider, Form, Input, InputNumber, Modal, Row, Typography, Upload, message, Select } from 'antd';
import { LeftOutlined, ExclamationCircleOutlined, FileImageOutlined, FileTextOutlined } from '@ant-design/icons';
import axios from 'axios';
import { each, groupBy, isEmpty, keyBy } from 'lodash';
import ErrorBoundary from 'components/Errors/ErrorBoundary';
import BackIcon from 'components/UtilComponents/BackIcon';
import ModalCloseIcon from 'components/UtilComponents/ModalCloseIcon';
import { formatRefNo } from 'utils/formatter';
import { DISPATCH_URL, ORDER_URL } from 'constants/ApiConstants';
import { useEffect } from 'react';
import { checkForAnomaly } from 'utils/anomaly';

const { Paragraph, Title, Text } = Typography;

const UpdateOrderStatus = ({ visible, close, order }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [orderId, setOrderId] = useState(null);
  const [searchTerm, setSearchTerm] = useState([]);
  const [ordersById, setOrdersById] = useState(null);
  const [step, setStep] = useState('collect-details');
  const [refreshing, setRefreshing] = useState(false);

  const onClickPrev = () => {
    if (step === 'review-details') return setStep('collect-details');
  };

  const onClickNext = () => {
    if (step === 'collect-details') return setStep('review-details');
    if (step === 'review-details') return onSubmit();
  };

  useEffect(() => {
    if (order) {
      setSelectedOrder(order);
    } else {
      setSelectedOrder(null);
    }
  }, [order]);

  const totalQty = selectedOrder?.qty;
  const deliveredQty = selectedOrder?.metadata?.dispatchedQty || 0;
  const remainingQty = totalQty - deliveredQty > 0 ? totalQty - deliveredQty : 0;

  useEffect(() => {
    if (!selectedOrder) {
      onLoadOrders();
    }
  }, [selectedOrder]);

  useEffect(() => {
    if (orderId) {
      setSelectedOrder(ordersById[orderId]);
    }
  }, [orderId]);

  const onLoadOrders = async () => {
    setRefreshing(true);
    try {
      const res = await axios.get(`${ORDER_URL}?populate=true`, {
        headers: {
          'X-API-Filters': JSON.stringify({ status: ['In Progress'], seller: ['me'] }),
          'X-API-Sort': JSON.stringify({ orderDate: 'desc' }),
        },
      });
      if (res.data.status === 'error') {
        message.error(res.data.error);
        setRefreshing(false);
        return [];
      } else {
        setOrdersById(keyBy(res.data.orders, '_id'));
        setSearchTerm(res.data.orders);
        setRefreshing(false);
      }
    } catch (err) {
      message.error(err.message);
      setRefreshing(false);
      return [];
    }
  };
  const getTitle = () => {
    if (step === 'collect-details') {
      return (
        <>
          <Title level={5}>Create Dispatch</Title>

          {order && (
            <>
              <Text type="secondary" className="text-small">
                Order No :
              </Text>
              <Text className="text-small">{` ${formatRefNo(order?.orderRefNo)}`}</Text>
            </>
          )}
        </>
      );
    }
    return (
      <>
        <BackIcon onClick={onClickPrev} className="mr-3" />
        {' Review Dispatch Details'}
      </>
    );
  };

  /* Create dispatch on submit */
  const onSubmit = async () => {
    if (loading) return;
    setLoading(true);
    try {
      const values = form.getFieldsValue(true);
      const detectedAnomaly = checkForAnomaly('dispatch', { ...values, subUnit: selectedOrder?.subUnit, type: 'Purchase' });
      if (detectedAnomaly) {
        setLoading(false);
        return message.error(detectedAnomaly);
      }

      const res = await axios.post(`${DISPATCH_URL}`, {
        order: selectedOrder._id,
        purchaseBillNo: values.purchaseBillNo,
        dispatchedQty: values.dispatchedQty,
        dispatchedOn: new Date(),
        vehicleNo: values.vehicleNo,
        dispatchFrom: values.dispatchFrom,
        notes: values.notes,
        status: 'In Preparation',
        transitStatus: 'Awaiting Loading',
      });

      if (res.data.status === 'success') {
        message.success('Dispatch details updated successfully');

        // upload related files for created dispatch
        const files = new FormData();
        if (!isEmpty(values?.invoice)) files.append('purchase.invoice', values?.invoice[0].originFileObj);
        if (!isEmpty(values?.ewayBill)) files.append('purchase.ewayBill', values?.ewayBill[0].originFileObj);
        if (!isEmpty(values?.weightSlip)) files.append('purchase.weightSlip', values?.weightSlip[0].originFileObj);
        if (!isEmpty(values?.qualityReport)) files.append('purchase.qualityReport', values?.qualityReport[0].originFileObj);
        if (!isEmpty(values?.attachments))
          each(values.attachments, (attachment) => {
            files.append('purchase.attachments', attachment.originFileObj);
          });

        await axios.post(`${DISPATCH_URL}/upload/${res?.data?.dispatch?._id}`, files);

        setStep('collect-details');
        form.resetFields();
        close(true);
      } else {
        message.error(res.data.error);
      }
    } catch (err) {
      message.error(err.message);
    }
    setLoading(false);
  };

  const getFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const confirmOnClose = () => {
    if ((!loading && form.isFieldsTouched()) || step === 'review-details') {
      Modal.confirm({
        title: 'Unsaved Data',
        icon: <ExclamationCircleOutlined />,
        content: 'This form has unsaved data. Are you sure you want to close the screen?',
        okText: 'Yes',
        onOk: () => {
          form.resetFields();
          setStep('collect-details');
          if (!order) setSelectedOrder(null);
          close(false);
        },
        cancelText: 'No',
      });
    } else {
      setStep('collect-details');
      if (!order) setSelectedOrder(null);
      form.resetFields();
      close(false);
    }
  };

  return (
    <Modal title={getTitle()} open={visible} onCancel={confirmOnClose} footer={null} closeIcon={<ModalCloseIcon />} maskClosable={false} width={480} bodyStyle={{ height: '75vh', overflow: 'scroll' }}>
      <ErrorBoundary>
        <Form form={form} layout="vertical" onFinish={step === 'collect-details' ? onClickNext : onSubmit} requiredMark={false} scrollToFirstError={true}>
          {step === 'collect-details' && (
            <>
              {!order && (
                <Form.Item
                  name="order"
                  valuePropName="value"
                  label="Order No"
                  rules={[
                    {
                      required: true,
                      message: 'Please select a valid Order No',
                    },
                  ]}>
                  <Select
                    size="large"
                    showSearch
                    onChange={(order) => setOrderId(order)}
                    filterOption={(inputValue, option) => {
                      return (option?.label ?? '').includes(inputValue.toUpperCase());
                    }}
                    loading={refreshing}>
                    {Object.entries(groupBy(searchTerm, 'product.name')).map(([productName, orders]) => (
                      <Select.OptGroup key={productName} label={productName}>
                        {orders.map((trader) => (
                          <Select.Option key={trader._id} value={trader._id} label={`Order No: ${formatRefNo(trader.orderRefNo)}`}>
                            <div>
                              <p className="mt-0" style={{ fontSize: '13px' }}>
                                <span style={{ fontWeight: 'bold' }}>Order No: </span>
                                {formatRefNo(trader.orderRefNo)}, <span style={{ fontWeight: 'bold' }}>Delivery Location: </span>
                                {trader?.deliveryAddress?.city},{trader?.deliveryAddress?.state}
                              </p>
                            </div>
                          </Select.Option>
                        ))}
                      </Select.OptGroup>
                    ))}
                  </Select>
                </Form.Item>
              )}
              <Form.Item
                name="purchaseBillNo"
                label="Purchase / E-way Bill No"
                rules={[
                  {
                    required: true,
                    message: 'Please enter the purchase bill number',
                  },
                ]}
                hasFeedback>
                <Input maxLength={25} size="large" />
              </Form.Item>
              {
                <Form.Item
                  name="dispatchedQty"
                  label={`Dispatched Quantity ${selectedOrder?.subUnit ? `(${selectedOrder?.subUnit})` : ''}`}
                  className="bg-transparent"
                  extra={Boolean(selectedOrder) && `Pending Quantity: ${remainingQty} ${selectedOrder?.subUnit}`}
                  rules={[
                    {
                      required: true,
                      message: 'Please enter valid dispatched quantity',
                    },
                    {
                      validator: (_, value) => {
                        if (value <= remainingQty * 1.25) {
                          return Promise.resolve();
                        }
                        return Promise.reject(`Dispatched Quantity should be lesser than ${(remainingQty * 1.25).toFixed(3)} ${selectedOrder?.subUnit ? `(${selectedOrder?.subUnit})` : ''}`);
                      },
                    },
                  ]}
                  hasFeedback>
                  <InputNumber precision={3} className="w-100" size="large" min={0.3} controls={false} />
                </Form.Item>
              }

              <Form.Item
                name="vehicleNo"
                label="Vehicle No"
                className="bg-transparent"
                rules={[
                  {
                    required: true,
                    message: 'Please enter the vehicle no',
                  },
                ]}
                hasFeedback>
                <Input size="large" maxLength={15} />
              </Form.Item>
              <Form.Item
                name="dispatchFrom"
                label="Dispatch Area Pincode"
                className="bg-transparent"
                rules={[
                  {
                    required: true,
                    pattern: /^[0-9]*$/,
                    message: 'Please enter the Dispatch From Pincode',
                  },
                  () => ({
                    validator(_, value) {
                      if (value.toString()?.length > 0) {
                        if (value.toString()?.length !== 6) {
                          return Promise.reject('Please enter a valid 6 digit Pin Code');
                        }
                        return Promise.resolve();
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
                hasFeedback>
                <Input size="large" maxLength={12} />
              </Form.Item>
              <Form.Item name="invoice" label="Invoice" getValueFromEvent={getFile}>
                <Upload maxCount={1} beforeUpload={() => false} defaultFileList={form.getFieldValue('invoice') || []} accept=".jpg, .jpeg, .png, .pdf">
                  <Button size="large" icon={<FileImageOutlined />}>
                    Upload Invoice
                  </Button>
                </Upload>
              </Form.Item>

              <Form.Item name="ewayBill" label="E-Way Bill" getValueFromEvent={getFile}>
                <Upload maxCount={1} beforeUpload={() => false} defaultFileList={form.getFieldValue('ewayBill') || []} accept=".jpg, .jpeg, .png, .pdf">
                  <Button size="large" icon={<FileImageOutlined />}>
                    Upload Eway Bill
                  </Button>
                </Upload>
              </Form.Item>

              <Form.Item name="weightSlip" label="Weight Slip" getValueFromEvent={getFile}>
                <Upload maxCount={1} beforeUpload={() => false} defaultFileList={form.getFieldValue('weightSlip') || []} accept=".jpg, .jpeg, .png, .pdf">
                  <Button size="large" icon={<FileTextOutlined />}>
                    Upload Weight Slip
                  </Button>
                </Upload>
              </Form.Item>

              <Form.Item name="qualityReport" label="Quality Report" getValueFromEvent={getFile}>
                <Upload maxCount={1} beforeUpload={() => false} defaultFileList={form.getFieldValue('qualityReport') || []} accept=".jpg, .jpeg, .png, .pdf">
                  <Button size="large" icon={<FileTextOutlined />}>
                    Upload Quality Report
                  </Button>
                </Upload>
              </Form.Item>

              <Form.Item name="attachments" label="Attachments" getValueFromEvent={getFile}>
                <Upload multiple beforeUpload={() => false} defaultFileList={form.getFieldValue('attachments') || []} accept=".jpg, .jpeg, .png, .pdf">
                  <Button size="large" icon={<FileTextOutlined />}>
                    Upload Additional Files
                  </Button>
                </Upload>
              </Form.Item>

              <Form.Item label="Additional Information" className="bg-transparent" name="notes">
                <Input.TextArea size="large" rows={3} maxLength={300} showCount allowClear />
              </Form.Item>
            </>
          )}

          {/* Review the details before submitting */}
          {step === 'review-details' && (
            <>
              <>
                <Row justify="space-between" className="mt-3">
                  <Text>Purchase Bill No:</Text>
                  <Text>{form.getFieldValue('purchaseBillNo')}</Text>
                </Row>

                <Row justify="space-between" className="my-1">
                  <Text>Dispatched Quantity:</Text>
                  <Text>
                    {form.getFieldValue('dispatchedQty')} {`${selectedOrder?.subUnit ? `(${selectedOrder?.subUnit})` : ''}`}
                  </Text>
                </Row>

                {form.getFieldValue('vehicleNo') && (
                  <Row justify="space-between" className="my-1">
                    <Text>Vehicle No:</Text>
                    <Text>{form.getFieldValue('vehicleNo')}</Text>
                  </Row>
                )}
                {form.getFieldValue('dispatchFrom') && (
                  <Row justify="space-between" className="my-1">
                    <Text>Dispatch Area Pincode:</Text>
                    <Text>{form.getFieldValue('dispatchFrom')}</Text>
                  </Row>
                )}
              </>

              <Divider />

              {form.getFieldValue('notes') && (
                <>
                  <Paragraph>
                    <strong>Additional Information:</strong>
                  </Paragraph>
                  <Paragraph>&bull; {form.getFieldValue('notes')}</Paragraph>
                  <Divider />
                </>
              )}

              <Form.Item className="mt-4">
                <Button type="link" onClick={() => setStep('collect-details')} icon={<LeftOutlined />}>
                  Edit Details
                </Button>
              </Form.Item>
            </>
          )}

          <Form.Item className="mt-4">
            <Button type="primary" size="large" block htmlType="submit" loading={loading}>
              {step === 'collect-details' && 'Next'}
              {step === 'review-details' && 'Add Dispatch'}
            </Button>
          </Form.Item>
        </Form>
      </ErrorBoundary>
    </Modal>
  );
};

export default UpdateOrderStatus;
