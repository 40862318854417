import React from 'react';
import { PageHeader, Tabs } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import BackIcon from 'components/UtilComponents/BackIcon';
import ErrorBoundary from 'components/Errors/ErrorBoundary';
import ActiveSchedules from './ActiveSchedules';
import CompletedSchedules from './CompletedSchedules';

const Quotes = () => {
  const { tab } = useParams();
  const navigate = useNavigate();

  const tabItems = [
    // remember to pass the key prop
    {
      label: 'Active',
      key: 'active',
      children: <ActiveSchedules tab={tab} navigate={navigate} />,
    },
    {
      label: 'Completed',
      key: 'completed',
      children: <CompletedSchedules tab={tab} navigate={navigate} />,
    },
  ];
  return (
    <div className="app-container">
      <PageHeader onBack={() => navigate('/sell')} backIcon={<BackIcon />} title="Dispatch Schedules" className="px-0" />

      <ErrorBoundary>
        <Tabs type="line" items={tabItems} defaultActiveKey={tab} onChange={(key) => navigate(`/sell/dispatch-schedules/${key}`)} />
      </ErrorBoundary>
    </div>
  );
};

export default Quotes;
