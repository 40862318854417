import React from 'react';
import { Avatar, message, Typography } from 'antd';

const { Paragraph } = Typography;

const CopyToClipboard = ({ text }) => {
  const success = () => {
    message.success({
      icon: <Avatar src={require('assets/images/action-complete.png')} size="default" shape="circle" />,
      content: ` Copied to clipboard`,
      style: {
        marginTop: '80vh',
      },
    });
  };

  return (
    <Paragraph
      copyable={{
        onCopy: () => success(),
        // icon: [<Text className="text-primary">Copy</Text>],
        tooltips: false,
      }}>
      {text}
    </Paragraph>
  );
};

export default CopyToClipboard;
