import React from 'react';
import ExpressCard from 'components/Enquiry/ExpressCard';
import OfferCard from 'components/Enquiry/OfferCard';
import OrderCard from 'components/Enquiry/OrderCard';
import QuoteCard from 'components/Enquiry/QuoteCard';
import RFQCard from 'components/Enquiry/RFQCard';
import StatusCard from 'components/Enquiry/StatusCard';
import CompanyName from 'components/UtilComponents/CompanyName';

const DirectRFQsChat = () => {
  return (
    <>
      <div className="d-flex w-100 px-3 py-2 position-sticky border-bottom">
        <CompanyName showLogo={require('assets/images/dummy-company.png')} logoSize={28} name={'Raw Carbon Inc.'} verified />
      </div>
      <div className="d-flex flex-column w-100 p-3 px-4 chatlist-scrollbar" style={{ height: '80vh', overflowY: 'auto' }}>
        <RFQCard chatType="sent" />
        <QuoteCard chatType="received" />
        <OrderCard chatType="sent" />
        <OrderCard chatType="received" />
        <ExpressCard chatType="sent" />
        <ExpressCard chatType="received" />
        <OfferCard chatType="sent" />
        <OfferCard chatType="received" />
        <StatusCard chatType="sent" />
        <StatusCard chatType="received" />
      </div>
    </>
  );
};

export default DirectRFQsChat;
