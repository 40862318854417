import React, { useEffect, useState } from 'react';
import { Card, Col, List, Row, Typography, message, Avatar, Divider } from 'antd';
import axios from 'axios';
import ContentLoader from 'react-content-loader';
import { ORDER_URL, UPLOAD_URL } from 'constants/ApiConstants';
import { formatRefNo } from 'utils/formatter';
import { CloseCircleTwoTone } from '@ant-design/icons';
import CreateOpenListing from 'components/Modals/Sell/CreateOpenListing';
import IconOrders from 'assets/images/orders.png';
import EmptyContent from 'components/UtilComponents/EmptyContent';
import { getBreakPoint } from 'utils/layout';
import NumberText from 'components/UtilComponents/NumberText';
import DetailsGrid from 'components/UtilComponents/DetailsGrid';

const { Text, Title } = Typography;

const DroppedOrders = ({ tab }) => {
  const isMobile = !getBreakPoint().includes('sm');
  const [showOpenListing, setShowOpenListing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    if (tab === 'cancelled-orders') {
      onLoadOrders();
    }
  }, [tab]);

  const onLoadOrders = async () => {
    setLoading(true);
    try {
      const res = await axios.get(`${ORDER_URL}?populate=true`, {
        headers: {
          'X-API-Filters': JSON.stringify({ seller: ['me'], status: ['Cancelled'] }),
          'X-API-Sort': JSON.stringify({ createdAt: 'desc' }),
        },
      });
      if (res.data.status === 'error') {
        message.error(res.data.error);
      } else {
        setOrders(res.data.orders);
      }
    } catch (err) {
      message.error(err.message);
    }
    setLoading(false);
  };

  const onCreateWholesaleOpenListing = () => {
    setShowOpenListing(!showOpenListing);
  };

  if (loading) {
    return (
      <ContentLoader width="100%" height="70vh" backgroundColor="#d9d9d9" foregroundColor="#ededed">
        <rect x="0" y="25" rx="10" ry="10" width="48%" height="211" />
        <rect x="50%" y="25" rx="10" ry="10" width="50%" height="211" />
        <rect x="0" y="250" rx="10" ry="10" width="48%" height="211" />
        <rect x="50%" y="250" rx="10" ry="10" width="50%" height="211" />
      </ContentLoader>
    );
  }

  return (
    <>
      {orders?.length > 0 ? (
        <List
          className="my-4"
          header={null}
          footer={null}
          grid={{ gutter: [16, 4], column: isMobile ? 1 : 2 }}
          dataSource={orders}
          pagination={orders?.length > 0}
          renderItem={(order) => {
            const { product } = order;
            return (
              <List.Item>
                <Card size={isMobile ? 'small' : 'default'}>
                  <Row align="middle" justify="flex-start" className="mb-3">
                    <Col span={18}>
                      <Row gutter={10} justify="flex-start" wrap={false} className="mb-3">
                        <Col>
                          <Avatar src={`${UPLOAD_URL}/${order?.product?.image}`} shape="square" size="large" />
                        </Col>
                        <Col>
                          <Title level={5} className="fw-medium">
                            {product?.name}
                          </Title>
                          <NumberText value={order?.purchasingPrice / 100} prefix={order?.currency} suffix={`/ ${order?.subUnit}`} />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <DetailsGrid
                    icon={<Avatar src={require('assets/images/po-number.png')} shape="square" size="small" />}
                    title="Order Number"
                    description={<Text strong>{formatRefNo(order?.orderRefNo)}</Text>}
                  />
                  <Row gutter={16} className="mt-3">
                    <Col span={12}>
                      <DetailsGrid
                        icon={<Avatar src={require('assets/images/total-quantity.png')} shape="square" size="small" />}
                        title="Total Quantity"
                        description={
                          <Text strong>
                            {order?.qty || '∞'} {order?.subUnit}
                          </Text>
                        }
                      />
                    </Col>
                    <Col span={12}>
                      <DetailsGrid
                        icon={<Avatar src={require('assets/images/dispatched-quantity.png')} shape="square" size="small" />}
                        title="Dispatch Quantity"
                        description={
                          <Text strong>
                            {order?.metadata?.dispatchedQty}
                            {order?.subUnit}
                          </Text>
                        }
                      />
                    </Col>
                  </Row>
                  <Divider dashed={true} className="my-3" />

                  <Row align="middle" className="mt-3">
                    <CloseCircleTwoTone twoToneColor="#ff4d4f" style={{ fontSize: '16px' }} />
                    <Text className="ml-2">Your order has been cancelled. </Text>
                  </Row>
                </Card>
              </List.Item>
            );
          }}
        />
      ) : (
        <EmptyContent source={IconOrders} title={'No Cancelled Orders'} subTitle={'There are no dropped orders found right now.'} />
      )}
      <CreateOpenListing visible={showOpenListing} close={onCreateWholesaleOpenListing} />
    </>
  );
};

export default DroppedOrders;
