export const API_BASE_URL = process.env.REACT_APP_BASE_URL;
export const DTN_URL = process.env.REACT_APP_DTN_URL;
export const USER_URL = `${API_BASE_URL}/user`;
export const LEAD_URL = `${API_BASE_URL}/lead`;
export const LANGUAGE_URL = `${API_BASE_URL}/language`;
export const LOCALISATION_URL = `${API_BASE_URL}/localisation`;
export const BUSINESS_URL = `${API_BASE_URL}/business`;
export const PRODUCT_URL = `${API_BASE_URL}/product`;
export const UNIT_URL = `${API_BASE_URL}/unit`;
export const LISTING_URL = `${API_BASE_URL}/openlisting`;
export const LISTING_OFFER_URL = `${API_BASE_URL}/openlistingoffer`;
export const DASHBOARD_URL = `${API_BASE_URL}/dashboard`;
export const REPORTING_URL = `${API_BASE_URL}/reporting`;
export const DIRECT_ENQUIRY_URL = `${API_BASE_URL}/directenquiry`;
export const OPEN_RFQ_URL = `${API_BASE_URL}/openrequirement`;
export const OPEN_RFQ_QUOTE_URL = `${API_BASE_URL}/openrequirementquote`;
export const ORDER_URL = `${API_BASE_URL}/order`;
export const DISPATCH_URL = `${API_BASE_URL}/dispatch`;
export const PAYMENT_URL = `${API_BASE_URL}/payment`;
export const ADVANCED_PAYMENT_URL = `${API_BASE_URL}/advancedpayment`;
export const PURCHASE_ORDER_URL = `${API_BASE_URL}/purchaseorder`;
export const PURCHASE_ORDER_SCHEDULE_URL = `${API_BASE_URL}/purchaseorderschedule`;
export const COMMON_URL = `${API_BASE_URL}/common`;
export const UPLOAD_URL = `${API_BASE_URL}/uploads`;
export const ACTION_URL = `${API_BASE_URL}/action`;
export const NOTIFICATION_URL = `${API_BASE_URL}/notification`;
export const SAVED_ADDRESS_URL = `${API_BASE_URL}/savedaddress`;
export const PAYMENT_TERMS_URL = `${API_BASE_URL}/paymentterm`;
export const PARTNER_URL = `${API_BASE_URL}/partner`;
export const GMAP_API_KEY = process.env.REACT_APP_GMAP_API_KEY;
export const API_VERSION = process.env.REACT_APP_API_VERSION;

export const OPEN_MERCHANT_NAME = 'BUYO INDIA PVT LTD';
export const DIRECT_ENQUIRY_ENABLED = false;
export const PAYMENT_ENABLED = false;
export const MAXIMUM_ACTIVE_ITEMS = 5;

export const POSTHOG_PROJECT_KEY = process.env.REACT_APP_POSTHOG_PROJECT_KEY;
export const POSTHOG_HOST = process.env.REACT_APP_POSTHOG_HOST;
