import React, { useState } from 'react';
import { Button, Col, Divider, Form, Input, Modal, Row, message } from 'antd';
import { MdEmail } from 'react-icons/md';
import ErrorBoundary from 'components/Errors/ErrorBoundary';
import DetailsGrid from 'components/UtilComponents/DetailsGrid';
import ModalCloseIcon from 'components/UtilComponents/ModalCloseIcon';
import { USER_URL } from 'constants/ApiConstants';
import axios from 'axios';

const ChangeEmailAddress = ({ user, visible, close }) => {
  const [form] = Form.useForm();
  const [step, setStep] = useState('email');
  const [otp, setOtp] = useState(null);
  const [email, setEmail] = useState(null);
  const [loading, setLoading] = useState(false);
  const [sendLoading, setSendLoading] = useState(false);
  const [counter, setCounter] = React.useState(0);

  React.useEffect(() => {
    const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  const onSendOTP = async (values) => {
    if (sendLoading) return;
    setSendLoading(true);
    try {
      const otp = String(Math.floor(Math.random() * 899999 + 100000));
      const res = await axios.post(`${USER_URL}/send/otp/verify`, {
        email: values.email ? values.email?.toLowerCase() : email,
        otp,
      });
      if (res.data.status === 'error') {
        message.error(res.data.error);
      } else {
        setEmail(values.email);
        setOtp(otp);
        setStep('otp');
        setCounter(59);
        message.success('OTP sent successfully to your email address');
      }
    } catch (err) {
      message.error(err.message);
    }
    setSendLoading(false);
  };

  const onSubmit = async (values) => {
    if (loading) return;
    setLoading(true);
    try {
      if (values.otp === otp) {
        const res = await axios.put(`${USER_URL}/me`, {
          email,
        });
        if (res.data.status === 'error') {
          message.error(res.data.error);
        } else {
          message.success('Email address changed successfully');
          setEmail(null);
          setOtp(null);
          setStep('email');
          form.resetFields();
          close(true);
        }
      } else {
        message.error('Please enter a valid OTP');
      }
    } catch (err) {
      message.error(err.message);
    }
    setLoading(false);
  };

  const onCancel = () => {
    setEmail(null);
    setOtp(null);
    setStep('email');
    form.resetFields();
    close();
  };

  return (
    <Modal destroyOnClose={true} title={step === 'email' ? 'Change Email Address' : 'Verify Email Address'} open={visible} onCancel={onCancel} footer={null} closeIcon={<ModalCloseIcon />}>
      <ErrorBoundary>
        <Form form={form} layout="vertical" onFinish={step === 'email' ? onSendOTP : onSubmit}>
          {step === 'email' && (
            <>
              <DetailsGrid icon={<MdEmail size={24} />} title="Current Email Address" description={user?.user?.email} />

              <Divider />

              <Form.Item
                className="bg-transparent"
                name="email"
                rules={[
                  {
                    type: 'email',
                    message: 'Please enter a valid email!',
                  },
                  {
                    required: true,
                    message: 'Please enter your new email address',
                  },
                ]}
                hasFeedback>
                <Input size="large" placeholder="New Email Address" allowClear />
              </Form.Item>
            </>
          )}

          {step === 'otp' && (
            <>
              <Row align="middle" justify="space-between">
                <Col>
                  <DetailsGrid icon={<MdEmail size={24} />} title="Your New Email Address" description={form.getFieldValue('email')} />
                </Col>
                <Col>
                  <Button type="link" onClick={() => setStep('email')}>
                    Edit
                  </Button>
                </Col>
              </Row>

              <Divider />

              <Form.Item
                className="bg-transparent"
                name="otp"
                rules={[
                  {
                    required: true,
                    message: 'Please enter the OTP received',
                  },
                  {
                    message: 'Please enter a 6 digit valid OTP',
                    pattern: new RegExp('^[0-9]{6}$'),
                  },
                ]}
                hasFeedback
                extra={
                  <>
                    <small>Didn't receive OTP? {counter > 0 && `Request again in ${counter}s`}</small>
                    {counter === 0 && (
                      <Button onClick={onSendOTP} type="link" size="small">
                        Re-send OTP
                      </Button>
                    )}
                  </>
                }>
                <Input size="large" placeholder="Enter 6 Digit OTP" allowClear />
              </Form.Item>
            </>
          )}

          <Form.Item shouldUpdate className="mt-4">
            {() => (
              <Button size="large" type="primary" block htmlType="submit" loading={step === 'email' ? sendLoading : loading}>
                {step === 'email' ? 'Next' : 'Verify Email Address'}
              </Button>
            )}
          </Form.Item>
        </Form>
      </ErrorBoundary>
    </Modal>
  );
};

export default ChangeEmailAddress;
