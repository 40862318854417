import React, { useEffect, useState } from 'react';
import { Button, Card, Col, DatePicker, Divider, Image, PageHeader, Row, Space, Typography, message, notification } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import axios from 'axios';
import FileSaver from 'file-saver';
import Loader from 'components/UtilComponents/Loader';
import { BUSINESS_URL, DASHBOARD_URL, UPLOAD_URL } from 'constants/ApiConstants';
import moment from 'moment';
import { posthog } from 'posthog-js';
import { format } from 'date-fns';

const { Title, Paragraph } = Typography;
const { RangePicker } = DatePicker;

const REPORT_CONTENT = [
  {
    id: 'totalCarbonEmissionReduced',
    title: 'Total CO2 Emissions Reduced',
    image: require('assets/images/sustainability-dashboard/carbon-dioxide.png'),
    unit: 'MT',
  },
  {
    id: 'numberOfDistanceTravelledByCar',
    title: 'Eq. to distance travelled by car',
    image: require('assets/images/sustainability-dashboard/route.png'),
    unit: 'KMs',
  },
  {
    id: 'numberOfTreesInLifetime',
    title: 'Equivalent No. of Banyans Planted',
    image: require('assets/images/sustainability-dashboard/photosynthesis.png'),
    unit: 'Trees',
  },
  {
    id: 'numberOfPlasticBottles',
    title: 'Equivalent No. of plastic bottles',
    image: require('assets/images/sustainability-dashboard/plastic.png'),
    unit: 'Mil. Bottles',
  },
  {
    id: 'landFillEmissionReduced',
    title: 'Landfill emission reduced',
    image: require('assets/images/sustainability-dashboard/landfill.png'),
    unit: 'MT',
  },
  {
    id: 'wasteReduced',
    title: 'Waste Reduced',
    image: require('assets/images/sustainability-dashboard/waste.png'),
    unit: 'MT',
  },
  {
    id: 'socialCostSaved',
    title: 'Social Cost Saved',
    image: require('assets/images/sustainability-dashboard/government.png'),
    unit: 'INR',
  },
];

const SustainabilityReport = () => {
  const viewName = 'sustainability-report';
  const [loading, setLoading] = useState(false);
  const [dates, setDates] = useState([]);
  const [report, setReport] = useState({});
  const [downloading, setDownloading] = useState(false);

  useEffect(() => {
    onLoadReport(dates);
  }, [dates]);

  const onLoadReport = async (dates) => {
    setLoading(true);
    try {
      // TODO: Added business url temporarily. Remove once business: ['me'] filter is implemented for dashboard endpoint.
      const businessRes = await axios.get(`${BUSINESS_URL}/me`);

      const xApiFilters = { business: [businessRes?.data?.business?._id] };
      if (dates && dates.length > 0) {
        xApiFilters['dateRange'] = [dates[0].startOf('day'), dates[1].endOf('day')];
      }

      const res = await axios.get(`${DASHBOARD_URL}/sustainability`, {
        headers: {
          'X-API-Filters': JSON.stringify(xApiFilters),
        },
      });

      if (res.data.status === 'error') {
        message.error(res.data.error);
      } else {
        setReport(res.data.report);
      }
    } catch (error) {
      message.error(error.message);
    }
    setLoading(false);
  };

  const onDownloadCertificate = async () => {
    setDownloading(true);
    try {
      // TODO: Added business url temporarily. Remove once business: ['me'] filter is implemented for dashboard endpoint.
      const businessRes = await axios.get(`${BUSINESS_URL}/me`);

      const xApiFilters = { business: [businessRes?.data?.business?._id] };
      if (dates && dates.length > 0) {
        xApiFilters['dateRange'] = [dates[0].startOf('day'), dates[1].endOf('day')];
      }

      const res = await axios.get(`${DASHBOARD_URL}/sustainability`, {
        headers: {
          'X-API-Filters': JSON.stringify(xApiFilters),
          'X-API-Mode': 'export',
        },
      });

      if (res.data.status === 'error') {
        notification.error({
          message: 'Error',
          description: res.data.error,
        });
      } else {
        FileSaver.saveAs(`${UPLOAD_URL}/${res.data.filePath}?t=${new Date().getTime()}`, `byf-certificate-${new Date().getTime()}`);
        const data = {};
        if (dates && dates.length > 0) {
          data.from = format(new Date(dates[0]), 'dd MMM yyyy');
          data.to = format(new Date(dates[1]), 'dd MMM yyyy');
        }
        posthog.capture(`$${viewName} | $certificate-downloaded`, {
          data: data,
          $set: { lastActivityDate: new Date().toISOString() },
        });
      }
    } catch (error) {
      message.error(error.message);
    }
    setDownloading(false);
  };

  return (
    <>
      <div className="app-container">
        <PageHeader
          onBack={() => null}
          backIcon={null}
          title={`Sustainability Report`}
          extra={
            <RangePicker
              size="medium"
              placement="topRight"
              format="DD/MM/YYYY"
              value={dates}
              onChange={(date) => {
                if (date && date.length === 2) {
                  posthog.capture(`$${viewName} | $applied-date-filter`, {
                    data: { from: format(new Date(date[0]), 'dd MMM yyyy'), to: format(new Date(date[1]), 'dd MMM yyyy') },
                    $set: { lastActivityDate: new Date().toISOString() },
                  });
                }
                setDates(date);
              }}
              ranges={{
                Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                'This Week': [moment().startOf('week'), moment().endOf('week')],
                'This Month': [moment().startOf('month'), moment().endOf('month')],
                'This Financial Year': [moment().month('April').startOf('month'), moment().add('year', 1).month('March').endOf('month')],
                'Last Week': [moment().subtract(1, 'weeks').startOf('week'), moment().subtract(1, 'weeks').endOf('week')],
                'Last 30 Days': [moment().subtract(30, 'days'), moment()],
                'Last 90 Days': [moment().subtract(90, 'days'), moment()],
              }}
            />
          }
          className="py-sm-4 page-header"
        />
      </div>
      {loading && <Loader />}
      {!loading && (
        <div className="app-container my-5">
          <Row gutter={[24, 24]} align="top" justify="center">
            <Col span={24}>
              <Card className="bg-transparent border">
                <Row gutter={16} align="middle" justify="center">
                  <Col>
                    <Image width={48} height={48} src={REPORT_CONTENT[0].image} preview={false} alt={REPORT_CONTENT[0].title} />
                  </Col>
                  <Col>
                    <Title level={4} className="text-center">
                      {REPORT_CONTENT[0].title} (in {REPORT_CONTENT[0].unit})
                    </Title>
                    <Title level={4} type="success" className="text-center">
                      {report[REPORT_CONTENT[0].id] || 0}
                    </Title>
                  </Col>
                </Row>
              </Card>
            </Col>

            {REPORT_CONTENT.map((data, index) => {
              if (index === 0) return;
              return (
                <Col xs={12} sm={12} md={8} lg={8} xl={6} key={data.id}>
                  <Card className="bg-transparent border">
                    <Space direction="vertical" className="d-flex flex-column align-items-center">
                      <Paragraph className="text-center word">{data.title}</Paragraph>
                      <Image width={50} height={50} src={data.image} preview={false} alt={data.title} />
                      <span className="fw-medium" style={{ color: '#1a75da' }}>
                        {report[data.id] || 0} {data.unit}
                      </span>
                    </Space>
                  </Card>
                </Col>
              );
            })}
          </Row>

          <Row justify="center" className="my-5">
            <Button type="primary" size="large" icon={<DownloadOutlined />} onClick={onDownloadCertificate} loading={downloading} disabled={!report.volume}>
              Download Certificate
            </Button>
          </Row>

          <Divider />

          <div className="my-5 d-flex flex-column align-items-center justify-content-center">
            <Paragraph type="secondary">Powered by</Paragraph>
            <Row gutter={[24, 24]} align="middle" justify="center" className="mt-3">
              <Col>
                <Image width={75} src={require('assets/images/sustainability-dashboard/livabl.jpeg')} preview={false} alt={REPORT_CONTENT[0].title} />
              </Col>
              <Col>
                <Image width={225} src={require('assets/images/sustainability-dashboard/ghg-protocol-logo.png')} preview={false} alt={REPORT_CONTENT[0].title} />
              </Col>
            </Row>
          </div>
        </div>
      )}
    </>
  );
};

export default SustainabilityReport;
