import React, { useEffect, useState } from 'react';
import { Avatar, Button, Col, Divider, PageHeader, Row, Timeline, Typography, message, Image, Space, Progress, Card, Tag, Modal } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { isEmpty } from 'lodash';
import { format } from 'date-fns';
import { BsCheckCircleFill, BsFillXCircleFill, BsTruck } from 'react-icons/bs';
import { HiOutlineExternalLink } from 'react-icons/hi';
import { DownOutlined, FileTextOutlined, UpOutlined } from '@ant-design/icons';
import BackIcon from 'components/UtilComponents/BackIcon';
import DetailsGrid from 'components/UtilComponents/DetailsGrid';
import NumberText from 'components/UtilComponents/NumberText';
import Loader from 'components/UtilComponents/Loader';
import ModalCloseIcon from 'components/UtilComponents/ModalCloseIcon';
import { AccessDenied, PageNotFound } from 'components/Errors';
import { BUSINESS_URL, DISPATCH_URL, DTN_URL, PURCHASE_ORDER_URL, UPLOAD_URL } from 'constants/ApiConstants';
import { Colors } from 'constants/ColorConstants';
import OnReceivedDispatch from 'components/Modals/Buy/OnReceivedDispatch';
const { Title, Text, Paragraph } = Typography;
const { CheckableTag } = Tag;

const PurchaseOrderDetails = () => {
  const { purchaseOrderId } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [dispatchesLoading, setDispatchesLoading] = useState(false);
  const [accessDenied, setAccessDenied] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [order, setOrder] = useState({});
  const [dispatches, setDispatches] = useState([]);
  const [showMore, setShowMore] = useState(false);
  const [status, setStatus] = useState('All');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [business, setBusiness] = useState([]);
  const [dispatch, setDispatch] = useState([]);
  const [dispatchData, setDispatchData] = useState('');
  const [viewReceiveDispatch, SetViewReceiveDispatch] = useState(false);
  const [dispatchCount, setDispatchCount] = useState({
    All: 0,
    'In Preparation': 0,
    Dispatched: 0,
    Completed: 0,
    'On Hold': 0,
    Cancelled: 0,
  });
  const onShowMore = () => setShowMore(!showMore);

  const showModal = (dispatch) => {
    setIsModalOpen(true);
    setDispatch(dispatch);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    onLoad();
    onLoadDispatches();
  }, []);

  useEffect(() => {
    onLoadDispatches();
  }, [status]);

  const onLoad = async () => {
    setLoading(true);
    try {
      const businessRes = await axios.get(`${BUSINESS_URL}/me`);
      const res = await axios.get(`${PURCHASE_ORDER_URL}/${purchaseOrderId}?&populate=true`);

      // if purchaseorderId is invalid, display not found component.
      if (res.data.code === 1006 || res.data.code === 1007) {
        setNotFound(true);
        return setLoading(false);
      }

      // if purchaseorder's buyer is same as current business then deny access.
      if (res.data?.purchaseorder?.buyer?._id !== businessRes.data?.business?._id) {
        setAccessDenied(true);
        return setLoading(false);
      }

      if (res.data.status === 'error') {
        message.error(res.data.error);
      } else {
        setOrder(res.data.purchaseorder);
        setBusiness(businessRes.data.business);
      }
    } catch (err) {
      message.error(err.message);
    }
    setLoading(false);
  };

  const onLoadDispatches = async () => {
    setDispatchesLoading(true);
    try {
      const xApiFilters = { enquiry: [purchaseOrderId] };
      const dispatchRes = await axios.get(`${DISPATCH_URL}?populate=true`, {
        headers: {
          'X-Api-Filters': JSON.stringify(xApiFilters),
          'X-Api-Sort': JSON.stringify({ createdAt: 'desc' }),
        },
      });
      if (dispatchRes.data.status === 'error') {
        message.error(dispatchRes.data.error);
      } else {
        const { dispatches } = dispatchRes.data;
        setDispatches(dispatches.filter((d) => status === 'All' || d.status === status));
        setDispatchCount({
          All: dispatches.length,
          'In Preparation': dispatches.filter((d) => d.status === 'In Preparation').length,
          Dispatched: dispatches.filter((d) => d.status === 'Dispatched').length,
          Completed: dispatches.filter((d) => d.status === 'Completed').length,
          'On Hold': dispatches.filter((d) => d.status === 'On Hold').length,
          Cancelled: dispatches.filter((d) => d.status === 'Cancelled').length,
        });
      }
    } catch (err) {
      message.error(err.message);
    }
    setDispatchesLoading(false);
  };

  const onViewDispatch = (id) => {
    navigate(`/buy/purchaseorders/dispatch/${id}`);
  };

  const tabOptions = [
    {
      key: 'All',
      label: 'All',
    },
    {
      key: 'In Preparation',
      label: 'Ongoing',
    },
    {
      key: 'Dispatched',
      label: 'Dispatched',
    },
    {
      key: 'Completed',
      label: 'Completed',
    },
    {
      key: 'Cancelled',
      label: 'Cancelled',
    },
    {
      key: 'On Hold',
      label: 'On Hold',
    },
  ];

  const getReceivedDispatch = (dispatch) => {
    setDispatchData(dispatch);
    SetViewReceiveDispatch(true);
  };

  const closeReceived = () => {
    setDispatchData(null);
    SetViewReceiveDispatch(false);
  };

  const getColor = (status) => {
    if (status === 'Awaiting Loading') return 'gold';
    if (status === 'Loaded') return 'green';
    if (status === 'In Transit') return 'gold';
    if (status === 'Goods Delivered') return 'green';
    if (status === 'Goods Rejected') return 'red';
    return 'gold';
  };

  const getStatus = (dispatch) => {
    if (dispatch.status === 'In Preparation') {
      return 'Your goods are in preparation and will be shipped soon.';
    } else if (dispatch.status === 'Dispatched') {
      return `Dispatched Quantity: `;
    } else if (dispatch.status === 'Completed') {
      return `Delivered Quantity: `;
    } else if (dispatch.status === 'On Hold') {
      return `Your order is currently on hold. Please contact us if you have any questions`;
    } else if (dispatch.status === 'Cancelled') {
      return `Dispatch of ${dispatch.dispatchedQty?.toFixed(3)} ${order?.subUnit} has been cancelled. Please contact us if you have any questions`;
    }
    return '';
  };

  if (loading) return <Loader />;

  if (accessDenied) return <AccessDenied />;

  if (notFound) return <PageNotFound />;

  return (
    <>
      <div className="app-container">
        <PageHeader
          onBack={() => navigate(-1)}
          backIcon={<BackIcon />}
          title={`${order?.product?.name} - ${order?.fromQty}${order?.toQty > 0 ? ` - ${order?.toQty}` : ''} ${order?.subUnit} | ${order?.purchaseOrderNo}`}
          className="px-0"
        />
      </div>
      <Divider className="my-2" />
      <div className="app-container">
        <Row gutter={[24, 16]}>
          <Col xs={24} sm={24} md={8} lg={8}>
            <Image
              alt={order?.product?.name}
              style={{
                width: '100%',
                height: '300',
                objectFit: 'cover',
                borderRadius: '15px',
              }}
              src={`${UPLOAD_URL}/${order?.product?.image}`}
            />
          </Col>
          <Col xs={24} sm={24} md={16} lg={16}>
            <Title level={3} className="fw-bold">
              {order?.product?.name}
            </Title>
            <Title level={4} className="fw-medium">
              <NumberText prefix={order?.currency} value={order?.price / 100} suffix={`/ ${order?.subUnit}`} />
            </Title>
            <Space direction="vertical" className="my-3" style={{ width: '100%' }}>
              <Row gutter={[16, 16]} className="mt-2">
                <Col span={24}>
                  <DetailsGrid
                    icon={<Avatar src={require('assets/images/po-number.png')} shape="square" style={{ borderRadius: '0px', height: 25 }} size="small" />}
                    title="PO Number"
                    description={<Text strong>{order?.purchaseOrderNo}</Text>}
                  />
                </Col>
                <Col span={12}>
                  <DetailsGrid
                    icon={<Avatar src={require('assets/images/icon-quantity.png')} shape="square" size="small" />}
                    title="Total Quantity"
                    description={
                      <Text strong>
                        {order?.fromQty}
                        {order?.toQty > 0 ? ` - ${order?.toQty}` : ''} {order?.subUnit}
                      </Text>
                    }
                  />
                </Col>
                <Col span={12}>
                  <DetailsGrid
                    icon={<Avatar src={require('assets/images/icon-calendar.png')} shape="square" size="small" />}
                    title="PO Date"
                    description={<Text strong>{format(new Date(order?.purchaseOrderDate), 'dd MMM yyyy')}</Text>}
                  />
                </Col>
                {order?.status !== 'Requested' && (
                  <>
                    <Col span={12}>
                      <DetailsGrid
                        icon={<Avatar src={require('assets/images/icon-quantity.png')} shape="square" size="small" />}
                        title="Pending Quantity"
                        description={
                          <Text strong>
                            {order?.metadata?.pendingQty?.toFixed(3)} {order?.subUnit}
                          </Text>
                        }
                      />
                    </Col>
                    <Col span={12}>
                      <DetailsGrid
                        icon={<Avatar src={require('assets/images/icon-quantity.png')} shape="square" size="small" />}
                        title="Dispatched Quantity"
                        description={
                          <Text strong>
                            {order?.metadata?.dispatchedQty} of {order?.metadata?.dispatchedQty + order?.metadata?.pendingQty} {order?.subUnit}
                          </Text>
                        }
                      />
                    </Col>
                    <Col span={12}>
                      <DetailsGrid
                        icon={<Avatar src={require('assets/images/icon-quantity.png')} shape="square" size="small" />}
                        title="For this month"
                        description={
                          <Text strong>
                            {order?.metadata?.monthlyDispatchedQty?.toFixed(3) || 0} {order?.subUnit}
                          </Text>
                        }
                      />
                    </Col>
                    <Col span={16} className="my-2">
                      <Progress
                        percent={order?.metadata?.percentCompleted}
                        status="normal"
                        format={() => `${order?.metadata?.percentCompleted}% Completed`}
                        strokeColor={order?.metadata?.percentCompleted >= 100 ? '#53c41c' : '#1890ff'}
                      />
                    </Col>
                  </>
                )}
              </Row>
            </Space>
            <Row align={'middle'} gutter={[24, 24]}>
              <Col xs={12} sm={12} md={6} lg={12}>
                <Button type="link" className="p-0" onClick={onShowMore}>
                  {showMore ? (
                    <>
                      Close Details <UpOutlined style={{ fontSize: 10 }} />
                    </>
                  ) : (
                    <>
                      View Details <DownOutlined style={{ fontSize: 10 }} />
                    </>
                  )}
                </Button>
              </Col>
            </Row>

            {showMore && (
              <>
                <Paragraph className="fw-medium" type="secondary">
                  Delivery Details
                </Paragraph>
                <Space direction="vertical" size={'large'} className="w-100 my-2">
                  <DetailsGrid
                    icon={<Avatar src={require('assets/images/icon-location-dark.png')} shape="square" size="small" />}
                    title="Delivery Location"
                    description={`${order?.deliveryAddress?.line1}, ${order?.deliveryAddress?.line2 ? order?.deliveryAddress?.line2 + ',' : ''} ${order?.deliveryAddress?.city}, ${
                      order?.deliveryAddress?.state
                    }, ${order?.deliveryAddress?.country}-${order?.deliveryAddress?.pincode}`}
                  />
                </Space>
                <Paragraph className="fw-medium" type="secondary">
                  Additional Details
                </Paragraph>
                <Space direction="vertical" size={'large'} className="w-100 my-2">
                  <Row>
                    <Col className="m-2" span={24}>
                      <DetailsGrid icon={<Avatar src={require('assets/images/pending-quantity.png')} shape="square" size="small" />} title="Quality Testing" description={order?.qualityTesting} />
                    </Col>
                    <Col className="m-2" span={24}>
                      <DetailsGrid icon={<Avatar src={require('assets/images/icon-quality.png')} shape="square" size="small" />} title="Quality Remarks" description={order?.remark || '-'} />
                    </Col>
                    <Col className="m-2" span={24}>
                      <DetailsGrid icon={<Avatar src={require('assets/images/icon-cash.png')} shape="square" size="small" />} title="Payment Terms" description={order?.paymentTerm?.name} />
                    </Col>
                  </Row>
                </Space>
                <Row gutter={[16, 16]} align="middle" className="my-2">
                  {order?.purchaseOrderFile && (
                    <Col span={12}>
                      <Button type="link" href={`${UPLOAD_URL}/${order?.purchaseOrderFile}`} icon={<FileTextOutlined />} target="_blank">
                        View Purchase Order File
                      </Button>
                    </Col>
                  )}
                </Row>
              </>
            )}
          </Col>
        </Row>
        <Divider />

        {order?.status !== 'Requested' && (
          <>
            <div className="d-flex w-100 vh-100 flex-column align-items-center">
              <Title level={5} className="fw-bold">
                Track Dispatch
              </Title>
              <Row className="mt-4" wrap={false}>
                {tabOptions.map((tab) => (
                  <>
                    <CheckableTag
                      key={tab.key}
                      checked={status === tab.key}
                      onChange={() => {
                        setStatus(tab.key);
                      }}>
                      {tab.label} ({dispatchCount[tab.key]})
                    </CheckableTag>
                  </>
                ))}
              </Row>
              {!dispatchesLoading && dispatches?.length > 0 && (
                <Timeline className="my-5">
                  {dispatches.map((dispatch) => {
                    const icon =
                      dispatch?.status === 'Accepted' ? (
                        <BsCheckCircleFill style={{ backgroundColor: 'transparent' }} />
                      ) : ['Cancelled', 'Rejected'].includes(dispatch?.status) ? (
                        <BsFillXCircleFill style={{ backgroundColor: 'transparent' }} />
                      ) : (
                        <BsTruck style={{ backgroundColor: 'transparent' }} />
                      );
                    const color = dispatch?.status === 'Accepted' ? '#0A8A85' : ['Cancelled', 'Rejected'].includes(dispatch?.status) ? '#ff4d4f' : Colors.primary;
                    return (
                      <Col xs={24} sm={24} mg={12} lg={24}>
                        <Timeline.Item color={color} dot={icon} key={dispatch?._id}>
                          <Card>
                            <Typography.Paragraph>
                              Transit Status:
                              <Tag className="rounded px-2 border-0" color={getColor(dispatch?.transitStatus)} icon={<BsTruck className="mr-2" />}>
                                {dispatch?.transitStatus}
                              </Tag>
                            </Typography.Paragraph>

                            <Typography.Paragraph>
                              {getStatus(dispatch)}
                              {['Completed', 'Dispatched'].includes(dispatch?.status) && (
                                <Typography.Text>{dispatch?.status === 'Dispatched' ? dispatch?.dispatchedQty : dispatch?.status === 'Completed' ? dispatch?.receivedQty : ''}</Typography.Text>
                              )}
                              <Typography.Text>{dispatch?.order && ['Completed', 'Dispatched'].includes(dispatch?.status) ? order?.subUnit : ''}</Typography.Text>
                            </Typography.Paragraph>
                            <br />
                            {dispatch?.dispatchTrackingNo && (
                              <>
                                Tracking ID:
                                <Button
                                  style={{ margin: '0px', padding: '0px' }}
                                  type="link"
                                  onClick={() => window.open(`${DTN_URL}/track/${dispatch?.dispatchTrackingNo}?utm_source=app&utm_medium=web&utm_campaign=link_open&utm_content=${business._id}`)}>
                                  {dispatch?.dispatchTrackingNo} <HiOutlineExternalLink size={16} />
                                </Button>
                              </>
                            )}
                            <Divider className="my-2" />
                            <Row className="my-2">
                              <Paragraph className="text-small" type="secondary">{`Created at: ${format(new Date(dispatch?.createdAt), 'dd MMM yyyy, hh:mm a')} `}</Paragraph>
                            </Row>
                            {dispatch?.salesBillNo && (
                              <div className="mb-2">
                                <Text type="secondary">Sales Bill No: </Text>
                                <DetailsGrid description={dispatch?.salesBillNo} />
                              </div>
                            )}
                            {!isEmpty(dispatch?.sales) && (
                              <Button type="link" className="pl-0" onClick={() => showModal(dispatch)}>
                                View Attachments
                                <HiOutlineExternalLink size={16} />
                              </Button>
                            )}
                            <Button type="primary" className="ml-0" onClick={() => onViewDispatch(dispatch?._id)}>
                              View
                            </Button>
                          </Card>
                          {dispatch?.status === 'Dispatched' && ['In Transit', 'Awaiting Unloading'].includes(dispatch?.transitStatus) && (
                            <Button type="primary" ghost className="mt-2 ml-2" onClick={() => getReceivedDispatch(dispatch)}>
                              Received?
                            </Button>
                          )}
                        </Timeline.Item>
                      </Col>
                    );
                  })}
                </Timeline>
              )}

              {!isEmpty(dispatch?.sales) && (
                <Modal title="View Attachment" open={isModalOpen} footer={null} onOk={handleOk} onCancel={handleCancel} width={350} closeIcon={<ModalCloseIcon />}>
                  <Card>
                    {dispatch?.sales?.invoice && (
                      <Col span={24}>
                        <Button type="link" href={`${UPLOAD_URL}/${dispatch?.sales?.invoice}`} target="_blank">
                          Download Invoice&nbsp;
                          <HiOutlineExternalLink size={16} />
                        </Button>
                      </Col>
                    )}
                    {dispatch?.sales?.ewayBill && (
                      <Col span={24}>
                        <Button type="link" href={`${UPLOAD_URL}/${dispatch?.sales?.ewayBill}`} target="_blank">
                          Download E-way Bill&nbsp;
                          <HiOutlineExternalLink size={16} />
                        </Button>
                      </Col>
                    )}
                    {dispatch?.sales?.grnReceipt && (
                      <Col span={24}>
                        <Button type="link" href={`${UPLOAD_URL}/${dispatch?.sales?.grnReceipt}`} target="_blank">
                          Download GRN Receipt&nbsp;
                          <HiOutlineExternalLink size={16} />
                        </Button>
                      </Col>
                    )}
                    {dispatch?.sales?.weightSlip && (
                      <Col span={24}>
                        <Button type="link" href={`${UPLOAD_URL}/${dispatch?.sales?.weightSlip}`} target="_blank">
                          Download Weight Slip&nbsp;
                          <HiOutlineExternalLink size={16} />
                        </Button>
                      </Col>
                    )}
                    {dispatch?.sales?.moistureReport && (
                      <Col span={24}>
                        <Button type="link" href={`${UPLOAD_URL}/${dispatch?.sales?.moistureReport}`} target="_blank">
                          Download Moisture Report&nbsp;
                          <HiOutlineExternalLink size={16} />
                        </Button>
                      </Col>
                    )}
                    {dispatch?.sales?.attachments?.length > 0 &&
                      dispatch?.sales?.attachments.map((attachment, index) => {
                        return (
                          <Col span={24} key={attachment}>
                            <Button type="link" href={`${UPLOAD_URL}/${attachment}`} target="_blank">
                              Download Attachment #{index + 1}&nbsp;
                              <HiOutlineExternalLink size={16} />
                            </Button>
                          </Col>
                        );
                      })}
                  </Card>
                </Modal>
              )}

              {!dispatchesLoading && dispatches?.length === 0 && <Text className="my-5">No {status === 'In Preparation' ? 'Ongoing' : status} Dispatches</Text>}
              {dispatchesLoading && <Loader />}
            </div>
          </>
        )}
      </div>
      <OnReceivedDispatch dispatch={dispatchData} visible={viewReceiveDispatch} save={onLoadDispatches} close={closeReceived} />
    </>
  );
};

export default PurchaseOrderDetails;
