import 'intl';
import 'intl/locale-data/jsonp/en';

const inr = new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' });
const usd = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });

const formatPrice = (value, currency) => {
  if (currency === 'USD') return usd.format(value);
  if (currency === 'INR') return inr.format(value);
  return 'No Currency';
};

const formatTransport = (value) => {
  return value;
};

const formatRefNo = (value, prefix = 'BF') => {
  if (value) {
    return `${prefix}${String(value).padStart(4, '0')}`;
  }
  return value;
};

export { formatPrice, formatTransport, formatRefNo };
