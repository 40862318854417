import React from 'react';
import { Col, Image, Row, Typography } from 'antd';
const { Link } = Typography;

const FooterLinks = ({ justify = 'center' }) => {
  const links = [
    {
      href: 'https://buyofuel.com/privacy-policy',
      target: '_blank',
      text: 'Privacy Policy',
    },
    {
      href: 'https://buyofuel.com/terms-and-conditions/',
      target: '_blank',
      text: 'Terms and Conditions',
    },
    {
      href: 'https://buyofuel.com/contact/',
      target: '_blank',
      text: 'Contact',
    },
  ];

  return (
    <>
      <Row justify={justify}>
        <Row gutter={16} align="middle" className="my-2">
          {links.map((link) => (
            <Col key={link.text}>
              <Link underline type="secondary" href={link.href} target={link.target}>
                {link.text}
              </Link>
            </Col>
          ))}
        </Row>
        <FooterLinks.DownloadApps />
      </Row>
    </>
  );
};

FooterLinks.DownloadApps = () => {
  const onOpenGooglePlay = () => {
    window.open(`https://play.google.com/store/apps/details?id=com.buyofuelmobile`);
  };

  const onOpenAppleStore = () => {
    window.open(`https://apps.apple.com/in/app/buyofuel/id1631586762`);
  };

  return (
    <Row gutter={[24, 16]} justify="center" align="middle" className="my-2">
      <Col>
        <Image src={require('assets/images/googleplay.png')} className="cursor-pointer" preview={false} width={150} onClick={onOpenGooglePlay} />
      </Col>
      <Col>
        <Image src={require('assets/images/appstore.png')} className="cursor-pointer" preview={false} width={150} onClick={onOpenAppleStore} />
      </Col>
    </Row>
  );
};

export default FooterLinks;
