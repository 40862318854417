import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Divider, List, Row, Typography, message } from 'antd';
import axios from 'axios';
import ContentLoader from 'react-content-loader';
import CompanyName from 'components/UtilComponents/CompanyName';
import { DIRECT_ENQUIRY_ENABLED, OPEN_MERCHANT_NAME, ORDER_URL } from 'constants/ApiConstants';
import { formatRefNo } from 'utils/formatter';
import { CloseCircleTwoTone } from '@ant-design/icons';
import EmptyContent from 'components/UtilComponents/EmptyContent';
import IconOrders from 'assets/images/orders.png';
import CreateNewOpenRFQ from 'components/Modals/Buy/CreateNewOpenRFQ';
import { getBreakPoint } from 'utils/layout';

const { Text, Title } = Typography;

const DroppedOrders = ({ tab }) => {
  const isMobile = !getBreakPoint().includes('sm');
  const [loading, setLoading] = useState(false);
  const [showOpenRFQ, setShowOpenRFQ] = useState(false);
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    if (tab === 'dropped-orders') {
      onLoadOrders();
    }
  }, [tab]);

  const onLoadOrders = async () => {
    setLoading(true);
    try {
      const res = await axios.get(`${ORDER_URL}?populate=true`, {
        headers: {
          'X-API-Filters': JSON.stringify({ buyer: ['me'], status: ['Cancelled'] }),
          'X-API-Sort': JSON.stringify({ createdAt: 'desc' }),
        },
      });

      if (res.data.status === 'error') {
        message.error(res.data.error);
      } else {
        setOrders(res.data.orders);
      }
    } catch (err) {
      message.error(err.message);
    }
    setLoading(false);
  };

  const onCreateNewOpenRFQ = () => {
    setShowOpenRFQ(!showOpenRFQ);
  };

  if (loading) {
    return (
      <ContentLoader width="100%" height="70vh" backgroundColor="#d9d9d9" foregroundColor="#ededed">
        <rect x="0" y="25" rx="10" ry="10" width="48%" height="211" />
        <rect x="50%" y="25" rx="10" ry="10" width="50%" height="211" />
        <rect x="0" y="250" rx="10" ry="10" width="48%" height="211" />
        <rect x="50%" y="250" rx="10" ry="10" width="50%" height="211" />
      </ContentLoader>
    );
  }

  return (
    <>
      {orders.length > 0 ? (
        <List
          className="my-4"
          header={null}
          footer={null}
          grid={{ gutter: [16, 4], column: isMobile ? 1 : 2 }}
          dataSource={orders}
          pagination={orders?.length > 0}
          renderItem={(order) => {
            const { product } = order;
            return (
              <List.Item>
                <Card size={isMobile ? 'small' : 'default'}>
                  <Row justify="flex-start" align="top">
                    <Col span={24}>
                      <Title level={5} className="fw-medium">
                        {product?.name}
                      </Title>
                      <Text>
                        {order?.qty || '∞'} {order?.subUnit} | {formatRefNo(order?.orderRefNo)}
                      </Text>
                      <CompanyName name={OPEN_MERCHANT_NAME} verified={true} />
                    </Col>
                  </Row>

                  <Row align="middle" className="my-3">
                    <CloseCircleTwoTone twoToneColor="#ff4d4f" style={{ fontSize: '16px' }} />
                    <Text className="ml-2">Seller didn't accept the order</Text>
                  </Row>

                  {DIRECT_ENQUIRY_ENABLED && (
                    <>
                      <Divider dashed={true} className="my-3" />

                      <Row gutter={16}>
                        <Col span={12}>
                          <Button type="primary" ghost block onClick={() => {}}>
                            Resend RFQ
                          </Button>
                        </Col>
                      </Row>
                    </>
                  )}
                </Card>
              </List.Item>
            );
          }}
        />
      ) : (
        <EmptyContent source={IconOrders} title={'No Dropped Orders'} subTitle={'There are no dropped orders found right now.'} />
      )}
      <CreateNewOpenRFQ visible={showOpenRFQ} close={onCreateNewOpenRFQ} />
    </>
  );
};

export default DroppedOrders;
