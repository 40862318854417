import React, { useEffect, useState } from 'react';
import { Alert, Avatar, Button, Col, Divider, Image, PageHeader, Row, Space, Tag, Tooltip, Typography, message } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { ClockCircleOutlined, DownOutlined, FileTextOutlined, FileImageOutlined, InfoCircleOutlined, SendOutlined, UpOutlined } from '@ant-design/icons';
import BackIcon from 'components/UtilComponents/BackIcon';
import CompanyName from 'components/UtilComponents/CompanyName';
import DetailsGrid from 'components/UtilComponents/DetailsGrid';
import Loader from 'components/UtilComponents/Loader';
import SendQuote from 'components/Modals/Sell/SendQuote';
import { format } from 'date-fns';
import { BUSINESS_URL, OPEN_MERCHANT_NAME, OPEN_RFQ_QUOTE_URL, OPEN_RFQ_URL, PRODUCT_URL, UPLOAD_URL } from 'constants/ApiConstants';
import axios from 'axios';
import TimeAgo from 'timeago-react';
import NumberText from 'components/UtilComponents/NumberText';
import { AccessDenied, PageNotFound } from 'components/Errors';
import { posthog } from 'posthog-js';
import { keyBy } from 'lodash';

const { Title, Text, Paragraph } = Typography;

const RequestedQuoteDetails = ({ quote }) => {
  const [showMore, setShowMore] = useState(false);

  const onShowMore = () => setShowMore(!showMore);

  return (
    <div className="my-5" style={{ transition: 'visibility 0s linear 0s, opacity 300ms' }}>
      <Row gutter={16}>
        <Col xs={24} sm={24} md={8} lg={8}>
          <Image alt={quote?.product?.name} height={250} src={`${UPLOAD_URL}/${quote?.product?.image}`} />
        </Col>

        <Col xs={24} sm={24} md={16} lg={16}>
          <Row gutter={[24, 24]} align="middle" className="my-4">
            <Col xs={12} sm={12} md={6} lg={12}>
              <DetailsGrid title="Price Quoted" description={<NumberText bold value={quote?.purchasingPrice / 100} prefix={quote?.currency} suffix={`/ ${quote?.subUnit}`} />} />
            </Col>
            <Col xs={12} sm={12} md={6} lg={12}>
              <DetailsGrid
                title="Quantity Available"
                description={
                  <Text strong>
                    {quote?.qty} {quote?.subUnit}
                  </Text>
                }
              />
            </Col>
            {!showMore && (
              <>
                <Col xs={12} sm={12} md={6} lg={12}>
                  <Row wrap={false}>
                    <Col className={'ml-2'}>
                      {'Price Basis' && (
                        <Paragraph type="secondary" className="text-small">
                          {'Price Basis'}
                        </Paragraph>
                      )}
                      <Paragraph>&bull; {`GST - ${quote?.tradingPriceBasis?.gst.value ? ' Extra' : 'Included'}`}</Paragraph>
                      <Paragraph>
                        &bull; {`Delivery - ${quote?.transportType}`}
                        {quote?.transportType === 'Extra' ? <span>{`(${quote?.currency === 'INR' ? '₹' : '$'}${quote?.transportCharges / 100} / ${quote?.subUnit})`}</span> : ''}
                      </Paragraph>
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} sm={12} md={6} lg={12}>
                  <DetailsGrid title="Pickup Location" description={`${quote?.pickupAddress?.city}, ${quote?.pickupAddress?.state}`} />
                </Col>
              </>
            )}
            <Col xs={12} sm={12} md={6} lg={12}>
              <DetailsGrid title="Delivery by" description={quote?.expectedDeliveryOn ? format(new Date(quote.expectedDeliveryOn), 'dd MMM yyyy') : ''} />
            </Col>
          </Row>

          <Row align={'middle'}>
            <Col>
              <Button type="link" className="p-0" onClick={onShowMore}>
                {showMore ? (
                  <>
                    Close Details <UpOutlined style={{ fontSize: 10 }} />
                  </>
                ) : (
                  <>
                    View Details <DownOutlined style={{ fontSize: 10 }} />
                  </>
                )}
              </Button>
            </Col>
          </Row>

          {showMore && (
            <>
              <div>
                <DetailsGrid icon={<Avatar src={require('assets/images/icon-cash.png')} shape="square" size="small" />} description="Price Basis" />
                <Row justify="space-between" className="mt-3">
                  <Text>Price Per Unit</Text>
                  <NumberText bold prefix={quote?.currency} value={quote?.purchasingPrice / 100} suffix={`/ ${quote?.subUnit}`} />
                </Row>
                <Row justify="space-between" className="my-1">
                  <Text>Quantity</Text>
                  <Text strong>
                    {quote?.qty} {quote?.subUnit}
                  </Text>
                </Row>
                <Divider className="my-0" />
                <Row justify="space-between" className="my-1">
                  <Text>{quote?.purchasingPriceBasis?.price?.label}</Text>
                  <NumberText bold prefix={quote?.currency} value={quote?.purchasingPriceBasis?.price?.value / 100} />
                </Row>
                {quote?.purchasingPriceBasis?.deliveryCharges?.value > 0 ? (
                  <Row justify="space-between" className="my-1">
                    <Text>{quote?.purchasingPriceBasis?.deliveryCharges?.label}</Text>
                    <NumberText bold prefix={quote?.currency} value={quote?.purchasingPriceBasis?.deliveryCharges?.value / 100} />
                  </Row>
                ) : (
                  ''
                )}
                {quote?.purchasingPriceBasis?.gst?.value > 0 ? (
                  <Row justify="space-between" className="my-1">
                    <Text>{quote?.purchasingPriceBasis?.gst?.label}</Text>
                    <NumberText bold prefix={quote?.currency} value={quote?.purchasingPriceBasis?.gst?.value / 100} />
                  </Row>
                ) : (
                  <Row justify="space-between" className="my-1">
                    <Text>{quote?.purchasingPriceBasis?.gst?.label}</Text>
                    <Text strong>Included</Text>
                  </Row>
                )}
                <Divider className="my-0" />
                <Row justify="space-between" className="my-1">
                  <Text>{quote?.purchasingPriceBasis?.total?.label}</Text>
                  <NumberText bold prefix={quote?.currency} value={quote?.purchasingPriceBasis?.total?.value / 100} />
                </Row>
                <Divider className="my-0" />
                <Tag icon={<InfoCircleOutlined />} color="#B4B4B4" className="my-3">
                  Final invoice amount will be calculated after loading
                </Tag>
              </div>

              <Space direction="vertical" size={'large'} className="w-100 my-2">
                <DetailsGrid
                  icon={<Avatar src={require('assets/images/icon-cash.png')} shape="square" size="small" />}
                  title={<Text strong>Terms of Payment</Text>}
                  description={quote?.paymentTerm?.name}
                />
                <DetailsGrid icon={<Avatar src={require('assets/images/icon-delivery.png')} shape="square" size="small" />} description="Delivery" />
                <Row wrap={false} style={{ marginTop: '-2%' }}>
                  <Col span={12}>
                    <DetailsGrid title="Pickup Location" description={`${quote?.pickupAddress?.city}, ${quote?.pickupAddress?.state}`} />
                  </Col>
                  <Col span={12}>
                    <DetailsGrid title="Delivery Charges" description={quote?.transportType} />
                  </Col>
                </Row>
                <DetailsGrid
                  icon={<Avatar src={require('assets/images/icon-quality.png')} shape="square" size="small" />}
                  title={<Text strong>Quality Remarks</Text>}
                  description={quote?.remark || 'N/A'}
                />
              </Space>

              <Row gutter={[16, 16]} align="middle" className="my-2">
                {quote?.qualityReportFile && (
                  <Col span={12}>
                    <Button type="link" href={`${UPLOAD_URL}/${quote?.qualityReportFile}`} icon={<FileTextOutlined />} target="_blank">
                      View Quality Report
                    </Button>
                  </Col>
                )}
                {quote?.productImageFile && (
                  <Col xs={24} sm={12} md={12} lg={12}>
                    <Button type="link" href={`${UPLOAD_URL}/${quote?.productImageFile}`} icon={<FileImageOutlined />} target="_blank">
                      View Product Image
                    </Button>
                  </Col>
                )}
              </Row>
            </>
          )}

          <Divider className="my-3" />

          <Alert
            type={quote.status === 'Rejected' ? 'error' : 'info'}
            showIcon
            message={quote.status === 'Accepted' ? 'Quote has been Accepted & Order has been Placed' : quote.status === 'Rejected' ? 'Quote has been Rejected' : 'Quote is Yet to be Accepted'}
          />
        </Col>
      </Row>
      <Divider />
    </div>
  );
};

const OpenRFQDetails = () => {
  const viewName = 'sell | open-rfq-details';
  const { id } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [accessDenied, setAccessDenied] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [showSendQuote, setShowSendQuote] = useState(false);

  const [openRfq, setOpenRfq] = useState(null);
  const [quotes, setQuotes] = useState([]);
  const [qualitiesById, setQualitiesById] = useState({});

  const onSendQuote = (reload) => {
    if (reload) {
      onLoad();
    }
    setShowSendQuote(!showSendQuote);
  };

  useEffect(() => {
    posthog.capture(`$screenview | $${viewName}`, {
      data: {
        rfqId: id,
      },
      $set: {
        lastActivityDate: new Date().toISOString(),
      },
    });
    onLoad();
  }, []);

  useEffect(() => {
    if (openRfq?.productQuality) onLoadProduct();
  }, [openRfq]);

  const onLoadProduct = async () => {
    setLoading(true);
    try {
      const productRes = await axios.get(`${PRODUCT_URL}/${openRfq?.product?._id}?populate=true`);
      if (productRes.data.status === 'error') {
        message.error(productRes.data.error);
      } else {
        setQualitiesById(keyBy(productRes.data.product?.qualities, '_id'));
      }
    } catch (error) {
      message.error(error.message);
    }
    setLoading(false);
  };

  const onLoad = async () => {
    setLoading(true);
    try {
      // TODO: Added business url temporarily. Remove once seller: ['me'] filter is implemented for fetch open requirement quotes route
      const businessRes = await axios.get(`${BUSINESS_URL}/me`);
      const res = await axios.get(`${OPEN_RFQ_URL}/${id}?&populate=true`);
      const quoteRes = await axios.get(`${OPEN_RFQ_QUOTE_URL}?&populate=true`, {
        headers: {
          'X-API-Filters': JSON.stringify({
            enquiry: [id],
            seller: [businessRes?.data?.business._id], // TODO: Not implemented yet in the backend
          }),
        },
      });

      // if open requirement's buyer is same as current business then deny access.
      if (res.data?.openrequirement?.buyer?._id === businessRes.data?.business?._id) {
        setAccessDenied(true);
        return setLoading(false);
      }
      // if open requirement id is invalid, display not found component.
      if (res.data.code === 1006 || res.data.code === 1007) {
        setNotFound(true);
        return setLoading(false);
      }

      if (res.data.status === 'error') {
        message.error(res.data.error);
      } else if (quoteRes.data.status === 'error') {
        message.error(quoteRes.data.error);
      } else {
        setOpenRfq(res.data.openrequirement);
        setQuotes(quoteRes.data.openrequirementquotes);
      }
    } catch (err) {
      message.error(err.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (showSendQuote) {
      posthog.capture(`$${viewName} | $send-quote-attempted`, {
        data: {
          rfqId: id,
        },
        $set: {
          lastActivityDate: new Date().toISOString(),
        },
      });
    }
  }, [showSendQuote]);

  // Enable send quote button only if there is no quotes or all the sent quotes are rejected.
  const allowSendQuote = quotes.length === quotes.filter((quote) => quote.status === 'Rejected').length;

  if (loading) return <Loader />;

  if (accessDenied) return <AccessDenied />;

  if (notFound) return <PageNotFound />;

  return (
    <>
      <div className="app-container">
        <PageHeader
          onBack={() => navigate(-1)}
          backIcon={<BackIcon />}
          title={
            <Row gutter={16} align="middle">
              <Col>
                <Row align="middle">
                  <Title level={4} className="fw-semibold">
                    Open RFQ | &nbsp;
                  </Title>
                  <Avatar src={`${UPLOAD_URL}/${openRfq?.product?.image}`} size="large" className="mr-2" />
                  <Title level={4} className="fw-semibold">
                    {openRfq?.product?.name}
                  </Title>
                </Row>
              </Col>
              <Col>
                <Tooltip placement="right" title={'Expires on ' + new Date(openRfq?.metadata?.expiresOn).toDateString()}>
                  <Tag color="red">
                    <ClockCircleOutlined />
                    &nbsp; <TimeAgo datetime={openRfq?.metadata?.expiresOn} locale="en_US" />
                  </Tag>
                </Tooltip>
              </Col>
            </Row>
          }
          className="px-0"
        />
      </div>
      <Divider className="mt-1 mb-6" />
      <div className="app-container">
        {!loading && (
          <>
            <Row gutter={16} align="middle" className="mb-2">
              <Col>
                <CompanyName showLogo={false} name={OPEN_MERCHANT_NAME} verified={true} />
              </Col>
            </Row>

            <Row gutter={[32, 32]} className="mt-4">
              <Col xs={24} sm={12} md={12} lg={8}>
                <DetailsGrid
                  icon={<Avatar src={require('assets/images/icon-quantity.png')} shape="square" size="default" />}
                  title="Required Quantity"
                  description={`${openRfq?.fromQty}${openRfq?.toQty > 0 ? ` - ${openRfq?.toQty}` : ''} ${openRfq?.subUnit} / ${openRfq?.frequency}`}
                />
              </Col>

              <Col xs={24} sm={12} md={12} lg={8}>
                <DetailsGrid
                  icon={<Avatar src={require('assets/images/icon-delivery.png')} shape="square" size="default" />}
                  title="Delivery Location"
                  description={`${openRfq?.deliveryAddress?.city}, ${openRfq?.deliveryAddress?.state}`}
                />
              </Col>

              <Col xs={24} sm={12} md={12} lg={8}>
                <DetailsGrid
                  icon={<Avatar src={require('assets/images/icon-calendar.png')} shape="square" size="default" />}
                  title="Expected Delivery Date"
                  description={openRfq?.preferredDeliveryOn ? format(new Date(openRfq?.preferredDeliveryOn), 'dd MMM yyyy') : '-'}
                />
              </Col>

              <Col xs={24} sm={12} md={12} lg={8}>
                <DetailsGrid icon={<Avatar src={require('assets/images/icon-cash.png')} shape="square" size="default" />} title="Payment Terms" description={openRfq?.paymentTerm?.name} />
              </Col>

              <Col xs={24} sm={12} md={12} lg={8}>
                <DetailsGrid icon={<Avatar src={require('assets/images/icon-quality.png')} shape="square" size="default" />} title="Quality Testing" description={openRfq?.qualityTesting} />
              </Col>
              <Col xs={24} sm={12} md={12} lg={8}>
                <DetailsGrid icon={<Avatar src={require('assets/images/icon-quality.png')} shape="square" size="default" />} title="Trial Quantity" description={openRfq?.trialQuantity} />
              </Col>
              <Col xs={24} sm={12} md={12} lg={8}>
                <DetailsGrid title="Quality Remarks" description={openRfq?.remark || '-'} />
              </Col>
              <Col xs={24} sm={12} md={12} lg={8}>
                <DetailsGrid
                  icon={<Avatar src={require('assets/images/grade.png')} shape="square" size="default" />}
                  title="Grade"
                  description={
                    <>
                      <Text strong>{qualitiesById[openRfq?.productQuality]?.grade}</Text>
                      <ul>
                        {qualitiesById[openRfq?.productQuality]?.descriptions?.map((e) => (
                          <li>{e}</li>
                        ))}
                      </ul>
                    </>
                  }
                />
              </Col>
            </Row>
            <Row gutter={[32, 32]} className="mt-4">
              {allowSendQuote && (
                <Col xs={24} sm={12} md={12} lg={8}>
                  <Button type="primary" size="large" block className="my-4" onClick={() => onSendQuote()}>
                    Send Quote
                    <SendOutlined />
                  </Button>
                </Col>
              )}
            </Row>
          </>
        )}

        {!loading && quotes?.length > 0 && (
          <>
            <Divider />
            <Title level={5}>Your Quote{quotes?.length > 0 ? `s (${quotes.length})` : `(${quotes.length})`}</Title>
            {quotes.map((quote) => {
              // component defined at the top of this file
              return <RequestedQuoteDetails key={quote?._id} quote={quote} onRefresh={onLoad} />;
            })}
          </>
        )}

        <SendQuote visible={showSendQuote} close={onSendQuote} openRfq={openRfq} />
      </div>
    </>
  );
};

export default OpenRFQDetails;
