import React, { useState } from 'react';
import { Button, Checkbox, Col, Divider, List, PageHeader, Radio, Row, Space, Tag, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import BackIcon from 'components/UtilComponents/BackIcon';
import RecommendedSellerCard from 'components/Cards/RecommendedSellerCard';

const { CheckableTag } = Tag;
const { Text, Paragraph } = Typography;

const tagsData = ['Bio Diesel', 'Saw Dust', 'Wood Chips', 'Bio-CNG'];

const frequencyOptions = [
  {
    label: 'Once',
    value: 'Once',
  },
  {
    label: 'Per Month',
    value: 'Per Month',
  },
];

const RecommendedSellers = () => {
  const navigate = useNavigate();

  const [selectedTags, setSelectedTags] = useState(['Bio-CNG']);

  const onProductsChange = (tag, checked) => {
    const nextSelectedTags = checked ? [...selectedTags, tag] : selectedTags.filter((t) => t !== tag);
    console.log('Selected products: ', nextSelectedTags);
    setSelectedTags(nextSelectedTags);
  };

  const onFrequencyChange = (checkedValues) => {
    console.log('frequency checked: ', checkedValues);
  };

  const onSortByQuantity = () => {};

  const onProximityChange = (checkedValues) => {
    console.log('proximity checked: ', checkedValues);
  };

  const onRatingWise = () => {};

  const onSortByName = () => {};

  return (
    <div>
      <PageHeader onBack={() => navigate(-1)} backIcon={<BackIcon />} title="Recommended Sellers" extra={null} />

      <Divider className="m-0" />

      <Row>
        <Col span={16} className="p-4">
          <List
            header={<Text>Showing 6 of 86 Recommended Sellers</Text>}
            footer={
              <Row justify="center">
                <Button type="primary" size="large" className="px-5">
                  View all listings
                </Button>
              </Row>
            }
            grid={{ gutter: 16, column: 1 }}
            dataSource={[1, 2, 3, 4, 5, 6, 7]}
            // pagination={false}
            pagination={{
              onChange: (page) => {
                console.log(page);
              },
              pageSize: 5,
            }}
            renderItem={(item) => (
              <List.Item>
                <RecommendedSellerCard data={item} />
              </List.Item>
            )}
          />
        </Col>

        <Col span={8} flex={1} className="p-4">
          <>
            <Paragraph type="secondary" className="fw-medium">
              FILTERING OPTIONS
            </Paragraph>

            <div className="custom-tag tag-container my-3">
              <Paragraph type="secondary">Products to show</Paragraph>
              {tagsData.map((tag) => (
                <CheckableTag className="mt-2" key={tag} checked={selectedTags.indexOf(tag) > -1} onChange={(checked) => onProductsChange(tag, checked)}>
                  {tag}
                </CheckableTag>
              ))}
            </div>

            <div className="my-3">
              <Paragraph type="secondary">Order Frequency</Paragraph>
              <Checkbox.Group className="mt-2" options={frequencyOptions} onChange={onFrequencyChange} />
            </div>
          </>

          <Divider />
          <>
            <Paragraph type="secondary" className="fw-medium">
              SORTING OPTIONS
            </Paragraph>

            <div className="my-4">
              <Paragraph type="secondary">Quantity</Paragraph>
              <Radio.Group className="mt-2" onChange={onSortByQuantity}>
                <Space direction="vertical" size="small">
                  <Radio value={1}>Quantity - Low to High</Radio>
                  <Radio value={2}>Quantity - High to Low</Radio>
                </Space>
              </Radio.Group>
            </div>

            <div className="my-4">
              <Paragraph type="secondary">Proximity</Paragraph>
              <Checkbox.Group className="w-100 mt-2" onChange={onProximityChange}>
                <Row>
                  <Col span={24}>
                    <Checkbox value="A">Show nearest results first</Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox value="B">Only show sellers within my state</Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </div>

            <div className="my-4">
              <Paragraph type="secondary">Rating</Paragraph>
              <Checkbox.Group className="w-100 mt-2" onChange={onRatingWise}>
                <Row>
                  <Col span={24}>
                    <Checkbox value="A">Highest rated sellers first</Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </div>

            <div className="my-4">
              <Paragraph>Alphabetical - Buyer Name</Paragraph>
              <Radio.Group className="mt-2" onChange={onSortByName}>
                <Space direction="vertical" size="small">
                  <Radio value={1}>Show from A - Z</Radio>
                  <Radio value={2}>Show from Z - A</Radio>
                </Space>
              </Radio.Group>
            </div>
          </>
        </Col>
      </Row>
    </div>
  );
};

export default RecommendedSellers;
