import React, { useState } from 'react';
import { Avatar, Button, Col, Divider, List, PageHeader, Row, Space, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import { StarFilled } from '@ant-design/icons';
import BackIcon from 'components/UtilComponents/BackIcon';
import CompanyName from 'components/UtilComponents/CompanyName';
import DetailsGrid from 'components/UtilComponents/DetailsGrid';
import Loader from 'components/UtilComponents/Loader';
import { format } from 'date-fns';
import Product from 'components/Cards/Product';
import ListingCard from 'components/Cards/ListingCard';
import { useEffect } from 'react';

const { Title, Text } = Typography;

const SellerProfile = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
  }, []);

  const onSendRFQ = () => {};
  const onSendExpressOrder = () => {};

  if (loading) return <Loader />;

  return (
    <>
      <div className="app-container">
        <PageHeader onBack={() => navigate(-1)} backIcon={<BackIcon />} title={'Seller Profile'} className="px-0" />
      </div>
      <Divider />
      <div className="app-container">
        <Row gutter={16} align="middle" wrap={false}>
          <Col>
            <Avatar src={require('assets/images/dummy-company.png')} shape="circle" size={75} />
          </Col>
          <Col span={16}>
            <CompanyName showLogo={false} name={<span style={{ fontSize: '20px' }}>Jayadev Biofuels</span>} verified={true} />
            <Row align="middle" className="my-2">
              <StarFilled style={{ color: '#EDC200' }} />
              <Text className="mx-1 text-small">4.5/5</Text>
              <Text className="mx-1 text-secondary text-small">(25 Reviews)</Text>
            </Row>
          </Col>
          <Col flex={1} className="d-flex flex-column justify-content-around">
            <Space direction="vertical">
              <Button type="primary" block size="large" onClick={onSendRFQ}>
                Send RFQ
              </Button>
              <Button type="link" block size="large" onClick={onSendExpressOrder}>
                Send Express Order
              </Button>
            </Space>
          </Col>
        </Row>

        <Row align="middle" className="mt-3">
          <Col span={8}>
            <DetailsGrid icon={<Avatar src={require('assets/images/icon-location-dark.png')} shape="square" size="default" />} title="Coimbatore" description="Coimbatore, TamilNadu" />
          </Col>
          <Col span={8}>
            <DetailsGrid icon={<Avatar src={require('assets/images/icon-calendar.png')} shape="square" size="default" />} title="Member Since" description={format(new Date(), 'MMMM yyyy')} />
          </Col>
        </Row>

        <Divider />

        <div className="my-4">
          <Title level={5}>Available Products</Title>
          <Row wrap={false} gutter={[16, 16]} style={{ overflowX: 'auto' }} className="mt-2">
            {[1, 2, 3, 4, 5, 6, 7, 8].map((data, index) => {
              return (
                <Col key={index}>
                  <Product data={data} />
                </Col>
              );
            })}
          </Row>
        </div>

        <div className="my-4">
          <Title level={5}>Open Listings</Title>
          <List
            header={null}
            footer={null}
            grid={{ gutter: 16, column: 3 }}
            dataSource={[1, 2, 3, 4, 5, 6, 7]}
            pagination={{ pageSize: 3 }}
            renderItem={(item) => (
              <List.Item>
                <ListingCard data={item} />
              </List.Item>
            )}
          />
        </div>

        <Divider />
      </div>
    </>
  );
};

export default SellerProfile;
