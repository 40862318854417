import React, { useEffect, useState } from 'react';
import { Avatar, Button, Card, Col, Divider, Image, List, Tag, Row, Tooltip, Typography, message, Progress } from 'antd';
import { useNavigate } from 'react-router-dom';
import { RightOutlined } from '@ant-design/icons';
import IconCreate from 'assets/images/create-listing.png';
import IconListings from 'assets/images/my-rfqs.png';
import IconOffers from 'assets/images/my-offers.png';
import IconOrders from 'assets/images/purchase-order.png';
import IconPayments from 'assets/images/ongoing-payment.png';
import IconDispatch from 'assets/images/ongoing-dispatch.png';
import OpenRFQCard from 'components/Cards/OpenRFQCard';
import ExploreBanner from 'assets/images/explore-banner.png';
import { DASHBOARD_URL, DISPATCH_URL, ORDER_URL } from 'constants/ApiConstants';
import axios from 'axios';
import ContentLoader from 'react-content-loader';
import ListingIcon from 'assets/images/icon-recommended-listings.png';
import EmptyContent from 'components/UtilComponents/EmptyContent';
import CreateOpenListing from 'components/Modals/Sell/CreateOpenListing';
// import DispatchScheduleCard from 'components/Cards/DispatchScheduleCard';
// import DispatchStatusCard from 'components/Cards/DispatchStatusCard';
import DashboardPaymentCard from 'components/Cards/DashboardPaymentCard';
import { getBreakPoint } from 'utils/layout';
import { posthog } from 'posthog-js';
import SellNotificationScreen from './SellNotificationScreen';
import AppTour from 'containers/AppTour';
import { formatRefNo } from 'utils/formatter';
import UpdateOrderStatus from 'components/Modals/Sell/UpdateOrderStatus';

const { Title, Paragraph, Text } = Typography;

const badgeStyle = {
  backgroundColor: '#2181DF',
  borderRadius: 30,
  width: 30,
  height: 30,
  color: '#fff',
  paddingTop: 3,
  marginRight: 7,
};

const SellDasboard = () => {
  const viewName = 'sell';
  const isMobile = !getBreakPoint().includes('sm');
  const navigate = useNavigate();
  const [showOpenListing, setShowOpenListing] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [introModal, setIntroModal] = useState(false);
  const [dispatches, setDispatches] = useState([]);
  const [activeOrders, setActiveOrders] = useState([]);
  const [dispatchVisible, setDispatchVisible] = useState(false);
  const [currentOrder, setCurrentOrder] = useState({});
  const [counts, setCounts] = useState({
    notitications: 0,
    requirements: {
      recommended: 0,
      nearby: 0,
    },
  });
  const [result, setResult] = useState({
    notitications: [],
    requirements: {
      recommended: [],
      nearby: [],
    },
  });

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    onLoad();
  }, []);

  const onLoad = async () => {
    if (loading) return;
    setLoading(true);
    try {
      const res = await axios.get(`${DASHBOARD_URL}/sell`);
      const dispatchesRes = await axios.get(`${DISPATCH_URL}?page=${1}&limit=${3}&populate=true`, {
        headers: {
          'X-API-Filters': JSON.stringify({ transitStatus: ['Awaiting Loading', 'In Transit', 'Awaiting Unloading', 'Goods Delivered'], business: ['me'] }),
          'X-API-Sort': JSON.stringify({ createdAt: 'desc' }),
        },
      });
      const activeOrdersRes = await axios.get(`${ORDER_URL}?limit=${3}&populate=true`, {
        headers: {
          'X-API-Filters': JSON.stringify({ seller: ['me'], status: ['In Progress'] }),
          'X-API-Sort': JSON.stringify({ createdAt: 'desc' }),
        },
      });
      if (res.data.status === 'error') {
        message.error(res.data.error);
      } else if (dispatchesRes.data.status === 'error') {
        message.error(dispatchesRes.data.error);
      } else if (activeOrdersRes.data.status === 'error') {
        message.error(activeOrdersRes.data.error);
      } else {
        setResult(res.data.result);
        setCounts(res.data.counts);
        setDispatches(dispatchesRes?.data?.dispatches);
        setActiveOrders(activeOrdersRes?.data?.orders);
      }
    } catch (err) {
      message.error(err.message);
    }
    setLoading(false);
  };

  /*
   * Create Listing
   * Open My RFQs
   * My Offers
   * Purchase Orders
   * Ongoing Payments
   * Ongoing Dispatches
   * View All Listings
   */
  const onCreateListing = (reload = false) => {
    if (reload) {
      onLoad();
    }
    setShowOpenListing(!showOpenListing);
  };

  const hideNotification = () => {
    setShowNotification(!showNotification);
  };

  const onOpenMyListings = () => {
    posthog.capture(`$${viewName} | $my-open-listings`, {
      $set: { lastActivityDate: new Date().toISOString() },
    });
    navigate('/sell/my-listings');
  };

  const onOpenMyQuotes = () => {
    posthog.capture(`$${viewName} | $my-quotes`, {
      $set: { lastActivityDate: new Date().toISOString() },
    });
    navigate('/sell/quotes/pending-quotes');
  };

  const onOpenOrders = () => {
    posthog.capture(`$${viewName} | $my-orders`, {
      $set: { lastActivityDate: new Date().toISOString() },
    });
    navigate('/sell/orders/active-orders');
  };

  const onOpenOngoingPayments = () => {
    posthog.capture(`$${viewName} | $upcoming-payments`, { $set: { lastActivityDate: new Date().toISOString() } });
    navigate('/payments/upcoming-payments');
  };

  const onOpenOngoingDispatches = () => {
    posthog.capture(`$${viewName} | $ongoing-dispatches`, { $set: { lastActivityDate: new Date().toISOString() } });
    navigate('/dispatches/ongoing-dispatches');
  };

  const onViewOrders = (orderId) => {
    navigate(`/sell/orders/active-orders/${orderId}`);
  };

  const onOrders = () => {
    posthog.capture(`$${viewName} | $orders`, { $set: { lastActivityDate: new Date().toISOString() } });
    navigate('/sell/orders/active-orders');
  };

  const onViewDispatch = (id) => {
    navigate(`/sell/orders/dispatch/${id}`);
  };

  const onDispatches = () => {
    posthog.capture(`$${viewName} | $ongoing-dispatches`, { $set: { lastActivityDate: new Date().toISOString() } });
    navigate('/dispatches/ongoing-dispatches');
  };

  const onViewRecommendedOpenRFQs = () => navigate('/sell/open-rfqs');

  const onOpenDispatch = (order) => {
    setCurrentOrder(order);
    setDispatchVisible(true);
  };

  const onCloseDispatch = (reload) => {
    if (reload) {
      onLoad();
    }
    setDispatchVisible(false);
  };

  // const onViewDispatchSchedules = () => navigate('/sell/dispatch-schedules');

  const getColor = (status) => {
    if (status === 'Awaiting Loading') return 'gold';
    if (status === 'In Transit') return 'yellow';
    if (status === 'Goods Delivered') return 'green';
    if (status === 'Goods Rejected') return 'red';
    return 'gold';
  };

  const progressConfig = (transitStatus) => {
    if (transitStatus === 'Awaiting Loading') return { color: '#1a75da', percent: 25 };
    if (transitStatus === 'In Transit') return { color: '#1a75da', percent: 50 };
    if (transitStatus === 'Awaiting Unloading') return { color: '#1a75da', percent: 75 };
    if (transitStatus === 'Goods Delivered') return { color: 'Green', percent: 100 };
  };

  return (
    <div className="dashboard-container">
      <div className="dashboard-header">
        <Row align="middle" justify="space-between">
          <Col>
            <Title level={2} className="fw-bold dashboard-title">
              Sell
            </Title>
          </Col>
          <Col>
            <Button type="default" shape="round" size="large" style={{ padding: '0 15px', border: 'none', color: 'rgb(33, 129, 223)' }} onClick={onOpenOrders}>
              {counts?.orders ? <span style={badgeStyle}>{counts?.orders}</span> : <span />}
              Orders
            </Button>
          </Col>
          <Col>
            <Tooltip title="App Tour">
              <Avatar src={require('assets/images/app-tour.png')} size="small" className="cursor-pointer mx-2" onClick={() => setIntroModal(true)} />
            </Tooltip>
            <Tooltip title="Notifications">
              <Avatar src={require('assets/images/notification.png')} size="small" className="cursor-pointer mx-2" onClick={() => setShowNotification(!showNotification)} />
            </Tooltip>
          </Col>
        </Row>
      </div>

      {/* Menu */}
      <Row gutter={[isMobile ? 8 : 24, 8]} className="my-4">
        <Col xs={{ span: 24, order: 2 }} sm={{ span: 24, order: 2 }} md={{ span: 24, order: 2 }} lg={{ span: 16, order: 1 }}>
          <Row gutter={[isMobile ? 8 : 16, 16]}>
            <Col className="tour-create-listing" span={8}>
              <Card onClick={() => onCreateListing()} bordered={false} hoverable={true} bodyStyle={{ padding: '15px 5px', minHeight: isMobile ? 120 : 0 }} className="shadow">
                <Row gutter={[16, 8]} align="middle" justify="center">
                  <Col>
                    <Avatar shape="square" size={36} src={IconCreate} />
                  </Col>
                  <Col span={isMobile && 24}>
                    <Paragraph className="text-center fw-medium">Create Listing</Paragraph>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col className="tour-my-listings" span={8}>
              <Card onClick={onOpenMyListings} bordered={false} hoverable={true} bodyStyle={{ padding: '15px 5px', minHeight: isMobile ? 120 : 0 }} className="shadow">
                <Row gutter={[16, 8]} align="middle" justify="center">
                  <Col>
                    <Avatar shape="square" size={36} src={IconListings} />
                  </Col>
                  <Col span={isMobile && 24}>
                    <Paragraph className="text-center fw-medium">My Listings</Paragraph>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col className="tour-my-quotes" span={8}>
              <Card onClick={onOpenMyQuotes} bordered={false} hoverable={true} bodyStyle={{ padding: '15px 5px', minHeight: isMobile ? 120 : 0 }} className="shadow">
                <Row gutter={[16, 8]} align="middle" justify="center">
                  <Col>
                    <Avatar shape="square" size={36} src={IconOffers} />
                  </Col>
                  <Col span={isMobile && 24}>
                    <Paragraph className="text-center fw-medium">My Quotes</Paragraph>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col className="tour-orders" span={8}>
              <Card onClick={onOpenOrders} bordered={false} hoverable={true} bodyStyle={{ padding: '15px 5px', minHeight: isMobile ? 120 : 0 }} className="shadow">
                <Row gutter={[16, 8]} align="middle" justify="center">
                  <Col>
                    <Avatar shape="square" size={36} src={IconOrders} />
                  </Col>
                  <Col span={isMobile && 24}>
                    <Paragraph className="text-center fw-medium">Orders</Paragraph>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col className="tour-sell-ongoing-payments" span={8}>
              <Card onClick={onOpenOngoingPayments} bordered={false} hoverable={true} bodyStyle={{ padding: '15px 5px', minHeight: isMobile ? 120 : 0 }} className="shadow">
                <Row gutter={[16, 8]} align="middle" justify="center">
                  <Col>
                    <Avatar shape="square" size={36} src={IconPayments} />
                  </Col>
                  <Col span={isMobile && 24}>
                    <Paragraph className="text-center fw-medium">Ongoing Payments</Paragraph>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col className="tour-sell-ongoing-dispatches" span={8}>
              <Card onClick={onOpenOngoingDispatches} bordered={false} hoverable={true} bodyStyle={{ padding: '15px 5px', minHeight: isMobile ? 120 : 0 }} className="shadow">
                <Row gutter={[16, 8]} align="middle" justify="center">
                  <Col>
                    <Avatar shape="square" size={36} src={IconDispatch} />
                  </Col>
                  <Col span={isMobile && 24}>
                    <Paragraph className="text-center fw-medium">Ongoing Dispatches</Paragraph>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>

          {false && (
            <>
              {/* Pending Payments */}
              <Divider className="my-4" />
              <Row align="middle" justify="space-between" className="mb-2">
                <Col>
                  <Paragraph type="secondary">Payment Process</Paragraph>
                </Col>
                <Col>
                  <Button type="link" onClick={() => {}}>
                    View all
                    <RightOutlined />
                  </Button>
                </Col>
              </Row>
              <Row gutter={16} align="middle" justify="start" wrap={false} style={{ overflowX: 'scroll' }}>
                {[{ _id: 1983778 }].map(() => {
                  return (
                    <Col xs={24} sm={12} md={12} lg={12}>
                      <DashboardPaymentCard />
                    </Col>
                  );
                })}
              </Row>
            </>
          )}

          <Divider className="my-4" />

          {/* Recommended Open RFQs */}
          <div>
            {result?.requirements?.recommended?.length > 0 || loading ? (
              <>
                <Row align="middle" justify="space-between" className="my-3">
                  <Col>
                    <Title level={isMobile ? 5 : 3} className="fw-bold">
                      Recommended Open RFQs
                    </Title>
                    <Paragraph type="secondary">
                      Showing {result?.requirements?.recommended?.length} of {counts?.requirements?.recommended} Open RFQs
                    </Paragraph>
                  </Col>
                  <Col>
                    <Button type="link" onClick={onViewRecommendedOpenRFQs}>
                      View all
                      <RightOutlined />
                    </Button>
                  </Col>
                </Row>
                {loading && (
                  <ContentLoader width="100%" height="70vh" backgroundColor="#d9d9d9" foregroundColor="#ededed">
                    <rect x="0" y="0" rx="10" ry="10" width="32%" height="211" />
                    <rect x="34%" y="0" rx="10" ry="10" width="32%" height="211" />
                    <rect x="68%" y="0" rx="10" ry="10" width="32%" height="211" />
                    <rect x="0" y="230" rx="10" ry="10" width="32%" height="211" />
                    <rect x="34%" y="230" rx="10" ry="10" width="32%" height="211" />
                    <rect x="68%" y="230" rx="10" ry="10" width="32%" height="211" />
                  </ContentLoader>
                )}
                {!loading && (
                  <List
                    className="my-4"
                    header={null}
                    footer={
                      result?.requirements?.recommended?.length > 0 ? (
                        <Row justify="center">
                          <Button className="px-5" type="primary" size="large" onClick={onViewRecommendedOpenRFQs}>
                            View all Open RFQs
                          </Button>
                        </Row>
                      ) : null
                    }
                    grid={{ gutter: [24, 12], column: isMobile ? 1 : 3 }}
                    dataSource={result?.requirements?.recommended ? result.requirements.recommended : []}
                    pagination={false}
                    renderItem={(requirement) => (
                      <List.Item>
                        <OpenRFQCard requirement={requirement} />
                      </List.Item>
                    )}
                  />
                )}
                <Divider />
              </>
            ) : (
              <EmptyContent
                source={ListingIcon}
                title={'No Open RFQs found at the moment'}
                subTitle={'Create an Open Listing and attract orders from buyers.'}
                onActionPress={() => onCreateListing()}
                actionText={'Create Open Listing'}
              />
            )}
          </div>

          {/* Recent Open RFQs by Known Buyers */}
          {result?.requirements?.nearby?.length > 0 && (
            <>
              <Row align="middle" justify="space-between" className="my-5">
                <Col>
                  <Title level={isMobile ? 5 : 3} className="fw-normal">
                    Recent Open RFQs by <span className="fw-bold">Known Buyers</span>
                  </Title>
                  <Paragraph type="secondary">
                    Showing {result?.requirements?.nearby?.length} of {counts?.requirements?.nearby} Open RFQs
                  </Paragraph>
                </Col>
                <Col>
                  <Button type="link" onClick={onViewRecommendedOpenRFQs}>
                    View all
                    <RightOutlined />
                  </Button>
                </Col>
              </Row>
              {loading && (
                <ContentLoader width="100%" height="70vh" backgroundColor="#d9d9d9" foregroundColor="#ededed">
                  <rect x="0" y="0" rx="10" ry="10" width="32%" height="211" />
                  <rect x="34%" y="0" rx="10" ry="10" width="32%" height="211" />
                  <rect x="68%" y="0" rx="10" ry="10" width="32%" height="211" />
                  <rect x="0" y="230" rx="10" ry="10" width="32%" height="211" />
                  <rect x="34%" y="230" rx="10" ry="10" width="32%" height="211" />
                  <rect x="68%" y="230" rx="10" ry="10" width="32%" height="211" />
                </ContentLoader>
              )}
              {!loading && (
                <List
                  className="my-4"
                  header={null}
                  divider={false}
                  footer={
                    result?.requirements?.nearby?.length > 0 ? (
                      <Row justify="center">
                        <Button className="px-5" type="primary" size="large" onClick={onViewRecommendedOpenRFQs}>
                          View all Open RFQs
                        </Button>
                      </Row>
                    ) : null
                  }
                  grid={{ gutter: [24, 12], column: isMobile ? 1 : 3 }}
                  dataSource={result?.requirements?.nearby ? result?.requirements?.nearby : []}
                  pagination={false}
                  renderItem={(requirement) => (
                    <List.Item>
                      <OpenRFQCard requirement={requirement} />
                    </List.Item>
                  )}
                />
              )}
              <Divider />
            </>
          )}
        </Col>

        {/* Right Side Components */}
        {true && (
          <Col xs={{ span: 24, order: 1 }} sm={{ span: 24, order: 1 }} md={{ span: 24, order: 1 }} lg={{ span: 8, order: 2 }}>
            <Card bodyStyle={{ padding: '1rem' }}>
              <Image src={ExploreBanner} preview={false} />

              {/* <div>
                <Row align="middle" justify="space-between" wrap={false} className="mt-4 mb-2">
                  <Col>
                    <Text className="fw-medium" type="secondary">
                      Delivery Schedules
                    </Text>
                  </Col>
                  <Col>
                    <Button type="link" onClick={onViewDispatchSchedules}>
                      View all
                      <RightOutlined />
                    </Button>
                  </Col>
                </Row>
                <Row gutter={[16, 16]} align="middle" wrap={false} style={{ overflowX: 'scroll' }}>
                  {[{ _id: '682736' }].map((data) => {
                    return (
                      <Col xs={24} sm={12} md={12} lg={24} key={data?._id}>
                        <DispatchScheduleCard type="sell" data={data} />
                      </Col>
                    );
                  })}
                </Row>
              </div> */}

              {dispatches?.length > 0 ? (
                <Row align="middle" justify="space-between" wrap={false} className="mt-4 mb-2">
                  <Col>
                    <Text className="fw-medium" type="secondary">
                      Recent Delivery Status
                    </Text>
                  </Col>
                  <Col>
                    <Button type="link" onClick={onDispatches}>
                      View all
                      <RightOutlined />
                    </Button>
                  </Col>
                </Row>
              ) : (
                ''
              )}
              {dispatches?.length > 0 ? (
                <Row gutter={[16, 16]} align="middle" wrap={false} style={{ overflowX: 'scroll' }}>
                  {dispatches?.map((data) => {
                    return (
                      <Col xs={24} sm={12} md={12} lg={24} key={data?._id}>
                        <Card className="cursor-pointer" onClick={() => onViewDispatch(data?._id)} bodyStyle={{ padding: '10px' }}>
                          <Row className="m-sm-1">
                            <Col className="p-sm-0" xs={24} sm={12} md={12} lg={10}>
                              <Text strong>{data?.product?.name}</Text>
                            </Col>
                            <Col className="p-sm-0 d-flex justify-content-end" xs={24} sm={12} md={12} lg={14}>
                              Dispatch No: {formatRefNo(data?.dispatchRefNo, 'BFD')}
                            </Col>
                          </Row>
                          <Row className="m-sm-1">
                            <Col className="p-sm-0" xs={24} sm={12} md={12} lg={12}>
                              <Title level={4}>
                                {data?.dispatchedQty} {data?.subUnit}
                              </Title>
                            </Col>
                            <Col className="p-sm-0 d-flex justify-content-end" xs={24} sm={12} md={12} lg={12}>
                              <Tag className="rounded border-0" color={getColor(data?.transitStatus)}>
                                {data?.transitStatus}
                              </Tag>
                            </Col>
                          </Row>

                          <Progress size="small" style={{ padding: '0px' }} strokeColor={progressConfig(data?.transitStatus)?.color} percent={progressConfig(data?.transitStatus)?.percent} />
                          <Row className="m-sm-1" gutter={[16, 16]}>
                            <Col className="p-sm-0" xs={24} sm={12} md={12} lg={12}>
                              <Text style={{ fontSize: '12px' }} type="secondary">
                                Awaiting Loading
                              </Text>
                            </Col>
                            <Col className="p-sm-0 d-flex justify-content-end" xs={24} sm={12} md={12} lg={12}>
                              <Text style={{ fontSize: '12px' }} type="secondary">
                                Delivered
                              </Text>
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                    );
                  })}
                </Row>
              ) : (
                ''
              )}

              {activeOrders?.length > 0 ? (
                <Row align="middle" justify="space-between" wrap={false} className="mt-4 mb-2">
                  <Col>
                    <Text className="fw-medium" type="secondary">
                      Active Orders
                    </Text>
                  </Col>
                  <Col>
                    <Button type="link" onClick={onOrders}>
                      View all
                      <RightOutlined />
                    </Button>
                  </Col>
                </Row>
              ) : (
                ''
              )}

              {activeOrders?.length > 0 ? (
                <Row gutter={[16, 16]} align="middle" wrap={false} style={{ overflowX: 'scroll' }}>
                  {activeOrders?.map((data) => {
                    return (
                      <Col xs={24} sm={12} md={12} lg={24} key={data?._id}>
                        <Card bodyStyle={{ padding: '10px' }}>
                          <div className="cursor-pointer" onClick={() => onViewOrders(data?._id)}>
                            <Row className="m-sm-1">
                              <Col className="p-sm-0" xs={24} sm={12} md={12} lg={12}>
                                <Text strong>{data?.product?.name}</Text>
                                <Title level={5}>
                                  {data?.qty} {data?.subUnit}
                                </Title>
                              </Col>
                              <Col className="p-sm-0 d-flex justify-content-end" xs={24} sm={12} md={12} lg={12}>
                                <Text type="secondary">Dispatched Qty:</Text>
                                <Title level={5}>
                                  {data?.metadata?.dispatchedQty}
                                  {data?.subUnit}
                                </Title>
                              </Col>
                            </Row>
                            <Row className="my-2">
                              <Col className="p-sm-0" xs={24} sm={24} md={24} lg={24}>
                                <Text type="secondary">Order Ref No</Text>
                                <Title level={5}>{formatRefNo(data?.orderRefNo, 'BF')}</Title>
                              </Col>
                            </Row>
                            <div style={{ display: 'flex', alignItems: 'center', maxWidth: '100%' }}>
                              <Progress percent={data?.metadata?.percentCompleted} />
                              <span style={{ fontSize: '13px', marginLeft: '31px', marginTop: '5px' }}>Completed</span>
                            </div>
                            <Divider className="my-2" />
                          </div>
                          <Row>
                            <Col className="cursor-pointer" onClick={() => onViewOrders(data?._id)} xs={0} sm={0} md={8} lg={8}></Col>
                            <Col className="cursor-pointer" onClick={() => onOpenDispatch(data)} xs={0} sm={0} md={8} lg={8}>
                              <div className="d-flex justify-content-center">
                                <Button type="link">
                                  <u>
                                    Create Dispatch
                                    <RightOutlined />
                                  </u>
                                </Button>
                              </div>
                            </Col>
                            <Col className="cursor-pointer" onClick={() => onViewOrders(data?._id)} xs={0} sm={0} md={8} lg={8}></Col>
                          </Row>
                        </Card>
                      </Col>
                    );
                  })}
                </Row>
              ) : (
                ''
              )}
            </Card>
          </Col>
        )}
      </Row>
      <SellNotificationScreen visible={showNotification} close={hideNotification} />
      <CreateOpenListing visible={showOpenListing} close={() => onCreateListing()} />
      <AppTour type={'Sell'} introModal={introModal} setIntroModal={setIntroModal} />
      <UpdateOrderStatus order={currentOrder} visible={dispatchVisible} close={onCloseDispatch} />
    </div>
  );
};

export default SellDasboard;
