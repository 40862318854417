import React from 'react';
import { Card, Tag, Typography, Tooltip } from 'antd';
import { ClockCircleOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import TimeAgo from 'timeago-react';

const { Title, Paragraph } = Typography;

const OpenRFQCard = ({ requirement, filters, sort, search, pagination }) => {
  const navigate = useNavigate();

  const onOpenRFQ = () => {
    localStorage.setItem('filters', JSON.stringify({ filters, sort, search, pagination }));
    navigate(`/sell/open-rfq/${requirement?._id}`);
  };

  return (
    <Card onClick={onOpenRFQ} hoverable bodyStyle={{ padding: '15px', cursor: 'pointer' }}>
      <div>
        <Title level={5} className="fw-medium">
          {requirement.product.name}
        </Title>
        <Paragraph>
          {requirement.fromQty}
          {requirement.toQty > 0 ? ` - ${requirement.toQty}` : ''} {requirement.subUnit} / {requirement.frequency}
        </Paragraph>
      </div>

      <Tooltip placement="right" title={'Expires on ' + new Date(requirement?.metadata?.expiresOn).toDateString()}>
        <Tag color="red" className="my-2">
          <ClockCircleOutlined />
          &nbsp; <TimeAgo datetime={requirement?.metadata?.expiresOn} locale="en_US" />
        </Tag>
      </Tooltip>

      <div className="my-2">
        <Paragraph>{format(new Date(requirement?.preferredDeliveryOn), 'dd MMM yyyy')}</Paragraph>
        <Paragraph type="secondary" className="text-small">
          Delivery Date
        </Paragraph>
      </div>

      <div className="my-2">
        <Paragraph>
          {requirement?.deliveryAddress?.city}, {requirement?.deliveryAddress?.state}
        </Paragraph>
        <Paragraph type="secondary" className="text-small">
          Delivery Location
        </Paragraph>
      </div>

      {/* <Divider dashed={true} className="my-3" />

      <Row gutter={16}>
        <Col span={16}>
          <CompanyName name={"Jayadev Biofuels"} verified={true} />
        </Col>

        <Col flex={1} className="d-flex align-items-center justify-content-end">
          <StarFilled style={{ color: "#EDC200" }} />
          <Text className="ml-1">4.5/5</Text>
        </Col>
      </Row> */}
    </Card>
  );
};

export default OpenRFQCard;
