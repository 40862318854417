import React, { useState } from 'react';
import { Alert, Avatar, Button, Col, Divider, Row, Tag, Typography } from 'antd';
import { format } from 'date-fns';
import { InfoCircleOutlined } from '@ant-design/icons';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import DetailsGrid from 'components/UtilComponents/DetailsGrid';
import NumberText from 'components/UtilComponents/NumberText';
import { useNavigate } from 'react-router-dom';
const { Paragraph, Text } = Typography;

const OffersDetails = ({ listingOffer }) => {
  const { tradingPriceBasis } = listingOffer;
  const navigate = useNavigate();
  const price = listingOffer?.tradingPrice / 100;
  const totalPrice = tradingPriceBasis.price.value / 100;
  const deliveryCharges = tradingPriceBasis.deliveryCharges.value / 100;
  const gst = tradingPriceBasis.gst.value / 100;
  const grandTotal = tradingPriceBasis.total.value / 100;

  const [showMore, setShowMore] = useState(false);
  const onListing = () => navigate(`/buy/listing/${listingOffer?.enquiry?._id}`);

  const onShowMore = () => setShowMore(!showMore);
  return (
    <div className="w-100">
      <Row align="middle" justify="space-between" className="my-3">
        <Text strong style={{ textAlign: 'center' }}>{`${listingOffer?.product?.name} | ${listingOffer?.qty} ${listingOffer?.subUnit} | ${listingOffer?.frequency}${
          listingOffer?.frequency === 'Monthly' ? ` (${listingOffer?.duration} months)` : ''
        }`}</Text>
        <div>
          <Button type="primary" className="my-2" onClick={onListing}>
            View Listing
          </Button>
          <Button type="link" onClick={onShowMore}>
            {showMore ? (
              <>
                Close Details <IoIosArrowUp />
              </>
            ) : (
              <>
                View Details <IoIosArrowDown />
              </>
            )}
          </Button>
        </div>
      </Row>

      <Row gutter={[24, 16]} className="my-4">
        <Col xs={12} sm={12} md={12} lg={6}>
          <DetailsGrid title="Requested Price" description={<NumberText bold value={price} prefix={listingOffer?.currency} suffix={`/ ${listingOffer?.subUnit}`} />} />
        </Col>
        <Col xs={12} sm={12} md={12} lg={6}>
          <DetailsGrid
            title="Quantity Needed"
            description={
              <Paragraph strong>
                {listingOffer?.qty} {listingOffer?.subUnit}
              </Paragraph>
            }
          />
        </Col>
        <Col xs={12} sm={12} md={12} lg={6}>
          <DetailsGrid title="Delivery by" description={<Paragraph strong>{listingOffer?.expectedDeliveryOn ? format(new Date(listingOffer?.expectedDeliveryOn), 'dd MMM yyyy') : '-'}</Paragraph>} />
        </Col>
        <Col xs={12} sm={12} md={12} lg={6}>
          <DetailsGrid
            title="Delivery To"
            description={
              <Paragraph strong>
                {listingOffer?.deliveryAddress?.city}, {listingOffer?.deliveryAddress?.state}
              </Paragraph>
            }
          />
        </Col>
      </Row>

      {showMore && (
        <>
          <Row gutter={[24, 16]} justify="start" className="mb-4">
            <Col xs={24} sm={24} md={16} lg={12}>
              <DetailsGrid icon={<Avatar src={require('assets/images/icon-cash.png')} shape="square" size="small" />} description="Price Basis" />
              <Row justify="space-between" className="mt-3">
                <Paragraph>Price Per Unit</Paragraph>
                <NumberText bold value={price} prefix={listingOffer.currency} />
              </Row>
              <Row justify="space-between" className="my-1">
                <Paragraph>Quantity</Paragraph>
                <Paragraph strong>
                  {listingOffer?.qty} {listingOffer?.subUnit}
                </Paragraph>
              </Row>
              <Divider className="my-0" />
              <Row justify="space-between" className="my-1">
                <Paragraph>{tradingPriceBasis.price.label}</Paragraph>
                <NumberText bold value={totalPrice} prefix={listingOffer.currency} />
              </Row>

              {listingOffer?.enquiry?.transportCharges > 0 ? (
                <Row justify="space-between" className="my-1">
                  <Text>{listingOffer?.tradingPriceBasis?.deliveryCharges?.label}</Text>
                  <NumberText bold value={deliveryCharges} prefix={listingOffer.currency} />
                </Row>
              ) : (
                ''
              )}

              {listingOffer?.enquiry?.gstPercentage > 0 ? (
                <Row justify="space-between" className="my-1">
                  <Paragraph>GST ({listingOffer?.enquiry?.gstPercentage}%)</Paragraph>
                  <NumberText bold value={gst} prefix={listingOffer.currency} />
                </Row>
              ) : (
                <Row justify="space-between" className="my-1">
                  <Paragraph>GST</Paragraph>
                  <Paragraph strong>Included</Paragraph>
                </Row>
              )}

              <Divider className="my-0" />
              <Row justify="space-between" className="my-1">
                <Paragraph>Grand Total (Approx.)</Paragraph>
                <NumberText bold value={grandTotal} prefix={listingOffer.currency} />
              </Row>
              <Divider className="my-0" />
              <Tag icon={<InfoCircleOutlined />} color="#B4B4B4" className="my-3">
                Final invoice amount will be calculated after loading
              </Tag>

              <Paragraph strong>Terms of Payment</Paragraph>
              <Paragraph>{listingOffer?.paymentTerm?.name}</Paragraph>

              <div style={{ marginTop: '30px' }}>
                <Paragraph strong>Additional Information</Paragraph>
                <Paragraph>• Quality Testing - {listingOffer?.qualityTesting}</Paragraph>
                {listingOffer?.remark && <Paragraph>• Quality Remark - {listingOffer?.remark}</Paragraph>}
              </div>
            </Col>
          </Row>
        </>
      )}
      <Row gutter={16}>
        <Col xs={24} sm={24} md={16} lg={12}>
          <Alert
            type={listingOffer.status === 'Rejected' ? 'error' : 'info'}
            showIcon
            message={listingOffer.status === 'Accepted' ? 'Order has been Accepted' : listingOffer.status === 'Rejected' ? 'Order has been Rejected' : 'Order is Yet to be Accepted'}
          />
        </Col>
      </Row>
    </div>
  );
};

export default OffersDetails;
