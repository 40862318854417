import React, { useEffect, useState } from 'react';
import { Alert, Avatar, Button, Card, Col, Divider, List, Row, Tooltip, Typography } from 'antd';
import TimeAgo from 'timeago-react';
import { ClockCircleOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { differenceInDays, format } from 'date-fns';
import DetailsGrid from 'components/UtilComponents/DetailsGrid';
import ExtendRFQExpiration from 'components/Modals/Buy/ExtendRFQExpiration';
import { getBreakPoint } from 'utils/layout';
import { posthog } from 'posthog-js';

const { Text, Title } = Typography;

const MyOpenRFQCard = ({ rfq, onRefresh = () => {} }) => {
  const viewName = 'buy | my-requirements';
  const isMobile = !getBreakPoint().includes('lg');
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);

  const onViewRFQs = () => navigate(`/buy/rfqs/open-rfq/${rfq._id}`);

  const isExpired = rfq?.metadata?.expiresOn ? new Date(rfq?.metadata?.expiresOn) < new Date() : true;
  const expiringIn = rfq?.metadata?.expiresOn ? differenceInDays(new Date(rfq?.metadata?.expiresOn), new Date()) : 0;
  const expiresOn = rfq?.metadata?.expiresOn;
  const preferredDeliveryOn = rfq?.preferredDeliveryOn ? format(new Date(rfq?.preferredDeliveryOn), 'dd MMM yyyy') : '';
  const postedOn = rfq?.createdAt ? format(new Date(rfq?.createdAt), 'dd MMM yyyy') : '';

  const onRepost = (reload) => {
    if (reload) {
      onRefresh();
    }
    setVisible(!visible);
  };

  useEffect(() => {
    if (visible) {
      posthog.capture(`$${viewName} | $extend-open-rfq-clicked`, {
        $set: {
          lastActivityDate: new Date().toISOString(),
        },
      });
    }
  }, [visible]);

  return (
    <List.Item>
      <Card size={isMobile ? 'small' : 'default'}>
        <Row justify="flex-start" align="middle">
          <Col span={12}>
            <Title level={5} className="fw-medium">
              {rfq?.product?.name}
            </Title>
            <Text>
              {rfq?.fromQty}
              {rfq?.toQty ? ` - ${rfq.toQty}` : ''} {rfq?.subUnit}
            </Text>
          </Col>
          <Col span={12} flex={1} className="d-flex justify-content-end">
            {isExpired ? (
              <Button type="link" onClick={() => onRepost()}>
                Repost
              </Button>
            ) : (
              <Button type="primary" onClick={onViewRFQs}>
                View
              </Button>
            )}
          </Col>
        </Row>

        <Row gutter={16} className="mt-3">
          <Col
            style={{
              cursor: rfq?.metadata?.quotesReceived > 0 ? 'pointer' : 'default',
            }}
            onClick={rfq?.metadata?.quotesReceived > 0 && onViewRFQs}
            span={12}>
            <DetailsGrid
              icon={<Avatar src={require('assets/images/icon-quote-received.png')} shape="square" size="small" />}
              title="Total Received"
              description={`${rfq?.metadata?.quotesReceived > 0 ? `${rfq?.metadata?.quotesReceived} Quote${rfq?.metadata?.quotesReceived === 1 ? '' : 's'}` : 'None'}`}
            />
          </Col>
          <Col span={12}>
            <DetailsGrid icon={<Avatar src={require('assets/images/icon-calendar.png')} shape="square" size="small" />} title="Expected Delivery" description={preferredDeliveryOn} />
          </Col>
        </Row>

        <Divider dashed={true} className="my-3" />

        <Row gutter={16} align="middle" justify="space-between">
          <Col span={12}>
            <Text className="ml-1 text-small" type={isExpired && 'danger'}>
              <ClockCircleOutlined />{' '}
              {!isExpired ? (
                <Tooltip placement="right" title={new Date(expiresOn).toDateString()}>
                  Expires <TimeAgo datetime={expiresOn} locale="en_US" />
                </Tooltip>
              ) : (
                'Expired'
              )}
            </Text>
          </Col>
          <Col span={12}>
            <Text className="text-small float-right">Posted on {postedOn}</Text>
          </Col>
        </Row>
        {!isExpired && expiringIn <= 3 && (
          <Row gutter={16} className="mt-3">
            <Col span={24}>
              <Alert
                className="py-1 px-2 text-small"
                message={<Text type="danger">Expiring Soon</Text>}
                type="error"
                showIcon={true}
                icon={<ClockCircleOutlined />}
                action={
                  <Button type="link" size="small" onClick={() => onRepost()}>
                    Extend Expiration
                  </Button>
                }
              />
            </Col>
          </Row>
        )}
      </Card>
      <ExtendRFQExpiration visible={visible} close={onRepost} rfq={rfq} />
    </List.Item>
  );
};

export default MyOpenRFQCard;
