import React from 'react';
import { PageHeader, Tabs } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import CreatedOffers from './CreatedOffers';
import AcceptedOffers from './AcceptedOffers';
import RejectedOffers from './RejectedOffers';
import BackIcon from 'components/UtilComponents/BackIcon';
import ErrorBoundary from 'components/Errors/ErrorBoundary';

const Offers = () => {
  const { tab } = useParams();
  const navigate = useNavigate();

  const tabItems = [
    // remember to pass the key prop
    {
      label: 'Pending',
      key: 'pending-offers',
      children: <CreatedOffers tab={tab} navigate={navigate} />,
    },
    {
      label: 'Accepted',
      key: 'accepted-offers',
      children: <AcceptedOffers tab={tab} navigate={navigate} />,
    },
    {
      label: 'Rejected',
      key: 'rejected-offers',
      children: <RejectedOffers tab={tab} navigate={navigate} />,
    },
  ];

  return (
    <div className="app-container">
      <PageHeader onBack={() => navigate('/buy')} backIcon={<BackIcon />} title="My Offers" className="px-0" />

      <ErrorBoundary>
        <Tabs type="line" items={tabItems} defaultActiveKey={tab} onChange={(key) => navigate(`/buy/offers/${key}`)} />
      </ErrorBoundary>
    </div>
  );
};

export default Offers;
