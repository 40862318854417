import React from 'react';
import { Avatar } from 'antd';
import { Colors } from 'constants/ColorConstants';

const BackIcon = ({ onClick }) => {
  return (
    <Avatar
      src={require('assets/images/icon-back.png')}
      shape="circle"
      onClick={onClick}
      style={{
        backgroundColor: Colors.white,
        border: `0.5px solid ${Colors.black}`,
        padding: '8px',
        cursor: 'pointer',
      }}
    />
  );
};

export default BackIcon;
