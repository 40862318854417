import React from 'react';
import { Divider, PageHeader, Tabs } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import UpcomingDispatch from './UpcomingDispatch';
import CompletedDispatch from './CompletedDispatch';

const Dispatches = () => {
  const { tab } = useParams();
  const navigate = useNavigate();

  const tabItems = [
    // remember to pass the key prop
    {
      label: 'Ongoing',
      key: 'ongoing-dispatches',
      children: <UpcomingDispatch tab={tab} />,
    },
    {
      label: 'Completed',
      key: 'completed-dispatches',
      children: <CompletedDispatch tab={tab} />,
    },
  ];

  return (
    <div className="app-container">
      <PageHeader onBack={() => null} backIcon={null} title="Dispatches" className="py-sm-4 page-header" />

      <Tabs className="my-4" type="line" items={tabItems} defaultActiveKey={tab} onChange={(key) => navigate(`/dispatches/${key}`)} />

      <Divider />
    </div>
  );
};

export default Dispatches;
