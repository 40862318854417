import React, { useEffect, useState } from 'react';
import { Form, Row, Col, Alert, Checkbox, Button, PageHeader, Switch, Card, Divider, message } from 'antd';
import { MailOutlined, MobileOutlined, WhatsAppOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { isEmpty } from 'lodash';
import axios from 'axios';
import { getBreakPoint } from 'utils/layout';
import { USER_URL } from 'constants/ApiConstants';
import Icon from 'components/UtilComponents/Icon';
import NotificationCheckbox from './NotificationCheckbox';
import BackIcon from 'components/UtilComponents/BackIcon';

const ModifyUser = () => {
  const isMobile = !getBreakPoint().includes('sm');
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [selectAllComm, setSelectAllComm] = useState();
  const [notificationSwitch, setNotificationSwitch] = useState(true);
  const [notificationPreference, setNotificationPreference] = useState({});
  const [frequency, setFrequency] = useState('Weekly');
  const [selectAllChecked, setSelectAllChecked] = useState({ email: true, push: true, whatsapp: true });
  const [indeterminate, setIndeterminate] = useState({ email: true, push: true, whatsapp: true });

  const fields = [
    { label: 'Order', field: 'order', extra: 'Get reminder notifications for Order Confirmation, Order dispatch, Order Delivered' },
    { label: 'Dispatch', field: 'dispatch', extra: 'Get information about New Dispatch' },
    { label: 'Open Rfq', field: 'openRfq', extra: 'Get reminder notifications for New Open RFQ, New quote received' },
    { label: 'Open Listing', field: 'openListing', extra: 'Get reminder notifications for New Listing, New Order received' },
    { label: 'Payments', field: 'payments', extra: 'Get information about New Payment Issued, Pending payments' },
    { label: 'Sustainability Report', field: 'sustainabilityReport', extra: 'Get reminder notifications for Sustainability Report', isFrequency: true },
    { label: 'Others', field: 'others', extra: 'Get information about Login & Logout from other devices and know about app updates.' },
  ];
  const medium = [
    { type: 'Email', field: 'email', color: 'red', icon: MailOutlined },
    { type: 'Push', field: 'push', color: 'grey', icon: MobileOutlined },
    { type: 'Whatsapp', field: 'whatsapp', color: 'green', icon: WhatsAppOutlined },
  ];

  useEffect(() => {
    onLoadNotificationPreference();
  }, []);

  useEffect(() => {
    if (!isEmpty(notificationPreference)) {
      setNotificationSwitch(notificationPreference.allowNotification);
      setFrequency(notificationPreference.sustainabilityReport.frequency);
      form.setFields([
        { name: 'order', value: obj2Array('order') },
        { name: 'dispatch', value: obj2Array('dispatch') },
        { name: 'openListing', value: obj2Array('openListing') },
        { name: 'openRfq', value: obj2Array('openRfq') },
        { name: 'payments', value: obj2Array('payments') },
        { name: 'sustainabilityReport', value: obj2Array('sustainabilityReport') },
        { name: 'others', value: obj2Array('others') },
      ]);
      const counts = countTrueValue(notificationPreference);
      setSelectAllChecked({ email: counts.email === fields.length, push: counts.push === fields.length, whatsapp: counts.whatsapp === fields.length });
      setIndeterminate({
        email: counts.email > 0 && counts.email < fields.length,
        push: counts.push > 0 && counts.push < fields.length,
        whatsapp: counts.whatsapp > 0 && counts.length < fields.length,
      });
    }
  }, [notificationPreference]);

  useEffect(() => {
    if (!notificationSwitch) {
      form.setFields([
        { name: 'order', value: [] },
        { name: 'dispatch', value: [] },
        { name: 'openListing', value: [] },
        { name: 'openRfq', value: [] },
        { name: 'payments', value: [] },
        { name: 'sustainabilityReport', value: [] },
        { name: 'others', value: [] },
      ]);
      setSelectAllChecked({ email: false, push: false, whatsapp: false });
    }
  }, [notificationSwitch]);

  const onLoadNotificationPreference = async () => {
    setLoading(true);
    try {
      const res = await axios.get(`${USER_URL}/me`);
      if (res.data.status === 'error') {
        message.error(res.data.error);
      } else {
        setNotificationPreference(res.data.user.notification);
      }
    } catch (err) {
      message.error(err.message);
    }
    setLoading(false);
  };

  const obj2Array = (field) => {
    return Object.keys(notificationPreference[field]).filter((key) => notificationPreference[field][key] === true);
  };

  const array2Obj = (values) => {
    const convertedObj = {};
    for (const key in values) {
      if (Object.hasOwnProperty.call(values, key)) {
        convertedObj[key] = {};
        values[key].forEach((option) => {
          convertedObj[key][option] = true;
        });
        convertedObj[key]['email'] = convertedObj[key]['email'] || false;
        convertedObj[key]['push'] = convertedObj[key]['push'] || false;
        convertedObj[key]['whatsapp'] = convertedObj[key]['whatsapp'] || false;
      }
    }
    return convertedObj;
  };

  const countTrueValue = (notificationPreference) => {
    const counts = { email: 0, push: 0, whatsapp: 0 };
    for (let category in notificationPreference) {
      if (category !== 'allowNotification') {
        for (let medium in notificationPreference[category]) {
          if (notificationPreference[category][medium] === true) {
            counts[medium]++;
          }
        }
      }
    }
    return counts;
  };

  const handleSelectAllComm = (type) => (e) => {
    const checked = e.target.checked;
    if (type === 'all') {
      const updatedSelectAllComm = { ...selectAllComm, email: checked, push: checked, whatsapp: checked };
      setSelectAllComm(updatedSelectAllComm);
      setSelectAllChecked({ ...selectAllChecked, email: checked, push: checked, whatsapp: checked });
    } else {
      const updatedSelectAllComm = { ...selectAllComm, [type]: checked };
      setSelectAllComm(updatedSelectAllComm);
      setSelectAllChecked({ ...selectAllChecked, [type]: checked });
    }
  };

  const onUpdate = () => {
    if (loading) return false;
    if (!isEmpty(notificationPreference)) {
      const values = form.getFieldsValue();
      const notificationPreference = array2Obj(values);
      const counts = countTrueValue(notificationPreference);
      setSelectAllChecked({ email: counts.email === fields.length, push: counts.push === fields.length, whatsapp: counts.whatsapp === fields.length });
      setIndeterminate({
        email: counts.email > 0 && counts.email < fields.length,
        push: counts.push > 0 && counts.push < fields.length,
        whatsapp: counts.whatsapp > 0 && counts.whatsapp < fields.length,
      });
    }
  };

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const convertedObj = array2Obj(values);
      convertedObj.sustainabilityReport.frequency = frequency;
      const data = {
        notification: {
          allowNotification: notificationSwitch,
          ...convertedObj,
        },
      };
      const res = await axios.put(`${USER_URL}/me`, data);
      if (res.data.status === 'error') {
        message.error(res.data.error);
      } else {
        onLoadNotificationPreference();
        message.success('Notification Preferences Updated Succesfully.');
      }
    } catch (err) {
      message.error(err.message);
    }
    setLoading(false);
    navigate(-1);
  };

  return (
    <div className="app-container">
      <Alert.ErrorBoundary message="Oops!" description="Something went wrong">
        <PageHeader
          onBack={() => navigate('/my-account')}
          tags={
            <Switch
              checked={notificationSwitch}
              onChange={(e) => {
                setNotificationSwitch(e);
              }}
            />
          }
          backIcon={<BackIcon />}
          title="Notification Preferences"
          className="px-0"
        />
        <Card
          title={
            isMobile ? (
              <Card
                title={
                  <Checkbox
                    indeterminate={indeterminate?.email && indeterminate?.push && indeterminate?.whatsapp}
                    checked={selectAllChecked?.email && selectAllChecked?.push && selectAllChecked?.whatsapp}
                    onChange={handleSelectAllComm('all')}
                    disabled={!notificationSwitch}>
                    <b>All Notifications</b>
                  </Checkbox>
                }>
                {medium &&
                  medium.map((el, index) => (
                    <>
                      <Row>
                        <Col span={22} key={el.field}>
                          {el.type}
                        </Col>
                        <Col span={2}>
                          <Checkbox indeterminate={indeterminate[el.field]} onChange={handleSelectAllComm(el.field)} checked={selectAllChecked[el.field]} disabled={!notificationSwitch} />
                        </Col>
                      </Row>
                      {index < medium.length - 1 && <Divider dashed={true} />}
                    </>
                  ))}
              </Card>
            ) : (
              <Row gutter={16}>
                <Col xs={8}></Col>
                <Col xs={16}>
                  <Row>
                    {medium &&
                      medium.map((el) => (
                        <Col span={8} key={el.type}>
                          <Icon type={el.icon} color={el.color} className="mr-2" />
                          {!isMobile && <span>{el.type}</span>}
                        </Col>
                      ))}
                  </Row>
                </Col>
              </Row>
            )
          }>
          <Form
            form={form}
            name="admin"
            layout="vertical"
            onFinish={onFinish}
            scrollToFirstError={{
              behavior: 'smooth',
              block: 'center',
              inline: 'center',
            }}>
            <Row gutter={[16, 16]}>
              <Col xs={24}>
                {fields &&
                  fields.map((el) => (
                    <div key={el.field}>
                      <Form.Item noStyle shouldUpdate={(prevValues, curValues) => prevValues[el.field] !== curValues[el.field]}>
                        {() => {
                          return (
                            <Form.Item name={el.field} noStyle>
                              <NotificationCheckbox
                                label={el.label}
                                field={el.field}
                                extra={el.extra}
                                form={form}
                                isMobile={isMobile}
                                selected={form.getFieldValue([el.field])}
                                selectAllComm={selectAllComm}
                                disabled={!notificationSwitch}
                                onUpdate={() => onUpdate()}
                                setFrequency={setFrequency}
                                frequency={frequency}
                                isFrequency={el.isFrequency}
                              />
                            </Form.Item>
                          );
                        }}
                      </Form.Item>
                      <Divider dashed={isMobile ? false : true} />
                    </div>
                  ))}
                {!isMobile && (
                  <>
                    <Row gutter={16}>
                      <Col xs={8}>
                        <Checkbox
                          indeterminate={indeterminate?.email && indeterminate?.push && indeterminate?.whatsapp}
                          checked={selectAllChecked?.email && selectAllChecked?.push && selectAllChecked?.whatsapp}
                          onChange={handleSelectAllComm('all')}
                          disabled={!notificationSwitch}>
                          Select All
                        </Checkbox>
                      </Col>
                      <Col xs={16}>
                        <Row>
                          {medium &&
                            medium.map((el) => (
                              <Col span={8} key={el.field}>
                                <Checkbox indeterminate={indeterminate[el.field]} onChange={handleSelectAllComm(el.field)} checked={selectAllChecked[el.field]} disabled={!notificationSwitch}>
                                  Select All
                                </Checkbox>
                              </Col>
                            ))}
                        </Row>
                      </Col>
                    </Row>
                    <Divider />
                  </>
                )}
                <Form.Item>
                  <Row justify="end" gutter={16}>
                    <Col>
                      <Button loading={loading} onClick={() => onLoadNotificationPreference()}>
                        Cancel
                      </Button>
                    </Col>
                    <Col>
                      <Button type="primary" htmlType="submit" loading={loading}>
                        Save
                      </Button>
                    </Col>
                  </Row>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </Alert.ErrorBoundary>
    </div>
  );
};
export default ModifyUser;
