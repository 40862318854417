import React, { useEffect, useState } from 'react';
import { Alert, Avatar, Button, Col, Divider, Image, List, PageHeader, Row, Space, Tag, Typography, message, Tooltip } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { ClockCircleOutlined, FileImageOutlined, FileTextOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import BackIcon from 'components/UtilComponents/BackIcon';
import TimeAgo from 'timeago-react';
import DetailsGrid from 'components/UtilComponents/DetailsGrid';
import CreateOrder from 'components/Modals/Buy/CreateOrder';
import { BUSINESS_URL, LISTING_OFFER_URL, LISTING_URL, OPEN_MERCHANT_NAME, UPLOAD_URL } from 'constants/ApiConstants';
import axios from 'axios';
import { format } from 'date-fns';
import NumberText from 'components/UtilComponents/NumberText';
import CompanyName from 'components/UtilComponents/CompanyName';
import Loader from 'components/UtilComponents/Loader';
import { AccessDenied, PageNotFound } from 'components/Errors';
import { posthog } from 'posthog-js';

const { Title, Text, Paragraph } = Typography;

const MyOffers = ({ listingOffer }) => {
  const { tradingPriceBasis } = listingOffer;

  const price = listingOffer?.tradingPrice / 100;
  const totalPrice = tradingPriceBasis.price.value / 100;
  const deliveryCharges = tradingPriceBasis.deliveryCharges.value / 100;
  const gst = tradingPriceBasis.gst.value / 100;
  const grandTotal = tradingPriceBasis.total.value / 100;

  const [showMore, setShowMore] = useState(false);

  const onShowMore = () => setShowMore(!showMore);

  return (
    <div className="w-100">
      <Row align="middle" justify="space-between" className="my-3">
        <CompanyName showLogo={false} name={OPEN_MERCHANT_NAME} verified={true} />
        <Button type="link" onClick={onShowMore}>
          {showMore ? (
            <>
              Close Details <IoIosArrowUp />
            </>
          ) : (
            <>
              View Details <IoIosArrowDown />
            </>
          )}
        </Button>
      </Row>

      <Row gutter={[24, 16]} className="my-4">
        <Col xs={12} sm={12} md={12} lg={6}>
          <DetailsGrid title="Requested Price" description={<NumberText bold value={price} prefix={listingOffer?.currency} suffix={`/ ${listingOffer?.subUnit}`} />} />
        </Col>
        <Col xs={12} sm={12} md={12} lg={6}>
          <DetailsGrid
            title="Quantity Needed"
            description={
              <Text strong>
                {listingOffer?.qty} {listingOffer?.subUnit}
              </Text>
            }
          />
        </Col>
        <Col xs={12} sm={12} md={12} lg={6}>
          <DetailsGrid title="Delivery by" description={<Text strong>{listingOffer?.expectedDeliveryOn ? format(new Date(listingOffer?.expectedDeliveryOn), 'dd MMM yyyy') : '-'}</Text>} />
        </Col>
        <Col xs={12} sm={12} md={12} lg={6}>
          <DetailsGrid
            title="Delivery To"
            description={
              <Text strong>
                {listingOffer?.deliveryAddress?.city}, {listingOffer?.deliveryAddress?.state}
              </Text>
            }
          />
        </Col>
      </Row>

      {showMore && (
        <>
          <Row gutter={[24, 16]} justify="start" className="mb-4">
            <Col xs={24} sm={24} md={16} lg={12}>
              <DetailsGrid icon={<Avatar src={require('assets/images/icon-cash.png')} shape="square" size="small" />} description="Price Basis" />
              <Row justify="space-between" className="mt-3">
                <Text>Price Per Unit</Text>
                <NumberText bold value={price} prefix={listingOffer.currency} />
              </Row>
              <Row justify="space-between" className="my-1">
                <Text>Quantity</Text>
                <Text strong>
                  {listingOffer?.qty} {listingOffer?.subUnit}
                </Text>
              </Row>
              <Divider className="my-0" />
              <Row justify="space-between" className="my-1">
                <Text>{tradingPriceBasis.price.label}</Text>
                <NumberText bold value={totalPrice} prefix={listingOffer.currency} />
              </Row>
              {listingOffer?.enquiry?.transportCharges > 0 ? (
                <Row justify="space-between" className="my-1">
                  <Text>{listingOffer?.tradingPriceBasis?.deliveryCharges?.label}</Text>
                  <NumberText bold value={deliveryCharges} prefix={listingOffer.currency} />
                </Row>
              ) : (
                ''
              )}
              {listingOffer?.enquiry?.gstPercentage > 0 ? (
                <Row justify="space-between" className="my-1">
                  <Text>GST ({listingOffer?.enquiry?.gstPercentage}%)</Text>
                  <NumberText bold value={gst} prefix={listingOffer.currency} />
                </Row>
              ) : (
                <Row justify="space-between" className="my-1">
                  <Text>GST</Text>
                  <Text strong>Included</Text>
                </Row>
              )}
              <Divider className="my-0" />
              <Row justify="space-between" className="my-1">
                <Text>Grand Total (Approx.)</Text>
                <NumberText bold value={grandTotal} prefix={listingOffer.currency} />
              </Row>
              <Divider className="my-0" />
              <Tag icon={<InfoCircleOutlined />} color="#B4B4B4" className="my-3">
                Final invoice amount will be calculated after loading
              </Tag>
              <Paragraph strong>Terms of Payment</Paragraph>
              <Text>{listingOffer?.paymentTerm?.name}</Text>
              <Paragraph strong>Quality Testing</Paragraph>
              <Text>{listingOffer?.qualityTesting}</Text>
              {listingOffer?.remark && (
                <>
                  <Paragraph strong>Quality Remarks</Paragraph>
                  <Text>{listingOffer?.remark}</Text>
                </>
              )}
            </Col>
          </Row>
        </>
      )}

      <Row gutter={[24, 16]}>
        <Col xs={24} sm={24} md={16} lg={12}>
          <Alert
            type={listingOffer.status === 'Rejected' ? 'error' : 'info'}
            showIcon
            message={listingOffer.status === 'Accepted' ? 'Order has been Accepted' : listingOffer.status === 'Rejected' ? 'Order has been Rejected' : 'Order is Yet to be Accepted'}
          />
        </Col>
      </Row>
    </div>
  );
};

const ListingDetails = () => {
  const viewName = 'buy | listing-details';
  const { id } = useParams();
  const navigate = useNavigate();
  const [listing, setListing] = useState(null);
  const [listingOffers, setListingOffers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [accessDenied, setAccessDenied] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [showOrder, setShowOrder] = useState(false);

  const onCreateOrder = (reload) => {
    if (reload) {
      onLoad();
    }
    setShowOrder(!showOrder);
  };

  useEffect(() => {
    posthog.capture(`$screenview | $${viewName}`, {
      data: {
        listingId: id,
      },
      $set: {
        lastActivityDate: new Date().toISOString(),
      },
    });
    onLoad();
  }, [id]);

  const onLoad = async () => {
    setLoading(true);
    try {
      // TODO: Added business url temporarily. Remove once buyer: ['me'] filter is implemented for fetch listing offer route
      const businessRes = await axios.get(`${BUSINESS_URL}/me`);
      const res = await axios.get(`${LISTING_URL}/${id}?&populate=true`);
      const offerRes = await axios.get(`${LISTING_OFFER_URL}?&populate=true`, {
        headers: {
          'X-API-Filters': JSON.stringify({
            enquiry: [id],
            buyer: [businessRes?.data?.business._id], // TODO: Not implemented yet in the backend
          }),
        },
      });

      // if open listing's seller is same as current business then deny access.
      if (res.data?.openlisting?.seller?._id === businessRes.data?.business?._id) {
        setAccessDenied(true);
        return setLoading(false);
      }
      // if open listing id is invalid, display not found component.
      if (res.data.code === 1006 || res.data.code === 1007) {
        setNotFound(true);
        return setLoading(false);
      }

      if (res.data.status === 'error') {
        message.error(res.data.error);
      } else if (offerRes.data.status === 'error') {
        message.error(offerRes.data.error);
      } else {
        setListing(res.data.openlisting);
        setListingOffers(offerRes.data.openlistingoffers);
      }
    } catch (err) {
      message.error(err.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (showOrder) {
      posthog.capture(`$${viewName} | $send-offer-attempted`, {
        data: {
          listingId: id,
        },
        $set: {
          lastActivityDate: new Date().toISOString(),
        },
      });
    }
  }, [showOrder]);

  if (loading) return <Loader />;

  if (accessDenied) return <AccessDenied />;

  if (notFound) return <PageNotFound />;

  return (
    <>
      <div className="app-container">
        <PageHeader onBack={() => navigate(-1)} backIcon={<BackIcon />} title={`Listing | ${listing?.product?.name} | ${listing?.qty} ${listing?.subUnit}`} className="px-0" />
      </div>
      <Divider className="mt-1 mb-6" />
      <div className="app-container">
        {!loading && (
          <Row gutter={[24, 16]}>
            <Col xs={24} sm={24} md={10} lg={10}>
              <Image
                alt="example"
                style={{
                  width: '100%',
                  height: '300',
                  objectFit: 'cover',
                  borderRadius: '15px',
                }}
                src={`${UPLOAD_URL}/${listing?.product?.image}`}
              />
              <Tooltip placement="right" title={'Expires on ' + new Date(listing?.metadata?.expiresOn).toDateString()}>
                <Tag color="red" style={{ position: 'absolute', top: 16, right: 16 }}>
                  <ClockCircleOutlined />
                  &nbsp; Expires&nbsp;
                  <TimeAgo datetime={listing.metadata?.expiresOn} locale="en_US" />
                </Tag>
              </Tooltip>
            </Col>

            <Col xs={24} sm={24} md={14} lg={14}>
              <Title level={3} className="fw-bold">
                {listing?.product?.name}
              </Title>
              <Title level={4} className="fw-medium">
                <NumberText prefix={listing?.currency} value={listing?.tradingPrice / 100} suffix={`/ ${listing?.subUnit}`} />
              </Title>

              <Row className="my-3">
                {listing?.metadata?.isNegotiable && (
                  <Tag
                    className="rounded px-2 py-1 border-0"
                    color="cyan"
                    icon={<Avatar src={require('assets/images/icon-price-negotiable-green.png')} shape="square" size={20} className="rounded-0" />}>
                    &nbsp;Price is Negotiable
                  </Tag>
                )}
                <Tag className="rounded px-2 py-1 border-0" color="gold" icon={<Avatar src={require('assets/images/icon-transport-yellow.png')} shape="square" size={16} className="rounded-0" />}>
                  &nbsp;
                  {listing?.transportCharges > 0
                    ? `Delivery Charges Extra (${listing?.currency === 'USD' ? '$' : '₹'}${listing?.transportCharges / 100} / ${listing?.subUnit})`
                    : `Delivery ${listing?.transportType}`}
                </Tag>
                <Tag className="rounded px-2 py-1 border-0" color="gold" icon={<Avatar src={require('assets/images/icon-gst-yellow.png')} shape="square" size={16} className="rounded-0" />}>
                  &nbsp;{listing?.gstPercentage > 0 ? `GST Extra (${listing?.gstPercentage}%)` : 'GST Included'}
                </Tag>
              </Row>

              <Paragraph className="fw-medium" type="secondary">
                DETAILS
              </Paragraph>
              <Space direction="vertical" className="my-3" style={{ width: '100%' }}>
                <Row gutter={[16, 16]} className="mt-2">
                  <Col span={12}>
                    <DetailsGrid
                      icon={<Avatar src={require('assets/images/icon-quantity.png')} shape="square" size="default" />}
                      title="Available Quantity"
                      description={
                        <Text strong>
                          {listing?.qty} {listing?.subUnit} {listing?.frequency === 'Once' && '/ Once'}
                        </Text>
                      }
                    />
                  </Col>
                  {listing?.frequency === 'Monthly' && (
                    <Col span={12}>
                      <DetailsGrid title="Available For" description={`${listing.duration} Months`} />
                    </Col>
                  )}
                </Row>

                <Divider className="my-3" />

                <Row gutter={[16, 16]} align="middle">
                  <Col span={12}>
                    <DetailsGrid
                      icon={<Avatar src={require('assets/images/icon-location-dark.png')} shape="square" size="default" />}
                      title="Pickup Location"
                      description={`${listing?.pickupAddress?.city}, ${listing?.pickupAddress?.state}`}
                    />
                  </Col>
                  {listing?.preferredDeliveryOn && (
                    <Col span={12}>
                      <DetailsGrid title="Preferred Delivery On" description={format(new Date(listing?.preferredDeliveryOn), 'dd MMM yyyy')} />
                    </Col>
                  )}
                </Row>

                <Divider className="my-3" />

                <DetailsGrid icon={<Avatar src={require('assets/images/icon-cash.png')} shape="square" size="default" />} title="Payment Terms" description={listing?.paymentTerm?.name} />

                <Divider className="my-3" />

                <DetailsGrid icon={<Avatar src={require('assets/images/icon-quality.png')} shape="square" size="default" />} title="Quality Remarks" description={listing?.remark || 'N/A'} />

                <Row gutter={[16, 8]} align="middle" className="my-2">
                  {listing?.qualityReportFile && (
                    <Col xs={24} sm={12} md={12} lg={12}>
                      <Button type="link" href={`${UPLOAD_URL}/${listing?.qualityReportFile}`} icon={<FileTextOutlined />} target="_blank">
                        View Quality Report
                      </Button>
                    </Col>
                  )}
                  {listing?.productImageFile && (
                    <Col xs={24} sm={12} md={12} lg={12}>
                      <Button type="link" href={`${UPLOAD_URL}/${listing?.productImageFile}`} icon={<FileImageOutlined />} target="_blank">
                        View Product Image
                      </Button>
                    </Col>
                  )}
                </Row>
              </Space>

              <Button type="primary" size="large" block className="my-4" onClick={() => onCreateOrder()}>
                Create Order
              </Button>
            </Col>
          </Row>
        )}

        {listingOffers?.length > 0 && (
          <>
            <Divider />
            <Text strong type="secondary">
              YOUR ORDERS
            </Text>
            <List
              header={null}
              footer={null}
              grid={{ gutter: 16, column: 1 }}
              dataSource={listingOffers}
              pagination={false}
              split={false}
              renderItem={(listingOffer) => (
                <List.Item key={listingOffer?._id}>
                  {/* Please find the component declared at top this file */}
                  <MyOffers listing={listing} listingOffer={listingOffer} />
                  <Divider />
                </List.Item>
              )}
            />
          </>
        )}

        <CreateOrder listingOffers={listingOffers} listing={listing} visible={showOrder} close={onCreateOrder} />
      </div>
    </>
  );
};

export default ListingDetails;
