import React, { useEffect, useState } from 'react';
import { Button, Col, Divider, Form, Input, Modal, Row, Tag, Typography, message } from 'antd';
import ModalCloseIcon from 'components/UtilComponents/ModalCloseIcon';
import { PlusOutlined } from '@ant-design/icons';
import { SAVED_ADDRESS_URL } from 'constants/ApiConstants';
import axios from 'axios';
import AddNewAddress from 'components/UtilComponents/AddNewAddress';
import ErrorBoundary from 'components/Errors/ErrorBoundary';

const { Text, Paragraph } = Typography;

const SavedAddressess = ({ visible, close }) => {
  const [form] = Form.useForm();
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showAddAddress, setShowAddAddress] = useState(false);
  const [selected, setSelected] = useState({});

  const [step, setStep] = useState('list');
  const [type, setType] = useState('');

  useEffect(() => {
    onLoad();
  }, []);

  useEffect(() => {
    onLoad();
  }, [showAddAddress]);

  const onLoad = async () => {
    if (loading) return;
    setLoading(true);
    try {
      const res = await axios.get(`${SAVED_ADDRESS_URL}?&populate=true`, {
        headers: {
          'X-API-Filters': JSON.stringify({
            business: ['me'],
          }),
        },
      });
      if (res.data.status === 'error') {
        message.error(res.data.error);
      } else {
        setDataSource(res.data.savedaddresses);
      }
    } catch (err) {
      message.error(err.message);
    }
    setLoading(false);
  };

  const onAddNewLocation = (values) => {
    console.log('Finish:', values);
  };

  const getTitle = () => {
    if (step === 'map' && type === 'new') return 'Select Location';
    if (step === 'map' && type === 'edit') return 'Edit Location';
    if (step === 'details' && type === 'new') return 'Add New Address Details';
    if (step === 'details' && type === 'edit') return 'Edit Address Details';
    return 'Saved Addresses';
  };

  const onClose = () => {
    setStep('list');
    setType('');
    close();
  };

  const onToggleAddAddress = (address) => {
    setSelected(address);
    setShowAddAddress(!showAddAddress);
  };

  const onDeleteAddress = async (addressId, name) => {
    Modal.confirm({
      title: `Are you sure you want to delete "${name}" address?`,
      content: 'You will not be able to undo after you delete it. You can add it again from saved addresses.',
      icon: null,
      closable: true,
      closeIcon: <ModalCloseIcon />,
      okText: 'Delete',
      cancelText: 'Cancel',
      cancelButtonProps: {
        type: 'primary',
        ghost: true,
      },
      onCancel() {},
      okButtonProps: {
        loading: loading,
      },
      onOk: async () => {
        if (loading) return;
        setLoading(true);
        try {
          const res = await axios.delete(`${SAVED_ADDRESS_URL}/${addressId}`, {
            deleted: true,
          });
          if (res.data.status === 'error') {
            message.error(res.data.error);
          } else {
            onLoad();
            message.success('Address Deleted Successfully');
          }
        } catch (err) {
          message.error(err.message);
        }
        setLoading(false);
      },
    });
  };

  return (
    <Modal
      destroyOnClose={true}
      title={getTitle()}
      open={visible}
      onCancel={onClose}
      footer={null}
      closeIcon={<ModalCloseIcon />}
      width={480}
      bodyStyle={{ height: '70vh', overflow: 'scroll' }}
      centered>
      <ErrorBoundary>
        {step === 'list' && (
          <>
            <Row align="middle" justify="space-between">
              <Col>
                <Text level={5} className="fw-medium">
                  Want to add a new address?
                </Text>
              </Col>
              <Col>
                <Button type="link" block icon={<PlusOutlined />} onClick={() => onToggleAddAddress()}>
                  Add New Address
                </Button>
              </Col>
            </Row>
            <Divider />

            {dataSource.map((data) => {
              return (
                <div className="mb-3" key={data?._id}>
                  <Text level={5} className="fw-medium">
                    {data.name} {data.type === 'Registered' && <Tag color="blue">Registered Address</Tag>}
                  </Text>
                  <Paragraph>
                    {data.line1},{data.line2 ? ` ${data.line2}, ` : ''} {data.city}, {data.state}, {data.country} - {data.pincode}
                  </Paragraph>

                  <Row className="my-3" align="middle" justify="space-between">
                    <Col xs={12} sm={12} md={12} lg={12} align="middle">
                      <Button type="primary" ghost block onClick={() => onToggleAddAddress(data)}>
                        Edit Address
                      </Button>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12} align="middle">
                      <Button block type="link" disabled={data.type === 'Registered'} onClick={() => onDeleteAddress(data?._id, data?.name)}>
                        Delete
                      </Button>
                    </Col>
                  </Row>
                  <Divider dashed={true} className="my-3" />
                </div>
              );
            })}
          </>
        )}

        {step === 'map' && (
          <>
            <p>Map Container</p>
            <Button type="primary" size="large" block onClick={() => setStep('details')}>
              Confirm Location
            </Button>
          </>
        )}

        {step === 'details' && (
          <Form form={form} layout="vertical" onFinish={onAddNewLocation}>
            <Form.Item name="locationName" rules={[{ required: true, message: 'Please enter the location name' }]} hasFeedback>
              <Input size="large" placeholder="Name for future reference" allowClear />
            </Form.Item>
            <Form.Item
              name="siteNo"
              rules={[
                {
                  required: true,
                  message: 'Please enter the site no./building no.',
                },
              ]}
              hasFeedback>
              <Input size="large" placeholder="Site No. / Building No." allowClear />
            </Form.Item>
            <Form.Item name="streetName" rules={[{ required: true, message: 'Please enter the street/area' }]} hasFeedback>
              <Input size="large" placeholder="Street Area" allowClear />
            </Form.Item>
            <Form.Item name="email" rules={[{ required: true, message: 'Please enter the location name' }]} hasFeedback>
              <Input size="large" placeholder="Locality / Landmark" allowClear />
            </Form.Item>

            <Form.Item className="mt-4">
              <Button
                size="large"
                type="primary"
                block
                // htmlType="submit"
                onClick={() => {}}>
                Save Address
              </Button>
            </Form.Item>
          </Form>
        )}
      </ErrorBoundary>

      <AddNewAddress visible={showAddAddress} close={onToggleAddAddress} selected={selected} />
    </Modal>
  );
};

export default SavedAddressess;
